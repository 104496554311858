import React from 'react';
import { VictoryPie } from 'victory-pie';
import { VictoryLabel, VictoryAccessibleGroup } from 'victory-core';
import { VictoryLegend } from 'victory-legend';
import { VictoryTooltip } from 'victory-tooltip';

import { Flex, SkeletonCircle } from '@chakra-ui/react';

import useAttendanceStats from '../hooks/useAttendanceDonutStats';
import type { AllStatsType } from '../hooks/useClientsDashboardStats';

const colorScale = [
  'var(--chakra-colors-brand-blue2)',
  'var(--chakra-colors-brand-orange1)',
  'var(--chakra-colors-brand-gray5)',
];

const chartLabels = {
  ATTENDED: 'Attended',
  MISSED: 'Missed',
  BLANK: 'No outcome',
};

const toData = (stats: Record<keyof typeof chartLabels, number>) =>
  Object.entries(stats).map(([key, value]) => ({
    name: chartLabels[key as keyof typeof chartLabels],
    value,
  }));

interface AttendanceDonutProps {
  allStats: AllStatsType;
  loading: boolean;
}
function AttendanceDonut({
  allStats,
  loading,
}: AttendanceDonutProps): JSX.Element {
  const { stats } = useAttendanceStats({ allStats });
  const { ATTENDED, MISSED, BLANK, TOTAL } = stats;

  const attendedRate = Math.round((ATTENDED / TOTAL) * 100);

  return (
    <Flex
      maxHeight="350px"
      maxWidth="350px"
      padding={loading ? 10 : 1}
      paddingTop={loading ? 10 : 0}
    >
      <SkeletonCircle isLoaded={!loading} width="100%" height="100%">
        <svg viewBox="0 0 200 200">
          {TOTAL > 0 ? (
            <>
              <VictoryPie
                standalone={false}
                width={200}
                height={200}
                padding={25}
                colorScale={colorScale}
                data={toData({ ATTENDED, MISSED, BLANK })}
                x="name"
                y="value"
                innerRadius={50}
                events={[
                  {
                    target: 'data',
                    eventHandlers: {
                      onMouseOver: () => [
                        {
                          target: 'data',
                          mutation: (values) => ({
                            style: {
                              ...values.style,
                              stroke: 'var(--chakra-colors-brand-gray1)',
                            },
                          }),
                        },
                        {
                          target: 'labels',
                          mutation: () => ({
                            active: true,
                          }),
                        },
                      ],

                      onMouseOut: () => [
                        {
                          target: 'data',
                          mutation: (values) => ({
                            style: {
                              ...values.style,
                              stroke: 'none',
                            },
                          }),
                        },
                        {
                          target: 'labels',
                          mutation: () => ({
                            active: false,
                          }),
                        },
                      ],
                    },
                  },
                ]}
                labels={({ datum }) => `${datum.value}/${TOTAL}`}
                labelComponent={
                  <VictoryTooltip
                    renderInPortal={false}
                    center={{ x: 100, y: 10 }}
                    flyoutStyle={{ fill: 'none', stroke: 'none' }}
                    style={{
                      fontSize: 12,
                      fill: 'var(--chakra-colors-brand-gray1)',
                    }}
                  />
                }
                groupComponent={
                  <VictoryAccessibleGroup
                    aria-label="Attendance stats"
                    desc={`Out of a total of ${TOTAL} events, ${BLANK} are missing attendance values, ${MISSED} were missed, and ${ATTENDED} were attended.`}
                  />
                }
              />

              <VictoryLabel
                textAnchor="middle"
                verticalAnchor="end"
                style={{
                  fontSize: 18,
                  fill: 'var(--chakra-colors-brand-gray1)',
                }}
                x={100}
                y={100}
                text={`${attendedRate}%`}
              />
              <VictoryLabel
                textAnchor="middle"
                verticalAnchor="start"
                style={{
                  fontSize: 12,
                  fill: 'var(--chakra-colors-brand-gray1)',
                }}
                x={100}
                y={100}
                text={['attendance', 'rate']}
              />
            </>
          ) : (
            <>
              <VictoryPie
                standalone={false}
                width={200}
                height={200}
                padding={25}
                colorScale={['var(--chakra-colors-brand-gray8)']}
                data={[{ name: 'null', value: 1 }]}
                x="name"
                y="value"
                innerRadius={50}
                labels={() => ''}
              />
              <VictoryLabel
                textAnchor="middle"
                verticalAnchor="middle"
                style={{
                  fontSize: 12,
                  fill: 'var(--chakra-colors-brand-gray1)',
                }}
                x={100}
                y={100}
                text="No events"
              />
            </>
          )}

          <VictoryLegend
            standalone={false}
            x={1}
            y={180}
            colorScale={colorScale}
            orientation="horizontal"
            gutter={10}
            symbolSpacer={8}
            padding={{ left: 10, right: 10 }}
            style={{
              labels: {
                fontSize: 11,
                fill: 'var(--chakra-colors-brand-gray1)',
              },
            }}
            data={toData({ ATTENDED, MISSED, BLANK })}
          />
        </svg>
      </SkeletonCircle>
    </Flex>
  );
}

export default AttendanceDonut;
