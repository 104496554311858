import React, { useRef } from 'react';
import { UseComboboxReturnValue } from 'downshift';
import { Popover, PopoverContent, PopoverTrigger } from '@chakra-ui/react';

import AutocompletePopover from './AutocompletePopover';
import useAutocompleteCombobox from './useAutocompleteCombobox';

interface PossibleItem {
  id: string;
  label: string;
}

const AutocompleteContext = React.createContext<
  AutocompleteContextType | undefined
>(undefined);

interface AutocompleteProviderProps<T> {
  /**
   * The ref that was passed to the input for the autocomplete
   */
  inputRef: React.MutableRefObject<HTMLInputElement | null>;
  /**
   * A list of items that are valid to be selected
   */
  possibleItemList: T[];
  /**
   * Function to be called when an item is selected
   */
  addItem: (item: T) => void;
  /**
   * A function to filter the list of possible items when a user types in the
   * input
   */
  filterItemsList: (inputValue: string | undefined) => void;
  /**
   * A render function which returns the element that triggers/anchors the
   * popover.
   */
  renderAnchorElement: (
    dropdownAnchorRef: React.ForwardedRef<HTMLDivElement>
  ) => React.ReactNode;
  /**
   * Any siblings to render alongside the anchor element
   */
  children?: React.ReactChildren;
  /**
   * Whether opening the autocomplete menu should be disabled
   */
  disabled?: boolean;
  labelText?: string;
}

/* eslint-disable react/jsx-props-no-spreading */
/**
 * @returns A provider that manages the popover state for an autocomplete
 *   combobox.  Renders a popup containing the list of available items to
 *   be selected, and provides a context with the props required to render
 *   an input.
 */
function AutocompleteProvider<T extends PossibleItem>({
  possibleItemList,
  addItem,
  filterItemsList,
  renderAnchorElement,
  children,
  inputRef,
  disabled,
  labelText,
}: AutocompleteProviderProps<T>): JSX.Element {
  const popoverAnchorRef = useRef<HTMLDivElement>(null);

  const {
    getLabelProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    getToggleButtonProps,
    highlightedIndex,
    getItemProps,
    inputValue,
    reset,
    isOpen,
  } = useAutocompleteCombobox({
    inputItems: possibleItemList,
    filterInputItems: filterItemsList,
    addItem,
  });

  return (
    <AutocompleteContext.Provider
      value={{
        getInputProps,
        getComboboxProps,
        inputValue,
        reset,
      }}
    >
      <div style={{ display: 'none' }} {...getLabelProps()}>
        {labelText}
      </div>
      <Popover
        isOpen={isOpen}
        placement="bottom"
        initialFocusRef={inputRef}
        arrowSize={1}
        matchWidth
      >
        {children}
        <PopoverTrigger>
          <div {...getToggleButtonProps({ disabled })}>
            {renderAnchorElement(popoverAnchorRef)}
          </div>
        </PopoverTrigger>
        <PopoverContent
          marginTop="-4px"
          width="100%"
          backgroundColor="brand.gray8"
          boxShadow="0px 8px 20px rgba(0, 0, 0, 0.15), 0px 0px 1px rgba(0, 0, 0, 0.9)"
        >
          <AutocompletePopover
            getMenuProps={getMenuProps}
            getItemProps={getItemProps}
            inputItems={possibleItemList}
            highlightedIndex={highlightedIndex}
          />
        </PopoverContent>
      </Popover>
    </AutocompleteContext.Provider>
  );
}

/* eslint-enable react/jsx-props-no-spreading */

AutocompleteProvider.defaultProps = {
  children: null,
  disabled: false,
  labelText: 'Select',
};

export default AutocompleteProvider;

interface AutocompleteContextType {
  /**
   * A prop getter, to be passed to the input associated with the autocomplete
   */
  getInputProps: UseComboboxReturnValue<PossibleItem>['getInputProps'];
  /**
   * A prop getter, to be passed to the element that wraps the input
   */
  getComboboxProps: UseComboboxReturnValue<PossibleItem>['getComboboxProps'];
  /**
   * The current value of the input element
   */
  inputValue: UseComboboxReturnValue<PossibleItem>['inputValue'];
  /**
   * A control function that returns the autocomplete to a reasonable default
   * state
   */
  reset: UseComboboxReturnValue<PossibleItem>['reset'];
}

export function useAutocomplete(): AutocompleteContextType {
  const context = React.useContext(AutocompleteContext);
  if (!context) {
    throw new Error(
      'useAutocomplete must be used within the AutocompleteProvider'
    );
  }
  return context;
}
