import React from 'react';
import { GridItem } from '@chakra-ui/react';

/**
 * An unsized widget for use with the DashboardGrid.
 * This widget spans all of the columns of the grid, and is the full height
 * of its contents.
 */

function FullSizeWidget({
  children,
}: {
  children: React.ReactChild;
}): JSX.Element {
  return (
    <GridItem colStart={1} colEnd={-1} height="100%">
      {children}
    </GridItem>
  );
}

export default FullSizeWidget;
