import React from 'react';
import { useReactiveVar } from '@apollo/client';
import {
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  CloseButton,
} from '@chakra-ui/react';
import Search from 'sharedIcons/Search';
import { announcementSearchTextVar } from 'sharedHooks/useAnnouncementClientsList';

function AnnouncementClientSearchBar(): JSX.Element {
  const searchText = useReactiveVar(announcementSearchTextVar);
  return (
    <InputGroup background="brand.white" borderRadius={4} marginBottom="18px">
      <InputLeftElement pointerEvents="none">
        <Search color="brand.gray4" boxSize={5} />
      </InputLeftElement>
      <Input
        placeholder="Search"
        value={searchText}
        data-testid="search-input"
        onChange={(e) => announcementSearchTextVar(e.target.value)}
      />
      <InputRightElement>
        <CloseButton
          data-testid="close-button"
          size="sm"
          visibility={searchText.length ? 'visible' : 'hidden'}
          onClick={() => announcementSearchTextVar('')}
        />
      </InputRightElement>
    </InputGroup>
  );
}

export default React.memo(AnnouncementClientSearchBar);
