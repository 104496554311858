import React from 'react';
import { Button, useDisclosure } from '@chakra-ui/react';
import AnnouncementContainer from 'sharedComponents/AnnouncementContainer';
import Campaign from 'sharedIcons/Campaign';

function NewAnnouncementButton(): JSX.Element {
  const {
    onOpen: onOpenAnnouncement,
    isOpen: isOpenAnnouncement,
    onClose: onCloseAnnouncement,
  } = useDisclosure();
  return (
    <>
      <Button
        variant="brandHeaderPrimary"
        leftIcon={<Campaign boxSize="16px" marginRight={1} />}
        onClick={onOpenAnnouncement}
      >
        Announcement
      </Button>
      <AnnouncementContainer
        isCreateDrawerOpen={isOpenAnnouncement}
        onCloseCreateDrawer={onCloseAnnouncement}
        onOpenCreateDrawer={onOpenAnnouncement}
      />
    </>
  );
}

export default NewAnnouncementButton;
