import { formatRelative } from 'date-fns';
import { enUS } from 'date-fns/locale';

export function phoneNumberFormat(phone: string): string;
export function phoneNumberFormat(phone: null | undefined): null;
export function phoneNumberFormat(
  phone: string | null | undefined
): string | null;
export function phoneNumberFormat(
  phone: string | null | undefined
): string | null {
  if (!phone) {
    return null;
  }
  const localOnlyPhone = phone.includes('+1') ? phone.slice(2) : phone;
  const areaCode = localOnlyPhone.slice(0, 3);
  const firstThree = localOnlyPhone.slice(3, 6);
  const lastFour = localOnlyPhone.slice(6);
  return `(${areaCode}) ${firstThree}-${lastFour}`;
}

export const sentenceCase = (text: string): string => {
  const sentences = text.split('.');
  const capitalizedSentences = sentences.map((sentence) => {
    const trimmedSentence = sentence.trim();
    return `${trimmedSentence.charAt(0).toUpperCase()}${trimmedSentence.slice(
      1
    )}`;
  });
  return capitalizedSentences.join('. ');
};

export const titleCase = (text = ''): string => {
  const words = text.split(' ');
  const capitalizedWords = words.map((word) => {
    const trimmedWord = word.trim();
    return `${trimmedWord.charAt(0).toUpperCase()}${trimmedWord
      .slice(1)
      .toLowerCase()}`;
  });
  return capitalizedWords.join(' ');
};

export const fullMonthDate = (
  date: number | null | undefined
): string | null => {
  if (!date) {
    return null;
  }
  const dateOptions: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };
  return new Date(date).toLocaleString('en-US', dateOptions);
};

export const formatMoney = (amount: number): string => `$${amount}`;

export const shortDayOrDateTime = (date: number | null): string | null => {
  if (!date) {
    return null;
  }
  const formatRelativeLocale = {
    lastWeek: "EEEE 'at' p",
    yesterday: "'Yesterday at' p",
    today: "'Today at' p",
    tomorrow: "'Tomorrow at' p",
    nextWeek: "EEEE 'at' p",
    other: "MMMM d, yyyy 'at' p",
  };

  const fullLocale = {
    ...enUS,
    formatRelative: (
      token:
        | 'lastWeek'
        | 'yesterday'
        | 'today'
        | 'tomorrow'
        | 'nextWeek'
        | 'other'
    ) => formatRelativeLocale[token],
  };

  return formatRelative(date, Date.now(), {
    locale: fullLocale,
  });
};

export const fullMonthDateWithDay = (date: number | null): string | null => {
  if (!date) {
    return null;
  }
  const dateOptions: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    weekday: 'long',
  };
  return new Date(date).toLocaleString('en-US', dateOptions);
};

export const shortTime = (date: number | null | undefined): string | null => {
  if (!date) {
    return null;
  }
  const timeOptions: Intl.DateTimeFormatOptions = {
    hour: 'numeric',
    minute: 'numeric',
  };
  return new Date(date).toLocaleString('en-US', timeOptions);
};

export const fullMonthDateWithDayTime = (
  date: number | null
): string | null => {
  if (!date) {
    return null;
  }
  return `${shortTime(date)} on ${fullMonthDate(date)}`;
};

export const formatCityStateZip = (
  city: string,
  state: string | undefined | null,
  zip: string | undefined | null
): string =>
  [[city, state].filter(Boolean).join(', '), zip].filter(Boolean).join(' ');
