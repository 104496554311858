import { Button, Text } from '@chakra-ui/react';
import { endOfWeek, startOfDay, startOfHour, startOfWeek } from 'date-fns';
import React from 'react';

interface EventsDaySelectorProps {
  startDate: Date;
  endDate: Date;
  setTodayDate: (day: Date) => void;
}

const linkButton: React.CSSProperties = {
  backgroundColor: 'transparent',
  border: 'none',
  cursor: 'pointer',
  display: 'inline',
  fontSize: '14px',
  margin: 0,
  padding: 0,
};

function EventsDaySelector({
  startDate = startOfWeek(startOfDay(Date.now()), { weekStartsOn: 1 }),
  endDate = endOfWeek(startOfDay(Date.now()), { weekStartsOn: 1 }),
  setTodayDate,
}: EventsDaySelectorProps) {
  const today = startOfDay(Date.now());
  const todayLabel = `${today.toLocaleString('default', {
    month: 'long',
  })} ${today.getDate()}, ${today.getFullYear()}`;

  const getWeekLabel = (): string => {
    const startDateMonth = startDate.toLocaleString('default', {
      month: 'long',
    });
    const endDateMonth = endDate.toLocaleString('default', { month: 'long' });
    const startDateYear = startDate.getFullYear();
    const endDateYear = endDate.getFullYear();

    switch (true) {
      case startDateMonth === endDateMonth && startDateYear === endDateYear:
        return `${startDateMonth} ${startDateYear}`;
      case startDateMonth !== endDateMonth && startDateYear === endDateYear:
        return `${startDateMonth} - ${endDateMonth} ${endDateYear}`;
      case startDateMonth !== endDateMonth && startDateYear !== endDateYear:
        return `${startDateMonth} ${startDateYear} - ${endDateMonth} ${endDateYear}`;
      default:
        return `${startDateMonth} ${startDateYear}`;
    }
  };

  const onClickTodayDate = (): void => {
    setTodayDate(
      startOfWeek(startOfDay(startOfHour(Date.now())), { weekStartsOn: 1 })
    );
  };

  return (
    <>
      <Text fontWeight="bold">{getWeekLabel()}</Text>
      <Text>
        Today:{' '}
        <Button
          color="brand.blue2"
          style={linkButton}
          onClick={onClickTodayDate}
        >
          {todayLabel}
        </Button>
      </Text>
    </>
  );
}

export default EventsDaySelector;
