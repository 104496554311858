import React from 'react';
import { FieldErrors } from 'react-hook-form';

import { FormControl, FormLabel, FormErrorMessage } from '@chakra-ui/react';
import Error from 'sharedIcons/Error';

interface FormInputControlProps {
  children: React.ReactNode;
  inputId: string;
  fieldLabel?: string;
  isRequired?: boolean;
  errors?: FieldErrors;
  marginBottom?: number;
}

function FormControlInput(props: FormInputControlProps): JSX.Element {
  const {
    inputId,
    isRequired,
    fieldLabel,
    children,
    errors,
    marginBottom,
  } = props;

  return (
    <FormControl
      id={inputId}
      data-testid={inputId}
      marginBottom={marginBottom}
      isRequired={isRequired}
      isInvalid={!!errors}
    >
      {fieldLabel && <FormLabel variant="editDrawer">{fieldLabel}</FormLabel>}
      {children}
      {errors?.message && (
        <FormErrorMessage color="brand.orange1">
          <Error /> {errors.message}
        </FormErrorMessage>
      )}
    </FormControl>
  );
}

FormControlInput.defaultProps = {
  fieldLabel: undefined,
  errors: undefined,
  isRequired: false,
  marginBottom: 4,
};

export default FormControlInput;
