import { useEffect, useState, useCallback } from 'react';
import {
  useCreateQuietHoursMutation,
  useUpdateQuietHoursMutation,
} from 'generated/graphql';
import quietHoursRefetch from '../graphql/queries/quietHoursRefetch';

type UseSaveQuietHoursData = [
  (quietHoursInput: QuietHoursInput) => void,
  {
    loading: boolean;
    error: boolean;
    complete: boolean;
  }
];

interface QuietHoursInput {
  autoreply: string;
  autoreply_enabled: boolean;
  enabled: boolean;
  end: number;
  id?: string | null;
  staffId?: string;
  start: number;
  weekends_off: boolean;
  __typename?: string;
}

function useSaveQuietHours(): UseSaveQuietHoursData {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [complete, setComplete] = useState(false);
  const [saving, setSaving] = useState(false);

  const [
    createQuietHours,
    { data: createComplete, loading: createLoading, error: createError },
  ] = useCreateQuietHoursMutation();
  const [
    updateQuietHours,
    { data: updateComplete, loading: updateLoading, error: updateError },
  ] = useUpdateQuietHoursMutation();

  const save = useCallback((quietHoursInput: QuietHoursInput) => {
    const {
      __typename: unusedTypename,
      staffId,
      id,
      ...rest
    } = quietHoursInput;
    if (id && !saving) {
      setSaving(true);
      const quietHours = { id, ...rest };
      updateQuietHours({
        variables: { quietHours },
        refetchQueries: [{ query: quietHoursRefetch }],
      });
    } else if (staffId) {
      setSaving(true);
      const quietHours = { staffId, ...rest };
      createQuietHours({
        variables: { quietHours },
        refetchQueries: [{ query: quietHoursRefetch }],
      });
    }
  }, []);

  useEffect(() => {
    setLoading(createLoading || updateLoading);
  }, [createLoading, updateLoading]);

  useEffect(() => {
    if (createError || updateError) {
      setError(true);
    } else {
      setError(false);
    }
  }, [createError, updateError]);

  useEffect(() => {
    if (createComplete || updateComplete) {
      setSaving(false);
      setComplete(true);
    } else {
      setSaving(false);
      setComplete(false);
    }
  }, [createComplete, updateComplete]);

  return [save, { complete, loading, error }];
}

export default useSaveQuietHours;
