import React, { useEffect, useState } from 'react';
import {
  Tabs,
  Tab,
  TabList,
  Box,
  Flex,
  Spacer,
  Text,
  Heading,
  Divider,
  Skeleton,
} from '@chakra-ui/react';
import AutoSizer from 'react-virtualized-auto-sizer';

import useAttendanceToolEvents from 'sharedHooks/useAttendanceToolEvents';
import EventsListByDay from 'sharedComponents/EventsListByDay';

import AttendanceToolEventRow from 'sharedComponents/AttendanceToolEventRow';
import { flattenEvents } from 'sharedHelpers/eventsByDay';
import type { ClientEvent } from '../types/clientTypes';

interface AttendanceToolProps {
  dashboardDates: {
    startDate: Date | number;
    endDate: Date | number;
  };
  selectedStaffId: string;
  scrollRef: React.MutableRefObject<HTMLDivElement | null>;
  refetchDashboard: () => void;
  restorePosition: () => void;
}

function AttendanceTool({
  dashboardDates,
  selectedStaffId,
  scrollRef,
  refetchDashboard,
  restorePosition,
}: AttendanceToolProps): JSX.Element {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [unmarkedOnly, setUnmarkedOnly] = useState(false);
  const [events, setEvents] = useState<Array<string | ClientEvent>>([]);

  const parentRef = React.useRef<HTMLDivElement>(null);

  const handleTabsChange = (index: number) => {
    setSelectedIndex(index);
    setUnmarkedOnly(!unmarkedOnly);
  };

  useEffect(() => {
    setSelectedIndex(0);
  }, []);

  const {
    events: dashboardEvents,
    loading,
    refetchQuery,
  } = useAttendanceToolEvents({
    dashboardDates,
    unmarkedOnly,
    selectedStaffId,
  });

  useEffect(() => {
    setEvents(flattenEvents(dashboardEvents));
    restorePosition();
  }, [dashboardEvents]);

  return (
    <Skeleton
      isLoaded={!loading}
      width="100%"
      paddingX={{ base: 0, xl: 4 }}
      height={loading ? '40px' : '100%'}
    >
      <Flex height="100%">
        <Tabs
          isLazy
          index={selectedIndex}
          onChange={handleTabsChange}
          variant="secondarySelect"
          height="100%"
          width="100%"
        >
          <Flex justifyContent="space-between" alignItems="baseline">
            <Heading color="brand.blue1" fontSize="xl" fontWeight="normal">
              Attendance Tool
            </Heading>
            <Spacer />
            <Text size="sm" color="brand.gray1">
              Show:
            </Text>
            <TabList>
              <Box marginX="0" height={8}>
                <Tab>All</Tab>
              </Box>
              <Divider
                orientation="vertical"
                borderColor="brand.gray5"
                height={5}
                opacity={1}
                width="px"
                alignSelf="center"
              />
              <Box marginX="0" height={8}>
                <Tab>Not marked</Tab>
              </Box>
            </TabList>
          </Flex>
          <Flex height="100%" ref={parentRef} data-testid="list">
            <AutoSizer>
              {({ height, width }) => (
                <div style={{ height, width }}>
                  <EventsListByDay
                    events={events}
                    variant="clientsDashboard"
                    parentRef={parentRef}
                    scrollRef={scrollRef}
                  >
                    {(event) => (
                      <AttendanceToolEventRow
                        key={event.id}
                        event={event}
                        refetchQuery={refetchQuery}
                        refetchDashboard={refetchDashboard}
                      />
                    )}
                  </EventsListByDay>
                </div>
              )}
            </AutoSizer>
          </Flex>
        </Tabs>
      </Flex>
    </Skeleton>
  );
}

export default AttendanceTool;
