import React, { useState } from 'react';

import {
  Flex,
  Box,
  useCheckboxGroup,
  CheckboxGroup,
  FormControl,
  FormErrorMessage,
} from '@chakra-ui/react';
import { useReactiveVar } from '@apollo/client';

import CreateAnnouncementClientPickerItem from 'sharedComponents/CreateAnnouncementClientPickerItem';
import {
  sendAnnouncementClientIdsVar,
  messageableClientsListVar,
} from 'sharedHooks/useAnnouncementClientsList';
import Error from 'sharedIcons/Error';
import AnnouncementClientSearchBar from './AnnouncementClientSearchBar';

const styles = {
  box: {
    marginBottom: 'var(--chakra-space-4)',
    padding: 'var(--chakra-space-4) var(--chakra-space-8)',
  },
  formControl: {
    marginBottom: 'var(--chakra-space-4)',
    marginRight: 'var(--chakra-space-5)',
    paddingRight: 'var(--chakra-space-2)',
  },
};

function CreateAnnouncementClientPicker({
  formId,
  setRenderClientsForm,
  previouslySelectedClients,
}: {
  formId: string;
  setRenderClientsForm: React.Dispatch<React.SetStateAction<boolean>>;
  previouslySelectedClients: string[];
}): JSX.Element {
  const [selectedClients, setSelectedClients] = useState<string[]>(
    previouslySelectedClients || []
  );
  const [errorMessage, setErrorMessage] = useState('');

  const clientsList = useReactiveVar(messageableClientsListVar);

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (selectedClients.length) {
      setErrorMessage('');
      sendAnnouncementClientIdsVar(selectedClients);
      setRenderClientsForm(false);
    } else {
      setErrorMessage('You must select some clients to send the message to.');
    }
  };

  const { value, onChange } = useCheckboxGroup({
    value: selectedClients,
    onChange: setSelectedClients as React.Dispatch<
      React.SetStateAction<Array<string | number>>
    >,
  });

  return (
    <form id={formId} onSubmit={onSubmit} aria-label="Choose Clients">
      <Flex direction="column" height="100%">
        <Box
          backgroundColor="brand.gray8"
          color="brand.gray1"
          borderRadius="8px"
          style={styles.box}
        >
          <FormControl isInvalid={!!errorMessage.length}>
            <FormErrorMessage color="brand.orange1">
              <Error /> {errorMessage}
            </FormErrorMessage>
            <AnnouncementClientSearchBar />
            {!clientsList.length && (
              <Box padding={3} textAlign="center">
                There are no clients matching your search
              </Box>
            )}
            {!!clientsList.length && (
              <Box
                borderWidth="1px"
                borderRadius="4px"
                borderColor="brand.gray3"
                backgroundColor="brand.white"
              >
                <CheckboxGroup>
                  {clientsList.map((client) => {
                    const isChecked = value.includes(client.id);
                    return (
                      <CreateAnnouncementClientPickerItem
                        key={client.id}
                        onChange={onChange}
                        client={client}
                        isChecked={isChecked}
                      />
                    );
                  })}
                </CheckboxGroup>
              </Box>
            )}
          </FormControl>
        </Box>
      </Flex>
    </form>
  );
}

export default CreateAnnouncementClientPicker;
