import React, { useContext } from 'react';
import { useRadio, Box } from '@chakra-ui/react';
import { RadioGroupContext } from './ListPanelGroup';

/** This is a generic implementation of an appropriate listPanelChildren
 * component.  It is expected that pages will implement their own more
 * specialized versions, though this may be used as a wrapper to get the basic
 * radio functionality.  */

interface ListPanelItemProps {
  id: string;
  children: React.ReactNode;
  style: React.CSSProperties;
}
function ListPanelItem(props: ListPanelItemProps): JSX.Element {
  const { getRadioProps } = useContext(RadioGroupContext);
  const { id, children, style } = props;
  const { getInputProps, getCheckboxProps } = useRadio({
    ...getRadioProps({ value: id }),
  });

  /** useRadio is kind of confusing.  I found the list of props returned by
   *  each function to be helpful in understanding how it works.  They can be
   *  found at: https://github.com/chakra-ui/chakra-ui/blob/main/packages/radio/src/use-radio.ts
   */

  /** getInputProps returns all of the props that are normally handled by the
   *  overall radio input.  This includes the relevent aria attributes, and
   *  other attributes of the input like onChange, style, disabled.
   */
  const input = getInputProps();

  /** getCheckboxProps returns all of the props that are specific to radio
   *  inputs.  This includes props like isActive, isHovered, and focus.  The
   *  checkbox props should be applied to the element you want users to click.
   */
  const checkbox = getCheckboxProps();

  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <Box
      borderBottom="1px"
      borderColor="brand.gray5"
      _last={{
        borderBottom: 'none',
      }}
      style={style}
    >
      <input {...input} id={id} />
      <Box
        as="label"
        htmlFor={id}
        display="block"
        color="brand.gray1"
        textAlign="left"
        {...checkbox}
        cursor="pointer"
        _checked={{
          bg: 'brand.blue3',
          color: 'brand.blue1',
        }}
        _focus={{
          boxShadow: 'outline',
        }}
        paddingX={9}
        paddingY={4}
      >
        {children}
      </Box>
    </Box>
  );
}
/* eslint-enable react/jsx-props-no-spreading */

export default React.memo(ListPanelItem);
