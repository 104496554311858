import React from 'react';
import { Avatar, Tooltip } from '@chakra-ui/react';
import Campaign from 'sharedIcons/Campaign';

import type { Message } from '../types/clientTypes';
import symbol from '../../images/uptrust-symbol.png';

interface MessageBubbleAvatarProps {
  message: Message;
}

function MessageBubbleAvatar(props: MessageBubbleAvatarProps): JSX.Element {
  const { message } = props;
  const avatarImage = message.senderName ? 'null' : symbol;
  const announcement = !!message.announcement?.id;

  const AnnouncementAvatar = (
    <Avatar
      icon={<Campaign width="24px" height="24px" />}
      data-testid="announcement avatar"
    />
  );

  const MessageAvatar = (
    <Avatar
      name={message.senderName || undefined}
      src={avatarImage}
      sx={{ img: { width: 'auto', height: '90%' } }}
      data-testid="message avatar"
    />
  );

  return (
    <Tooltip
      hasArrow
      label={message.senderName || 'Uptrust'}
      // We must use bg here instead of background in order for the tooltip
      // arrow to correctly use the background color.
      bg="brand.gray7"
      color="brand.gray2"
    >
      {announcement ? AnnouncementAvatar : MessageAvatar}
    </Tooltip>
  );
}

export default MessageBubbleAvatar;
