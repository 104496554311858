import React, { useCallback, useState } from 'react';
import {
  Modal,
  ModalBody,
  ModalOverlay,
  Image,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  Flex,
  IconButton,
  Box,
} from '@chakra-ui/react';

import ArrowForward from 'sharedIcons/ArrowForwardIos';
import ArrowBack from 'sharedIcons/ArrowBackIos';

interface ExpandMessageImageModalProps {
  mediaUrl: string;
  isOpen: boolean;
  onClose: () => void;
  onNextImage: () => void;
  onPreviousImage: () => void;
}

function ExpandMessageImageModal({
  mediaUrl,
  isOpen,
  onClose,
  onNextImage,
  onPreviousImage,
}: ExpandMessageImageModalProps): JSX.Element {
  const closeModal = () => {
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={closeModal} isCentered size="lg">
      <ModalOverlay bg="blackAlpha.900" />
      <ModalContent bg="blackAlpha.50">
        <ModalHeader />
        <ModalCloseButton color="white" />
        <ModalBody>
          <Box>
            <Flex direction="row" justifyContent="space-evenly">
              <Flex
                direction="column"
                justifyContent="center"
                minWidth={6}
                paddingLeft={1}
              >
                <IconButton
                  aria-label="left-arrow"
                  icon={<ArrowBack boxSize="16px" color="brand.gray4" />}
                  size="xs"
                  variant="ghost"
                  minWidth="20px"
                  width="20px"
                  zIndex={2}
                  onClick={onPreviousImage}
                />
              </Flex>
              <Image boxSize="full" src={mediaUrl} />
              <Flex
                direction="column"
                justifyContent="center"
                minWidth={6}
                paddingLeft={1}
              >
                {/* Right Icon */}
                <IconButton
                  icon={<ArrowForward boxSize="16px" color="brand.gray4" />}
                  size="xs"
                  variant="ghost"
                  aria-label="right-arrow"
                  minWidth="20px"
                  width="20px"
                  zIndex={2}
                  onClick={onNextImage}
                />
              </Flex>
            </Flex>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default ExpandMessageImageModal;
