import React from 'react';
import { Box, Flex, Text, Tooltip } from '@chakra-ui/react';

import Error from 'sharedIcons/Error';

import SMSFailed from 'sharedIcons/SMSFailed';
import AnnouncementDetailsButton from 'sharedComponents/AnnouncementDetailsButton';

import useMessageErrorTooltip from 'sharedHooks/useMessageErrorTooltip';
import Help from 'sharedIcons/Help';
import type { Message } from '../types/clientTypes';
import MessageFailureStatus from '../constants/MessageFailureStatus';

interface MessageBubbleSubTextProps {
  message: Message;
}

function MessageBubbleSubText(props: MessageBubbleSubTextProps): JSX.Element {
  const { message } = props;
  const tooltip = useMessageErrorTooltip(message.errorMessage);

  const announcement = !!message.announcement?.id;
  const sendError =
    message.status === MessageFailureStatus.error ||
    message.status === MessageFailureStatus.undelivered ||
    message.status === MessageFailureStatus.skipped;

  const messageTextColor = sendError ? 'brand.orange1' : 'brand.blue2';
  const MessageIcon = announcement ? SMSFailed : Error;
  const messageErrorText = announcement
    ? 'Unable to deliver announcement'
    : 'Unable to deliver message';
  const announcementLink = announcement ? 'View announcement details' : '';

  return (
    <Flex
      direction="row"
      flexWrap="wrap"
      align="flex-end"
      justify="end"
      paddingTop={1}
    >
      {sendError && (
        <>
          {tooltip && (
            <Tooltip label={tooltip} hasArrow>
              <span>
                <Help color="brand.orange1" role="img" boxSize="20px" />
              </span>
            </Tooltip>
          )}
          <MessageIcon color="brand.orange1" role="img" boxSize="20px" />
          <Text color={messageTextColor} fontSize="sm" paddingX={1.5}>
            {messageErrorText}
          </Text>
        </>
      )}
      {announcement && (
        <AnnouncementDetailsButton
          messageTextColor={messageTextColor}
          announcementId={
            message.announcement?.id ? message.announcement.id : ''
          }
          paddingRight={1.5}
        >
          {announcementLink}
        </AnnouncementDetailsButton>
      )}
    </Flex>
  );
}

export default MessageBubbleSubText;
