import { useState, useEffect } from 'react';

import { useGetClientDetailsQuery, CarrierType } from 'generated/graphql';

export interface StatusData {
  appInstalled: 'INSTALLED' | 'NOT_INSTALLED';
  messageError: 'NONE' | 'OPTED_OUT' | 'BOUNCED';
  phoneType: 'UNREACHABLE' | CarrierType;
}

export default (
  clientId: string
): {
  closed: boolean;
  statusIconsData: StatusData | undefined;
  loading: boolean;
  closedAt: number | null | undefined;
} => {
  const [statusData, setStatusData] = useState<StatusData>();
  const [clientClosed, setClientClosed] = useState(false);
  const [loading, setLoading] = useState(true);

  const { data } = useGetClientDetailsQuery({
    variables: { id: clientId },
  });
  const { closed, closedAt } = data?.getClient || {};

  useEffect(() => {
    setLoading(true);
  }, [clientId]);

  useEffect(() => {
    if (data && Object.keys(data).length) {
      const { hasMobileApp, carrier, noPhone, lastSend } =
        data?.getClient || {};
      const { error, type } = carrier ?? {};

      const appInstalled = hasMobileApp ? 'INSTALLED' : 'NOT_INSTALLED';
      const messageError = error || lastSend === 'error' ? 'BOUNCED' : 'NONE';

      const phoneType = noPhone || !type ? 'UNREACHABLE' : type;

      setStatusData({ appInstalled, messageError, phoneType });
      setLoading(false);
      setClientClosed(!!closed);
    }
  }, [data]);
  return {
    closed: clientClosed,
    statusIconsData: statusData,
    loading,
    closedAt,
  };
};
