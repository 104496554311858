import React from 'react';
import {
  Flex,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';

import Folder from 'sharedIcons/Folder';
import { useUpdateClientClosedMutation } from 'generated/graphql';

function CloseClientMenu({
  clientId,
  isClosed,
}: {
  clientId: string;
  isClosed: boolean;
}): JSX.Element {
  const [save, saveState] = useUpdateClientClosedMutation();

  const setClientClosed = (closed: boolean) => {
    if (closed !== isClosed) {
      const saveClientInput = {
        id: clientId,
        closed,
      };
      save({
        variables: { input: saveClientInput },
      });
    }
  };

  const svgStyles = {
    height: '20px',
    width: '20px',
    margin: 'auto 8px',
  };
  return (
    <Menu isLazy>
      <MenuButton
        aria-label="set client file status"
        variant="ghost"
        rightIcon={<ChevronDownIcon />}
        as={Button}
        padding={0}
        fontWeight="normal"
        borderRadius={0}
        color="brand.gray2"
        isLoading={saveState.loading}
        leftIcon={<Folder style={svgStyles} />}
      >
        {isClosed ? 'Inactive' : 'Active'}
      </MenuButton>
      <MenuList minWidth="145px" color="brand.gray1">
        <Text paddingY={2} paddingX={4} color="brand.gray3">
          Set client file to:
        </Text>
        <MenuItem paddingX={4} onClick={() => setClientClosed(false)}>
          Active
        </MenuItem>
        <MenuItem paddingX={4} onClick={() => setClientClosed(true)}>
          Inactive
        </MenuItem>
      </MenuList>
    </Menu>
  );
}

export default CloseClientMenu;
