import React from 'react';
import { Flex, Skeleton } from '@chakra-ui/react';
import AutoSizer from 'react-virtualized-auto-sizer';

import EventsListByDay from 'sharedComponents/EventsListByDay';

import DashboardEventRow from './DashboardEventRow';
import type { ClientEvent } from '../../../shared/types/clientTypes';

interface DashboardEventsListProps {
  scrollRef: React.MutableRefObject<HTMLDivElement | null>;
  loading: boolean;
  events: ClientEvent[];
}

function DashboardEventsList({
  scrollRef,
  loading,
  events,
}: DashboardEventsListProps): JSX.Element {
  const parentRef = React.useRef<HTMLDivElement>(null);

  return (
    <Skeleton
      isLoaded={!loading}
      width="100%"
      paddingX={{ base: 0, xl: 4 }}
      height={loading ? '40px' : '100%'}
    >
      <Flex height="100%" ref={parentRef} data-testid="list">
        <AutoSizer>
          {({ height, width }) => (
            <div style={{ height, width }}>
              <EventsListByDay
                events={events}
                variant="clientsDashboard"
                parentRef={parentRef}
                scrollRef={scrollRef}
              >
                {(event) => <DashboardEventRow event={event} />}
              </EventsListByDay>
            </div>
          )}
        </AutoSizer>
      </Flex>
    </Skeleton>
  );
}

export default DashboardEventsList;
