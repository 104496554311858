import React, { useContext, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client/react';
import { Skeleton } from '@chakra-ui/react';

import LaunchDarklyProvider from 'sharedComponents/LaunchDarklyProvider';
import DatadogRumIdentificationProvider from 'sharedComponents/DatadogRumIdentificationProvider';

import useGlobalErrorNotification from 'sharedHooks/useGlobalErrorNotification';
import AuthRequiredRoutes from './AuthRequiredRoutes';
import { AuthContext } from './AuthProvider';
import apolloClient from '../graphql/apolloClient';

function AuthRequiredRouter(): JSX.Element {
  const { loading, idToken, notLoggedIn } = useContext(AuthContext);
  const [showError, setShowError] = useState(false);
  const setErrorTrue = () => setShowError(true);
  const updateErrorState = (shouldShowError: boolean): void =>
    setShowError(shouldShowError);

  useGlobalErrorNotification({ showNotification: showError, updateErrorState });
  if (loading) {
    return <Skeleton width="100%" height="100%" />;
  }

  if (notLoggedIn) {
    return <Redirect to="/login" />;
  }

  return (
    <>
      <ApolloProvider client={apolloClient(idToken, setErrorTrue)}>
        <LaunchDarklyProvider>
          <DatadogRumIdentificationProvider>
            <AuthRequiredRoutes />
          </DatadogRumIdentificationProvider>
        </LaunchDarklyProvider>
      </ApolloProvider>
    </>
  );
}

export default AuthRequiredRouter;
