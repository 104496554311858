import React, { Suspense, useState, createContext, useContext } from 'react';

import emphasizedDateTime from 'sharedHelpers/emphasizedDateTime';
import type { EditDrawerType } from 'sharedComponents/EditDrawer';
import type { EditEventsFormType } from 'sharedComponents/EditEventsForm';
import type { EventsFormInputs } from '../../../shared/types/formTypes';
import type { ClientEvent } from '../../../shared/types/clientTypes';

const EditEventsForm = (React.lazy(
  () => import('sharedComponents/EditEventsForm')
) as unknown) as EditEventsFormType;
const EditDrawer = (React.lazy(
  () => import('sharedComponents/EditDrawer')
) as unknown) as EditDrawerType<EventsFormInputs>;

type EventFormData = ClientEvent | { clientId: string };
interface EditDrawerContextType {
  isOpen: boolean;
  onOpen: (eventData: EventFormData) => void;
  onClose: () => void;
  formData: EventsFormInputs;
}

const extractDateTime = (
  timeNumber: number | undefined | null
): [date: Date | undefined, time: Date | undefined] => {
  if (!timeNumber) {
    return [undefined, undefined];
  }
  const date = new Date(timeNumber);
  const timestamp = new Date(timeNumber);
  return [date, timestamp];
};

const defaultData = {
  location: undefined,
  room: undefined,
  checkInTemplate: undefined,
};

const EditDrawerContext = createContext<EditDrawerContextType | undefined>(
  undefined
);

const formatFormData = (eventData: EventFormData): EventsFormInputs => {
  if ('id' in eventData) {
    const {
      location,
      room,
      checkInTemplate,
      startTimestamp: startNumber,
      endTimestamp: endNumber,
      staffId,
      eventClients,
      groupEventId,
      ...formFields
    } = { ...defaultData, ...(eventData as ClientEvent) };
    const [date, timestamp] = extractDateTime(
      emphasizedDateTime(eventData as ClientEvent)
    );
    const [, endTimestamp] = extractDateTime(endNumber);
    const [startDate, startTimestamp] = extractDateTime(startNumber);
    const locationId = location?.id;
    const roomId = room?.id;
    const checkInTemplateId = checkInTemplate?.id;
    const clientIds = eventClients?.length
      ? eventClients.map((client) => client.id || '')
      : undefined;

    return {
      ...formFields,
      staffIds: [staffId || ''],
      date,
      timestamp,
      locationId,
      checkInTemplateId,
      roomId,
      endTimestamp,
      startDate,
      startTimestamp,
      clientIds,
      groupEventId: groupEventId || '',
    };
  }
  return eventData;
};

export function EditEventDrawerProvider({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  const [isOpen, setIsOpen] = useState(false);
  const [formData, setFormData] = useState<EventsFormInputs>({});
  const onOpen = (eventData: EventFormData) => {
    setFormData(formatFormData(eventData));
    setIsOpen(true);
  };

  const onClose = () => {
    setFormData({ clientId: '' });
    setIsOpen(false);
  };

  const value = { isOpen, onOpen, onClose, formData };
  return (
    <EditDrawerContext.Provider value={value}>
      {children}
      <Suspense fallback={<></>}>
        <EditDrawer<EventsFormInputs>
          isOpen={isOpen}
          onClose={onClose}
          FormContent={EditEventsForm}
          title="Edit Event"
          formData={formData}
          formId="editEventForm"
        />
      </Suspense>
    </EditDrawerContext.Provider>
  );
}

export function useEditEventDrawer(): EditDrawerContextType {
  const context = useContext(EditDrawerContext);
  if (!context) {
    throw new Error('useEditDrawer must be used within the EditDrawerProvider');
  }
  return context;
}
