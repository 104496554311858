import React from 'react';
import { Box, ListItem, List } from '@chakra-ui/react';

export interface DetailListItem {
  id: string;
  value: string;
}
interface ClientDetailItemProps {
  label: string;
  value: string | DetailListItem[] | null | undefined;
}
function ClientDetailItem(props: ClientDetailItemProps): JSX.Element {
  const { label, value } = props;
  return (
    <>
      <Box textAlign="right" color="brand.gray2">
        {label}
      </Box>
      <List textAlign="left" color="brand.gray1">
        {Array.isArray(value) ? (
          value.map((subvalue) => (
            <ListItem
              key={subvalue.id}
              paddingBottom={2}
              _last={{ paddingBottom: 0 }}
            >
              {subvalue.value}
            </ListItem>
          ))
        ) : (
          <ListItem>{value}</ListItem>
        )}
      </List>
    </>
  );
}

export default ClientDetailItem;
