export default (
  lastReceivedTimestamp: number | undefined | null,
  lastOpenedTimestamp: number | undefined | null
): Array<'unread'> => {
  const lastReceivedTimestampNumber = lastReceivedTimestamp || 0;
  const lastOpenedTimestampNumber = lastOpenedTimestamp || 0;
  const unreadMessages =
    lastReceivedTimestampNumber > lastOpenedTimestampNumber ? 'unread' : null;
  const flagArray = [unreadMessages].filter((e): e is 'unread' => !!e);
  return flagArray;
  return [];
};
