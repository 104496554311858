import React from 'react';
import { Flex } from '@chakra-ui/react';

import { EventType } from 'generated/graphql';
import { VirtualEventWithClients } from 'src/shared/types/clientTypes';

import EventDetailsPopoverBase from './EventDetailsPopoverBase';
import DateWithStartAndEndTime from './DateWithStartAndEndTime';
import DateTimeInformation from './DateTimeInformation';
import ClientInformation from './ClientInformation';
import StaffInformation from './StaffInformation';
import VirtualEventStatus from './VirtualEventStatus';

interface VirtualEventPopoverProps {
  event: VirtualEventWithClients;
}

function VirtualEventPopover({ event }: VirtualEventPopoverProps): JSX.Element {
  return (
    <EventDetailsPopoverBase event={{ ...event, type: EventType.Virtual }}>
      <Flex direction="column" rowGap={3}>
        <DateTimeInformation>
          <DateWithStartAndEndTime
            event={{ ...event, type: EventType.Virtual }}
          />
        </DateTimeInformation>
        <ClientInformation
          id={event.clientId}
          firstName={event.clientFirstName}
          lastName={event.clientLastName}
          groupEventClients={event.eventClients}
        />
        <StaffInformation id={event.staffId} />
        <VirtualEventStatus
          id={event.id}
          staffId={event.staffId || ''}
          clientId={event.clientId}
          startTimestamp={event.startTimestamp}
          endTimestamp={event.endTimestamp}
        />
      </Flex>
    </EventDetailsPopoverBase>
  );
}

export default VirtualEventPopover;
