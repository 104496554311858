import React, { useState, useEffect, useLayoutEffect } from 'react';
import {
  Flex,
  Tabs,
  TabList,
  Tab,
  Text,
  Tooltip,
  IconButton,
} from '@chakra-ui/react';
import { motion, AnimatePresence } from 'framer-motion';

import ArrowForward from 'sharedIcons/ArrowForwardIos';
import ArrowBack from 'sharedIcons/ArrowBackIos';
import {
  startOfDay,
  eachDayOfInterval,
  startOfWeek,
  endOfWeek,
  format,
  getDay,
  addWeeks,
  subWeeks,
  isSameWeek,
} from 'date-fns';

interface DashboardCalendarCarouselProps {
  onChange: (day: Date, index: number, tabComponentRef: any) => void;
  startDate: Date;
  setStartDate: (day: Date) => void;
}

const variants = {
  enter: (direction: number) => ({
    x: direction > 0 ? 1000 : -1000,
    opacity: 0,
  }),
  center: {
    x: 0,
    opacity: 1,
  },
};

function DashboardCalendarCarousel({
  onChange,
  startDate = startOfWeek(startOfDay(Date.now()), { weekStartsOn: 1 }),
  setStartDate,
}: DashboardCalendarCarouselProps): JSX.Element {
  const roundedDate = startOfDay(Date.now());

  const [selectedIndex, setSelectedIndex] = useState(getDay(roundedDate) - 1);
  const [[page, direction], setPage] = useState([0, 0]);
  const [updateSelected, setUpdateSelected] = useState(true);

  const inputRef = React.useRef();
  const [tabComponent, setTabComponent] = useState(inputRef.current);

  const [windowsSize, setwindowsSize] = useState([0, 0]);

  useLayoutEffect(() => {
    function updateSize() {
      setwindowsSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  const dates = eachDayOfInterval({
    start: startOfWeek(startDate, { weekStartsOn: 1 }),
    end: endOfWeek(startDate, { weekStartsOn: 1 }),
  });
  const paginate = (newDirection: number) => {
    setPage([page + newDirection, newDirection]);
  };

  const showNextWeek = () => {
    setStartDate(addWeeks(startDate, 1));
    paginate(1);
  };
  const showPreviousWeek = () => {
    setStartDate(subWeeks(startDate, 1));
    paginate(-1);
  };

  const handleTabsChange = (index: number) => {
    setSelectedIndex(index);
    onChange(dates[index], index, tabComponent);
  };

  useEffect(() => {
    if (updateSelected) {
      setTabComponent(inputRef.current);
      onChange(dates[selectedIndex], selectedIndex, inputRef.current);
    }
  }, [inputRef, updateSelected, windowsSize]);

  useEffect(() => {
    if (updateSelected) {
      if (isSameWeek(Date.now(), startDate)) {
        handleTabsChange(getDay(Date.now()) - 1);
      } else {
        handleTabsChange(0);
      }
    }
  }, [startDate, updateSelected, tabComponent]);

  return (
    <Tabs
      variant="calendarCarousel"
      index={selectedIndex}
      onChange={handleTabsChange}
      aria-label="Date select"
    >
      <Flex direction="row" justifyContent="space-evenly">
        <Flex
          direction="column"
          justifyContent="center"
          minWidth={6}
          paddingLeft={1}
        >
          <IconButton
            icon={<ArrowBack boxSize="16px" color="brand.gray4" />}
            size="xs"
            variant="ghost"
            aria-label="previous week"
            minWidth="20px"
            width="20px"
            onClick={showPreviousWeek}
          />
        </Flex>
        <AnimatePresence initial={false} custom={direction}>
          <motion.div
            key={page}
            custom={direction}
            variants={variants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{
              opacity: { duration: 0.1 },
              x: { duration: 0.1 },
            }}
            style={{ width: '100%' }}
            onAnimationStart={() => {
              setUpdateSelected(false);
            }}
            onTransitionEnd={() => {
              setUpdateSelected(true);
            }}
          >
            <TabList
              ref={(el) => {
                inputRef.current = el;
              }}
            >
              {dates.map((date) => (
                <Tooltip
                  key={date.toString()}
                  label={format(date, 'MMM d yyyy')}
                  hasArrow
                  aria-label={format(date, 'MMM d yyyy')}
                  placement="top"
                  background="brand.gray7"
                  arrowShadowColor="brand.gray7"
                  color="brand.gray1"
                  arrowSize={10}
                  fontSize="xs"
                >
                  <Tab
                    aria-label={format(date, 'MMM d yyyy')}
                    marginX={1}
                    width="100%"
                    color={
                      date.getTime() === roundedDate.getTime()
                        ? 'brand.blue2'
                        : undefined
                    }
                    backgroundColor={
                      date.getTime() === roundedDate.getTime()
                        ? 'brand.blue3'
                        : undefined
                    }
                    borderColor={
                      date.getTime() === roundedDate.getTime()
                        ? 'brand.blue3'
                        : undefined
                    }
                    _selected={
                      date.getTime() !== roundedDate.getTime()
                        ? {
                            color: 'brand.gray1',
                            backgroundColor: 'brand.white',
                            borderColor: 'brand.gray5',
                          }
                        : {
                            color: 'brand.blue2',
                            backgroundColor: 'brand.blue3',
                            borderColor: 'brand.blue3',
                          }
                    }
                  >
                    <Flex direction="column">
                      <Text casing="uppercase" fontSize="xs">
                        {format(date, 'E')}
                      </Text>
                      <Text fontWeight="bold" fontSize="3xl" lineHeight="1">
                        {format(date, 'd')}
                      </Text>
                    </Flex>
                  </Tab>
                </Tooltip>
              ))}
            </TabList>
          </motion.div>
        </AnimatePresence>
        <Flex
          direction="column"
          justifyContent="center"
          minWidth={6}
          paddingRight={1}
        >
          <IconButton
            icon={<ArrowForward boxSize="16px" color="brand.gray4" />}
            aria-label="next week"
            size="xs"
            variant="ghost"
            minWidth="20px"
            width="20px"
            onClick={showNextWeek}
          />
        </Flex>
      </Flex>
    </Tabs>
  );
}

export default DashboardCalendarCarousel;
