import React from 'react';

import { Box, IconButton } from '@chakra-ui/react';
import { useReactiveVar } from '@apollo/client';

import Cancel from 'sharedIcons/Cancel';

import { sendAnnouncementClientIdsVar } from 'sharedHooks/useAnnouncementClientsList';

import { allClientsListVar } from '../graphql/apolloCache';

function AnnouncementFormClientBoxChip({
  clientId,
}: {
  clientId: string;
}): JSX.Element {
  const allClientsList = useReactiveVar(allClientsListVar);
  const clientsList = useReactiveVar(sendAnnouncementClientIdsVar);

  const removeClient = (id: string) => {
    const newClientsList = clientsList.filter((client) => client !== id);
    sendAnnouncementClientIdsVar(newClientsList);
  };

  const client = allClientsList.find((c) => c.id === clientId);
  const displayName = client ? `${client.lastName}, ${client.firstName}` : '';
  return (
    <Box
      borderRadius="8px"
      backgroundColor="brand.gray7"
      color="brand.gray1"
      margin={2}
      paddingX={2}
      paddingY={1}
      width="fit-content"
    >
      {displayName}
      <IconButton
        icon={<Cancel boxSize="16px" />}
        aria-label={`Remove ${displayName}`}
        backgroundColor="brand.gray7"
        color="brand.gray2"
        size="xs"
        onClick={() => {
          removeClient(clientId);
        }}
      />
    </Box>
  );
}

export default AnnouncementFormClientBoxChip;
