import React from 'react';

import { EventType } from 'generated/graphql';
import EventDetailsPopoverBase from './EventDetailsPopoverBase';
import CourtDatePopover from './CourtDatePopover';
import OfficeEventPopover from './OfficeEventPopover';
import CheckInPopover from './CheckInPopover';
import VirtualEventPopover from './VirtualEventPopover';
import type { ClientEvent } from '../../../../shared/types/clientTypes';

interface EventDetailsPopoverProps {
  event: ClientEvent;
}

function EventDetailsPopover({ event }: EventDetailsPopoverProps): JSX.Element {
  switch (event.type) {
    case EventType.Virtual:
      return <VirtualEventPopover event={event} />;
    case EventType.Office:
      return <OfficeEventPopover event={event} />;
    case EventType.CourtDate:
      return <CourtDatePopover event={event} />;
    case EventType.CheckIn:
      return <CheckInPopover event={event} />;
    default:
      return <EventDetailsPopoverBase event={event} />;
  }
}

export default EventDetailsPopover;
