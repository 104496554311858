import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export default (props: IconProps): JSX.Element => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Icon viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M16.34 12.54L14.91 11.11C15.47 10.38 15.96 9.61 16.38 8.79L14.18 6.59C13.9 6.31 13.82 5.92 13.93 5.57C14.3 4.45 14.5 3.25 14.5 2C14.5 1.45 14.95 0.999998 15.5 0.999998H19C19.55 0.999998 20 1.45 20 2C20 5.98 18.63 9.64 16.34 12.54ZM13.52 15.35C10.63 17.64 6.97 19 3 19C2.45 19 2 18.55 2 18V14.51C2 13.96 2.45 13.51 3 13.51C4.24 13.51 5.45 13.31 6.57 12.94C6.92 12.82 7.32 12.91 7.59 13.18L9.79 15.38C10.6 14.96 11.37 14.48 12.09 13.92L0.389999 2.22L1.81 0.809998L20.19 19.2L18.78 20.61L13.52 15.35Z"
    />
  </Icon>
);
