import React from 'react';

import { Text } from '@chakra-ui/react';

import useGetStaffInfo from 'sharedHooks/useGetStaffInfo';
import Person from 'sharedIcons/Person';
import IconDetail from './IconDetail';

interface StaffInformationProps {
  id: string;
}

function StaffInformation({ id }: StaffInformationProps): JSX.Element {
  const staff = useGetStaffInfo({ id });
  return (
    <IconDetail DetailIcon={Person}>
      <Text variant="eventPopoverInformation">{staff.name}</Text>
    </IconDetail>
  );
}

export default StaffInformation;
