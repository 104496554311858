export const widgetSizes = {
  RectangleWidget: 3,
  SquareWidget: 2,
};

type SizedComponent = keyof typeof widgetSizes;

export function isSizedComponent(name: string): name is SizedComponent {
  return Object.keys(widgetSizes).includes(name);
}

export { default as SquareWidget } from './SquareWidget';
export { default as RectangleWidget } from './RectangleWidget';
export { default as FullSizeWidget } from './FullSizeWidget';
