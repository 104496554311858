import React from 'react';
import { Button } from '@chakra-ui/react';
import AssignmentTurnedIn from 'sharedIcons/AssignmentTurnedIn';
import { useCheckInResponseDrawer } from './CheckInResponseDrawerProvider';

export interface ViewCheckInResponseButtonProps {
  checkInReportId: string;
  variant?: string;
  marginLeft?: number;
  marginY?: number;
}

function ViewCheckInResponseButton(
  props: ViewCheckInResponseButtonProps
): JSX.Element {
  const { checkInReportId, marginY, variant } = props;
  const { onOpen } = useCheckInResponseDrawer();
  return (
    <Button
      leftIcon={<AssignmentTurnedIn boxSize="20px" />}
      marginY={marginY}
      variant={variant}
      onClick={() => onOpen(checkInReportId)}
    >
      View responses
    </Button>
  );
}

ViewCheckInResponseButton.defaultProps = {
  variant: 'brandGhost',
  marginY: 3,
};

export default ViewCheckInResponseButton;
