import { gql } from '@apollo/client';

export default gql`
  query getCombinedScheduleEvents(
    $lastActiveTimestamp: Float!
    $startDate: Float!
    $from: Float
    $to: Float
  ) {
    getUser {
      id
      type
      ... on StaffUser {
        events(
          fromLastActiveTimestamp: $lastActiveTimestamp
          eventFilter: UPCOMING
          eventFilterTimestamp: $startDate
          from: $from
          to: $to
        ) {
          edges {
            node {
              id
              type
              startTimestamp
              endTimestamp
              title
              clientFirstName
              clientLastName
              clientId
              staffId
              closed
              groupEventId
              staffAttending
              ... on CourtDateEvent {
                attendanceInfo @client
                location {
                  id
                  addressLine1
                  city
                  state
                  zip
                }
                room {
                  id
                  description
                }
                attended {
                  id
                  outcomeId
                  name
                }
                outcome {
                  outcomeUid
                  status
                }
              }
              ... on OfficeEvent {
                attendanceInfo @client
                location {
                  id
                  addressLine1
                  city
                  state
                  zip
                }
                room {
                  id
                  description
                }
                attended {
                  id
                  outcomeId
                  name
                }
                outcome {
                  outcomeUid
                  status
                }
              }
              ... on CheckInEvent {
                isCompleted
                submittedTimestamp
                checkInTemplate {
                  id
                  staffDisplayName
                }
                checkInReport {
                  id
                }
              }
            }
          }
        }
      }
    }
  }
`;
