import { useEffect, useReducer } from 'react';
import { makeVar, ApolloError, DocumentNode } from '@apollo/client';

import transformConnection from 'sharedHelpers/transformConnection';
import {
  useGetApiClientsListLazyQuery,
  GetClientsFilter,
  TimePeriod,
} from 'generated/graphql';
import useIdentifyUser from './useIdentifyUser';
import getApiClientsList from '../graphql/queries/getApiClientsList';

interface ApiClientsList {
  clients: string[];
  loaded: boolean;
  error?: ApolloError;
}
export interface ApiClientsLists {
  Today: ApiClientsList;
  InTheNextWeek: ApiClientsList;
  MissingOutcomes: ApiClientsList;
}

export const apiClientsListsVar = makeVar<ApiClientsLists>({
  Today: { clients: [], loaded: false },
  InTheNextWeek: { clients: [], loaded: false },
  MissingOutcomes: { clients: [], loaded: false },
});

const constructTimePeriodFilters = (
  eventsInPeriod: 'Today' | 'InTheNextWeek',
  staffId: string,
  organizationId: string
): GetClientsFilter => {
  const timePeriodFilter = TimePeriod[eventsInPeriod];
  return {
    organizationId,
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    hasEventsInPeriod: timePeriodFilter,
    messageableByStaffId: staffId,
    onlyOpen: true,
  };
};

const constructOutcomesFilter = (
  staffId: string,
  organizationId: string
): GetClientsFilter => ({
  organizationId,
  messageableByStaffId: staffId,
  outcomeStatus: null,
});

export const apiClientsListsRefetchQueriesVar = makeVar<
  {
    query: DocumentNode;
    variables: {
      filter: GetClientsFilter;
    };
  }[]
>([]);

const refetchQueries = (
  listsToFetch: Array<keyof ApiClientsLists>,
  staffId: string,
  organizationId: string
) =>
  listsToFetch.map((listName) => {
    let filter;
    if (listName === 'MissingOutcomes') {
      filter = constructOutcomesFilter(staffId, organizationId);
    } else {
      filter = constructTimePeriodFilters(listName, staffId, organizationId);
    }
    return { query: getApiClientsList, variables: { filter } };
  });

const initialState = {
  Today: { clients: [], loaded: false },
  InTheNextWeek: { clients: [], loaded: false },
  MissingOutcomes: { clients: [], loaded: false },
};
function reducer(
  state: ApiClientsLists,
  action: {
    type: 'clients' | 'loaded' | 'error';
    data: { listName: keyof ApiClientsLists; value: unknown };
  }
) {
  const { listName, value } = action.data;
  switch (action.type) {
    case 'clients':
      return { ...state, [listName]: { ...state[listName], clients: value } };
    case 'loaded':
      return { ...state, [listName]: { ...state[listName], loaded: value } };
    case 'error':
      return { ...state, [listName]: { ...state[listName], error: value } };
    default:
      throw new Error('must provide an action type');
  }
}

export default (listsToFetch: Array<keyof ApiClientsLists>): void => {
  const staffUser = useIdentifyUser();
  const staffId = staffUser?.id || '';
  const organizationId = staffUser?.organization.id || '';

  const [localApiClientsLists, dispatch] = useReducer(reducer, initialState);

  const queryHookReturnValues = {
    Today: useGetApiClientsListLazyQuery({
      variables: {
        filter: constructTimePeriodFilters('Today', staffId, organizationId),
      },
    }),
    InTheNextWeek: useGetApiClientsListLazyQuery({
      variables: {
        filter: constructTimePeriodFilters(
          'InTheNextWeek',
          staffId,
          organizationId
        ),
      },
    }),
    MissingOutcomes: useGetApiClientsListLazyQuery({
      variables: {
        filter: constructOutcomesFilter(staffId, organizationId),
      },
    }),
  };

  useEffect(
    () => () => {
      apiClientsListsRefetchQueriesVar([]);
    },
    []
  );

  useEffect(() => {
    if (staffId && organizationId) {
      listsToFetch.forEach((listName) => {
        queryHookReturnValues[listName][0]();
      });
      apiClientsListsRefetchQueriesVar([
        ...apiClientsListsRefetchQueriesVar(),
        ...refetchQueries(listsToFetch, staffId, organizationId),
      ]);
    }
  }, [staffId, organizationId]);

  useEffect(() => {
    if (listsToFetch.length) {
      listsToFetch.forEach((entryName) => {
        const queryData = queryHookReturnValues[entryName][1];
        const clientsConnection = queryData.data?.getClients;
        if (clientsConnection) {
          const clients = transformConnection(clientsConnection).map(
            (client) => client.id
          );
          if (
            JSON.stringify(localApiClientsLists[entryName].clients) !==
            JSON.stringify(clients)
          ) {
            dispatch({
              type: 'clients',
              data: { listName: entryName, value: clients },
            });
          }
        }
      });
    }
  }, [
    listsToFetch.map((entryName) => queryHookReturnValues[entryName][1].data),
  ]);

  useEffect(() => {
    if (listsToFetch.length) {
      listsToFetch.forEach((entryName) => {
        const queryData = queryHookReturnValues[entryName][1];
        const isLoaded = queryData.called && !queryData.loading;
        if (localApiClientsLists[entryName].loaded !== isLoaded) {
          dispatch({
            type: 'loaded',
            data: { listName: entryName, value: isLoaded },
          });
        }
      });
    }
  }, [
    ...listsToFetch.map(
      (entryName) => queryHookReturnValues[entryName][1].loading
    ),
    ...listsToFetch.map(
      (entryName) => queryHookReturnValues[entryName][1].called
    ),
  ]);

  useEffect(() => {
    if (listsToFetch.length) {
      listsToFetch.forEach((entryName) => {
        const queryData = queryHookReturnValues[entryName][1];
        if (localApiClientsLists[entryName].error !== queryData.error) {
          dispatch({
            type: 'error',
            data: { listName: entryName, value: queryData.error },
          });
        }
      });
    }
  }, [
    listsToFetch.map((entryName) => queryHookReturnValues[entryName][1].error),
  ]);

  useEffect(() => {
    apiClientsListsVar(localApiClientsLists);
  }, [localApiClientsLists]);
};
