import React from 'react';
import { Flex, IconButton, PopoverTrigger, Text } from '@chakra-ui/react';
import AssignmentLate from 'sharedIcons/AssignmentLate';
import AssignmentInd from 'sharedIcons/AssignmentInd';

interface DashboardAttendanceResumenProps {
  numberOfAttendance: number;
  badAttendance: boolean;
  allAttendance?: boolean;
  attendanceName?: string;
}

function DashboardAttendanceResumen({
  numberOfAttendance,
  allAttendance,
  badAttendance,
  attendanceName,
}: DashboardAttendanceResumenProps): JSX.Element {
  switch (true) {
    case numberOfAttendance && badAttendance:
      return (
        <PopoverTrigger>
          <Flex
            width="30%"
            borderColor="brand.white"
            borderBottomColor="brand.gray6"
            borderWidth="1px 0"
            alignItems="center"
            _hover={{
              cursor: 'pointer',
            }}
          >
            <IconButton
              aria-label="Mark attendance"
              size="sm"
              icon={<AssignmentLate boxSize="20px" />}
              color="brand.orange1"
              variant="ghost"
              paddingLeft="18px"
              paddingRight="8px"
              _hover={{
                background: 'inherith',
              }}
            />
            <Text
              color="brand.orange1"
              paddingRight={0.5}
              fontSize="14px"
              flexShrink="0"
            >
              {numberOfAttendance} missed
            </Text>
          </Flex>
        </PopoverTrigger>
      );
    case numberOfAttendance &&
      !badAttendance &&
      !allAttendance &&
      (attendanceName === 'Excused' || attendanceName === 'Vacated'):
      return (
        <PopoverTrigger>
          <Flex
            width="30%"
            borderColor="brand.white"
            borderBottomColor="brand.gray6"
            borderWidth="1px 0"
            alignItems="center"
            _hover={{
              cursor: 'pointer',
            }}
          >
            <IconButton
              aria-label="Mark attendance"
              size="sm"
              icon={<AssignmentInd boxSize="20px" />}
              color="brand.blue2"
              variant="ghost"
              paddingLeft="18px"
              paddingRight="8px"
            />
            <Text
              color="brand.blue2"
              paddingRight={0.5}
              fontSize="14px"
              flexShrink="0"
            >
              {numberOfAttendance} {attendanceName}
            </Text>
          </Flex>
        </PopoverTrigger>
      );
    case numberOfAttendance && !badAttendance && allAttendance:
      return (
        <PopoverTrigger>
          <Flex
            width="30%"
            borderColor="brand.white"
            borderBottomColor="brand.gray6"
            borderWidth="1px 0"
            alignItems="center"
            _hover={{
              cursor: 'pointer',
            }}
          >
            <IconButton
              aria-label="Mark attendance"
              size="sm"
              icon={<AssignmentInd boxSize="20px" />}
              color="brand.blue2"
              variant="ghost"
              paddingLeft="18px"
              paddingRight="8px"
              _hover={{
                background: 'inherith',
              }}
            />
            <Text
              color="brand.blue2"
              paddingRight={0.5}
              fontSize="14px"
              flexShrink="0"
            >
              Attended
            </Text>
          </Flex>
        </PopoverTrigger>
      );
    case numberOfAttendance && !badAttendance && !allAttendance:
      return (
        <PopoverTrigger>
          <Flex
            width="30%"
            borderColor="brand.white"
            borderBottomColor="brand.gray6"
            borderWidth="1px 0"
            alignItems="center"
            _hover={{
              cursor: 'pointer',
            }}
          >
            <IconButton
              aria-label="Mark attendance"
              size="sm"
              icon={<AssignmentInd boxSize="20px" />}
              color="brand.blue2"
              variant="ghost"
              paddingLeft="18px"
              paddingRight="8px"
            />
            <Text
              color="brand.blue2"
              paddingRight={0.5}
              fontSize="14px"
              flexShrink="0"
            >
              {numberOfAttendance} Attended
            </Text>
          </Flex>
        </PopoverTrigger>
      );
    default:
      return (
        <PopoverTrigger>
          <Flex
            width="30%"
            borderColor="brand.white"
            borderBottomColor="brand.gray6"
            borderWidth="1px 0"
            alignItems="center"
            _hover={{
              cursor: 'pointer',
            }}
          >
            <IconButton
              aria-label="Mark attendance"
              size="sm"
              icon={<AssignmentInd boxSize="20px" />}
              color="brand.gray3"
              variant="ghost"
              paddingLeft="18px"
              paddingRight="8px"
            />
            <Text
              color="brand.blue2"
              paddingRight={0.5}
              fontSize="14px"
              flexShrink="0"
            >
              Mark attendance
            </Text>
          </Flex>
        </PopoverTrigger>
      );
  }
}

DashboardAttendanceResumen.defaultProps = {
  allAttendance: false,
  attendanceName: 'Mark attendance',
};

export default DashboardAttendanceResumen;
