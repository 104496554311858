import { useState, useEffect } from 'react';
import type { AllStatsType } from './useClientsDashboardStats';

interface TidbitNumbers {
  total: number;
  count: number;
}

export interface EngagementTidbitsData {
  clientsWithApp: TidbitNumbers;
  clientsWithValidSMS: TidbitNumbers;
  unreachableClients: TidbitNumbers;
  successfulReminders: TidbitNumbers;
  clientsWhoSentMessage: TidbitNumbers;
}

interface UseEngagementTidbitsStatsParams {
  allStats: AllStatsType;
}
const blankStats = {
  hasMobileApp: 0,
  reachableBySms: 0,
  unreachable: 0,
  successfulReminders: 0,
  sentMessage: 0,
  totalReminders: 0,
  totalClients: 0,
};

const engagementTidbitNumbers = (stats: {
  hasMobileApp: number;
  reachableBySms: number;
  unreachable: number;
  successfulReminders: number;
  sentMessage: number;
  totalReminders: number;
  totalClients: number;
}) => {
  const {
    hasMobileApp,
    reachableBySms,
    unreachable,
    successfulReminders,
    sentMessage,
    totalClients,
    totalReminders,
  } = stats;
  return {
    clientsWithApp: { count: hasMobileApp, total: totalClients },
    clientsWithValidSMS: { count: reachableBySms, total: totalClients },
    unreachableClients: { count: unreachable, total: totalClients },
    successfulReminders: { count: successfulReminders, total: totalReminders },
    clientsWhoSentMessage: {
      count: sentMessage,
      total: totalClients,
    },
  };
};

function useEngagementTidbitStats({
  allStats,
}: UseEngagementTidbitsStatsParams): {
  stats: EngagementTidbitsData;
} {
  const [stats, setStats] = useState<EngagementTidbitsData>(
    engagementTidbitNumbers(blankStats)
  );

  useEffect(() => {
    if (allStats) {
      const {
        engagementTidbitDeliveredReminders: {
          fromPlatform: successfulReminders,
        },
        engagementTidbitAllReminders: { fromPlatform: totalReminders },
        engagementTidbitClients: {
          hasMobileApp,
          reachableBySms,
          unreachable,
          sentMessage,
          total: totalClients,
        },
      } = allStats;
      setStats(
        engagementTidbitNumbers({
          hasMobileApp,
          reachableBySms,
          unreachable,
          successfulReminders,
          sentMessage,
          totalReminders,
          totalClients,
        })
      );
    }
  }, [allStats]);

  return { stats };
}

export default useEngagementTidbitStats;
