import { datadogRum } from '@datadog/browser-rum';
import config from '../../config';

const allowedEnvironments = ['production', 'dev'];

const shouldInitializeDatadog = allowedEnvironments.includes(
  config().datadogRUM.env
);

export const datadogInit = (): void => {
  if (shouldInitializeDatadog) {
    datadogRum.init(config().datadogRUM);
  }
};

interface DatadogUserData {
  id: string;
  name: string;
  org: string;
  orgType: string;
}
export const datadogIdentifyUser = (user: DatadogUserData): void => {
  if (shouldInitializeDatadog) {
    datadogRum.setUser({ ...user });
  }
};

export const datadogRemoveUser = (): void => {
  if (shouldInitializeDatadog) {
    datadogRum.removeUser();
  }
};
