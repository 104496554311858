import React from 'react';
import { useLocation } from 'react-router-dom';

// Sourced from React Router docs within example:
// https://v5.reactrouter.com/web/example/custom-link

export default function useQuery(): URLSearchParams {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}
