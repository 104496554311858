import React from 'react';
import { List, Icon } from '@chakra-ui/react';
import StatsTidbitRow from 'sharedComponents/StatsTidbitRow';

interface MakeStatsParams<T extends string> {
  names: readonly T[];
  templates: { [p in T]: string };
  singleTemplates: { [p in T]: string };
  numbers: { [p in T]: { count: number; total: number } };
  icons?: { [p in T]: typeof Icon };
}

function makeStats<T extends string>({
  names,
  templates,
  singleTemplates,
  numbers,
  icons,
}: MakeStatsParams<T>) {
  return names.map((name) => ({
    name,
    tidbit: {
      textTemplate:
        numbers[name].count === 1 ? singleTemplates[name] : templates[name],
      count: numbers[name].count,
      total: numbers[name].total,
    },
    RowIcon: icons && icons[name] ? icons[name] : undefined,
  }));
}

function StatsTidbitStack<T extends string>({
  ...params
}: MakeStatsParams<T>): JSX.Element {
  const stats = makeStats(params);
  return (
    <List>
      {stats.map((stat) => (
        <StatsTidbitRow
          RowIcon={stat.RowIcon}
          tidbit={stat.tidbit}
          key={stat.name}
        />
      ))}
    </List>
  );
}

StatsTidbitStack.defaultProps = {
  icons: undefined,
};

export default StatsTidbitStack;
