import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export default (props: IconProps): JSX.Element => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Icon viewBox="0 0 24 24" {...props}>
    <g>
      <rect fill="none" height="24" width="24" />
    </g>
    <g fill="currentColor">
      <g>
        <rect height="7" width="3" x="4" y="10" />
        <rect height="7" width="3" x="10.5" y="10" />
        <rect height="3" width="20" x="2" y="19" />
        <rect height="7" width="3" x="17" y="10" />
        <polygon points="12,1 2,6 2,8 22,8 22,6" />
      </g>
    </g>
  </Icon>
);
