import { useEffect, useState } from 'react';
import { useGetUserIdentificationQuery } from 'generated/graphql';

export interface UserIdentificationData {
  id: string;
  name: string;
  email: string;
  role?: string;
  authUid?: string;
  organization: {
    id: string;
    uid?: string;
    type?: string;
  };
  visibleStaffIds?: string[];
}

const initialValues = {
  id: undefined,
  name: undefined,
  email: undefined,
  role: undefined,
  authUid: undefined,
  organization: {
    id: undefined,
    uid: undefined,
    type: undefined,
  },
  visibleStaffIds: undefined,
};

export default (): UserIdentificationData | typeof initialValues => {
  const [user, setUser] = useState<UserIdentificationData>();
  const { data } = useGetUserIdentificationQuery();
  useEffect(() => {
    const userInfo = data?.getUser;
    setUser(
      userInfo && userInfo.__typename === 'StaffUser' // eslint-disable-line no-underscore-dangle
        ? {
            id: userInfo.id,
            email: userInfo.email,
            name: userInfo.name,
            authUid: userInfo.authUid || undefined,
            role: userInfo.localRole || undefined,
            organization: {
              id: userInfo.organization.id,
              uid: userInfo.organization.organizationId,
              type: userInfo.organization.type,
            },
            visibleStaffIds: userInfo.visibleStaffIds || undefined,
          }
        : undefined
    );
  }, [data]);
  return user || initialValues;
};
