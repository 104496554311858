import React from 'react';
import { Flex } from '@chakra-ui/react';

import { EventType } from 'generated/graphql';
import { CheckInEventWithClients } from 'src/shared/types/clientTypes';

import EventDetailsPopoverBase from './EventDetailsPopoverBase';
import OpenCloseDateTime from './OpenCloseDateTime';
import DateTimeInformation from './DateTimeInformation';
import ClientInformation from './ClientInformation';
import CheckInStatus from './CheckInStatus';

interface CheckInPopoverProps {
  event: CheckInEventWithClients;
}

function CheckInPopover({ event }: CheckInPopoverProps): JSX.Element {
  return (
    <EventDetailsPopoverBase event={{ ...event, type: EventType.CheckIn }}>
      <Flex direction="column" rowGap={3}>
        <DateTimeInformation>
          <OpenCloseDateTime event={{ ...event, type: EventType.CheckIn }} />
        </DateTimeInformation>
        <ClientInformation
          id={event.clientId}
          firstName={event.clientFirstName}
          lastName={event.clientLastName}
          groupEventClients={event.eventClients}
        />
        <CheckInStatus
          checkInReportId={event.checkInReport?.id}
          submittedTimestamp={event.submittedTimestamp}
          endTimestamp={event.endTimestamp}
        />
      </Flex>
    </EventDetailsPopoverBase>
  );
}

export default CheckInPopover;
