import React from 'react';

import ScheduleDateBar from 'sharedComponents/ScheduleDateBar';
import useDynamicVirtual from 'sharedHooks/useDynamicVirtualList';

import type { ClientEvent } from '../types/clientTypes';

interface EventListProps {
  children: (event: ClientEvent) => JSX.Element;
  events: Array<string | ClientEvent>;
  variant: 'clientsDashboard' | 'clientSchedule';
  parentRef: React.MutableRefObject<HTMLDivElement | null>;
  scrollRef: React.MutableRefObject<HTMLDivElement | null>;
}

const getHeaderContent = (
  data: string,
  variant: 'clientsDashboard' | 'clientSchedule'
) =>
  variant === 'clientsDashboard' ? (
    <ScheduleDateBar variant={variant} displayDate={data ?? ''} />
  ) : (
    <></>
  );

const eventChildren = (
  events: Array<string | ClientEvent>,
  children: (event: ClientEvent) => JSX.Element,
  variant: 'clientsDashboard' | 'clientSchedule'
) => ({
  style,
  index,
  ref,
  key,
}: {
  style: React.CSSProperties;
  index: number;
  key: number | string;
  ref: (el: HTMLElement | null) => void;
}) => {
  const data = events[index];
  return (
    <div style={style} key={key} ref={ref} data-key={key}>
      {typeof data === 'string'
        ? getHeaderContent(data, variant)
        : children(data)}
    </div>
  );
};

function EventsListByDay({
  events,
  children,
  variant,
  parentRef,
  scrollRef,
}: EventListProps): JSX.Element {
  const numberOfItems = events.length;

  const paddingHeight =
    parentRef.current && scrollRef.current
      ? Math.abs(
          scrollRef.current.clientHeight - parentRef.current.clientHeight
        )
      : 0;

  const rowVirtualizer = useDynamicVirtual({
    size: numberOfItems,
    parentRef,
    paddingStart: paddingHeight,
    onScrollElement: scrollRef,
    // add extra elements to overscan based on paddingHeight, to make scrolling
    // smoother when there's a large paddingStart
    overscan: Math.round(paddingHeight ? paddingHeight / 35 : 1),
  });

  const eventChild = eventChildren(events, children, variant);

  return (
    <div
      style={{
        height: `${rowVirtualizer.totalSize - paddingHeight}px`,
        width: '100%',
      }}
    >
      {!!events.length &&
        rowVirtualizer.virtualItems.map((virtualRow) =>
          eventChild({
            index: virtualRow.index,
            style: {
              position: 'absolute',
              top: `-${paddingHeight}px`,
              left: 0,
              width: '100%',
              transform: `translateY(${virtualRow.start}px)`,
            },
            ref: virtualRow.measureRef,
            key: virtualRow.key,
          })
        )}
    </div>
  );
}

export default EventsListByDay;
