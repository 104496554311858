import React from 'react';
import { GridItem } from '@chakra-ui/react';

/**
 * A sized widget for use with the DashboardGrid.
 * This widget is rectangular in shape, and is approximately 1.5 times
 * as wide as it is tall.
 */
function RectangleWidget({
  children,
  name = 'RectangleWidget', // eslint-disable-line @typescript-eslint/no-unused-vars
}: {
  /**
   * A collection of react children to render inside of the widget
   */
  children: React.ReactChild;
  name?: 'RectangleWidget';
}): JSX.Element {
  return (
    <GridItem
      colSpan={3}
      style={{ aspectRatio: '354 / 195' }}
      maxHeight="min(100%, 350px)"
      maxWidth="min(100%, 540px)"
    >
      {children}
    </GridItem>
  );
}

RectangleWidget.defaultProps = {
  name: 'RectangleWidget',
};
export default RectangleWidget;
