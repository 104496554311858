import React from 'react';

import {
  InputGroup,
  Input,
  InputRightElement,
  IconButton,
  CloseButton,
} from '@chakra-ui/react';

import Cancel from 'sharedIcons/Cancel';

import { useAutocomplete } from './AutocompleteProvider';

interface AutocompleteInputProps {
  /**
   * A ref for the input.  Used to allow AutocompleteProvider to set focus
   * in the input when the user opens the autocomplete popover.
   */
  inputRef: React.MutableRefObject<HTMLInputElement | null>;
  /**
   * Whether to allow typing in the input
   */
  disabled?: boolean;
}

/* eslint-disable react/jsx-props-no-spreading */
/**
 * @returns An input connected to the parent's AutocompleteContext.  Must be
 *   be rendered as a child of an AutocompleteProvider.
 */

function AutocompleteInput({
  inputRef,
  disabled,
}: AutocompleteInputProps): JSX.Element {
  const {
    getInputProps,
    getComboboxProps,
    inputValue,
    reset,
  } = useAutocomplete();
  return (
    <InputGroup
      {...getComboboxProps({
        width: 40,
      })}
      visibility={disabled ? 'hidden' : 'visible'}
    >
      <Input
        data-testid="autocomplete-input"
        justifyContent="center"
        borderColor="brand.white"
        fontSize="sm"
        height={8}
        _focus={{ borderColor: 'brand.blue2' }}
        {...getInputProps({
          ref: inputRef,
          disabled,
        })}
      />
      <InputRightElement
        display={inputValue.length ? 'flex' : 'none'}
        height={8}
      >
        <CloseButton
          size="sm"
          className="clearInput"
          aria-label="Clear"
          color="brand.gray2"
          onMouseDown={(e) => {
            e.preventDefault();
            reset();
            inputRef.current?.focus();
          }}
        />
      </InputRightElement>
    </InputGroup>
  );
}
/* eslint-enable react/jsx-props-no-spreading */

AutocompleteInput.defaultProps = {
  disabled: false,
};

export default AutocompleteInput;
