import React, { useState } from 'react';

import { Stack, Tabs, TabList, TabPanels, TabPanel } from '@chakra-ui/react';
import Info from 'sharedIcons/Info';
import Chat from 'sharedIcons/Chat';
import Today from 'sharedIcons/Today';
import TextSnippet from 'sharedIcons/TextSnippet';
import DetailPanelHeader from 'sharedComponents/DetailPanelHeader';
import useTabNavigation from 'sharedHooks/useTabNavigation';
import useGetClientInformation from 'sharedHooks/useGetClientInformation';
import TabStyled from 'sharedComponents/TabStyled';
import ClientDetailsTabPanel from './ClientDetailsTabPanel';
import ClientNotesTabPanel from './ClientNotesTabPanel';

interface ClientDetailPanelProps {
  tab: number;
  clientId: string;
}

function ClientDetailPanel({
  tab,
  clientId,
}: ClientDetailPanelProps): JSX.Element {
  const [selectedIndex, setSelectedIndex] = useState(tab || 0);

  const navigateToPage = useTabNavigation();

  const { displayName } = useGetClientInformation(clientId);

  const handleTabsChange = (index: number) => {
    setSelectedIndex(index);
    navigateToPage(index);
  };

  const tabPanelHeight = 'calc(100% - 0.5rem)';

  return (
    <Stack width="100%" height="100%">
      <DetailPanelHeader
        title={displayName}
        miniBar={selectedIndex !== 0}
        clientId={clientId}
      />
      <Tabs
        index={selectedIndex}
        onChange={handleTabsChange}
        isLazy
        height="calc(100% - 64px)"
      >
        <TabList flexWrap="wrap" justifyContent="space-between" paddingX={9}>
          <TabStyled Icon={Info} text="Client Details" />
          <TabStyled Icon={Chat} text="Messages" />
          <TabStyled Icon={Today} text="Schedule" />
          <TabStyled Icon={TextSnippet} text="Notes" />
        </TabList>
        <TabPanels height="calc(100% - 40px)">
          <TabPanel height={tabPanelHeight}>
            <ClientDetailsTabPanel clientId={clientId} />
          </TabPanel>
          <TabPanel height={tabPanelHeight} padding="0" />
          <TabPanel height={tabPanelHeight} padding="0" />
          <TabPanel height="calc(100% - 0.5rem)" padding="0" tabIndex={0}>
            <ClientNotesTabPanel clientId={clientId} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Stack>
  );
}

export default ClientDetailPanel;
