import { gql } from '@apollo/client';
import FilterFields from '../fragments/filterFields';

export default gql`
  ${FilterFields}

  query getUser($filterIncludeEventStaff: Boolean!) {
    getUser {
      id
      type
      ... on StaffUser {
        name
        supervisor
        lawyer
        visibleStaffIds
        organization {
          id
          type
          maxStaffPerClient
          locations {
            id
            addressLine1
            addressLine2
            city
            state
            zip
            providesChildCare
            isExternal
            rooms {
              edges {
                node {
                  id
                  description
                }
              }
            }
          }
          outcomes {
            id
            outcomeUid
            name
          }
        }
        clients {
          edges {
            node {
              id
              firstName
              lastName
              closed
              cmsId
              shouldReceiveMessages
              lastSend
              carrier {
                type
                error
              }
              assignedStaff @include(if: $filterIncludeEventStaff) {
                id
              }
              ...FilterFields
            }
          }
        }
      }
    }
  }
`;
