import {
  Chip,
  ChipList,
} from 'sharedComponents/ChipSelector/ChipSelectorThemes';

const Avatar = {
  baseStyle: {
    container: {
      background: 'brand.white',
      color: 'brand.blue2',
      borderWidth: '1px',
      borderColor: 'brand.gray4',
    },
  },
};

const Button = {
  variants: {
    brandHeaderPrimary: {
      backgroundColor: 'var(--chakra-colors-brand-blue2)',
      color: 'var(--chakra-colors-brand-white)',
      borderRadius: '4px',
      width: '185px',
      paddingLeft: 4,
      paddingRight: 5,
      _hover: {
        backgroundColor: 'var(--chakra-colors-brand-blue2Hover)',
        _disabled: {
          backgroundColor: 'var(--chakra-colors-brand-blue2Hover)',
        },
      },
      _active: { backgroundColor: 'var(--chakra-colors-brand-blue2Hover)' },
    },
    brandPrimary: {
      backgroundColor: 'var(--chakra-colors-brand-blue2)',
      color: 'var(--chakra-colors-brand-white)',
      borderRadius: '4px',
      _hover: {
        backgroundColor: 'var(--chakra-colors-brand-blue2Hover)',
        _disabled: {
          backgroundColor: 'var(--chakra-colors-brand-blue2Hover)',
        },
      },
      _active: { backgroundColor: 'var(--chakra-colors-brand-blue2Hover)' },
    },
    brandCancel: {
      color: 'var(--chakra-colors-brand-blue2)',
      borderRadius: '4px',
      _hover: {
        backgroundColor: 'var(--chakra-colors-brand-blue3)',
      },
      _active: { backgroundColor: 'var(--chakra-colors-brand-blue3)' },
    },
    brandDelete: {
      color: 'var(--chakra-colors-brand-orange1)',
      borderRadius: '4px',
      _hover: {
        backgroundColor: 'var(--chakra-colors-brand-blue3)',
      },
      _active: { backgroundColor: 'var(--chakra-colors-brand-blue3)' },
    },
    brandGhost: {
      size: 'sm',
      height: 5,
      textColor: 'brand.blue2',
      variant: 'ghost',
      padding: 0,
      fontWeight: 'normal',
    },
  },
};

const InfoSection = {
  variants: {
    brandGray: {
      backgroundColor: 'brand.gray8',
      color: 'brand.gray1',
      borderRadius: '8px',
      marginBottom: 'var(--chakra-space-4)',
      padding: 'var(--chakra-space-4) var(--chakra-space-8)',
    },
  },
};

const Checkbox = {
  variants: {
    editDrawer: {
      paddingRight: 'var(--chakra-space-4)',
    },
  },
};

const FormLabel = {
  variants: {
    editDrawer: {
      fontSize: 'smaller',
      fontWeight: 'normal',
      color: 'var(--chakra-colors-brand-gray1)',
    },
  },
};

const Input = {
  variants: {
    editDrawer: {
      fieldSize: 'md',
    },
  },
};
const ScheduleDateBar = {
  baseStyle: {
    background: 'brand.gray8',
    color: 'brand.gray1',
    borderColor: 'brand.gray5',
    textAlign: 'left',
    paddingY: 1,
    textTransform: 'uppercase',
    fontSize: 'sm',
  },
  variants: {
    clientsDashboard: {
      paddingX: 3,
    },
    clientSchedule: {
      paddingX: 10,
    },
  },
};

const Select = {
  variants: {
    editDrawer: {
      backgroundColor: 'var(--chakra-colors-brand-white)',
    },
  },
};

const StatsTidbitRowBullet = {
  variants: {
    noIcon: {
      boxSize: '4px',
      color: 'brand.gray1',
      marginRight: '18px',
      marginLeft: '6px',
    },
    withIcon: {
      boxSize: '16px',
      color: 'brand.blue2',
      marginRight: '12px',
      marginLeft: '0px',
    },
  },
};

const Tabs = {
  parts: ['tab', 'tablist'],
  variants: {
    secondarySelect: {
      tablist: {
        paddingY: 2,
      },
      tab: {
        marginBottom: 'px',
        paddingBottom: 0,
        marginX: 4,
        paddingX: 0,
        paddingTop: 1,
        color: 'brand.gray1',
        fontSize: '14px',
        _selected: {
          fontWeight: 'bold',
          borderBottom: '1px',
          borderColor: 'brand.orange1',
        },
      },
    },
    calendarCarousel: {
      tablist: {
        paddingY: 3,
      },
      tab: {
        width: '70px',
        height: '60px',
        borderRadius: 4,
        borderWidth: '1px',
        borderColor: 'brand.gray5',
        color: 'brand.gray2',
        backgroundColor: 'brand.white',
        _selected: {
          borderColor: 'brand.blue2',
          color: 'brand.blue2',
          backgroundColor: 'brand.blue3',
        },
      },
    },
  },
};
const Text = {
  variants: {
    eventPopoverInformation: {
      color: 'brand.gray1',
      fontSize: 'sm',
    },
  },
};

export default {
  Avatar,
  Button,
  Checkbox,
  ...Chip,
  ChipList,
  FormLabel,
  InfoSection,
  Input,
  ScheduleDateBar,
  Select,
  StatsTidbitRowBullet,
  Tabs,
  Text,
};
