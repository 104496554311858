import { useMemo } from 'react';
import { get as getNestedProperty } from 'dot-prop';
import { useReactiveVar, makeVar } from '@apollo/client';
import {
  filtersVar,
  apiFiltersVar,
  Filters,
  ApiFilters,
} from 'sharedComponents/FilterPanel';

import type { Client } from 'generated/graphql';
import { clientFullName, clientPhoneNumber } from 'sharedHelpers/clientHelpers';
import useApiFilterTest from './useApiFilterTest';

interface AssignedStaffType {
  id: string;
}

export const searchTextVar = makeVar('');

const compareStringToArray = (text?: string, array?: string[]): boolean =>
  (array?.filter((el) => text?.includes(el)).length || 0) > 0;

const applyFilters = (
  currentFilters: Filters,
  apiTest: (client: Client) => boolean,
  apiFilters: ApiFilters
) => (client: Client) =>
  Object.keys(currentFilters).length
    ? Object.entries(currentFilters).every(([field, values]) => {
        if (values.length) {
          // if the field also includes an api filter, and the api filter is true,
          // we don't have to check the rest of the field's values as filters
          // within a field get evaluated with OR
          if (
            Object.keys(apiFilters).includes(field) &&
            !!apiFilters[field as keyof ApiFilters].length &&
            apiTest(client)
          ) {
            return apiTest(client);
          }
          // clientValues can be either an array of flags or a single value.
          const clientValues = getNestedProperty<
            | AssignedStaffType[]
            | string[]
            | string
            | number
            | boolean
            | null
            | undefined
          >(client, field);
          // This allows us to check if any of the elements from values are present in clientValues
          if (Array.isArray(clientValues)) {
            if (field === 'assignedStaff') {
              return values.some((r) => {
                let valueReturn = (clientValues as AssignedStaffType[]).some(
                  (x) => x.id === String(r)
                );
                if (!valueReturn)
                  valueReturn = client.staffId?.includes(String(r)) || false;
                return valueReturn;
              });
            }
            return values.some((r) =>
              (clientValues as string[]).includes(String(r))
            );
          }
          if (typeof clientValues === 'boolean') {
            return values.includes(clientValues.toString());
          }
          return (values as Array<
            typeof values[number] | undefined | null
          >).includes(clientValues);
        }
        return apiTest(client);
      })
    : apiTest(client);

const phoneFilterTest = (search: string) => (
  client: Client
): boolean | Client =>
  search.length
    ? clientPhoneNumber(client).includes(search.replace(/([()-\s])/g, ''))
    : client;

const searchFilterTest = (search: string) => (
  client: Client
): boolean | Client =>
  search.length
    ? clientFullName(client).includes(search.toLowerCase())
    : client;

const interactiveSearchClientByName = (search: string[]) => (
  client: Client
): boolean | Client =>
  search.length
    ? compareStringToArray(client.firstName?.toLowerCase(), search) &&
      compareStringToArray(client.lastName?.toLowerCase(), search)
    : client;

const filterClientsWithApi = (
  clients: Client[],
  currentFilters: Filters,
  search: string,
  apiTest: (client: Client) => boolean,
  apiFilters: ApiFilters
) => {
  const checkBoxFilterTest = applyFilters(currentFilters, apiTest, apiFilters);
  const searchTextArray =
    search.length > 3
      ? search
          .replace(/[^a-zA-Z,^0-9 ]/g, '')
          .split(' ')
          .filter((v) => v)
      : [];
  const searchFunction =
    searchTextArray.length > 1
      ? interactiveSearchClientByName(
          searchTextArray.map((el) => el.toLowerCase())
        )
      : searchFilterTest(search);

  const phoneSearch = phoneFilterTest(search);

  return clients.filter(
    (client) =>
      (searchFunction(client) && checkBoxFilterTest(client)) ||
      (phoneSearch(client) && checkBoxFilterTest(client))
  );
};

const filterClientRecentlySended = (clients: Client[]): Client[] => {
  const nowDate = Date.now();
  const yesterdayConst = 60 * 60 * 24 * 1000;
  const yesterday = nowDate - yesterdayConst;
  return clients.map((client) => {
    let newActivity = client.activity;
    if (
      newActivity &&
      client.lastSendTimestamp &&
      client.lastSendAutoTimestamp &&
      client.lastReceivedTimestamp &&
      client.lastSendTimestamp >= client.lastReceivedTimestamp &&
      client.lastSendAutoTimestamp >= client.lastReceivedTimestamp &&
      (yesterday <= client.lastSendTimestamp ||
        yesterday <= client.lastSendAutoTimestamp)
    )
      newActivity = [...newActivity, 'recent'];

    return { ...client, activity: newActivity };
  });
};

interface UseFilterClientsArgs {
  clientsList: Client[];
  sortFunction: (clientsList: Client[]) => Client[];
}

export default ({
  clientsList,
  sortFunction,
}: UseFilterClientsArgs): Client[] => {
  const filters = useReactiveVar(filtersVar);
  const apiFilters = useReactiveVar(apiFiltersVar);
  const searchText = useReactiveVar(searchTextVar);

  const { apiTest } = useApiFilterTest();

  const sortedClients = useMemo(() => sortFunction(clientsList), [
    sortFunction,
    clientsList,
    clientsList.length,
  ]);

  const filteredRencentlyClients = filterClientRecentlySended(sortedClients);
  const filteredClients = filterClientsWithApi(
    filteredRencentlyClients,
    filters,
    searchText,
    apiTest,
    apiFilters
  );

  return filteredClients;
};
