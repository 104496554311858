import React from 'react';
import { Flex, List, ListItem } from '@chakra-ui/react';
import { UseComboboxReturnValue } from 'downshift';

interface PossibleItem {
  id: string;
  label: string;
}

interface AutocompletePopoverProps<T extends PossibleItem> {
  getMenuProps: UseComboboxReturnValue<T>['getMenuProps'];
  getItemProps: UseComboboxReturnValue<T>['getItemProps'];
  inputItems: T[];
  highlightedIndex: number;
}

/* eslint-disable react/jsx-props-no-spreading */

function AutocompletePopover<T extends PossibleItem>({
  getMenuProps,
  getItemProps,
  inputItems,
  highlightedIndex,
}: AutocompletePopoverProps<T>): JSX.Element {
  return (
    <Flex direction="column" margin={1} maxHeight={300} overflowY="auto">
      <List padding={1} {...getMenuProps()}>
        {inputItems.map((item, index) => (
          <ListItem
            borderRadius="4px"
            paddingX={3}
            style={
              highlightedIndex === index
                ? {
                    backgroundColor: 'var(--chakra-colors-brand-blue2)',
                    color: 'var(--chakra-colors-brand-white)',
                  }
                : {}
            }
            key={`${item.id}`}
            {...getItemProps({ item, index })}
          >
            {item.label}
          </ListItem>
        ))}
      </List>
    </Flex>
  );
}
/* eslint-enable react/jsx-props-no-spreading */

export default AutocompletePopover;
