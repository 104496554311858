import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import { useHistory } from 'react-router-dom';
import {
  Box,
  Input,
  Button,
  Flex,
  Image,
  FormControl,
  FormLabel,
  FormErrorMessage,
  useDisclosure,
} from '@chakra-ui/react';
import { login } from 'sharedHelpers/firebaseAuth';
import Error from 'sharedIcons/Error';
import emailRegex from '../../shared/constants/emailRegex';
import ResetPasswordModal from './components/ResetPasswordModal';
import logo from '../../images/logo-header.png';

interface LoginFormFields {
  email: string;
  password: string;
  login: string;
}

function LoginPage(): JSX.Element {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setError,
    clearErrors,
  } = useForm<LoginFormFields>({ mode: 'onBlur' });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const history = useHistory();
  const [processingLogIn, setProcessingLogIn] = useState(false);

  const loginUser = async (loginDetails: LoginFormFields) => {
    setProcessingLogIn(true);
    const { email, password } = loginDetails;
    try {
      await login(email, password);
      history.push('/clients');
    } catch {
      setError('login', {
        type: 'manual',
        message: 'Login failed.  Check your username and password.',
        shouldFocus: false,
      });
      setProcessingLogIn(false);
    }
  };

  const emailInputFields = register('email', {
    required: 'Please enter a valid email address.',
    pattern: {
      value: emailRegex,
      message: 'Please enter a valid email address.',
    },
  });
  const emailValue = watch('email');
  const passwordInputFields = register('password', {
    required: 'Please enter your password.',
    minLength: 6,
  });
  const passwordValue = watch('password');

  const formIsValid =
    emailValue?.length &&
    passwordValue?.length &&
    !errors.email &&
    !errors.password;

  return (
    <Box backgroundColor="brand.gray4" height="100vh" textAlign="center">
      <Flex
        height="100%"
        justifyContent="center"
        direction="column"
        alignItems="center"
      >
        <Box
          backgroundColor="brand.white"
          color="brand.gray1"
          width="30%"
          padding={8}
          borderRadius={8}
        >
          <Image
            src={logo}
            fit="contain"
            alt="Uptrust logo"
            width="220px"
            height="110px"
            marginX="auto"
            paddingTop={4}
            paddingBottom={10}
          />
          <Box paddingBottom={10}>Welcome! Sign in to continue to Uptrust.</Box>
          <form noValidate onSubmit={handleSubmit(loginUser)}>
            <FormControl isInvalid={!!errors.login}>
              <FormErrorMessage color="brand.orange1">
                <Error />
                {errors.login?.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl
              isInvalid={!!errors.email}
              marginX="auto"
              paddingTop={4}
              paddingBottom={errors.email ? 2 : 10}
              width="calc(100% - 48px)"
              isRequired
            >
              <FormLabel>Email</FormLabel>
              <Input
                type="email"
                placeholder="Email"
                width="100%"
                {...emailInputFields} // eslint-disable-line react/jsx-props-no-spreading
              />
              <FormErrorMessage color="brand.orange1">
                <Error /> {errors.email?.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl
              isInvalid={!!errors.password}
              paddingTop={4}
              paddingBottom={errors.password ? 2 : 10}
              marginX="auto"
              width="calc(100% - 48px)"
              isRequired
            >
              <FormLabel>Password</FormLabel>
              <Input
                type="password"
                placeholder="Password"
                width="100%"
                {...passwordInputFields} // eslint-disable-line react/jsx-props-no-spreading
              />
              <FormErrorMessage color="brand.orange1">
                <Error /> {errors.password?.message}
              </FormErrorMessage>
            </FormControl>
            <Button
              backgroundColor="brand.blue2"
              color="brand.white"
              type="submit"
              width="calc(100% - 48px)"
              margin={4}
              isDisabled={!formIsValid}
              isLoading={processingLogIn}
              onClick={() => {
                clearErrors('login');
              }}
            >
              Sign in
            </Button>
          </form>
          <Box color="brand.gray2" paddingTop={10}>
            Trouble signing in?{' '}
            <Button
              variant="link"
              color="brand.blue2"
              fontWeight="400"
              marginX={1}
              onClick={onOpen}
            >
              Reset password
            </Button>
          </Box>
        </Box>
      </Flex>
      <ResetPasswordModal isOpen={isOpen} onClose={onClose} />
    </Box>
  );
}

export default LoginPage;
