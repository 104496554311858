import React, { ComponentType } from 'react';
import { Flex } from '@chakra-ui/react';
import type { ListChildComponentProps } from 'react-window';
import InfoViewHeader from './InfoViewHeader';
import InfoViewBody from './InfoViewBody';

export interface InfoViewProps {
  /** The id of the currently selected item */
  selectedItem: string | undefined;
  /** An array or single item to show in the left panel.

      Children need to consume the RadioGroupContext exported from ListPanelGroup
      to get the correct instance of getRadioProps.

      Children also need to implement the useRadio hook from chakra-ui/react to
      get the appropriate input and checkbox props.
  */
  listPanelChildren: ComponentType<ListChildComponentProps<unknown>> &
    React.ReactNode;
  /** A component to render in the list panel on the left.  The component should
   * accept as props the style and index props from react-window, as well as a
   * list of data items to be rendered
   * Children can access the currently selected item via the reactiveVar
   * exported from apolloCache
   */
  detailPanelChildren: React.ReactNode;
  /** A string with the title of the view.  Used to render the header and name
   * the list panel accesibly.
   */
  title: string;
  /** The number of items that are displayed in the list.
   */
  numberOfItems: number;
  /** The button to render on the right of the InfoViewHeader
   */
  HeaderButton: JSX.Element;
  errorMessage: string;
}

function InfoView(props: InfoViewProps): JSX.Element {
  const {
    selectedItem,
    listPanelChildren,
    detailPanelChildren,
    title,
    HeaderButton,
    numberOfItems,
    errorMessage,
  } = props;

  return (
    <Flex direction="column" borderRadius={16} width="68vw" height="100%">
      <InfoViewHeader title={title} HeaderButton={HeaderButton} />

      <InfoViewBody
        listPanelChildren={listPanelChildren}
        detailPanelChildren={detailPanelChildren}
        selectedItem={selectedItem}
        title={title}
        numberOfItems={numberOfItems}
        errorMessage={errorMessage}
      />
    </Flex>
  );
}

export default React.memo(InfoView);
