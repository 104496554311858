import { useEffect, useState } from 'react';

interface TooltipVaues {
  message: string;
  value: string;
}

const valuesOfErrorMessage: TooltipVaues[] = [
  {
    message: 'is not a valid phone number',
    value: 'This is not a valid mobile number.',
  },
  {
    message: "Invalid 'To' Phone Number",
    value: 'This is not a valid mobile number.',
  },
  {
    message: "Invalid 'From' Phone Number",
    value: 'This is not a valid mobile number.',
  },
  {
    message: 'Error 30007: undefined',
    value:
      'This message was blocked by the carrier. Sometimes this happens due to prohibited message content.',
  },
  {
    message: 'Error 30006: undefined',
    value: 'This number is a landline which cannot receive SMS.',
  },
  {
    message: 'pair violates a blacklist rule.',
    value: 'This number has unsubscribed (texted back “STOP”).',
  },
];
export default (
  errorMessage: string | null | undefined
): string | undefined => {
  if (errorMessage) {
    const index = valuesOfErrorMessage.findIndex((value) =>
      errorMessage.includes(value.message)
    );

    if (index !== -1) {
      const tooltip = valuesOfErrorMessage[index].value;
      return tooltip;
    }
  }

  return undefined;
};
