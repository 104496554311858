/**
"0": The client did not attend or appear at the event
"1": The client did attend the event
"2": The client was excused from having to attend
"3": A judge vacated the client's obligation to attend
"4": The event was canceled or rescheduled, but the original ocurrence remains in the org's system of record.
"5": The event was removed from the org's system of record
"6": A judge issued a bench warrant for the client for reasons believed to be causally related to the event (most likely, the judge is responding to a client's failure to appear for a
 */

enum Outcomes {
  Missed = '0',
  Attended = '1',
  Excused = '2',
  Vacated = '3',
  Canceled = '4',
  Removed = '5',
  BenchWarrant = '6',
}

export default Outcomes;
