import React from 'react';
import { Text } from '@chakra-ui/react';
import { subMinutes } from 'date-fns';
import { useFlags } from 'launchdarkly-react-client-sdk';

import useGetClientInformation from 'sharedHooks/useGetClientInformation';
import useIdentifyUser from 'sharedHooks/useIdentifyUser';
import { fullMonthDate, shortTime } from 'sharedHelpers/textFormat';
import JoinVideoChatButton from 'sharedComponents/JoinVideoChatButton';

import EventStatusWithButton from './EventStatusWithButton';

interface VirtualEventStatusProps {
  id: string;
  clientId: string;
  staffId: string;
  startTimestamp: number;
  endTimestamp: number;
}

const NotYetAvailableText = ({
  roomOpensTimestamp,
}: {
  roomOpensTimestamp: number;
}) => (
  <Text variant="eventPopoverInformation">
    The video room for this event will open at {shortTime(roomOpensTimestamp)}{' '}
    on {fullMonthDate(roomOpensTimestamp)}
  </Text>
);

const EventIsOverText = () => (
  <Text variant="eventPopoverInformation">
    The video room for this event is no longer available.
  </Text>
);

const RoomIsAvailable = () => (
  <Text variant="eventPopoverInformation">
    The video room for this event is now open.
  </Text>
);

const NotPrimaryStaff = ({
  staffMemberLabelFlag,
}: {
  staffMemberLabelFlag: string;
}) => (
  <Text variant="eventPopoverInformation">
    {`Video meetings can only be attended by the primary
  ${staffMemberLabelFlag}`}
  </Text>
);

function VirtualEventStatus({
  id,
  clientId,
  staffId,
  startTimestamp,
  endTimestamp,
}: VirtualEventStatusProps): JSX.Element {
  const { displayName } = useGetClientInformation(clientId);
  const { id: userId } = useIdentifyUser();
  const { staffMemberLabelFlag } = useFlags();
  const isOwnEvent = userId === staffId;

  const roomOpensTimestamp = Number(subMinutes(startTimestamp, 5));
  const notYetAvailable = Date.now() < roomOpensTimestamp;
  const eventIsInThePast = Date.now() > endTimestamp;

  const disabled = notYetAvailable || eventIsInThePast || !isOwnEvent;
  const roomIsOpen =
    Date.now() >= roomOpensTimestamp && Date.now() <= endTimestamp;
  return (
    <EventStatusWithButton
      StatusButton={
        <JoinVideoChatButton
          eventId={id}
          clientName={displayName}
          disabled={disabled}
        />
      }
    >
      {!isOwnEvent && (
        <NotPrimaryStaff staffMemberLabelFlag={staffMemberLabelFlag} />
      )}

      {isOwnEvent && (
        <>
          {roomIsOpen && <RoomIsAvailable />}
          {eventIsInThePast && <EventIsOverText />}
          {notYetAvailable && (
            <NotYetAvailableText roomOpensTimestamp={roomOpensTimestamp} />
          )}
        </>
      )}
    </EventStatusWithButton>
  );
}

export default VirtualEventStatus;
