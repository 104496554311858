import React from 'react';
import { IconButton } from '@chakra-ui/react';

import Edit from 'sharedIcons/Edit';

import type { ClientEvent } from '../../../shared/types/clientTypes';
import { useEditEventDrawer } from './EditEventDrawerProvider';

interface EditEventButtonProps {
  eventData: ClientEvent;
  iconSize?: string;
  color?: string;
}
function EditEventButton(props: EditEventButtonProps): JSX.Element {
  const { eventData, iconSize = '20px', color = 'brand.blue2' } = props;
  const { onOpen } = useEditEventDrawer();

  return (
    <IconButton
      aria-label="Edit event"
      size="sm"
      icon={<Edit boxSize={iconSize} />}
      color={color}
      variant="ghost"
      onClick={() => onOpen(eventData)}
    />
  );
}
EditEventButton.defaultProps = {
  iconSize: '20px',
  color: 'brand.blue2',
};

export default EditEventButton;
