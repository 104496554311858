import { format } from 'date-fns';
import { Media } from 'generated/graphql';
import type { Message } from '../types/clientTypes';

const title: pdfmake.Content = {
  text: 'Uptrust Message Transcript',
  style: 'title',
};

const clientInfoTable = (
  clientName: string,
  clientNumber: string,
  currentDate: Date
): pdfmake.ContentTable => ({
  margin: [0, 20],
  alignment: 'justify',
  layout: 'noBorders',
  table: {
    headerRows: 0,
    widths: ['auto', 'auto'],
    body: [
      [{ text: 'Client Name', style: 'infoTableLabel' }, clientName],
      [{ text: 'Current Number', style: 'infoTableLabel' }, clientNumber],
      [
        { text: 'Generated on', style: 'infoTableLabel' },
        format(currentDate, 'E PPpppp'),
      ],
    ],
  },
});

interface PrintableMessage {
  timestamp: number;
  senderName?: string;
  body: string;
  media?: Array<Media> | null;
  id: string;
  status: string;
  type?: string;
}

const isPrintableMessage = (
  message: Message | null | undefined
): message is PrintableMessage =>
  !!(message && (message.body || message?.media) && message.timestamp);

const getBase64ImageFromURL = (url: string) =>
  new Promise((resolve, reject) => {
    const img = new Image();
    img.setAttribute('crossOrigin', 'anonymous');

    img.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;

      const ctx = canvas.getContext('2d');
      ctx?.drawImage(img, 0, 0);

      const dataURL = canvas.toDataURL('image/png');

      resolve(dataURL);
    };

    img.onerror = (error: any) => {
      reject(error);
    };

    img.src = url;
  });

const createMediaMessage = async (
  message: PrintableMessage,
  clientName: string
) => {
  if (message.media) {
    const { type } = message;
    const isTwilioURL = message.media[0].url.includes('api.twilio');
    return {
      senderName: type === 'INCOMING' ? clientName : 'Uptrust',
      ...message,
      body: isTwilioURL
        ? {
            link: message.media[0].url,
            text:
              "This image couldn't be added to the PDF directly. Click the link to open it in your browser.",
            style: 'link',
            color: 'blue',
          }
        : {
            image: `${await getBase64ImageFromURL(message.media[0].url)}`,
            fit: [300, 300],
          },
    };
  }
  return undefined;
};

const messagesByDay = async (
  messages: Array<Message | undefined | null>,
  currentDate: Date,
  clientName: string
): Promise<Record<string, PrintFormattedMessage[]>> => {
  const filteredMessages = messages
    .filter(isPrintableMessage)
    .filter((message) => message.timestamp < Number(currentDate))
    .sort((a, b) => a.timestamp - b.timestamp);

  const messagePromises = filteredMessages.map(async (message) => {
    const { type } = message;
    const printMediaMessage = await createMediaMessage(message, clientName);
    const printMessage = {
      senderName: type === 'INCOMING' ? clientName : 'Uptrust',
      ...message,
    };
    return printMediaMessage || printMessage;
  });

  const messageResults = await Promise.all(messagePromises);
  const messagesByDate: Record<string, PrintFormattedMessage[]> = {};

  messageResults.forEach((message) => {
    if (!message) return;
    const { timestamp } = message;
    const yymmdd = format(new Date(timestamp || 0), 'u-MM-dd');
    if (!messagesByDate[yymmdd]) {
      messagesByDate[yymmdd] = [];
    }
    messagesByDate[yymmdd].push(message);
  });
  return messagesByDate;
};

const baseDateBody = { colSpan: 3, style: 'subheader' };

interface PrintFormattedMessage {
  timestamp: number;
  senderName: string;
  body: string | any;
}

const messageTableByDay = (
  date: string,
  dailyMessages: PrintFormattedMessage[]
): pdfmake.TableCell[][] => {
  const emptyCol = {};
  const dateHeaderBody = [
    { text: date, ...baseDateBody },
    emptyCol,
    emptyCol,
    emptyCol,
  ];
  const messageRows = dailyMessages.map((message) => [
    emptyCol,
    format(new Date(message.timestamp), 'kk:mm:ss'),
    {
      text: message.senderName,
      style: 'staffNameLabel',
    },
    message.body,
  ]);
  return [dateHeaderBody, ...messageRows];
};

const messageTableBody = (
  messages: Record<string, PrintFormattedMessage[]>
): pdfmake.TableCell[][] =>
  Object.keys(messages).reduce<pdfmake.TableCell[][]>((rows, date) => {
    const messagesForDay = messages[date];
    return [...rows, ...messageTableByDay(date, messagesForDay)];
  }, []);

const messageTable = async (
  messages: Array<Message | undefined | null>,
  currentDate: Date,
  clientName: string
): Promise<pdfmake.ContentTable> => ({
  table: {
    dontBreakRows: true,
    widths: [20, 60, 'auto', '*'],
    body: messageTableBody(
      await messagesByDay(messages, currentDate, clientName)
    ),
  },
  layout: {
    hLineWidth(rowIndex: number, node) {
      return rowIndex === 0 || rowIndex === node.table.body.length ? 2 : 1;
    },
    vLineWidth() {
      return 0;
    },
    hLineColor(rowIndex, node) {
      return rowIndex === 0 || rowIndex === node.table.body.length
        ? 'black'
        : 'gray';
    },
    paddingTop() {
      return 5;
    },
    paddingBottom() {
      return 5;
    },
  },
});

const documentTitle = (clientName: string, date: Date) =>
  `Uptrust Messages for ${clientName}, ${format(date, 'PPpp')}`;

const docDefinition = async (
  messages: Array<Message | undefined | null>,
  clientName: string,
  clientNumber: string
): Promise<pdfmake.TDocumentDefinitions> => {
  const currentDate = new Date();
  return {
    header: (currentPage, pageCount) => ({
      text: `${documentTitle(
        clientName,
        currentDate
      )}, page ${currentPage}/${pageCount}`,
      style: 'pageHeader',
    }),
    info: {
      title: documentTitle(clientName, currentDate),
    },
    content: [
      title,
      clientInfoTable(clientName, clientNumber, currentDate),
      await messageTable(messages, currentDate, clientName),
    ],
    styles: {
      title: {
        fontSize: 18,
        bold: true,
        alignment: 'left',
      },
      subheader: {
        fontSize: 14,
        bold: true,
      },
      messageTable: {
        fontSize: 14,
      },
      infoTableLabel: {
        bold: true,
      },
      staffNameLabel: {
        italics: true,
        noWrap: true,
      },
      pageHeader: {
        alignment: 'right',
        margin: 5,
        color: 'gray',
        fontSize: 10,
      },
    },
  };
};

export default docDefinition;

export const testFunctions = { messagesByDay };
