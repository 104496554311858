import React from 'react';
import { Box, Flex, SkeletonText } from '@chakra-ui/react';
import Info from 'sharedIcons/Info';
import listKeyFromString from 'sharedHelpers/listKeyFromString';
import { useGetClientNotesQuery } from 'generated/graphql';
import EditNotesButton from './EditNotesButton';

interface ClientNotesTabPanelProps {
  clientId: string;
}
function ClientNotesTabPanel(props: ClientNotesTabPanelProps): JSX.Element {
  const { clientId } = props;
  const { data, loading } = useGetClientNotesQuery({
    variables: { id: clientId },
  });
  let notes;
  if (data) {
    notes = data.getClient?.notes;
  }
  const lineBreakNotes = notes ? notes.split('\n') : [];

  return (
    <Flex
      justifyContent="space-between"
      paddingY={6}
      paddingX={10}
      alignItems="top"
      height="100%"
    >
      <Box
        textAlign="left"
        marginRight={8}
        paddingRight={2}
        height="100%"
        overflowY="auto"
        tabIndex={0}
      >
        {loading && <SkeletonText width="600px" noOfLines={4} spacing="4" />}
        {!loading &&
          (notes ? (
            lineBreakNotes.map((note) => (
              <Box
                paddingY={2}
                color="brand.gray1"
                key={listKeyFromString(note)}
              >
                {note}
              </Box>
            ))
          ) : (
            <Box paddingY={2} color="brand.gray3">
              <Info alignSelf="center" margin={1} /> Click the edit button to
              add notes for your client.
            </Box>
          ))}
      </Box>
      <EditNotesButton notes={notes} clientId={clientId} />
    </Flex>
  );
}

export default ClientNotesTabPanel;
