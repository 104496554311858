import React from 'react';
import { Box, Skeleton } from '@chakra-ui/react';

function EventsListSkeleton(): JSX.Element {
  return (
    <>
      <Box
        background="brand.gray8"
        textAlign="left"
        paddingX={10}
        paddingY={1}
        height="24px"
        data-testid="event-list-skeleton"
      >
        {' '}
        <Skeleton
          height="16px"
          width="100px"
          startColor="brand.gray3"
          endColor="brand.gray5"
        />
      </Box>

      <Skeleton height="40px" startColor="brand.gray3" endColor="brand.gray5" />
      <Skeleton height="40px" endColor="brand.gray3" startColor="brand.gray5" />
    </>
  );
}

export default EventsListSkeleton;
