import { gql } from '@apollo/client';

export default gql`
  fragment getClientDisplayName on Client {
    displayName
  }
`;

export interface GetClientDisplayNameData {
  displayName: string;
}

export type GetClientDisplayNameVars = never;
