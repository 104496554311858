import filterNullishObjectValues from 'sharedHelpers/filterNullishObjectValues';
import type {
  CreateClientInput,
  UpdateClientInfoInput,
  GenderType,
  LanguageType,
  RiskLevelType,
} from 'generated/graphql';
import { organizationId } from '../graphql/apolloCache';
import type { ClientFormFields } from '../types/formTypes';

export const toMultiStaffInput = (
  formData: Pick<ClientFormFields, 'staff'> & Pick<ClientFormFields, 'staffIds'>
): { staffId: string; additionalStaff: string[] } => {
  const { staff, staffIds } = formData;
  if (staffIds?.length) {
    const [staffId, ...additionalStaff] = staffIds;
    return {
      staffId,
      additionalStaff,
    };
  }
  if (!staff?.id) {
    throw new TypeError('staff is required');
  }
  return {
    staffId: staff.id,
    additionalStaff: [],
  };
};

export const toCreateClientInput = (
  formData: ClientFormFields
): CreateClientInput => {
  const created = Date.now();
  const defaultAddress = { addressLine1: '', city: '', state: '', zipCode: '' };
  const lastActiveTimestamp = Date.now();

  const { staffId, additionalStaff } = toMultiStaffInput(formData);

  const {
    staffIds,
    gender: genderString,
    language: languageString,
    riskLevel: riskLevelString,
    staff,
    contacts,
    address,
    ...inputKeys
  } = formData;

  const language = languageString ? (languageString as LanguageType) : null;

  const gender = genderString ? (genderString as GenderType) : null;

  const riskLevel = riskLevelString ? (riskLevelString as RiskLevelType) : null;

  const addressDetails = {
    addressLine1: address?.addressLine1 ?? defaultAddress.addressLine1,
    addressLine2: address?.addressLine2,
    city: address?.city ?? defaultAddress.city,
    state: address?.state ?? defaultAddress.state,
    zipCode: address?.zipCode ?? defaultAddress.zipCode,
  };
  let newObject = filterNullishObjectValues<CreateClientInput>({
    created,
    lastActiveTimestamp,
    organizationId: organizationId(),
    staffId,
    additionalStaff,
    language,
    gender,
    riskLevel,
    address:
      address && Object.values(address).some((value) => value)
        ? { ...addressDetails }
        : null,
    ...inputKeys,
  });
  newObject = { ...newObject, cmsId: inputKeys.cmsId ? inputKeys.cmsId : null };
  return newObject;
};

export const toUpdateClientInput = (
  formData: ClientFormFields & { terms?: Record<string, unknown> } & {
    cases?: Record<string, unknown>;
  }
): UpdateClientInfoInput => {
  const { id } = formData;
  if (!id) {
    throw new TypeError('Existing clients must have an id');
  }

  let { phone } = formData;
  if (phone === undefined) {
    phone = null;
  }
  let { employer } = formData;
  if (employer === undefined) {
    employer = null;
  }

  const {
    cases: unneededCases,
    terms: unneededTerms,
    clientUpdatedPhone,
    clientUpdatedAddress,
    clientUpdatedEmployer,
    ...formInput
  } = formData;

  const clientInput = toCreateClientInput(formInput);

  // UpdateClientInfoInput doesn't accept created, organizationId or lastActiveTimestamp.
  // Typescript doesn't allow deletion of non-optional properties, so we
  // destructure them and then don't use them.
  const {
    organizationId: unneededOrganization,
    created: unneededCreated,
    lastActiveTimestamp: unneededLastActive,
    ...validFields
  } = clientInput;
  return {
    id,
    phone,
    employer,
    ...validFields,
  };
};
