import React, { useEffect, useState } from 'react';
import { Button, Text } from '@chakra-ui/react';

import { fullMonthDateWithDay, shortTime } from 'sharedHelpers/textFormat';
import datadogLogGraphQLError from 'sharedHelpers/datadogLogGraphQLError';
import MutationConfirmation from 'sharedComponents/MutationConfirmation';
import ApiErrorAlert from 'sharedComponents/ApiErrorAlert';

import type { EventType } from 'generated/graphql';
import useDeleteEvents from 'sharedHooks/useDeleteEvents';

const eventTitle = (event: EventData) => {
  switch (event.type) {
    case 'COURT_DATE':
      return 'Court Date';
    case 'OFFICE':
    case 'VIRTUAL':
      return event.title;
    case 'CHECK_IN':
      return 'Quick Check-in';
    default:
      return 'Event';
  }
};

export interface EventData {
  id: string;
  type: EventType;
  title: string;
  startTimestamp: Date;
  startDate: Date;
  clientId: string;
  groupEventId: string;
  groupEventClientsCount: number;
}

interface DeleteEventConfirmationProps {
  /**
   * The data being passed to the DeleteEvent mutation.
   */
  eventData: EventData;
  /**
   * Whether or not the confirmation dialog is open
   */
  isOpen: boolean;
  /**
   * A function to close the window after the mutation is successfully executed.
   */
  onSuccessfulDelete: () => void;
  /**
   * A function to close the window when the user clicks cancel or hits esc.
   */
  onCancel: () => void;
  /**
   * A function to call if the user encounters a terminal error after clicking confirm.
   */
  onTerminalError: () => void;
}

function DeleteEventConfirmation({
  isOpen,
  onSuccessfulDelete,
  onCancel,
  eventData,
  onTerminalError,
}: DeleteEventConfirmationProps): JSX.Element {
  const {
    id,
    type,
    clientId,
    startTimestamp,
    startDate,
    groupEventId,
    groupEventClientsCount,
  } = eventData;
  const displayTitle = eventTitle(eventData);
  const buttonText = groupEventId
    ? `for ${groupEventClientsCount} clients`
    : displayTitle;

  const [showApiAlert, setShowApiAlert] = useState(false);
  const [unauthorizedError, setUnauthorizedError] = useState(false);
  const [deleteEvent, { complete, loading, error }] = useDeleteEvents();
  const onConfirm = () => {
    if (id) {
      deleteEvent({ id, type, clientId, groupEventId });
    }
  };

  useEffect(() => {
    if (complete) {
      onSuccessfulDelete();
    }
  }, [complete]);

  useEffect(() => {
    if (error) {
      datadogLogGraphQLError(error, 'deleteEvent', 'DeleteEventConfirmation');
      if (error.graphQLErrors?.length) {
        setUnauthorizedError(true);
      }
      if (!complete) {
        setShowApiAlert(true);
      }
    }
  }, [error]);

  const bodyContent = groupEventId ? (
    <>
      This event is scheduled for multiple clients. Please confirm that you want
      to delete event:
      <Text data-testid="event details" fontWeight="bold">
        {displayTitle}
      </Text>
    </>
  ) : (
    <>
      Before we delete your event, please confirm that you want to delete event:
      <Text data-testid="event details" fontWeight="bold">
        {displayTitle} on {fullMonthDateWithDay(Number(startDate))} at{' '}
        {shortTime(Number(startTimestamp))}
      </Text>
    </>
  );

  return (
    <>
      <ApiErrorAlert
        isOpen={showApiAlert}
        onTryAgain={() => {
          onConfirm();
        }}
        onCancel={onCancel}
        title="Unable to delete event"
        body="We encountered a system error and were unable to delete your event."
        enableRetry={!unauthorizedError}
        onTerminalError={onTerminalError}
      />
      {!showApiAlert && (
        <MutationConfirmation isOpen={isOpen} onCancel={onCancel}>
          {{
            bodyContent,
            confirmButton: (
              <Button
                paddingX={6}
                variant="brandPrimary"
                onClick={onConfirm}
                isDisabled={!id}
                isLoading={loading}
              >
                Yes, delete {buttonText}
              </Button>
            ),
            cancelButton: (cancelRef) => (
              <Button
                paddingX={6}
                variant="brandCancel"
                ref={cancelRef}
                onClick={onCancel}
                isLoading={loading}
              >
                Cancel
              </Button>
            ),
          }}
        </MutationConfirmation>
      )}
    </>
  );
}

export default DeleteEventConfirmation;
