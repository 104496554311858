import React, { useState, useEffect } from 'react';
import { Skeleton } from '@chakra-ui/react';
import { AutocompleteChipSelector } from 'sharedComponents/Autocomplete';

interface StaffMultiSelectorProps {
  value: string[] | undefined;
  onChange: (value: string[]) => void;
  staffMemberList: { id: string; label: string }[];
  defaultTo?: { id: string; label: string };
  maxItems?: number | undefined;
  disabled?: boolean;
  loading?: boolean;
}

const valueToFormValue = (value: { id: string; label: string }[]): string[] =>
  value.map((staff) => staff.id);

function StaffMultiSelector({
  value: initialValue,
  disabled,
  onChange,
  staffMemberList,
  defaultTo,
  maxItems,
  loading,
}: StaffMultiSelectorProps): JSX.Element {
  const [value, setValue] = useState<{ id: string; label: string }[]>([]);
  const [initialSelectedValue, setInitialSelectedValue] = useState<
    { id: string; label: string }[]
  >([]);

  const [initialValueSet, setInitialValueSet] = useState(false);
  useEffect(() => {
    if (staffMemberList.length) {
      if (!initialValueSet) {
        if (initialValue?.length) {
          const staff = initialValue
            .map((initialStaffId) =>
              staffMemberList.find(
                (staffEntry) => staffEntry.id === initialStaffId
              )
            )
            .filter((e): e is { id: string; label: string } => !!e);
          if (staff.length) {
            setInitialSelectedValue(staff);
            setInitialValueSet(true);
          }
        } else if (defaultTo) {
          setInitialSelectedValue([defaultTo]);
        }
      }
    }
  }, [staffMemberList.length, staffMemberList[0]?.id, defaultTo]);

  const [isLoaded, setIsLoaded] = useState(!loading);
  useEffect(() => {
    setTimeout(() => setIsLoaded(!loading), 1);
  }, [loading]);

  return (
    <Skeleton isLoaded={isLoaded} borderRadius={4}>
      <AutocompleteChipSelector
        itemList={staffMemberList}
        initialValue={initialSelectedValue}
        value={value}
        onChange={(selectedItems) => {
          if (!loading) {
            setValue(selectedItems);
            onChange(valueToFormValue(selectedItems));
          }
        }}
        disabled={disabled}
        maxItems={maxItems}
        labelText="Select staff"
      />
    </Skeleton>
  );
}

StaffMultiSelector.defaultProps = {
  defaultTo: undefined,
  maxItems: undefined,
  disabled: false,
  loading: false,
};

export default StaffMultiSelector;
