import useNavigateToPage from 'sharedHooks/useNavigateToPage';

const usePageNavigation = (): ((index: number) => void) => {
  const navigateToPage = useNavigateToPage();

  const tabPaths = ['clients', 'messages', 'schedule', 'clients'];
  // The messages and schedule pages do not implement any real tabs, so if we are on the
  // messages or schedule pages we do not need a tab number in the URL.
  const tabIndex = [0, undefined, undefined, 3];

  return (index: number) => {
    navigateToPage(tabPaths[index], tabIndex[index]);
  };
};

export default usePageNavigation;
