// import pdfMake from 'pdfmake';

const fontURLS = {
  Roboto: {
    normal:
      'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
    bold:
      'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
    italics:
      'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
    bolditalics:
      'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf',
  },
};

export default async (
  documentDefinition: pdfmake.TDocumentDefinitions
): Promise<pdfmake.TCreatedPdf> => {
  const pdfMake = await import('pdfmake');
  return pdfMake.createPdf(documentDefinition, null, fontURLS);
};
