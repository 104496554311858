import React from 'react';
import { Flex, Icon } from '@chakra-ui/react';

interface IconDetailProps {
  DetailIcon: typeof Icon;
  iconColor?: string;
  children: React.ReactNode;
}

function IconDetail({
  DetailIcon,
  iconColor,
  children,
}: IconDetailProps): JSX.Element {
  return (
    <Flex direction="row">
      <Flex height="1.5em" alignItems="center" marginRight={3}>
        <DetailIcon boxSize="20px" color={iconColor} />
      </Flex>
      <Flex direction="column" textAlign="left">
        {children}
      </Flex>
    </Flex>
  );
}

IconDetail.defaultProps = {
  iconColor: 'brand.blue2',
};

export default IconDetail;
