import React from 'react';
import { Text, Box } from '@chakra-ui/react';

import { fullMonthDate, shortTime } from 'sharedHelpers/textFormat';
import Error from 'sharedIcons/Error';

import IconDetail from './IconDetail';
import EventStatusWithButton from './EventStatusWithButton';
import ViewCheckInResponseButton from '../ViewCheckInResponseButton';

interface CheckInStatusProps {
  checkInReportId?: string | null;
  submittedTimestamp?: number | null;
  endTimestamp: number;
}

const SubmittedText = ({
  submittedTimestamp,
}: {
  submittedTimestamp: number;
}) => (
  <Box>
    <Text variant="eventPopoverInformation">
      Completed at {shortTime(submittedTimestamp)} on
    </Text>
    <Text variant="eventPopoverInformation">
      {fullMonthDate(submittedTimestamp)}.
    </Text>
  </Box>
);

const IncompleteText = () => (
  <IconDetail DetailIcon={Error} iconColor="brand.orange1">
    <Text variant="eventPopoverInformation">
      This check in was not completed by its due date.
    </Text>
  </IconDetail>
);

function CheckInStatus({
  checkInReportId,
  submittedTimestamp,
  endTimestamp,
}: CheckInStatusProps): JSX.Element {
  const eventIsInThePast = Date.now() > endTimestamp;
  return (
    <EventStatusWithButton
      StatusButton={
        checkInReportId && (
          <ViewCheckInResponseButton
            checkInReportId={checkInReportId}
            variant="brandPrimary"
            marginY={0}
          />
        )
      }
    >
      {submittedTimestamp ? (
        <SubmittedText submittedTimestamp={submittedTimestamp} />
      ) : (
        eventIsInThePast && <IncompleteText />
      )}
    </EventStatusWithButton>
  );
}

CheckInStatus.defaultProps = {
  submittedTimestamp: undefined,
  checkInReportId: undefined,
};

export default CheckInStatus;
