import React from 'react';

import { Flex, Box } from '@chakra-ui/react';
import ListPanelGroup from './ListPanelGroup';
import type { InfoViewProps } from './InfoView';

function InfoViewBody(props: Omit<InfoViewProps, 'HeaderButton'>): JSX.Element {
  const {
    selectedItem,
    listPanelChildren,
    detailPanelChildren,
    title,
    numberOfItems,
    errorMessage,
  } = props;

  return (
    <Flex
      direction="row"
      height="calc(100% - 96px)"
      background="white"
      borderRadius={8}
    >
      <Flex
        borderRight="1px"
        width="33%"
        borderColor="brand.gray5"
        height="100%"
        id="clientListDashboard"
      >
        <Flex width="100%" flex="0 1 auto" paddingTop={1}>
          {!numberOfItems && (
            <Box
              height="calc(100% - 80px)"
              borderRadius={8}
              background="brand.white"
            >
              <Box padding={6}>
                <Box paddingY={2}>{errorMessage}</Box>
              </Box>
            </Box>
          )}
          {!!numberOfItems && (
            <ListPanelGroup
              key={title}
              selectedItem={selectedItem}
              title={title}
              numberOfItems={numberOfItems}
            >
              {listPanelChildren}
            </ListPanelGroup>
          )}
        </Flex>
      </Flex>
      <Flex
        borderLeft="1px"
        width="67%"
        borderColor="brand.gray5"
        flex="0 1 auto"
      >
        {detailPanelChildren}
      </Flex>
    </Flex>
  );
}
export default InfoViewBody;
