import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Input,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Box,
} from '@chakra-ui/react';

import { resetPassword } from 'sharedHelpers/firebaseAuth';
import Error from 'sharedIcons/Error';
import emailRegex from '../../../shared/constants/emailRegex';

interface PasswordResetFields {
  email: string;
}

interface ResetPasswordModalProps {
  isOpen: boolean;
  onClose: () => void;
}

function ResetPasswordModal(props: ResetPasswordModalProps): JSX.Element {
  const { isOpen, onClose } = props;

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm<PasswordResetFields>({ mode: 'onBlur' });
  const [emailSent, setEmailSent] = useState(false);

  const sendPasswordResetEmail = (formDetails: PasswordResetFields) => {
    const { email } = formDetails;
    resetPassword(email);
    setEmailSent(true);
  };

  const closeModal = () => {
    reset();
    setEmailSent(false);
    onClose();
  };

  const emailInputFields = register('email', {
    required: 'Please enter a valid email address.',
    pattern: {
      value: emailRegex,
      message: 'Please enter a valid email address.',
    },
  });
  const emailValue = watch('email');

  const formIsValid = emailValue?.length && !errors.email;

  return (
    <Modal isOpen={isOpen} onClose={closeModal}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Reset Password</ModalHeader>
        {!emailSent && (
          <>
            <ModalBody>
              <form
                id="resetPasswordForm"
                noValidate
                onSubmit={handleSubmit(sendPasswordResetEmail)}
              >
                <FormControl
                  isInvalid={!!errors.email}
                  marginX="auto"
                  paddingTop={4}
                  paddingBottom={errors.email ? 0 : 8}
                  width="calc(100% - 48px)"
                  isRequired
                >
                  <FormLabel>Email</FormLabel>
                  <Input
                    type="email"
                    placeholder="Email"
                    width="100%"
                    {...emailInputFields} // eslint-disable-line react/jsx-props-no-spreading
                  />
                  <FormErrorMessage color="brand.orange1">
                    <Error /> {errors.email?.message}
                  </FormErrorMessage>
                </FormControl>
              </form>
            </ModalBody>

            <ModalFooter justifyContent="flex-start" paddingTop={0}>
              <Button
                type="submit"
                form="resetPasswordForm"
                margin={4}
                backgroundColor="brand.blue2"
                color="brand.white"
                isDisabled={!formIsValid}
              >
                Send reset email
              </Button>
              <Button variant="ghost" onClick={closeModal}>
                Cancel
              </Button>
            </ModalFooter>
          </>
        )}
        {emailSent && (
          <>
            <ModalBody>
              <Box>
                Check your email for instructions to reset your password.
              </Box>
            </ModalBody>
            <ModalFooter>
              <Button onClick={closeModal}>Close</Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
}

export default ResetPasswordModal;
