import React from 'react';
import { Flex } from '@chakra-ui/react';

import { EventType } from 'generated/graphql';
import { isEventWithEndTime } from 'sharedHelpers/typeNarrowing';
import { OfficeEventWithClients } from 'src/shared/types/clientTypes';

import EventDetailsPopoverBase from './EventDetailsPopoverBase';
import DateAndSingleTime from './DateAndSingleTime';
import DateWithStartAndEndTime from './DateWithStartAndEndTime';
import DateTimeInformation from './DateTimeInformation';
import ClientInformation from './ClientInformation';
import StaffInformation from './StaffInformation';
import LocationInformation from './LocationInformation';

interface OfficeEventPopoverProps {
  event: OfficeEventWithClients;
}

function OfficeEventPopover({ event }: OfficeEventPopoverProps): JSX.Element {
  return (
    <EventDetailsPopoverBase event={{ ...event, type: EventType.Office }}>
      <Flex direction="column" rowGap={3}>
        <DateTimeInformation>
          {isEventWithEndTime(event) ? (
            <DateWithStartAndEndTime
              event={{ ...event, type: EventType.Office }}
            />
          ) : (
            <DateAndSingleTime event={{ ...event, type: EventType.Office }} />
          )}
        </DateTimeInformation>
        <ClientInformation
          id={event.clientId}
          firstName={event.clientFirstName}
          lastName={event.clientLastName}
          groupEventClients={event.eventClients}
        />
        <StaffInformation id={event.staffId} />
        <LocationInformation location={event.location} room={event.room} />
      </Flex>
    </EventDetailsPopoverBase>
  );
}

export default OfficeEventPopover;
