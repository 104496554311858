import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { parse, format } from 'date-fns';
import {
  Box,
  Text,
  Stack,
  Flex,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Switch,
  Skeleton,
  Textarea,
} from '@chakra-ui/react';
import CustomTimepicker, {
  timePickerFormat,
} from 'sharedComponents/CustomTimepicker';
import { isStaffUser } from 'sharedHelpers/typeNarrowing';
import { useGetQuietHoursQuery } from 'generated/graphql';
import useSaveQuietHours from 'sharedHooks/useSaveQuietHours';
import Error from 'sharedIcons/Error';

export interface QuietHoursFormFields {
  autoreply?: string;
  autoreply_enabled?: boolean;
  enabled?: boolean;
  end?: number;
  id?: string;
  start?: number;
  weekends_off?: boolean;
}

interface EditQuietHoursProps {
  formId: string;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  onClose: () => void;
}

const styles = {
  formControl: {
    marginBottom: 4,
    marginRight: 5,
    paddingRight: 2,
  },
  formLabel: {
    fontSize: 'smaller',
    fontWeight: 'normal',
  },
};

/** Using Jan 2 instead of Jan 1 guarantees that all times will be > 0
 * and that later times will be > earlier times
 * */
const dateSmallEnoughToFitInInt = '1970-01-02';

const smallDateWithTimeSet = (timeString: string): number =>
  Number(
    parse(timeString, timePickerFormat, new Date(dateSmallEnoughToFitInInt))
  );

const toValidDateTime = (value: number | Date): number => {
  if (value < 0) {
    const timeString = format(value, timePickerFormat);
    return smallDateWithTimeSet(timeString);
  }
  return Number(value);
};

const defaultValues = {
  enabled: false,
  start: smallDateWithTimeSet('5:00 PM'),
  end: smallDateWithTimeSet('9:00 AM'),
  weekends_off: false,
  autoreply_enabled: false,
  autoreply:
    'Automated Reply: I am currently out of the office and may not be able to respond immediately.',
};

function EditQuietHoursForm(props: EditQuietHoursProps): JSX.Element {
  const { formId, setLoading, onClose } = props;
  const [formLoading, setFormLoading] = useState(true);
  const [staffId, setStaffId] = useState<string>();

  const { data: serverData, error: serverError } = useGetQuietHoursQuery();
  const [save, { complete, loading, error }] = useSaveQuietHours();

  const {
    handleSubmit,
    register,
    watch,
    reset,
    control,
    formState: { errors: formErrors },
  } = useForm<QuietHoursFormFields>({ defaultValues });

  const watchEnabled = watch('enabled');
  const watchAutoreplyEnabled = watch('autoreply_enabled');

  useEffect(() => {
    if (serverError) {
      // show an error message
    }
    if (serverData && isStaffUser(serverData.getUser)) {
      const formValues = { ...defaultValues, ...serverData.getUser.quietHours };
      reset(formValues);
      setStaffId(serverData.getUser.id);
      setFormLoading(false);
    }
  }, [serverData, serverError]);

  useEffect(() => {
    if (error) {
      // show a short-lived error like a toast or something asking user to try again.
      // also send to rollbar.
    }
    if (loading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
    if (complete) {
      // complete means we saved the form successfully so we can close it.
      onClose();
    }
  }, [error, loading, complete]);

  const onSubmit = async (formValues: QuietHoursFormFields) => {
    const nonNulls = Object.entries(formValues).reduce((safeValues, entry) => {
      const [key, value] = entry;
      if ([undefined, null].includes(value)) {
        return safeValues;
      }
      if (['start', 'end'].includes(key)) {
        return { ...safeValues, [key]: toValidDateTime(value) };
      }
      return { ...safeValues, [key]: value };
    }, {});
    save({ ...defaultValues, ...nonNulls, staffId });
  };

  const validateTime = (timeString: string) =>
    (watchEnabled ? !!timeString : true) ||
    'You must provide a time to start silencing alerts';

  return (
    <Box
      backgroundColor="brand.gray8"
      color="brand.gray1"
      paddingX={8}
      paddingY={4}
      borderRadius="8px"
    >
      <form id={formId} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={10} marginY={8}>
          <Stack direction="row" spacing={6}>
            <Skeleton isLoaded={!formLoading} height="16px">
              <Switch
                size="sm"
                marginTop={1}
                aria-label="Enable quiet hours"
                {...register('enabled')} // eslint-disable-line react/jsx-props-no-spreading
              />
            </Skeleton>
            <Stack direction="column">
              <Text>Quiet Hours</Text>
              <Text fontSize="sm" marginBottom={4}>
                During quiet hours you will not receive individual email alerts
                from Uptrust. Instead, those alerts will be delivered to you in
                a single email once a day.
              </Text>
              <Stack direction="row" spacing={5}>
                <FormControl
                  width="200px"
                  isRequired
                  isInvalid={watchEnabled && !!formErrors.start}
                >
                  <FormLabel
                    fontSize={styles.formLabel.fontSize}
                    fontWeight={styles.formLabel.fontWeight}
                    htmlFor="start-quiet-hours"
                  >
                    Begin Quiet Hours
                  </FormLabel>
                  <Skeleton
                    isLoaded={!formLoading}
                    data-testid="timepicker-start"
                  >
                    <CustomTimepicker
                      id="start-quiet-hours"
                      fieldName="start"
                      control={control}
                      rules={{
                        validate: validateTime,
                      }}
                      disabled={!watchEnabled}
                    />
                    <FormErrorMessage color="brand.orange1">
                      <Error />
                      {formErrors.start?.message}
                    </FormErrorMessage>
                  </Skeleton>
                </FormControl>
                <FormControl
                  width="200px"
                  isRequired
                  isInvalid={watchEnabled && !!formErrors.end}
                >
                  <FormLabel
                    fontSize={styles.formLabel.fontSize}
                    fontWeight={styles.formLabel.fontWeight}
                    htmlFor="end-quiet-hours"
                  >
                    End Quiet Hours
                  </FormLabel>
                  <Skeleton
                    isLoaded={!formLoading}
                    data-testid="timepicker-end"
                  >
                    <CustomTimepicker
                      id="end-quiet-hours"
                      fieldName="end"
                      control={control}
                      rules={{
                        validate: validateTime,
                      }}
                      disabled={!watchEnabled}
                    />
                  </Skeleton>
                  <FormErrorMessage color="brand.orange1">
                    <Error /> {formErrors.end?.message}
                  </FormErrorMessage>
                </FormControl>
              </Stack>
            </Stack>
          </Stack>

          {watchEnabled && (
            <>
              <Stack direction="row" spacing={6}>
                <Skeleton isLoaded={!formLoading} height="16px">
                  <Switch
                    aria-label="Enable quiet weekends"
                    size="sm"
                    marginTop={1}
                    {...register('weekends_off')} // eslint-disable-line react/jsx-props-no-spreading
                  />
                </Skeleton>
                <Flex direction="column">
                  <Text>Quiet Weekends</Text>
                  <Text fontSize="sm">
                    This extends quiet hours to include all day Saturday and all
                    day Sunday.
                  </Text>
                </Flex>
              </Stack>

              <Stack direction="row" spacing={6}>
                <Skeleton isLoaded={!formLoading} height="16px">
                  <Switch
                    aria-label="Enable autoreply"
                    size="sm"
                    marginTop={1}
                    {...register('autoreply_enabled')} // eslint-disable-line react/jsx-props-no-spreading
                  />
                </Skeleton>
                <Flex direction="column">
                  <FormLabel marginBottom={0}>Auto-reply</FormLabel>
                  <Text fontSize="sm" marginBottom={4}>
                    Auto-reply will automatically send the response below when a
                    client texts you during quiet hours.
                  </Text>

                  <FormControl
                    id="auto-reply-text"
                    marginBottom={styles.formControl.marginBottom}
                    isInvalid={!!formErrors.autoreply}
                  >
                    <Skeleton isLoaded={!formLoading}>
                      <Textarea
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...register('autoreply', {
                          validate: (v) =>
                            (watchAutoreplyEnabled ? !!v : true) ||
                            'You must provide an autoreply message',
                        })}
                        backgroundColor="brand.white"
                        resize="none"
                        rows={4}
                        isDisabled={!watchAutoreplyEnabled}
                        aria-label="Autoreply message"
                      />
                    </Skeleton>
                    <FormErrorMessage color="brand.orange1">
                      <Error />
                      {formErrors.autoreply?.message}
                    </FormErrorMessage>
                  </FormControl>
                </Flex>
              </Stack>
            </>
          )}
        </Stack>
      </form>
    </Box>
  );
}

export default EditQuietHoursForm;
