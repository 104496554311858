import React from 'react';
import { Box, Tooltip } from '@chakra-ui/react';
import Error from 'sharedIcons/Error';

interface BadgeProps {
  color: string;
  lastSend?: string;
  carrierType?: string;
}

interface BadgeInfo {
  Icon: typeof Error;
  tooltip?: string;
  showBadge: boolean;
}

const getClientBadgeInfo = (lastSend?: string, carrierType?: string) => {
  let error: BadgeInfo = { showBadge: false, Icon: Error };
  if (carrierType === 'landline') {
    error = {
      Icon: Error,
      tooltip: 'Client phone is a landline',
      showBadge: true,
    };
  }
  if (lastSend === 'error') {
    error = {
      Icon: Error,
      tooltip: 'There was an issue sending the latest message',
      showBadge: true,
    };
  }
  return error;
};

function Badge(props: BadgeProps): JSX.Element {
  let badge = <></>;
  const { lastSend, carrierType, color } = props;
  const { Icon, tooltip, showBadge } = getClientBadgeInfo(
    lastSend,
    carrierType
  );
  if (showBadge) {
    badge = (
      <Tooltip
        hasArrow
        label={tooltip}
        background="brand.gray7"
        color="brand.gray2"
      >
        <span>
          <Icon color={color} role="img" boxSize="20px" />
        </span>
      </Tooltip>
    );
  }
  return <Box data-testid="badge">{badge}</Box>;
}

Badge.defaultProps = {
  lastSend: '',
  carrierType: '',
};

export default Badge;
