import React from 'react';
import { Stack, Skeleton } from '@chakra-ui/react';
import MessageBubbleSkeleton from './MessageBubbleSkeleton';

function MessagesListSkeleton(): JSX.Element {
  return (
    <>
      {Array.from({ length: 16 }, (_, k) => (
        <Stack direction="column" key={k}>
          <Skeleton
            width="100%"
            height="16px"
            data-testid="message-list-skeleton"
          />
          <MessageBubbleSkeleton
            avatar={k % 2 === 0}
            outgoing={k % 2 === 0}
            incoming={k % 2 !== 0}
          />
        </Stack>
      ))}
    </>
  );
}

export default MessagesListSkeleton;
