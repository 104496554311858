import { useState, useEffect } from 'react';
import type { AllStatsType } from './useClientsDashboardStats';

interface EngagementStatsData {
  platformMessages: number;
  clientManualMessages: number;
  staffManualMessages: number;
}

interface UseEngagementStatsParams {
  allStats: AllStatsType;
}
const blankStats = {
  platformMessages: 0,
  clientManualMessages: 0,
  staffManualMessages: 0,
};

function useEngagementStats({
  allStats,
}: UseEngagementStatsParams): {
  stats: EngagementStatsData;
} {
  const [stats, setStats] = useState<EngagementStatsData>(blankStats);

  useEffect(() => {
    if (allStats) {
      setStats({
        platformMessages: allStats.engagementTripleStackMessages.fromPlatform,
        clientManualMessages:
          allStats.engagementTripleStackMessages.fromEndUser,
        staffManualMessages: allStats.engagementTripleStackMessages.fromStaff,
      });
    }
  }, [allStats]);

  return { stats };
}

export default useEngagementStats;
