import React from 'react';
import { Button, useDisclosure } from '@chakra-ui/react';
import EditDrawer from 'sharedComponents/EditDrawer';
import EditNotesForm from 'sharedComponents/EditNotesForm';

interface EditNotesButtonProps {
  notes: string | undefined | null;
  clientId: string;
}

interface NotesFormFields {
  notes: string | undefined | null;
  clientId: string;
}

function EditNotesButton(props: EditNotesButtonProps): JSX.Element {
  const { notes, clientId } = props;
  const { onOpen, isOpen, onClose } = useDisclosure();
  return (
    <>
      <Button
        size="xs"
        minWidth="60px"
        onClick={onOpen}
        backgroundColor="brand.gray7"
        color="brand.gray2"
      >
        Edit
      </Button>
      <EditDrawer<NotesFormFields>
        isOpen={isOpen}
        onClose={onClose}
        FormContent={EditNotesForm}
        title="Edit Notes"
        formData={{ notes, clientId }}
        formId="editNotesForm"
      />
    </>
  );
}

export default EditNotesButton;
