const Languages = {
  ENGLISH: { name: 'English', abbreviation: 'en-US' },
  SPANISH: { name: 'Spanish', abbreviation: 'es-US' },
  RUSSIAN: { name: 'Russian', abbreviation: 'ru-RU' },
  CHINESE: { name: 'Chinese', abbreviation: 'zh-CN' },
  OTHER: { name: 'Other', abbreviation: 'other' },
} as const;

export default Languages;

export type LanguageNames =
  | 'English'
  | 'Spanish'
  | 'Russian'
  | 'Chinese'
  | 'Other';
