import React from 'react';
import {
  Box,
  Flex,
  Heading,
  Portal,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
} from '@chakra-ui/react';
import type { ClientEvent } from '../../../../shared/types/clientTypes';
import EditEventAllAttendance from '../EditEventAllAttendance';

interface EventDetailsAttendancePopoverProps {
  event: ClientEvent;
  children?: React.ReactChild;
  setAllClient: (outcomeId: string | undefined) => void;
}

function EventDetailsAttendancePopover({
  event,
  children,
  setAllClient,
}: EventDetailsAttendancePopoverProps): JSX.Element {
  return (
    <Portal>
      <PopoverContent width="395px" height="415px">
        <PopoverArrow />

        <PopoverHeader textAlign="left">
          <Flex
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Heading
              size="md"
              fontWeight="700"
              color="brand.gray1"
              paddingX={2}
              fontSize="14px"
            >
              {`${event.eventClients?.length} clients`}
            </Heading>
            <Flex>
              <EditEventAllAttendance setAllClient={setAllClient} />
              <PopoverCloseButton
                position="inherit"
                size="sm"
                width="32px"
                height="32px"
                color="brand.gray3"
                variant="ghost"
                as={PopoverCloseButton}
              />
            </Flex>
          </Flex>
        </PopoverHeader>
        <PopoverBody
          backgroundColor="brand.gray7"
          height="100%"
          padding={0}
          minWidth="393px"
        >
          <Box>{children}</Box>
        </PopoverBody>
      </PopoverContent>
    </Portal>
  );
}

EventDetailsAttendancePopover.defaultProps = {
  children: <></>,
};

export default EventDetailsAttendancePopover;
