import React from 'react';
import { Text, Flex, Icon, useStyleConfig } from '@chakra-ui/react';
import Circle from 'sharedIcons/Circle';

interface StatsTidbit {
  textTemplate: string;
  count: number;
  total: number;
}

interface StatsTidbitRowProps {
  RowIcon?: typeof Icon | undefined;
  tidbit: StatsTidbit;
}

function StatsTidbitRow({
  RowIcon,
  tidbit: { textTemplate, count, total },
}: StatsTidbitRowProps): JSX.Element {
  const mainStatLine = textTemplate.replace(
    '{count}',
    new Intl.NumberFormat().format(count)
  );
  const percentageText = total
    ? `${Math.round((count / total) * 100)}%`
    : 'of 0';
  const bulletVariant = RowIcon ? 'withIcon' : 'noIcon';
  const styles = useStyleConfig('StatsTidbitRowBullet', {
    variant: bulletVariant,
  });
  const BulletIcon = RowIcon ?? Circle;
  return (
    <Flex
      alignItems="center"
      paddingTop={2}
      paddingBottom={1}
      fontSize={{ base: 'sm', lg: 'md', xl: 'lg' }}
    >
      <BulletIcon __css={styles} />
      <Flex>
        <Text color="brand.gray1" paddingRight={1}>
          {mainStatLine}
        </Text>
        <Text color="brand.gray3">({percentageText})</Text>
      </Flex>
    </Flex>
  );
}

StatsTidbitRow.defaultProps = {
  RowIcon: undefined,
};

export default StatsTidbitRow;
