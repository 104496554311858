import React, { useState, useEffect } from 'react';
import {
  Tabs,
  Tab,
  TabList,
  TabPanels,
  TabPanel,
  Spacer,
  Box,
} from '@chakra-ui/react';

import EventAccordion from './EventAccordion';
import EventList from './ScheduleDetailsEventList';
import EventClientDetails from './EventClientDetails';

interface ScheduleDetailsPanelProps {
  clientId: string;
}

function ScheduleDetailsPanel(props: ScheduleDetailsPanelProps): JSX.Element {
  const { clientId } = props;
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const handleTabsChange = (index: number) => {
    setSelectedTabIndex(index);
  };

  useEffect(() => {
    setSelectedTabIndex(0);
  }, [clientId]);

  return (
    <Tabs
      isLazy
      index={selectedTabIndex}
      onChange={handleTabsChange}
      variant="unstyled"
      height="100%"
      paddingX="30px"
    >
      <TabList paddingX={8} paddingY={3} color="brand.gray5" borderBottom="1px">
        <Box marginX="0" borderColor="brand.gray5" height={8}>
          <Tab
            marginBottom={1}
            paddingBottom={1}
            marginX={5}
            paddingX={0}
            paddingTop={1}
            color="brand.gray1"
            fontSize="15px"
            _selected={{
              borderBottom: '1px',
              color: 'brand.blue2',
              borderColor: '#307FE2',
            }}
          >
            Upcoming Events
          </Tab>
        </Box>
        <Box marginX="0" height={8}>
          <Tab
            marginBottom={1}
            paddingBottom={1}
            marginX={5}
            paddingX={0}
            paddingTop={1}
            color="brand.gray1"
            fontSize="14px"
            fontWeight={400}
            _selected={{
              borderBottom: '1px',
              color: 'brand.blue2',
              borderColor: '#307FE2',
            }}
          >
            Past Events
          </Tab>
        </Box>
        <Spacer />
      </TabList>
      <TabPanels height="calc(100% - 57px - 0.5rem)">
        <TabPanel padding="0" height="100%">
          <EventList
            EventChild={EventClientDetails}
            clientId={clientId}
            period="upcoming"
          />
        </TabPanel>
        <TabPanel padding="0" height="100%">
          <EventList
            EventChild={EventClientDetails}
            clientId={clientId}
            period="past"
          />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}

export default ScheduleDetailsPanel;
