import React, { useEffect } from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { datadogInit } from 'sharedHelpers/datadogRUM';
import LoginPage from './pages/login';

import theme from './shared/theme';

import AuthProvider from './shared/components/AuthProvider';
import AuthRequiredRouter from './shared/components/AuthRequiredRouter';
import ValidateFirebaseFunction from './pages/login/components/ValidateFirebaseFunction';

function App(): JSX.Element {
  useEffect(() => {
    datadogInit();
  }, []);
  return (
    <BrowserRouter>
      <ChakraProvider theme={theme}>
        <Switch>
          <Route path="/login">
            <LoginPage />
          </Route>
          <Route path="/validateFirebase">
            <ValidateFirebaseFunction />
          </Route>
          <Route>
            <AuthProvider>
              <AuthRequiredRouter />
            </AuthProvider>
          </Route>
        </Switch>
      </ChakraProvider>
    </BrowserRouter>
  );
}

export default App;
