import React from 'react';
import { Flex, Text, Spacer } from '@chakra-ui/react';

import { fullMonthDate } from 'sharedHelpers/textFormat';

function StatusIcons({
  mini = false,
  closedAt,
}: {
  mini: boolean;
  closedAt: number | null | undefined;
}): JSX.Element {
  const clientDate = fullMonthDate(closedAt);
  return (
    <Flex color="brand.gray2">
      {mini ? (
        <Text size="sm" paddingX={2}>
          INACTIVE
        </Text>
      ) : (
        <>
          {closedAt ? (
            <Text>This client was set to inactive on {clientDate}.</Text>
          ) : (
            <Text>Client file is inactive.</Text>
          )}
          <Spacer />
        </>
      )}
    </Flex>
  );
}
export default StatusIcons;
