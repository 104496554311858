import React, { ComponentType } from 'react';

import { useRadioGroup } from '@chakra-ui/react';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';

import useNavigateToClient from 'sharedHooks/useNavigateToClient';
import { newClientId } from 'sharedHooks/useSaveClient';

/** This component is intended to be internal to InfoView. It provides the
 * context RadioGroupContext that is consumed by InfoView listPanelChildren.
 */

interface GetRadioPropsArgs {
  value: string;
}

interface RadioProps {
  onChange?: (e: React.ChangeEvent<HTMLInputElement> | string | number) => void;
  value?: string | number;
}

interface RadioContextDefault {
  getRadioProps: (data: GetRadioPropsArgs) => RadioProps;
}

export const RadioGroupContext = React.createContext<RadioContextDefault>({
  getRadioProps: () => ({}),
});

interface ListPanelGroupProps {
  selectedItem: string | undefined;
  children: ComponentType<ListChildComponentProps<unknown>> & React.ReactNode;
  title: string;
  numberOfItems: number;
}

function ListPanelGroup(props: ListPanelGroupProps): JSX.Element {
  const { selectedItem, children, title, numberOfItems } = props;

  const navigateToClient = useNavigateToClient();

  const setSelectedItem = (item: string | undefined) => {
    if (item) {
      newClientId('');
      navigateToClient(item);
    }
  };

  const { getRadioProps } = useRadioGroup({
    onChange: (e) => setSelectedItem(e),
    name: title,
    value: selectedItem,
  });

  return (
    <RadioGroupContext.Provider value={{ getRadioProps }}>
      <AutoSizer>
        {({ height, width }) => (
          <FixedSizeList
            height={height}
            itemCount={numberOfItems}
            itemSize={56}
            width={width}
          >
            {children}
          </FixedSizeList>
        )}
      </AutoSizer>
    </RadioGroupContext.Provider>
  );
}

export default ListPanelGroup;
