import { EventType, CheckInEvent } from 'generated/graphql';
import type { ClientEvent } from '../types/clientTypes';

type DateableEvent = Partial<ClientEvent> &
  Pick<ClientEvent, 'type'> &
  (
    | Pick<ClientEvent, 'startTimestamp'>
    | Pick<CheckInEvent, 'endTimestamp'>
    | Pick<ClientEvent, 'timestamp'>
  );

/**
 *
 * @param event The event whos date to fetch
 * @returns the timestamp (number) which is the date to emphasized for the passed in event
 * - If timestamp is on the event, return timestamp (defer emphasis decision to backend)
 * - For CheckIn events, return endTimestamp
 * - For CourtDate, Office, Virtual events, return startTimestamp
 */
export const isDateableEvent = (
  event: Partial<ClientEvent>
): event is DateableEvent =>
  !!event.type &&
  Object.values(EventType).includes(event.type) &&
  (!!event.timestamp ||
    !!event.startTimestamp ||
    (event.type === EventType.CheckIn && !!event.endTimestamp));

export default (event: DateableEvent): number => {
  if (event.timestamp) {
    return event.timestamp;
  }
  return event.type === EventType.CheckIn
    ? event.endTimestamp || 0
    : event.startTimestamp || 0;
};
