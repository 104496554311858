import React from 'react';
import { useReactiveVar } from '@apollo/client';
import {
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  CloseButton,
} from '@chakra-ui/react';
import { searchTextVar } from 'sharedHooks/useFilterClients';
import Search from 'sharedIcons/Search';

function ClientSearchBar(): JSX.Element {
  const searchText = useReactiveVar(searchTextVar);
  return (
    <InputGroup width="370px" background="brand.white" borderRadius={4}>
      <InputLeftElement pointerEvents="none">
        <Search color="brand.gray4" boxSize={5} />
      </InputLeftElement>
      <Input
        placeholder="Search for a client"
        value={searchText}
        onChange={(e) => searchTextVar(e.target.value)}
      />
      <InputRightElement>
        <CloseButton
          size="sm"
          visibility={searchText.length ? 'visible' : 'hidden'}
          onClick={() => searchTextVar('')}
        />
      </InputRightElement>
    </InputGroup>
  );
}

export default React.memo(ClientSearchBar);
