import React from 'react';
import { Skeleton } from '@chakra-ui/react';

function ClientDetailItemSkeleton(): JSX.Element {
  return (
    <>
      <Skeleton height="20px" width="100px" />
      <Skeleton height="20px" />
    </>
  );
}

export default ClientDetailItemSkeleton;
