import {
  useUpdateCourtDateAttendanceMutation,
  useUpdateOfficeEventAttendanceMutation,
} from 'generated/graphql';
import type { ClientEvent } from '../types/clientTypes';

export type UpdateMutationType = ReturnType<typeof useUpdateEventAttendance>;

const useUpdateEventAttendance = (
  event: ClientEvent,
  eventId?: string
): {
  mutationFunction:
    | ReturnType<typeof useUpdateOfficeEventAttendanceMutation>[0]
    | ReturnType<typeof useUpdateCourtDateAttendanceMutation>[0]
    | null;
  eventId: string;
  loading: boolean;
} => {
  const eventIdForMutation = eventId || event.id;
  let mutationTuple;
  switch (event.type) {
    case 'COURT_DATE':
      mutationTuple = useUpdateCourtDateAttendanceMutation({
        variables: { id: eventIdForMutation },
      });
      break;
    case 'OFFICE':
      mutationTuple = useUpdateOfficeEventAttendanceMutation({
        variables: { id: eventIdForMutation },
      });
      break;
    default:
      return {
        mutationFunction: null,
        eventId: eventIdForMutation,
        loading: false,
      };
  }
  const [mutationFunction, { loading }] = mutationTuple;
  return { mutationFunction, eventId: eventIdForMutation, loading };
};

export default useUpdateEventAttendance;
