import type { Client } from 'generated/graphql';

export type SortableClient = Partial<Client> &
  Pick<Client, 'id' | 'lastName' | 'firstName'>;

type NameableClient = Partial<Client> & Pick<Client, 'lastName' | 'firstName'>;

export const clientListName = (client: SortableClient): string =>
  `${client.lastName}, ${client.firstName}`;

const clientSortName = (client: SortableClient): string =>
  clientListName(client).toLowerCase();

export const clientNameComparator = (
  a: SortableClient,
  b: SortableClient
): number => {
  const clientAName = clientSortName(a);
  const clientBName = clientSortName(b);
  if (clientAName < clientBName) {
    return -1;
  }
  if (clientAName > clientBName) {
    return 1;
  }
  return 0;
};

export const filterClosedClients = (clients: Client[]): Client[] =>
  clients.filter((client) => !client.closed);

export const alphabetizeClients = (clients: Client[]): Client[] =>
  clients.sort(clientNameComparator);

export const clientFullName = (client: Client): string =>
  `${client.firstName} ${client.lastName}`.toLowerCase();

export const clientDisplayName = (client: NameableClient): string =>
  `${client.firstName} ${client.lastName}`;

export const clientPhoneNumber = (client: Client): string => `${client.phone}`;
