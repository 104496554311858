import React from 'react';
import { SkeletonCircle, Skeleton, Stack, Spacer, Box } from '@chakra-ui/react';

interface MessageBubbleProps {
  avatar?: boolean;
  outgoing?: boolean;
  incoming?: boolean;
}

function MessageBubble(props: MessageBubbleProps): JSX.Element {
  const { avatar, outgoing, incoming } = props;
  const messageDirection = outgoing ? 'row-reverse' : 'row';
  const marginRight = incoming ? 14 : 0;
  const marginLeft = outgoing ? 14 : 0;

  return (
    <Box marginY={3}>
      <Stack
        direction={messageDirection}
        spacing={3}
        marginRight={marginRight}
        marginLeft={marginLeft}
      >
        {avatar && <SkeletonCircle size="56px" />}
        <Skeleton
          borderRadius={10}
          paddingX={4}
          paddingY={2}
          height="96px"
          width="400px"
        />
        <Spacer />
      </Stack>
    </Box>
  );
}

MessageBubble.defaultProps = {
  avatar: false,
  outgoing: false,
  incoming: false,
};

export default MessageBubble;
