import { useEffect } from 'react';
import useIdentifyUser, {
  UserIdentificationData,
} from 'sharedHooks/useIdentifyUser';

const zendeskIdentify = (user: UserIdentificationData) => {
  if (typeof window.zE?.identify === 'function') {
    window.zE.identify(user);
  }
};

const useZendeskIdentify = (): void => {
  const user = useIdentifyUser();
  useEffect(() => {
    if (user && user.id) {
      zendeskIdentify(user);
    }
    return () => {
      if (window.zE) {
        window.zE('webWidget', 'logout');
      }
    };
  }, [user]);
};

export default useZendeskIdentify;
