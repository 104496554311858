
      export type PossibleTypesResultData = {
  "possibleTypes": {
    "Badges": [
      "EndUserBadges",
      "StaffBadges"
    ],
    "BankedQuestion": [
      "MultipleChoiceBankedQuestion",
      "TextBankedQuestion"
    ],
    "BasePlatformMessage": [
      "FromPlatformEventMessage",
      "FromPlatformMessage"
    ],
    "CheckInQuestion": [
      "MultipleChoiceQuestion",
      "TextQuestion"
    ],
    "Event": [
      "CheckInEvent",
      "CourtDateEvent",
      "OfficeEvent",
      "VirtualEvent"
    ],
    "FullCheckInQuestion": [
      "FullMultipleChoiceQuestion",
      "FullTextQuestion"
    ],
    "Message": [
      "AutomatedAttendanceMessage",
      "FromEndUserMessage",
      "FromPlatformEventMessage",
      "FromPlatformMessage",
      "FromStaffMessage",
      "UndefinedMessage"
    ],
    "Node": [
      "Announcement",
      "AutomatedAttendanceMessage",
      "BankedChoice",
      "CarrierCheck",
      "Case",
      "CheckInEvent",
      "CheckInPage",
      "CheckInReport",
      "CheckInReportPage",
      "CheckInTemplate",
      "Choice",
      "Client",
      "Cohort",
      "Condition",
      "Contact",
      "CourtDateEvent",
      "EndUser",
      "FromEndUserMessage",
      "FromPlatformEventMessage",
      "FromPlatformMessage",
      "FromStaffMessage",
      "FullCheckInPage",
      "FullCheckInTemplate",
      "FullChoice",
      "FullMultipleChoiceQuestion",
      "FullTextQuestion",
      "GroupEvent",
      "Judge",
      "Location",
      "MessageThread",
      "MultipleChoiceBankedQuestion",
      "MultipleChoiceQuestion",
      "OfficeEvent",
      "Organization",
      "Outcome",
      "PhoneClaim",
      "QuietHours",
      "Restitution",
      "Room",
      "StaffUser",
      "Template",
      "Terms",
      "TextBankedQuestion",
      "TextQuestion",
      "UndefinedMessage",
      "VirtualEvent"
    ],
    "SmsSender": [
      "Client",
      "Contact"
    ],
    "User": [
      "EndUser",
      "StaffUser"
    ]
  }
};
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "Badges": [
      "EndUserBadges",
      "StaffBadges"
    ],
    "BankedQuestion": [
      "MultipleChoiceBankedQuestion",
      "TextBankedQuestion"
    ],
    "BasePlatformMessage": [
      "FromPlatformEventMessage",
      "FromPlatformMessage"
    ],
    "CheckInQuestion": [
      "MultipleChoiceQuestion",
      "TextQuestion"
    ],
    "Event": [
      "CheckInEvent",
      "CourtDateEvent",
      "OfficeEvent",
      "VirtualEvent"
    ],
    "FullCheckInQuestion": [
      "FullMultipleChoiceQuestion",
      "FullTextQuestion"
    ],
    "Message": [
      "AutomatedAttendanceMessage",
      "FromEndUserMessage",
      "FromPlatformEventMessage",
      "FromPlatformMessage",
      "FromStaffMessage",
      "UndefinedMessage"
    ],
    "Node": [
      "Announcement",
      "AutomatedAttendanceMessage",
      "BankedChoice",
      "CarrierCheck",
      "Case",
      "CheckInEvent",
      "CheckInPage",
      "CheckInReport",
      "CheckInReportPage",
      "CheckInTemplate",
      "Choice",
      "Client",
      "Cohort",
      "Condition",
      "Contact",
      "CourtDateEvent",
      "EndUser",
      "FromEndUserMessage",
      "FromPlatformEventMessage",
      "FromPlatformMessage",
      "FromStaffMessage",
      "FullCheckInPage",
      "FullCheckInTemplate",
      "FullChoice",
      "FullMultipleChoiceQuestion",
      "FullTextQuestion",
      "GroupEvent",
      "Judge",
      "Location",
      "MessageThread",
      "MultipleChoiceBankedQuestion",
      "MultipleChoiceQuestion",
      "OfficeEvent",
      "Organization",
      "Outcome",
      "PhoneClaim",
      "QuietHours",
      "Restitution",
      "Room",
      "StaffUser",
      "Template",
      "Terms",
      "TextBankedQuestion",
      "TextQuestion",
      "UndefinedMessage",
      "VirtualEvent"
    ],
    "SmsSender": [
      "Client",
      "Contact"
    ],
    "User": [
      "EndUser",
      "StaffUser"
    ]
  }
};
      export default result;
    