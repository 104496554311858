import React, { useEffect, useState } from 'react';
import {
  Flex,
  Text,
  Circle as CircleContainer,
  Popover,
  PopoverTrigger,
  PopoverAnchor,
  IconButton,
  Divider,
  Center,
} from '@chakra-ui/react';

import { isAfter, startOfHour, subMonths } from 'date-fns';

import LocationCity from 'sharedIcons/LocationCity';
import AccountBalance from 'sharedIcons/AccountBalance';
import AssignmentInd from 'sharedIcons/AssignmentInd';
import Videocam from 'sharedIcons/Videocam';
import Assignment from 'sharedIcons/Assignment';
import Circle from 'sharedIcons/Circle';
import AttendanceSelectionMenu from 'sharedComponents/AttendanceSelectionMenu';
import eventDisplayTitle from 'sharedHelpers/eventDisplayTitle';
import emphasizedDateTime from 'sharedHelpers/emphasizedDateTime';
import { shortTime } from 'sharedHelpers/textFormat';
import { EventType } from 'generated/graphql';

import { hasAttendance } from 'sharedHelpers/typeNarrowing';
import EventAttendanceStatus from 'sharedComponents/EventAttendanceStatus';
import DashboardAttendanceResumen from 'sharedComponents/DashboardAttendanceResumen';

import EventDetailsPopover from './EventDetailsPopover/EventDetailsPopover';
import type { ClientEvent } from '../../../shared/types/clientTypes';
import EventDetailsAttendancePopover from './EventDetailsPopover/EventDetailsAttendancePopover';
import EventDetailsSingleAttendancePopover from './EventDetailsPopover/EventDetailsSingleAttendancePopover';
import getAttendanceStatsEvents from '../../../shared/graphql/queries/getAttendanceStatsEvents';

interface DashboardEventRowProps {
  event: ClientEvent;
}

const eventIcons = {
  [EventType.Office]: LocationCity,
  [EventType.CourtDate]: AccountBalance,
  [EventType.Virtual]: Videocam,
  [EventType.CheckIn]: Assignment,
};

export const timeString = (event: ClientEvent): string => {
  switch (event.type) {
    case EventType.Virtual:
      return `${shortTime(event.startTimestamp)} to ${shortTime(
        Number(event.endTimestamp)
      )}`;
    case EventType.Office:
    case EventType.CourtDate:
      return `${shortTime(emphasizedDateTime(event))}`;
    case EventType.CheckIn:
      return `By ${shortTime(event.endTimestamp)}`;
    default:
      return '';
  }
};

function DashboardEventRow({ event }: DashboardEventRowProps): JSX.Element {
  const [updateAll, setUpdateAll] = useState<boolean>(false);
  const [outcomeIdForAll, setOutcomeIdForAll] = useState<string | undefined>();
  const [popoverTrigger, setPopoverTrigger] = useState<JSX.Element>(
    <DashboardAttendanceResumen
      numberOfAttendance={0}
      badAttendance={false}
      allAttendance={false}
    />
  );
  const EventIcon = eventIcons[event.type];
  const popoverTriggerDisabled = !isAfter(event.startTimestamp, Date.now());
  const roundedNow = startOfHour(Date.now());

  const variables = {
    startDate: Number(event.startTimestamp || Date.now()),
    lastActiveTimestamp: Number(subMonths(roundedNow, 6)) || Date.now(),
  };

  const refetchQuery = {
    query: getAttendanceStatsEvents,
    variables,
  };

  const setAllClientOutcome = (outcomeId: string | undefined) => {
    setUpdateAll(true);
    setOutcomeIdForAll(outcomeId);
  };

  useEffect(() => {
    if (event) {
      if (!popoverTriggerDisabled)
        setPopoverTrigger(
          <Flex
            width="30%"
            borderColor="brand.white"
            borderBottomColor="brand.gray6"
            borderWidth="1px 0"
            alignItems="center"
          >
            <IconButton
              aria-label="Mark attendance"
              size="sm"
              icon={<AssignmentInd boxSize="20px" />}
              color="brand.gray4"
              variant="ghost"
            />
            <Text
              color="brand.gray4"
              paddingRight={0.5}
              fontSize="14px"
              flexShrink="0"
            >
              Mark attendance
            </Text>
          </Flex>
        );
    }
  }, [event]);

  useEffect(() => {
    const allAttendanceMarked =
      event.eventClients?.filter(
        (client) => client.attendanceInfo !== undefined
      ) || [];
    if (allAttendanceMarked?.length > 0) {
      const badAttendance = allAttendanceMarked.filter(
        (clients) => clients.outcome?.status === 'FAILED'
      );
      const goodAttendance = allAttendanceMarked.filter(
        (clients) =>
          clients.outcome?.status === 'NOT_FAILED' &&
          clients.attendanceInfo === 'ATTENDED'
      );
      const otherAttendance = allAttendanceMarked.filter(
        (clients) =>
          clients.outcome?.status === 'NOT_FAILED' &&
          clients.attendanceInfo === 'OTHER'
      );
      switch (true) {
        case badAttendance.length > 0:
          setPopoverTrigger(
            <DashboardAttendanceResumen
              badAttendance
              numberOfAttendance={badAttendance.length}
              allAttendance={
                badAttendance.length === event.eventClients?.length
              }
            />
          );
          break;
        case otherAttendance.length > 0:
          setPopoverTrigger(
            <DashboardAttendanceResumen
              numberOfAttendance={
                otherAttendance.filter(
                  (clients) =>
                    clients.attended?.name === otherAttendance[0].attended?.name
                ).length
              }
              badAttendance={false}
              allAttendance={false}
              attendanceName={
                otherAttendance[0].attended?.name || 'Mark attendance'
              }
            />
          );
          break;
        case goodAttendance.length > 0:
          setPopoverTrigger(
            <DashboardAttendanceResumen
              numberOfAttendance={goodAttendance.length}
              badAttendance={false}
              allAttendance={false}
            />
          );
          break;

        default:
          setPopoverTrigger(
            <DashboardAttendanceResumen
              numberOfAttendance={0}
              badAttendance={false}
              allAttendance={false}
            />
          );
          break;
      }
    }
  }, [event]);

  return (
    <Flex>
      <Popover key="eventDetails" placement="right-start" isLazy>
        {({ isOpen }) => (
          <>
            <PopoverTrigger>
              <Flex
                direction="row"
                paddingY={2}
                backgroundColor={isOpen ? 'brand.blue3' : 'brand.white'}
                borderColor="brand.white"
                borderBottomColor="brand.gray6"
                borderWidth="1px 0"
                alignItems="center"
                width={hasAttendance(event) ? '70%' : '100%'}
                data-testid="schedule-dashboard-row"
                _hover={{
                  backgroundColor: 'brand.blue3',
                  borderColor: 'brand.blue2',
                  cursor: 'pointer',
                }}
              >
                <PopoverAnchor>
                  <Flex>
                    <CircleContainer
                      size="36px"
                      background="brand.gray7"
                      color="white"
                      marginX={3}
                    >
                      <EventIcon color="brand.gray3" boxSize="20px" />
                    </CircleContainer>
                  </Flex>
                </PopoverAnchor>
                <Flex
                  width="100%"
                  marginX={2}
                  direction="column"
                  alignItems="start"
                >
                  <Text
                    color="brand.gray1"
                    paddingRight={0.5}
                    fontSize="lg"
                    flexShrink="0"
                  >
                    {eventDisplayTitle(event)}
                  </Text>
                  <Flex direction="row" alignItems="center">
                    <Text color="brand.gray1" fontSize="sm" flexShrink="0">
                      {`${timeString(event)}`}
                    </Text>
                    <Circle boxSize="4px" color="brand.gray5" marginX={2} />
                    <Text
                      color="brand.gray1"
                      paddingRight={0.5}
                      fontSize="sm"
                      flexShrink="0"
                    >
                      {event.eventClients && event.eventClients.length > 1
                        ? `${event.eventClients.length} clients`
                        : `${event.clientFirstName} ${event.clientLastName}`}
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
            </PopoverTrigger>
            <EventDetailsPopover event={event} />
          </>
        )}
      </Popover>
      {hasAttendance(event) && (
        <Center height="auto" color="brand.gray6">
          <Divider orientation="vertical" />
        </Center>
      )}

      {hasAttendance(event) &&
        event.eventClients &&
        event.eventClients?.length > 1 && (
          <Popover key="eventAttendance" placement="right-end" isLazy>
            {popoverTrigger}

            <EventDetailsAttendancePopover
              event={event}
              setAllClient={setAllClientOutcome}
            >
              <Flex direction="column" marginTop="6px">
                {event.eventClients?.map((client) => (
                  <Flex
                    direction="row"
                    alignItems="center"
                    backgroundColor="white"
                    height="40px"
                    borderRadius="4px"
                    padding="10px 15px 6px 15px"
                    margin="3.5px 11px"
                    key={client.id}
                  >
                    <Text
                      color="brand.gray1"
                      paddingRight={0.5}
                      fontSize="14px"
                      width="200px"
                    >
                      {client.firstName} {client.lastName}
                    </Text>
                    <EventDetailsSingleAttendancePopover
                      event={event}
                      client={client}
                      outcomeId={
                        (hasAttendance(event) && client.attended?.id) || ''
                      }
                      updateAll={updateAll}
                      outcomeIdForAll={outcomeIdForAll}
                    />
                  </Flex>
                ))}
              </Flex>
            </EventDetailsAttendancePopover>
          </Popover>
        )}

      {(!event.eventClients || event.eventClients?.length < 2) &&
        hasAttendance(event) && (
          <>
            <Flex
              width="30%"
              borderColor="brand.white"
              borderBottomColor="brand.gray6"
              borderWidth="1px 0"
              alignItems="center"
              _hover={{
                cursor: 'pointer',
              }}
            >
              <AttendanceSelectionMenu
                clientEvent={event}
                refetchQuery={refetchQuery}
                refetchDashboard={() => {}}
                fromDashboard
              />
            </Flex>
          </>
        )}
    </Flex>
  );
}

export default React.memo(DashboardEventRow);
