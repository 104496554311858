/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useCallback, useEffect } from 'react';
import {
  Skeleton,
  AspectRatio,
  Box,
  Image,
  Center,
  useDisclosure,
  IconButton,
  Tooltip,
} from '@chakra-ui/react';

import type { Media } from 'generated/graphql';
import Error from 'sharedIcons/Error';
import OpenFull from 'sharedIcons/OpenFull';
import Download from 'sharedIcons/Download';
import OpenInNewTab from 'sharedIcons/OpenInNewTab';
import ExpandMessageImageModal from './ExpandMessageImageModal';
import type { Message } from '../types/clientTypes';

const LANDSCAPE_WIDTH = 400;
const PORTRAIT_WIDTH = 300;
const LANDSCAPE_ASPECT_RATIO = 4 / 3;
const PORTRAIT_ASPECT_RATIO = 3 / 4;

interface MessageImageProps {
  media: Media;
  marginTop: number;
  mediaMessages: Message[];
}

function MessageImage(props: MessageImageProps): JSX.Element {
  const { media, mediaMessages, marginTop } = props;
  const [isLoaded, setIsLoaded] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [aspectRatio, setAspectRatio] = useState(LANDSCAPE_ASPECT_RATIO);
  const [maxWidth, setMaxWidth] = useState(PORTRAIT_WIDTH);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [index, setIndex] = useState<number>(0);

  useEffect(() => {
    if (mediaMessages && mediaMessages.length > 0) {
      const indexValue = mediaMessages?.findIndex((message) => {
        const mediaDetails =
          message.media && message.media.length > 0
            ? message.media[0]
            : undefined;
        return mediaDetails === media;
      });
      setIndex(indexValue);
    }
  }, [mediaMessages]);

  const mediaUrl = () => {
    const message = mediaMessages[index];
    return message && message.media && message.media.length > 0
      ? message.media[0]?.url
      : media.url;
  };

  const onNextImage = () => {
    if (index > 0) {
      setIndex((prevState) => prevState - 1);
    }
  };
  const onPreviousImage = () => {
    if (index < mediaMessages.length - 1) {
      setIndex((prevState) => prevState + 1);
    }
  };

  const imageRef = useCallback(
    (node) => {
      if (node !== null && isLoaded) {
        const { height } = node.getBoundingClientRect();
        const { width } = node.getBoundingClientRect();
        if (width < maxWidth) {
          setMaxWidth(width);
        }
        if (height > width) {
          setAspectRatio(PORTRAIT_ASPECT_RATIO);
          setMaxWidth(PORTRAIT_WIDTH);
        } else if (height === width) {
          setAspectRatio(1);
          setMaxWidth(PORTRAIT_WIDTH);
        } else {
          setAspectRatio(LANDSCAPE_ASPECT_RATIO);
          setMaxWidth(LANDSCAPE_WIDTH);
        }
      }
    },
    [isLoaded]
  );

  const downloadImage = async () => {
    if (media.url) {
      const response = await fetch(media.url, {
        method: 'GET',
      });
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', '');
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
  };

  const openInNewTab = () => {
    window.open(media.url, '_blank');
  };

  const isTwilioMedia = media.url && media.url.includes('api.twilio');

  const messageBodyContent = hasError ? (
    <Box
      data-testid="message-image-error-component"
      backgroundColor="brand.gray8"
      color="brand.gray2"
      textAlign="left"
      borderRadius="lg"
    >
      <Center width="300px" height="200px">
        <Error marginX={1} boxSize="15px" marginY={1} />
        Unable to load image.
      </Center>
    </Box>
  ) : (
    <Image
      data-testid="message-img-element"
      borderRadius="lg"
      crossOrigin={isTwilioMedia ? undefined : 'anonymous'}
      ref={imageRef}
      onLoad={() => setIsLoaded(true)}
      onError={() => setHasError(true)}
      src={media.url}
      alt="Image sent by client."
    />
  );

  const commonIconProps = {
    boxSize: '16px',
    color: 'brand.gray1',
  };
  const OpenInNewTabTooltipLabel =
    "This image cannot be directly downloaded. Click to open it in a new tab, then use your browser's save option to download it.";

  return (
    <Box
      data-testid="message-image-component"
      borderRadius="lg"
      marginTop={marginTop}
    >
      <AspectRatio maxWidth={maxWidth} ratio={aspectRatio}>
        <Skeleton isLoaded={isLoaded || hasError}>
          <Box>
            {hasError ? null : (
              <Box position="absolute" top="2%" right="2%">
                <Tooltip
                  isDisabled={!isTwilioMedia}
                  label={isTwilioMedia ? OpenInNewTabTooltipLabel : null}
                >
                  <IconButton
                    icon={
                      isTwilioMedia ? (
                        <OpenInNewTab {...commonIconProps} />
                      ) : (
                        <Download {...commonIconProps} />
                      )
                    }
                    size="md"
                    aria-label={isTwilioMedia ? 'Open in new tab' : 'Expand'}
                    marginRight={1}
                    borderRadius={0}
                    onClick={isTwilioMedia ? openInNewTab : downloadImage}
                  />
                </Tooltip>
                <IconButton
                  icon={<OpenFull {...commonIconProps} />}
                  size="md"
                  aria-label="Expand"
                  borderRadius={0}
                  onClick={onOpen}
                />
              </Box>
            )}
            <Box>
              <IconButton
                icon={<OpenFull boxSize="16px" color="brand.gray1" />}
                size="md"
                aria-label="Expand"
                position="absolute"
                right="2%"
                top="2%"
                onClick={onOpen}
              />
            </Box>
            {messageBodyContent}
          </Box>
        </Skeleton>
      </AspectRatio>
      <ExpandMessageImageModal
        mediaUrl={mediaUrl()}
        isOpen={isOpen}
        onClose={onClose}
        onNextImage={onNextImage}
        onPreviousImage={onPreviousImage}
      />
    </Box>
  );
}

export default MessageImage;
