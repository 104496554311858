import React, { useEffect } from 'react';

import { Button, InputGroup, Stack, Text } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';

import useIdentifyUser from 'sharedHooks/useIdentifyUser';
import Send from 'sharedIcons/Send';
import AutoResizeTextarea from 'sharedComponents/AutosizingTextarea';
import {
  useSendFromStaffMessageMutation,
  MessageType,
} from 'generated/graphql';
import maxMessageLength from '../../../shared/constants/MessageInputMaxLength';

interface SendMessageFormProps {
  clientId: string;
}

interface MessageFormFields {
  body: string;
}

function SendMessageForm(props: SendMessageFormProps): JSX.Element {
  const { clientId } = props;
  const { register, handleSubmit, reset, watch } = useForm<MessageFormFields>();

  const { id, name } = useIdentifyUser();

  const [
    sendFromStaffMessageMutation,
    { loading, error },
  ] = useSendFromStaffMessageMutation();

  const messageValue = watch('body');
  const onSubmit = (formValues: MessageFormFields) => {
    const messageVariables = {
      clientId,
      senderName: name || '',
      senderId: id || '',
      timestamp: Date.now(),
      type: MessageType.Manual,
      ...formValues,
    };

    sendFromStaffMessageMutation({
      variables: { input: messageVariables },
    });
  };

  useEffect(() => {
    if (!loading && !error) {
      reset();
    }
  }, [loading]);

  return (
    <form id="sendMessage" onSubmit={handleSubmit(onSubmit)}>
      <Stack
        direction="row"
        backgroundColor="white"
        minH={70}
        maxH={105}
        padding={1}
        borderBottomRightRadius={8}
        alignItems="center"
        borderTopColor="brand.gray2"
        borderTopWidth="1px"
      >
        <InputGroup marginX={5}>
          <AutoResizeTextarea
            data-testid="message-input"
            alignSelf="center"
            color="brand.gray1"
            placeholder="Type your message"
            borderColor="white"
            borderRadius="0"
            isDisabled={loading}
            maxLength={maxMessageLength}
            _placeholder={{ color: 'brand.gray3' }}
            {...register('body', { required: true })} // eslint-disable-line react/jsx-props-no-spreading
          />
          <Button
            role="button"
            alignSelf="center"
            borderRadius="0"
            size="sm"
            paddingX={2}
            variant="ghost"
            type="submit"
            form="sendMessage"
            isLoading={loading}
            marginY="auto"
            isDisabled={messageValue?.trim().length === 0}
            color="brand.blue2"
            _disabled={{ color: 'brand.gray6', cursor: 'not-allowed' }}
          >
            <Send boxSize="20px" />
          </Button>
        </InputGroup>
      </Stack>
      {messageValue && messageValue?.length >= maxMessageLength && (
        <Text textAlign="end" color="brand.orange1" paddingRight="5%">
          Maximum message size is 2000 characters
        </Text>
      )}
    </form>
  );
}
export default SendMessageForm;
