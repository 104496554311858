import React, { useEffect } from 'react';

import { datadogIdentifyUser } from 'sharedHelpers/datadogRUM';
import useIdentifyUser from 'sharedHooks/useIdentifyUser';

export default function DatadogRumIdentificationProvider({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  const userInfo = useIdentifyUser();

  useEffect(() => {
    if (userInfo && userInfo.id) {
      const user = userInfo && {
        id: userInfo.id,
        name: userInfo.name,
        authUid: userInfo.authUid || '',
        org: userInfo.organization.uid || '',
        orgType: userInfo.organization.type || '',
      };
      datadogIdentifyUser(user);
    }
  }, [userInfo]);

  return <>{children}</>;
}
