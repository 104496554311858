import React from 'react';
import { useReactiveVar, makeVar } from '@apollo/client';

import AnnouncementDetailsDrawer from 'sharedComponents/AnnouncementDetailsDrawer';

const isOpenAnnouncementDetailsVar = makeVar(false);
const announcementDetailsIdVar = makeVar('');

export const onOpenAnnouncementDetails = (announcementId: string): void => {
  announcementDetailsIdVar(announcementId);
  isOpenAnnouncementDetailsVar(true);
};

export const onCloseAnnouncementDetails = (): void => {
  isOpenAnnouncementDetailsVar(false);
};

function AnnouncementNotificationContainer(): JSX.Element {
  const announcementId = useReactiveVar(announcementDetailsIdVar);
  const isOpenAnnouncementDetails = useReactiveVar(
    isOpenAnnouncementDetailsVar
  );

  return (
    <>
      <AnnouncementDetailsDrawer
        isOpen={isOpenAnnouncementDetails}
        onClose={onCloseAnnouncementDetails}
        id={announcementId}
      />
    </>
  );
}

export default AnnouncementNotificationContainer;
