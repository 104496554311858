import React from 'react';
import { Box, Circle, Tooltip } from '@chakra-ui/react';

interface NumeralBadgeProps {
  color: string;
  backgroundColor: string;
  number: number;
  tooltip: string;
}

function NumeralBadge(props: NumeralBadgeProps): JSX.Element {
  const { color, backgroundColor, number, tooltip } = props;
  return (
    <Tooltip hasArrow label={tooltip} background="brand.gray1" color="white">
      <Circle
        data-testid="numeral-badge"
        backgroundColor={backgroundColor}
        color={color}
        size="18px"
      >
        <Box as="span" fontWeight={700} fontSize="12px" color="white">
          {number}
        </Box>
      </Circle>
    </Tooltip>
  );
}

export default NumeralBadge;
