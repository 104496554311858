import React from 'react';
import {
  Box,
  Flex,
  Heading,
  Portal,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
} from '@chakra-ui/react';
import { isAfter } from 'date-fns';
import { EventType } from 'generated/graphql';

import eventDisplayTitle from 'sharedHelpers/eventDisplayTitle';
import EditEventButton from '../EditEventButton';
import type { ClientEvent } from '../../../../shared/types/clientTypes';

interface EventDetailsPopoverBaseProps {
  event: ClientEvent;
  children?: React.ReactChild;
}

const isEditableEvent = (event: ClientEvent): boolean =>
  !(event.eventClients && event.eventClients?.length > 1)
    ? isAfter(event.startTimestamp, Date.now()) &&
      !(event.type === EventType.CheckIn && event.isCompleted)
    : isAfter(event.startTimestamp, Date.now());

function EventDetailsPopoverBase({
  event,
  children,
}: EventDetailsPopoverBaseProps): JSX.Element {
  return (
    <Portal>
      <PopoverContent paddingX={4} paddingY={6} width="360px">
        <PopoverArrow />

        <Flex
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Heading
            size="md"
            fontWeight="normal"
            color="brand.gray1"
            paddingX={2}
          >
            {eventDisplayTitle(event)}
          </Heading>
          <Flex>
            {isEditableEvent(event) && (
              <EditEventButton
                eventData={event}
                iconSize="14px"
                color="brand.gray3"
              />
            )}
            <PopoverCloseButton
              data-testid="closeButton"
              position="inherit"
              size="sm"
              width="32px"
              height="32px"
              color="brand.gray3"
              variant="ghost"
              as={PopoverCloseButton}
            />
          </Flex>
        </Flex>
        <Box paddingX={2} paddingY={6}>
          {children}
        </Box>
      </PopoverContent>
    </Portal>
  );
}

EventDetailsPopoverBase.defaultProps = {
  children: <></>,
};

export default EventDetailsPopoverBase;
