import firebase from 'firebase/app';
import 'firebase/auth';
import { datadogRemoveUser } from 'sharedHelpers/datadogRUM';
import { datadogRum } from '@datadog/browser-rum';
import getTimeUntilTokenExpires from './getTimeUntilTokenExpires';
import config from '../../config';

const initFirebase = async () => {
  if (!firebase.apps.length) {
    const firebaseConfig = config().firebase;
    firebase.initializeApp(firebaseConfig);
  }
};

export const login = async (
  email: string,
  password: string
): Promise<firebase.auth.UserCredential | never> => {
  initFirebase();
  try {
    return await firebase.auth().signInWithEmailAndPassword(email, password);
  } catch (e) {
    // TODO: log to Rollbar
    throw new Error('sign in failure');
  }
};

export const logout = async (): Promise<void | never> => {
  initFirebase();
  try {
    datadogRemoveUser();
    return await firebase.auth().signOut();
  } catch (e) {
    // TODO: log to Rollbar
    throw new Error('sign out failure');
  }
};

export const resetPassword = async (email: string): Promise<void | never> => {
  initFirebase();
  try {
    return await firebase.auth().sendPasswordResetEmail(email, {
      url: 'http://localhost:8080/resetPassword',
    });
  } catch (e) {
    // TODO: log to Rollbar
    throw new Error('password reset failure');
  }
};

export const verifyPasswordResetCode = async (
  emailCode: string
): Promise<string | undefined> => {
  initFirebase();
  try {
    return await firebase.auth().verifyPasswordResetCode(emailCode);
  } catch (e: any) {
    if (e.code) return undefined;
    throw new Error('password reset failure');
  }
};

export const confirmPasswordReset = async (
  actionCode: string,
  newPassword: string
) => {
  initFirebase();
  try {
    return await firebase.auth().confirmPasswordReset(actionCode, newPassword);
  } catch (e) {
    throw new Error('password reset failure');
  }
};

let previousExpirationTime = '';
/**
 * @returns an unexpired ID token for the current Firebase Auth user.
 * Doesn't necessarily contact Firebase (will reuse existing ID token if it's still valid).
 * `login` must have been called, and have fulfilled, prior to calling this fuction,
 * otherwise the empty string will be returned.
 */

export const userToken = async (): Promise<string> => {
  await initFirebase();
  try {
    const { token, expirationTime, issuedAtTime } =
      (await firebase.auth().currentUser?.getIdTokenResult()) || {};

    if (!token) {
      return '';
    }

    if (expirationTime && previousExpirationTime !== expirationTime) {
      datadogRum.addAction('Auth Token State', {
        expirationTime,
        issuedAtTime,
        secondsUntilTokenExpires: getTimeUntilTokenExpires(expirationTime),
      });
      previousExpirationTime = expirationTime;
    }

    return token;
  } catch (e) {
    // TODO: log to Rollbar
    return Promise.reject(e);
  }
};
