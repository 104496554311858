import { gql } from '@apollo/client';

export default gql`
  fragment FilterFields on Client {
    lastOpenedTimestamp
    lastReceivedTimestamp
    lastSendAutoTimestamp
    lastSendTimestamp
    activity @client
    alerts @client
    status @client
    riskLevel
    cohortId
    staffId
    hasMobileApp
    inCustody
    noPhone
    phone
  }
`;
