import React, { useEffect, useState } from 'react';
import {
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuOptionGroup,
  MenuItemOption,
  Text,
  Skeleton,
  Spinner,
  Portal,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import type { PureQueryOptions } from '@apollo/client';
import AssignmentInd from 'sharedIcons/AssignmentInd';
import useOrganizationOutcomes from 'sharedHooks/useOrganizationOutcomes';
import useUpdateEventAttendance from 'sharedHooks/useUpdateEventAttendance';
import { hasAttendance } from 'sharedHelpers/typeNarrowing';
import Outcomes from '../constants/Outcomes';
import type { ClientEvent } from '../types/clientTypes';
import EventAttendanceStatus from './EventAttendanceStatus';

export const outcomeDisplay = {
  [Outcomes.Attended]: { order: 0, badOutcome: false },
  [Outcomes.Excused]: { order: 1, badOutcome: false },
  [Outcomes.Vacated]: { order: 2, badOutcome: false },
  [Outcomes.Canceled]: { order: 3, badOutcome: false },
  [Outcomes.Removed]: { order: 4, badOutcome: false },
  [Outcomes.Missed]: { order: 5, badOutcome: true },
  [Outcomes.BenchWarrant]: { order: 6, badOutcome: true },
};

export const orderOutcomes = (
  outcome1: { outcomeUid?: Outcomes | null },
  outcome2: { outcomeUid?: Outcomes | null }
): number => {
  if (
    !outcome1.outcomeUid ||
    !outcome2.outcomeUid ||
    !outcomeDisplay[outcome1.outcomeUid] ||
    !outcomeDisplay[outcome2.outcomeUid]
  ) {
    return 0;
  }

  return (
    outcomeDisplay[outcome1.outcomeUid].order -
    outcomeDisplay[outcome2.outcomeUid].order
  );
};

export const isBadOutcome = (outcome: {
  outcomeUid?: Outcomes | null;
}): boolean =>
  !!outcome.outcomeUid && outcomeDisplay[outcome.outcomeUid]?.badOutcome;

interface AttendanceSelectionMenuProps {
  clientEvent: ClientEvent;
  refetchQuery: PureQueryOptions;
  refetchDashboard: () => void;
  fromDashboard?: boolean;
}

function AttendanceSelectionMenu({
  clientEvent,
  refetchQuery,
  refetchDashboard,
  fromDashboard,
}: AttendanceSelectionMenuProps): JSX.Element {
  const { outcomes, loading } = useOrganizationOutcomes();
  const sortedOutcomes = [...outcomes].sort(orderOutcomes);
  const [refreshProgess, setRefreshProgress] = useState<boolean>(false);

  const {
    mutationFunction,
    eventId,
    loading: mutationLoading,
  } = useUpdateEventAttendance(clientEvent);

  const setAttendance = (value: string): void => {
    if (mutationFunction) {
      mutationFunction({
        variables: { id: eventId, attendedId: value },
        awaitRefetchQueries: true,
        refetchQueries: [refetchQuery],
      });
      setRefreshProgress(true);
      refetchDashboard();
    }
  };

  // This is to get around a not-quite-correct Chakra type.  A radio
  // group (and as such a radio group menu) can only have a single value.  It
  // should never be the case (given the setup of this particular component)
  // that value is not a string.
  const radioGroupOnChange = (value: string | string[]) => {
    if (typeof value === 'string') {
      setAttendance(value);
    } else {
      throw new Error('Attendance value set to array');
    }
  };

  // This is because in client dashboard in event tab, when you select an outcome,
  // first show the old outcome and when the clients is refresh show the new outcome.
  useEffect(() => {
    if (clientEvent) setRefreshProgress(false);
  }, [clientEvent]);

  return (
    <Menu placement="bottom-end">
      <Skeleton isLoaded={!loading} width="100%" height={6}>
        <MenuButton
          minWidth={32}
          width="100%"
          disabled={mutationLoading}
          data-testid="attendance-menu"
        >
          {mutationLoading || refreshProgess ? (
            <Spinner color="brand.gray5" />
          ) : (
            <Flex justifyContent="space-between" alignItems="center">
              <>
                {clientEvent.attendanceInfo === 'BLANK' &&
                hasAttendance(clientEvent) ? (
                  <Flex>
                    <AssignmentInd
                      color={fromDashboard ? 'brand.gray3' : 'brand.gray4'}
                      boxSize="20px"
                      marginLeft={fromDashboard ? '18px' : 'inherit'}
                      marginRight={fromDashboard ? '8px' : 'inherit'}
                    />{' '}
                    <Text
                      color="brand.blue2"
                      fontSize="sm"
                      paddingLeft={fromDashboard ? 0 : 2}
                      paddingRight={fromDashboard ? 0.5 : 'inheri'}
                    >
                      {fromDashboard ? 'Mark attendance' : 'Mark as...'}
                    </Text>
                  </Flex>
                ) : (
                  <Flex
                    alignItems="center"
                    justifyContent="start"
                    minWidth={32}
                    paddingLeft="18px"
                    paddingRight="8px"
                  >
                    <EventAttendanceStatus event={clientEvent} />
                  </Flex>
                )}
                {!fromDashboard && <ChevronDownIcon color="brand.gray3" />}
              </>
            </Flex>
          )}
        </MenuButton>
      </Skeleton>
      <Portal>
        <MenuList minWidth={32}>
          <MenuOptionGroup type="radio" onChange={radioGroupOnChange}>
            {sortedOutcomes.map((outcome) => (
              <MenuItemOption
                value={outcome.id}
                key={outcome.id}
                iconSpacing={1}
                color={isBadOutcome(outcome) ? 'brand.orange1' : 'brand.gray1'}
                _hover={{ backgroundColor: 'brand.blue3' }}
                fontSize="sm"
                data-testid="attendance-menu-item"
              >
                {outcome.name}
              </MenuItemOption>
            ))}
          </MenuOptionGroup>
        </MenuList>
      </Portal>
    </Menu>
  );
}

AttendanceSelectionMenu.defaultProps = {
  fromDashboard: false,
};

export default React.memo(AttendanceSelectionMenu);
