import React from 'react';
import { useReactiveVar } from '@apollo/client';

import CreateAnnouncementDrawer from 'sharedComponents/CreateAnnouncementDrawer';
import { sentAnnouncementIdsVar } from '../graphql/apolloCache';

interface AnnouncementContainerProps {
  /**
   * Defines whether the create drawer is open or not.  Pass this in from useDisclosure.
   */
  isCreateDrawerOpen: boolean;
  /**
   * A function that closes the create drawer.  Be sure to call the function returned from useDisclosure.
   */
  onCloseCreateDrawer: () => void;
  /**
   * A function that opens the create drawer.  Be sure to call the function returned from useDisclosure.
   */
  onOpenCreateDrawer: () => void;
}

function AnnouncementContainer({
  isCreateDrawerOpen,
  onCloseCreateDrawer,
  onOpenCreateDrawer,
}: AnnouncementContainerProps): JSX.Element {
  const currentSentIds = useReactiveVar(sentAnnouncementIdsVar);

  const addSentAnnouncementId = (id: string) => {
    sentAnnouncementIdsVar([...currentSentIds, id]);
  };

  return (
    <CreateAnnouncementDrawer
      isOpen={isCreateDrawerOpen}
      onOpen={onOpenCreateDrawer}
      onClose={onCloseCreateDrawer}
      addSentAnnouncementId={addSentAnnouncementId}
    />
  );
}

export default AnnouncementContainer;
