import React, { useEffect, useState } from 'react';
import { Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { Outcome } from 'generated/graphql';
import AssignmentInd from 'sharedIcons/AssignmentInd';
import useOrganizationOutcomes from 'sharedHooks/useOrganizationOutcomes';
import useUpdateEventAttendance from 'sharedHooks/useUpdateEventAttendance';
import {
  orderOutcomes,
  outcomeDisplay,
} from 'sharedComponents/AttendanceSelectionMenu';
import { ClientEvent, ClientEventData } from 'src/shared/types/clientTypes';
import { startOfHour, subMonths } from 'date-fns';
import Outcomes from '../../../../shared/constants/Outcomes';
import getCombinedScheduleEvents from '../../../../shared/graphql/queries/getCombinedScheduleEvents';
import getAttendanceStatsEvents from '../../../../shared/graphql/queries/getAttendanceStatsEvents';

interface EventDetailsSingleAttendancePopoverProps {
  outcomeId: string;
  event: ClientEvent;
  client: ClientEventData;
  updateAll?: boolean;
  outcomeIdForAll?: string;
}

const isBadOutcome = (outcomeUid?: Outcomes | null): boolean =>
  !!outcomeUid && outcomeDisplay[outcomeUid]?.badOutcome;

function EventDetailsSingleAttendancePopover(
  props: EventDetailsSingleAttendancePopoverProps
): JSX.Element {
  const { outcomeId, event, client, updateAll, outcomeIdForAll } = props;
  const { outcomes, loading } = useOrganizationOutcomes();
  const [buttonText, setButtonText] = useState<string>('Mark client');
  const [badOutcome, setBadOutcome] = useState<boolean>(false);
  const updateMutation = useUpdateEventAttendance(
    event,
    client.eventId || undefined
  );
  const roundedNow = startOfHour(Date.now());
  const variables = {
    startDate: Number(event.startTimestamp || Date.now()),
    lastActiveTimestamp: Number(subMonths(roundedNow, 6)) || Date.now(),
  };

  const refetchQuery = [
    {
      query: getCombinedScheduleEvents,
      variables,
    },
    {
      query: getAttendanceStatsEvents,
      variables,
    },
  ];

  const sortedOutcomes = [...outcomes].sort(orderOutcomes);

  const setAttendance = (outcome: Outcome) => {
    setButtonText(outcome.name || '');
    setBadOutcome(isBadOutcome(outcome.outcomeUid as Outcomes) || false);
    const { mutationFunction, eventId } = updateMutation;
    if (mutationFunction) {
      mutationFunction({
        variables: { id: eventId, attendedId: outcome.id },
        awaitRefetchQueries: true,
        refetchQueries: refetchQuery,
      });
    }
  };
  useEffect(() => {
    if (!loading && outcomes.length) {
      const outcomeSelected = outcomes.find(
        (outcome) => outcome.id === outcomeId
      );
      setButtonText(outcomeSelected?.name || 'Mark client');
      if (outcomeSelected)
        setBadOutcome(
          isBadOutcome(outcomeSelected.outcomeUid as Outcomes) || false
        );
    }
  }, [outcomes]);

  useEffect(() => {
    if (updateAll) {
      const { mutationFunction, eventId } = updateMutation;
      const outcomeSelected = outcomes.find(
        (outcome) => outcome.id === outcomeIdForAll
      );
      if (mutationFunction) {
        setButtonText(outcomeSelected?.name || 'Mark client');
        setBadOutcome(
          isBadOutcome(outcomeSelected?.outcomeUid as Outcomes) || false
        );
        mutationFunction({
          variables: { id: eventId, attendedId: outcomeIdForAll || '' },
        });
      }
    }
  }, [updateAll, outcomeIdForAll]);

  return (
    <Menu>
      <MenuButton
        width="190px"
        fontSize="14px"
        textAlign="start"
        color={badOutcome ? 'brand.orange1' : 'brand.blue2'}
      >
        <AssignmentInd boxSize="20px" marginRight="11px" />
        {buttonText} <ChevronDownIcon />
      </MenuButton>
      <MenuList fontSize="14px">
        {sortedOutcomes.map((outcome) => (
          <MenuItem
            key={outcome.id}
            disabled={!loading}
            onClick={() => setAttendance(outcome)}
            color={
              isBadOutcome(outcome.outcomeUid as Outcomes)
                ? 'brand.orange1'
                : 'brand.gray1'
            }
          >
            {outcome.name}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
}

EventDetailsSingleAttendancePopover.defaultProps = {
  updateAll: false,
  outcomeIdForAll: undefined,
};

export default EventDetailsSingleAttendancePopover;
