import React, { useEffect, useRef } from 'react';
import { Box, Stack, Spacer, Tooltip } from '@chakra-ui/react';
import { useFlags } from 'launchdarkly-react-client-sdk';

import MessageBubbleSubText from 'sharedComponents/MessageBubbleSubText';
import MessageBubbleAvatar from 'sharedComponents/MessageBubbleAvatar';
import MessageImage from 'sharedComponents/MessageImage';
import { shortDayOrDateTime } from 'sharedHelpers/textFormat';
import MessageFailureStatus from '../constants/MessageFailureStatus';
import type { Message } from '../types/clientTypes';

interface MessageBubbleProps {
  message: Message;
  mediaMessages?: Message[];
  avatar?: boolean;
  outgoing?: boolean;
  incoming?: boolean;
  sizeObserverRef?: React.MutableRefObject<ResizeObserver> | undefined;
}

function MessageBubble(props: MessageBubbleProps): JSX.Element {
  const {
    message,
    mediaMessages,
    avatar,
    outgoing,
    incoming,
    sizeObserverRef,
  } = props;
  const { showMessageImages } = useFlags();
  const messageDirection = outgoing ? 'row-reverse' : 'row';
  const boxColor = outgoing ? 'brand.blue3' : 'brand.gray8';
  const marginRight = incoming ? 14 : 0;
  const marginLeft = outgoing ? 14 : 0;
  const marginTop = 3;

  const announcement = !!message.announcement?.id;

  const outgoingMessageFailed =
    outgoing &&
    (message.status === MessageFailureStatus.error ||
      message.status === MessageFailureStatus.undelivered ||
      message.status === MessageFailureStatus.skipped);

  const renderMessageSubText = outgoingMessageFailed || announcement;

  const mediaDetails =
    message.media && message.media.length > 0 ? message.media[0] : undefined;

  const messageContentRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (
      // Only messages with media will shift size
      mediaDetails &&
      sizeObserverRef?.current &&
      messageContentRef.current
    ) {
      sizeObserverRef.current.observe(messageContentRef.current);
    }
  }, [message]);

  return (
    <Box ref={messageContentRef}>
      {showMessageImages && mediaDetails && incoming && (
        <MessageImage
          mediaMessages={mediaMessages || []}
          media={mediaDetails}
          marginTop={marginTop}
        />
      )}
      {message.body && message.body.length > 0 && (
        <Box data-testid="message-text-bubble" marginTop={marginTop}>
          <Stack
            direction={messageDirection}
            marginRight={marginRight}
            marginLeft={marginLeft}
          >
            {avatar && <MessageBubbleAvatar message={message} />}
            <Stack direction="column" align="flex-end" spacing="1px">
              <Box
                backgroundColor={boxColor}
                color="brand.gray1"
                textAlign="left"
                borderRadius={10}
                marginY="auto"
                paddingX={4}
                paddingY={2}
                alignItems="center"
              >
                <Tooltip label={shortDayOrDateTime(message.timestamp)}>
                  <Box>{message.body}</Box>
                </Tooltip>
              </Box>
              {renderMessageSubText && (
                <MessageBubbleSubText message={message} />
              )}
            </Stack>
            <Spacer />
          </Stack>
        </Box>
      )}
    </Box>
  );
}

MessageBubble.defaultProps = {
  avatar: false,
  outgoing: false,
  mediaMessages: [],
  incoming: false,
  sizeObserverRef: undefined,
};

export default MessageBubble;
