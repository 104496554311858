import React from 'react';
import { Box, useStyleConfig } from '@chakra-ui/react';

function InfoSection(props: {
  children: React.ReactNode;
  variant: 'brandGray' | string;
}): JSX.Element {
  const { variant, children, ...rest } = props;
  const styles = useStyleConfig('InfoSection', { variant });
  return (
    <Box
      __css={styles}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    >
      {children}
    </Box>
  );
}
export default InfoSection;
