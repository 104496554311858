import type {
  CreateLocationInput,
  CreateCourtDateInput,
  CreateOfficeEventInput,
  CreateCheckInEventInput,
  CreateVirtualEventInput,
  UpdateCourtDateInput,
  UpdateOfficeEventInput,
  UpdateCheckInEventInput,
  UpdateVirtualEventInput,
  CreateRoomInput,
  CreateGroupEventInput,
  CreateEventDetails,
  UpdateGroupEventInput,
  UpdateEventDetails,
} from 'generated/graphql';
import { organizationId } from '../graphql/apolloCache';
import type { EventsFormInputs } from '../types/formTypes';
import addingNewLocationKey from '../constants/AddNewLocationKey';
import addingNewRoomKey from '../constants/AddNewRoomKey';

interface BaseCreateEventInput {
  clientId: string;
  timestamp: number;
  endTimestamp: number;
  timezoneOffset: number;
  staffId: string | undefined;
}

const toTimestamp = (date: Date, timestamp: Date): number => {
  const fullYr = date.getFullYear();
  const monthIndex = date.getMonth();
  const day = date.getDate();
  const hours = timestamp.getHours();
  const minutes = timestamp.getMinutes();
  return Number(new Date(fullYr, monthIndex, day, hours, minutes));
};

const toBaseCreateEvent = (
  eventInput: EventsFormInputs
): BaseCreateEventInput => {
  const {
    type,
    date,
    timestamp: timeString,
    clientId,
    staffIds,
    endTimestamp: endDateString,
  } = eventInput;
  if (!clientId) {
    throw new TypeError('clientId is required to create events');
  }
  if (!date || !timeString) {
    throw new TypeError('Timestamp is required for all events');
  }
  const timestamp = toTimestamp(date, timeString);
  const timezoneOffset = new Date(timestamp).getTimezoneOffset();
  const staffId = staffIds?.[0];
  const endTimestamp =
    endDateString?.getTime() || (type && ['CHECK_IN', 'VIRTUAL'].includes(type))
      ? timestamp
      : timestamp + 1;
  return {
    timestamp,
    timezoneOffset,
    clientId,
    staffId,
    endTimestamp,
  };
};

const toBaseCreateGroupEventDetails = (
  eventInput: EventsFormInputs
): CreateEventDetails => {
  const { timestamp: timeString, date, roomId, checkInTemplateId } = eventInput;
  if (!date || !timeString) {
    throw new TypeError('Timestamp is required for all events');
  }
  const timestamp = toTimestamp(date, timeString);
  const timezoneOffset = new Date(timestamp).getTimezoneOffset();
  const returnInput: CreateEventDetails = {
    timezoneOffset,
    roomId,
    checkInTemplateId,
  };
  Object.keys(returnInput).forEach((key: string) => {
    if (returnInput[key as keyof CreateEventDetails] === undefined) {
      delete returnInput[key as keyof CreateEventDetails];
    }
  });
  return returnInput;
};

const toBaseUpdateGroupEventDetails = (
  eventInput: EventsFormInputs
): UpdateEventDetails => {
  const { roomId, checkInTemplateId } = eventInput;
  const newRoomId = roomId === '' ? undefined : roomId;
  const returnInput: UpdateEventDetails = {
    roomId: newRoomId,
    checkInTemplateId,
  };
  Object.keys(returnInput).forEach((key: string) => {
    if (returnInput[key as keyof UpdateEventDetails] === undefined) {
      delete returnInput[key as keyof UpdateEventDetails];
    }
  });
  return returnInput;
};

const toOfficeEventLocationInput = (
  eventInput: EventsFormInputs
):
  | { locationId: string }
  | { createLocation: CreateLocationInput }
  | { locationId: string; createRoom: CreateRoomInput }
  | { locationId: string; roomId: string | null } => {
  const { locationId, roomName, address, roomId } = eventInput;

  if (locationId === addingNewLocationKey) {
    if (!address) {
      throw new TypeError(
        'Address is required for office events with new location.'
      );
    }
    const rooms = roomName ? [{ description: roomName }] : [];
    const newLocationNewRoom = {
      createLocation: {
        organizationId: organizationId(),
        location: {
          name: address.addressLine1,
          isExternal: true,
          rooms,
          address: {
            addressLine1: address.addressLine1,
            city: address.city,
            state: address.state,
            zipCode: address.zipCode,
          },
        },
      },
    };
    return newLocationNewRoom;
  }

  if (locationId && roomId === addingNewRoomKey) {
    // Office events do not require a room on location
    if (!roomName) {
      return {
        locationId,
      };
    }
    const existingLocationNewRoom = {
      locationId,
      createRoom: {
        locationId,
        room: { description: roomName },
      },
    };
    return existingLocationNewRoom;
  }

  if (!locationId) {
    throw new TypeError('Location is required for office events.');
  }
  const existingLocation = {
    locationId,
    roomId: roomId || null,
  };
  return existingLocation;
};

const toCourtDateLocationInput = (eventInput: EventsFormInputs) => {
  const { locationId, roomId } = eventInput;

  if (!locationId) {
    throw new TypeError('Location is required for court date events');
  }

  return {
    locationId,
    roomId: roomId || null,
  };
};

export const toCreateCourtDateInput = (
  eventInput: EventsFormInputs
): CreateCourtDateInput => {
  const baseInput = toBaseCreateEvent(eventInput);
  const locationInfo = toCourtDateLocationInput(eventInput);

  return { ...baseInput, ...locationInfo };
};

export const toCreateCheckInEventInput = (
  eventInput: EventsFormInputs
): CreateCheckInEventInput => {
  const baseInput = toBaseCreateEvent(eventInput);

  const {
    checkInTemplateId,
    startDate,
    startTimestamp: timeString,
  } = eventInput;
  if (!startDate || !timeString || !checkInTemplateId) {
    throw new TypeError(
      'Start date, time, and template are required for check in events'
    );
  }
  const startTimestamp = toTimestamp(startDate, timeString);

  return { ...baseInput, checkInTemplateId, startTimestamp };
};

export const toCreateOfficeEventInput = (
  eventInput: EventsFormInputs
): CreateOfficeEventInput => {
  const baseInput = toBaseCreateEvent(eventInput);
  const locationInfo = toOfficeEventLocationInput(eventInput);
  const { title } = eventInput;
  if (!title) {
    throw new TypeError('Title is required for office events');
  }

  return { ...baseInput, ...locationInfo, title };
};

export const toCreateVirtualEventInput = (
  eventInput: EventsFormInputs
): CreateVirtualEventInput => {
  const baseInput = toBaseCreateEvent(eventInput);
  const { date, endTimestamp: timeString, title } = eventInput;
  if (!timeString || !date || !title) {
    throw new TypeError('End time and title are required for virtual events');
  }
  const endTimestamp = toTimestamp(date, timeString);

  return { ...baseInput, endTimestamp, title };
};

export const toCreateGroupEventInput = (
  eventInput: EventsFormInputs
): CreateGroupEventInput => {
  const { type: eventType, clientIds } = eventInput;
  if (!clientIds) {
    throw new TypeError('clientIds are required for group events');
  }
  let returnInput: any = {};
  switch (eventInput.type) {
    case 'COURT_DATE':
      returnInput = toCreateCourtDateInput(eventInput);
      returnInput.startTimestamp = returnInput.timestamp;
      break;
    case 'CHECK_IN':
      returnInput = toCreateCheckInEventInput(eventInput);
      returnInput.endTimestamp = returnInput.timestamp;
      break;
    case 'OFFICE':
      returnInput = toCreateOfficeEventInput(eventInput);
      returnInput.startTimestamp = returnInput.timestamp;
      break;
    case 'VIRTUAL':
      returnInput = toCreateVirtualEventInput(eventInput);
      returnInput.startTimestamp = returnInput.timestamp;
      break;
    default:
      throw new TypeError('type is required');
  }
  delete returnInput.clientId;
  delete returnInput.roomId;
  delete returnInput.timestamp;
  delete returnInput.timezoneOffset;
  delete returnInput.checkInTemplateId;
  returnInput.eventDetails = toBaseCreateGroupEventDetails(eventInput);
  return { ...returnInput, clientIds, eventType };
};

const toBaseUpdateEvent = (eventInput: EventsFormInputs) => {
  const { id, date, timestamp: timeString, staffIds } = eventInput;
  if (!id) {
    throw new TypeError('id is required to update events');
  }
  if (!date || !timeString) {
    throw new TypeError('Timestamp is required for all events');
  }
  const timestamp = toTimestamp(date, timeString);
  const timezoneOffset = new Date(timestamp).getTimezoneOffset();
  const staffId = staffIds?.[0];
  return { id, timestamp, timezoneOffset, staffId };
};

export const toUpdateCourtDateInput = (
  eventInput: EventsFormInputs
): UpdateCourtDateInput => {
  const baseUpdate = toBaseUpdateEvent(eventInput);
  const locationInfo = toCourtDateLocationInput(eventInput);

  return { ...baseUpdate, ...locationInfo };
};

export const toUpdateCheckInEventInput = (
  eventInput: EventsFormInputs
): UpdateCheckInEventInput => {
  const baseUpdate = toBaseUpdateEvent(eventInput);
  const {
    checkInTemplateId,
    startDate,
    startTimestamp: timeString,
  } = eventInput;
  if (!startDate || !timeString || !checkInTemplateId) {
    throw new TypeError(
      'Start date, time, and template are required for check in events'
    );
  }
  const startTimestamp = toTimestamp(startDate, timeString);

  return { ...baseUpdate, checkInTemplateId, startTimestamp };
};

export const toUpdateOfficeEventInput = (
  eventInput: EventsFormInputs
): UpdateOfficeEventInput => {
  const baseUpdate = toBaseUpdateEvent(eventInput);
  const locationInfo = toOfficeEventLocationInput(eventInput);
  const { title } = eventInput;

  return { ...baseUpdate, ...locationInfo, title };
};

export const toUpdateVirtualEventInput = (
  eventInput: EventsFormInputs
): UpdateVirtualEventInput => {
  const baseUpdate = toBaseUpdateEvent(eventInput);
  const { date, endTimestamp: timeString, title } = eventInput;
  if (!timeString || !date || !title) {
    throw new TypeError('End time and title are required for virtual events');
  }
  const endTimestamp = toTimestamp(date, timeString);

  return { ...baseUpdate, endTimestamp, title };
};

export const toUpdateGroupEventInput = (
  eventInput: EventsFormInputs
): UpdateGroupEventInput => {
  const { type: eventType, clientIds } = eventInput;
  if (!clientIds) {
    throw new TypeError('clientIds are required for group events');
  }
  let returnInput: any = {};
  switch (eventInput.type) {
    case 'COURT_DATE':
      returnInput = toUpdateCourtDateInput(eventInput);
      returnInput.startTimestamp = returnInput.timestamp;
      break;
    case 'CHECK_IN':
      returnInput = toUpdateCheckInEventInput(eventInput);
      returnInput.endTimestamp = returnInput.timestamp;
      break;
    case 'OFFICE':
      returnInput = toUpdateOfficeEventInput(eventInput);
      returnInput.startTimestamp = returnInput.timestamp;
      break;
    case 'VIRTUAL':
      returnInput = toUpdateVirtualEventInput(eventInput);
      returnInput.startTimestamp = returnInput.timestamp;
      break;
    default:
      throw new TypeError('type is required');
  }
  returnInput.groupEventId = eventInput.groupEventId;
  delete returnInput.clientId;
  delete returnInput.roomId;
  delete returnInput.timestamp;
  delete returnInput.checkInTemplateId;
  delete returnInput.id;
  returnInput.eventDetails = toBaseUpdateGroupEventDetails(eventInput);
  return { ...returnInput, clientIds, eventType };
};

export const toDeleteEventInput = (
  eventInput: EventsFormInputs
): { id: string } => {
  const { id } = eventInput;
  if (!id) {
    throw new TypeError('id is required to delete events');
  }
  return { id };
};
