import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export default (props: IconProps): JSX.Element => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Icon viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M10 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2h-8l-2-2z"
    />
  </Icon>
);
