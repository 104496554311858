import React from 'react';
import { useQuery } from '@apollo/client';
import { Box, Flex } from '@chakra-ui/react';
import AutoSizer from 'react-virtualized-auto-sizer';

import eventsByDay, { flattenDayEvents } from 'sharedHelpers/eventsByDay';
import transformConnection from 'sharedHelpers/transformConnection';

import EventsListByDay from 'sharedComponents/EventsListByDay';

import EventsListSkeleton from '../../../shared/components/EventsListSkeleton';
import type { ClientEvent } from '../../../shared/types/clientTypes';
import getEvents from '../../../shared/graphql/queries/getEvents';

interface ChildProps {
  clientEvent: ClientEvent;
  period: string;
}
interface EventListProps {
  EventChild: React.FC<ChildProps>;
  clientId: string;
  period: 'upcoming' | 'past';
}

function EventList(props: EventListProps): JSX.Element {
  const { EventChild, clientId, period } = props;
  const timeName = period.toUpperCase();
  const roundedNow = Number(new Date(Math.round(Date.now() / 1000) * 1000));
  const scrollRef = React.useRef<HTMLDivElement>(null);

  const { data, loading } = useQuery(getEvents, {
    variables: {
      id: clientId,
      timestamp: roundedNow,
      timeFilter: timeName,
    },
  });

  if (loading) {
    return (
      <>
        {Array.from({ length: 5 }, (_, k) => (
          <EventsListSkeleton key={k} />
        ))}
      </>
    );
  }

  const events: ClientEvent[] = data?.getClient.events
    ? transformConnection(data?.getClient.events)
    : [];
  const sortedEvents = eventsByDay(events);
  if (period === 'upcoming') {
    sortedEvents.sort((a, b) =>
      !a?.date || !b?.date
        ? 0
        : Number(new Date(a.date)) - Number(new Date(b.date))
    );
  }
  return (
    <Flex
      direction="column"
      maxHeight="100%"
      height="100%"
      width="100%"
      ref={scrollRef}
      overflowY="auto"
      overflowX="hidden"
    >
      <AutoSizer>
        {({ height, width }) => (
          <div style={{ height, width }}>
            {sortedEvents.length ? (
              <EventsListByDay
                events={flattenDayEvents(sortedEvents)}
                variant="clientSchedule"
                parentRef={scrollRef}
                scrollRef={scrollRef}
              >
                {(event: ClientEvent) => (
                  <EventChild
                    key={event.id}
                    period={period}
                    clientEvent={event}
                  />
                )}
              </EventsListByDay>
            ) : (
              <Box padding={4} color="brand.gray2">
                No {period} events
              </Box>
            )}
          </div>
        )}
      </AutoSizer>
    </Flex>
  );
}

export default EventList;
