import React from 'react';
import { Button, useToast } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';

import Videocam from 'sharedIcons/Videocam';
import { videoChatDisplayName } from '../graphql/apolloCache';

interface JoinVideoChatButtonProps {
  eventId: string;
  clientName?: string;
  disabled?: boolean;
  history?: ReturnType<typeof useHistory>;
}

function JoinVideoChatButton(props: JoinVideoChatButtonProps): JSX.Element {
  const { eventId, clientName, disabled, history } = props;
  const browserHistory = history ?? useHistory();
  const toast = useToast();

  const navigateToVideoChat = () => {
    window.zE?.('webWidget', 'hide');
    toast.closeAll();
    videoChatDisplayName(clientName);
    browserHistory.push(`/connect/${eventId}`);
  };

  return (
    <Button
      leftIcon={<Videocam boxSize="6" />}
      variant="brandPrimary"
      onClick={navigateToVideoChat}
      disabled={disabled}
      _disabled={{
        backgroundColor: 'brand.gray7',
        color: 'brand.gray3',
        cursor: 'not-allowed',
      }}
    >
      Join meeting
    </Button>
  );
}

JoinVideoChatButton.defaultProps = {
  clientName: '',
  disabled: false,
  history: undefined,
};

export default JoinVideoChatButton;
