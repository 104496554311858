import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
  Box,
  Textarea,
  FormControl,
  FormLabel,
  FormErrorMessage,
} from '@chakra-ui/react';
import { useUpdateClientNotesMutation } from 'generated/graphql';
import Error from 'sharedIcons/Error';

interface NotesFormFields {
  notes: string | undefined | null;
  clientId: string;
}

interface EditNotesProps {
  formData: NotesFormFields;
  formId: string;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  onClose: () => void;
}

function EditNotesForm(props: EditNotesProps): JSX.Element {
  const { formData, formId, setLoading, onClose } = props;
  const { clientId } = formData;
  if (!clientId) {
    return <></>;
  }
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<NotesFormFields>({ defaultValues: formData });

  const notesFields = register('notes', {
    maxLength: {
      value: 2000,
      message: 'You have exceeded the maximum character limit.',
    },
  });

  const [
    updateClientNotesMutation,
    { data, loading, error },
  ] = useUpdateClientNotesMutation();

  useEffect(() => {
    if (error) {
      // show a short-lived error like a toast or something asking user to try again.
      // also send to rollbar.
    }
    if (loading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
    if (data) {
      // data means we saved the form successfully so we can close it.
      onClose();
    }
  }, [error, loading, data]);

  const onSubmit = async (formValues: NotesFormFields) => {
    if (formValues.notes === formData.notes) {
      return onClose();
    }
    return updateClientNotesMutation({
      variables: { input: { id: clientId, notes: formValues.notes } },
    });
  };

  // This is a magic number.  It works pretty well for screen heights we care about (~900- 2160)
  const notesAreaRows = Math.floor(window.innerHeight / 30);

  return (
    <Box
      backgroundColor="brand.gray8"
      color="brand.gray1"
      paddingX={8}
      paddingY={4}
      borderRadius="8px"
    >
      <form id={formId} onSubmit={handleSubmit(onSubmit)}>
        <FormControl isInvalid={!!errors.notes}>
          <FormLabel>Notes</FormLabel>
          <FormErrorMessage color="brand.orange1">
            <Error /> {errors.notes?.message}
          </FormErrorMessage>
          <Textarea
            backgroundColor="brand.white"
            height="100%"
            resize="none"
            rows={notesAreaRows}
            disabled={loading}
            {...notesFields} // eslint-disable-line react/jsx-props-no-spreading
          />
        </FormControl>
      </form>
    </Box>
  );
}

export default EditNotesForm;
