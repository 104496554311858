import React from 'react';
import { Flex, Text, Icon } from '@chakra-ui/react';
import MobileFriendly from 'sharedIcons/MobileFriendly';
import MobileOff from 'sharedIcons/MobileOff';
import MarkChatRead from 'sharedIcons/MarkChatRead';

import SMSFailed from 'sharedIcons/SMSFailed';
import Phone from 'sharedIcons/Phone';
import PhoneDisabled from 'sharedIcons/PhoneDisabled';

import type { StatusData } from 'sharedHooks/useStatusBarInfo';

interface StatusIconProps {
  data: {
    IconComponent: typeof Icon;
    text: string;
    error: boolean;
  };
  mini: boolean;
}

const StatusIcon = ({ data, mini }: StatusIconProps): JSX.Element => {
  const { IconComponent, error, text } = data || {};
  const svgStyles = {
    height: '20px',
    width: '20px',
    margin: mini ? 'auto 4px' : 'auto 8px',
  };
  return (
    <Flex
      color={error ? 'brand.orange1' : 'brand.gray2'}
      paddingRight={mini ? 0 : 5}
    >
      <span title={text}>
        <IconComponent style={svgStyles} role={mini ? 'img' : 'presentation'} />
      </span>
      {!mini && <Text>{text}</Text>}
    </Flex>
  );
};

interface StatusIconsProps {
  statusData: StatusData;
  mini?: boolean;
}

function StatusIcons({
  mini = false,
  statusData,
}: StatusIconsProps): JSX.Element {
  const appStatus = {
    INSTALLED: {
      text: 'App installed',
      error: false,
      IconComponent: MobileFriendly,
    },
    NOT_INSTALLED: {
      text: 'Not Installed',
      error: true,
      IconComponent: MobileOff,
    },
  };
  const SMSStatus = {
    NONE: { text: 'Deliverable', error: false, IconComponent: MarkChatRead },
    OPTED_OUT: { text: 'Opted Out', error: true, IconComponent: SMSFailed },
    BOUNCED: { text: 'Bounced', error: true, IconComponent: SMSFailed },
  };
  const phoneStatus = {
    UNREACHABLE: {
      text: 'Unreachable',
      error: true,
      IconComponent: PhoneDisabled,
    },
    LANDLINE: { text: 'Landline', error: false, IconComponent: Phone },
    MOBILE: { text: 'Mobile', error: false, IconComponent: Phone },
    VIOP: { text: 'VOIP', error: false, IconComponent: Phone },
    VOIP: { text: 'VOIP', error: false, IconComponent: Phone },
  };

  return (
    <Flex>
      <StatusIcon data={appStatus[statusData.appInstalled]} mini={mini} />
      {appStatus[statusData.appInstalled].error && (
        <>
          <StatusIcon data={SMSStatus[statusData.messageError]} mini={mini} />
          {SMSStatus[statusData.messageError].error && (
            <StatusIcon data={phoneStatus[statusData.phoneType]} mini={mini} />
          )}
        </>
      )}
    </Flex>
  );
}

StatusIcons.defaultProps = {
  mini: false,
};
export default StatusIcons;
