import { useEffect } from 'react';
import useIdentifyUser, {
  UserIdentificationData,
} from 'sharedHooks/useIdentifyUser';

const pendoInitialize = (user: UserIdentificationData) => {
  if (typeof pendo?.initialize === 'function') {
    const { id, name, email, organization } = user;
    const { uid, type } = organization;
    pendo.initialize({
      visitor: {
        id,
        name,
        email,
      },
      account: uid
        ? {
            id: uid,
            type: type || '',
          }
        : undefined,
    });
  }
};

const usePendoIdentify = (): void => {
  const user = useIdentifyUser();
  useEffect(() => {
    if (user && user.id) {
      pendoInitialize(user);
    }
  }, [user]);
};

export default usePendoIdentify;
