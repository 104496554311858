import React from 'react';

import Schedule from 'sharedIcons/Schedule';

import IconDetail from './IconDetail';

interface ClientInformationProps {
  children: React.ReactNode;
}

function ClientInformation({ children }: ClientInformationProps): JSX.Element {
  return <IconDetail DetailIcon={Schedule}>{children}</IconDetail>;
}

export default ClientInformation;
