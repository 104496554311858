import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Image,
  Input,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import PasswordStrengthMeter from 'sharedComponents/PasswordStrengthMeter';
import { confirmPasswordReset } from 'sharedHelpers/firebaseAuth';

import logo from '../../../images/logo-header.png';

interface ResetPasswordPageProps {
  actionCode: string;
}

interface ResetPassowrdFields {
  password: string;
  confirmPassword: string;
  confirm: string;
}

function ResetPasswordPage({
  actionCode,
}: ResetPasswordPageProps): JSX.Element {
  const [processing, setProcessing] = useState(false);
  const [strongPassword, setStrongPassword] = useState<boolean>(false);
  const history = useHistory();
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setError,
  } = useForm<ResetPassowrdFields>({ mode: 'onBlur' });

  const passwordInputFields = register('password', {
    required: 'Please enter your password.',
    minLength: 8,
  });
  const confirmPasswordInputFields = register('confirmPassword', {
    required: 'Please enter your password.',
    minLength: 8,
  });

  const passwordValue = watch('password');
  const confirmPasswordValue = watch('confirmPassword');

  const changePassword = async (formDetails: ResetPassowrdFields) => {
    setProcessing(true);
    const { password, confirmPassword } = formDetails;
    if (password === confirmPassword) {
      try {
        await confirmPasswordReset(actionCode, password);
        history.replace('/login');
      } catch (error) {
        history.replace('/login');
        throw new Error('password reset failure');
      }
    } else {
      setError('confirm', {
        type: 'manual',
        message: 'Password are not matching',
        shouldFocus: false,
      });
      setProcessing(false);
    }
  };

  const formIsValid =
    confirmPasswordValue?.length &&
    passwordValue?.length &&
    !errors.confirmPassword &&
    !errors.password &&
    strongPassword;
  return (
    <Box backgroundColor="brand.gray4" height="100vh" textAlign="center">
      <Flex
        height="100%"
        justifyContent="center"
        direction="column"
        alignItems="center"
      >
        <Box
          backgroundColor="brand.white"
          color="brand.gray1"
          width="30%"
          padding={8}
          borderRadius={8}
          w={[350, 450, 550]}
        >
          <Image
            src={logo}
            fit="contain"
            alt="Uptrust logo"
            width="220px"
            height="110px"
            marginX="auto"
            paddingTop={4}
            paddingBottom={10}
          />

          <form noValidate onSubmit={handleSubmit(changePassword)}>
            <FormControl isInvalid={!!errors.confirm}>
              <FormErrorMessage>{errors.confirm?.message}</FormErrorMessage>
            </FormControl>
            <FormControl
              isInvalid={!!errors.password}
              paddingTop={4}
              paddingBottom={errors.password ? 2 : 10}
              marginX="auto"
              width="calc(100% - 48px)"
              isRequired
            >
              <FormLabel>Password</FormLabel>
              <Input
                type="password"
                placeholder="Password"
                width="100%"
                data-testid="password"
                {...passwordInputFields} // eslint-disable-line react/jsx-props-no-spreading
              />
              <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
            </FormControl>
            <PasswordStrengthMeter
              password={passwordValue || ''}
              setValue={setStrongPassword}
            />
            <FormControl
              isInvalid={!!errors.confirmPassword}
              paddingTop={4}
              paddingBottom={errors.confirmPassword ? 2 : 10}
              marginX="auto"
              width="calc(100% - 48px)"
              isRequired
            >
              <FormLabel>Confirm Password</FormLabel>
              <Input
                type="password"
                placeholder="Password"
                width="100%"
                data-testid="confirmPassword"
                {...confirmPasswordInputFields} // eslint-disable-line react/jsx-props-no-spreading
              />
              <FormErrorMessage>
                {errors.confirmPassword?.message}
              </FormErrorMessage>
            </FormControl>
            <Button
              backgroundColor="brand.blue2"
              color="brand.white"
              type="submit"
              width="calc(100% - 48px)"
              margin={4}
              isDisabled={!formIsValid}
              isLoading={processing}
              onClick={() => {}}
            >
              Reset Password
            </Button>
          </form>
        </Box>
      </Flex>
    </Box>
  );
}

export default ResetPasswordPage;
