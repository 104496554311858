import React, { useEffect, useState } from 'react';
import {
  Flex,
  Popover,
  PopoverTrigger,
  PopoverAnchor,
  Center,
  Text,
  Divider,
  Circle as CircleContainer,
} from '@chakra-ui/react';
import { startOfDay, startOfHour, startOfWeek, subMonths } from 'date-fns';
import {
  useGetCombinedScheduleEventsQuery,
  Event,
  EventType,
} from 'generated/graphql';
import { PureQueryOptions } from '@apollo/client';

import transformConnection from 'sharedHelpers/transformConnection';
import eventDisplayTitle from 'sharedHelpers/eventDisplayTitle';
import { fullMonthDate } from 'sharedHelpers/textFormat';
import {
  hasAttendance,
  isClientEvent,
  isStaffUser,
} from 'sharedHelpers/typeNarrowing';
import EventAttendanceStatus from 'sharedComponents/EventAttendanceStatus';

import emphasizedDateTime from 'sharedHelpers/emphasizedDateTime';
import LocationCity from 'sharedIcons/LocationCity';
import AccountBalance from 'sharedIcons/AccountBalance';
import Videocam from 'sharedIcons/Videocam';
import Assignment from 'sharedIcons/Assignment';
import Circle from 'sharedIcons/Circle';
import AttendanceSelectionMenu from 'sharedComponents/AttendanceSelectionMenu';
import type { ClientEvent } from '../../../shared/types/clientTypes';
import EventDetailsPopover from './EventDetailsPopover';
import { createArrayEventsDashboard } from '../hooks/useCombinedScheduleEvents';
import getAttendanceStatsEvents from '../../../shared/graphql/queries/getAttendanceStatsEvents';
import { timeString } from './DashboardEventRow';

interface EventClientDetailsProps {
  clientEvent: ClientEvent;
  period: string;
}

const eventIcons = {
  [EventType.Office]: LocationCity,
  [EventType.CourtDate]: AccountBalance,
  [EventType.Virtual]: Videocam,
  [EventType.CheckIn]: Assignment,
};

const roundedDate = startOfHour(Date.now());
function EventClientDetails(props: EventClientDetailsProps): JSX.Element {
  const { clientEvent, period } = props;
  const [groupEvent, setGroupEvent] = useState<ClientEvent>();
  const EventIcon = eventIcons[clientEvent.type];

  const roundedNow = startOfHour(Date.now());
  const variables = {
    startDate: Number(
      startOfWeek(startOfDay(roundedDate), { weekStartsOn: 1 })
    ),
    lastActiveTimestamp: Number(subMonths(roundedNow, 6)),
  };
  const { data, loading } = useGetCombinedScheduleEventsQuery({
    variables,
  });

  const variablesRefresh = {
    startDate: Number(clientEvent.startTimestamp),
    lastActiveTimestamp: Number(subMonths(roundedNow, 6)),
  };

  const refetchQuery: PureQueryOptions = {
    query: getAttendanceStatsEvents,
    variables: variablesRefresh,
  };

  useEffect(() => {
    if (
      !loading &&
      data?.getUser &&
      isStaffUser(data.getUser) &&
      data.getUser.events &&
      clientEvent.groupEventId
    ) {
      const eventsList = transformConnection<Event>(data.getUser.events).filter(
        isClientEvent
      );
      const filteredEvent = createArrayEventsDashboard(
        eventsList,
        clientEvent.groupEventId || ''
      );
      setGroupEvent(filteredEvent[0]);
    }
  }, [data]);
  return (
    <Flex>
      <Popover key="eventDetails" placement="right-start" isLazy>
        {({ isOpen }) => (
          <>
            <PopoverTrigger>
              <Flex
                direction="row"
                paddingY={2}
                backgroundColor={isOpen ? 'brand.blue3' : 'brand.white'}
                borderColor="brand.white"
                borderBottomColor="brand.gray6"
                borderWidth="1px 0"
                alignItems="center"
                width={hasAttendance(clientEvent) ? '70%' : '100%'}
                data-testid="schedule-dashboard-row"
                _hover={{
                  backgroundColor: 'brand.blue3',
                  borderColor: 'brand.blue2',
                  cursor: 'pointer',
                }}
              >
                <PopoverAnchor>
                  <Flex>
                    <CircleContainer
                      size="36px"
                      background="brand.gray7"
                      color="white"
                      marginX={3}
                    >
                      <EventIcon color="brand.gray3" boxSize="20px" />
                    </CircleContainer>
                  </Flex>
                </PopoverAnchor>
                <Flex
                  width="100%"
                  marginX={2}
                  direction="column"
                  alignItems="start"
                >
                  <Text
                    color="brand.gray1"
                    paddingRight={0.5}
                    fontSize="lg"
                    flexShrink="0"
                  >
                    {eventDisplayTitle(clientEvent)}
                  </Text>
                  <Flex direction="row" alignItems="center">
                    <Text color="brand.gray1" fontSize="sm" flexShrink="0">
                      {`${fullMonthDate(emphasizedDateTime(clientEvent))}`}
                    </Text>
                    <Circle boxSize="4px" color="brand.gray5" marginX={2} />
                    <Text
                      color="brand.gray1"
                      paddingRight={0.5}
                      fontSize="sm"
                      flexShrink="0"
                    >
                      {`${timeString(clientEvent)}`}
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
            </PopoverTrigger>
            <EventDetailsPopover event={groupEvent || clientEvent} />
          </>
        )}
      </Popover>
      {hasAttendance(clientEvent) && (
        <Center height="auto" color="brand.gray6">
          <Divider orientation="vertical" />
        </Center>
      )}

      {hasAttendance(clientEvent) && (
        <>
          <Flex
            width="30%"
            borderColor="brand.white"
            borderBottomColor="brand.gray6"
            borderWidth="1px 0"
            alignItems="center"
            _hover={{
              cursor: 'pointer',
            }}
          >
            <AttendanceSelectionMenu
              clientEvent={clientEvent}
              refetchQuery={refetchQuery}
              refetchDashboard={() => {}}
              fromDashboard
            />
          </Flex>
        </>
      )}
    </Flex>
  );
}

export default React.memo(EventClientDetails);
