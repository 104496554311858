import {
  split,
  from,
  ApolloClient,
  createHttpLink,
  NormalizedCacheObject,
} from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws';
import { setContext } from '@apollo/client/link/context';
import { getMainDefinition } from '@apollo/client/utilities';
import { TokenRefreshLink } from 'apollo-link-token-refresh';
import { onError } from '@apollo/client/link/error';
import { datadogRum } from '@datadog/browser-rum';
import { userToken, logout } from 'sharedHelpers/firebaseAuth';
import apolloCache, {
  resetReactiveVars,
  masqueradeToken,
  resetAppReactiveVars,
} from './apolloCache';
import config from '../../config';

let client: ApolloClient<NormalizedCacheObject> | undefined;

const individuallyHandledErrors = [
  'getAnnouncementDetails',
  'sendAnnouncement',
  'deleteCheckInEvent',
  'deleteCourtDate',
  'deleteOfficeEvent',
  'deleteVirtualEvent',
];

const httpLink = createHttpLink({
  uri: config().api.url,
});

const createClient = (token: string, showError: () => void) => {
  let currentToken = token;
  const tokenRefreshLink = new TokenRefreshLink({
    isTokenValidOrUndefined: () => false, // The firebase SDK only does a network request if the token is expired, so we can always call the SDK and let it decide whether or not to refresh.
    fetchAccessToken: async () => {
      const newToken = await userToken();
      return Promise.resolve(
        new Response(JSON.stringify({ access_token: newToken }))
      );
    },
    handleFetch: (accessToken) => {
      currentToken = accessToken;
    },
    handleError: (err) => {
      datadogRum.addError(err);
      logout();
    },
  });
  const wsLink = new WebSocketLink({
    uri: config().api.wss,
    options: {
      reconnect: true,
      connectionParams: async () => ({
        'x-firebase-token': masqueradeToken() || (await userToken()),
      }),
    },
  });
  const authLink = setContext(async (_, { headers }) => ({
    headers: {
      ...headers,
      'x-firebase-token': masqueradeToken() || currentToken,
    },
  }));
  const errorLink = onError(({ networkError, graphQLErrors, operation }) => {
    if (individuallyHandledErrors.includes(operation.operationName)) {
      return;
    }
    if (networkError) {
      datadogRum.addError(networkError, {
        queryName: operation.operationName,
        message: networkError.message,
      });
      showError();
    }
    if (graphQLErrors?.length) {
      showError();
      graphQLErrors.forEach((error) => {
        datadogRum.addError(
          new Error(
            `message: ${error.message}, path: ${JSON.stringify(error.path)}`
          ),
          {
            queryName: operation.operationName,
            message: error.message,
          }
        );
      });
    }
  });

  const authenticatedHttpLink = from([tokenRefreshLink, authLink, httpLink]);
  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    from([tokenRefreshLink, wsLink]),
    authenticatedHttpLink
  );

  const finalLink = from([errorLink, splitLink]);

  client = new ApolloClient({
    link: finalLink,
    cache: apolloCache,
    defaultOptions: {
      watchQuery: {
        errorPolicy: 'all',
      },
      query: {
        errorPolicy: 'all',
      },
      mutate: {
        errorPolicy: 'all',
      },
    },
  });
};

const apolloClient = (
  token: string,
  showError: () => void
): ApolloClient<NormalizedCacheObject> => {
  if (!client) {
    createClient(token, showError);
  }

  return client as ApolloClient<NormalizedCacheObject>;
};

export const deleteApolloClient = async (): Promise<void> => {
  if (client) {
    resetReactiveVars();
    await client.clearStore();
    client = undefined;
  }
};

export const resetApolloClient = async (): Promise<void> => {
  if (client) {
    resetAppReactiveVars();
    await client.resetStore();
  }
};

export default apolloClient;
