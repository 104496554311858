import React from 'react';
import { Textarea, TextareaProps } from '@chakra-ui/react';

import TextareaAutosize from 'react-textarea-autosize';

const AutoResizeTextarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  (props, ref) => (
    <Textarea
      minH="unset"
      overflow="auto"
      width="100%"
      resize="none"
      ref={ref}
      minRows={1}
      maxRows={3}
      as={TextareaAutosize}
      {...props} // eslint-disable-line react/jsx-props-no-spreading
    />
  )
);

export default AutoResizeTextarea;
