import React from 'react';
import { Button, useDisclosure } from '@chakra-ui/react';
import EditDrawer from 'sharedComponents/EditDrawer';
import transformConnection, {
  Connection,
} from 'sharedHelpers/transformConnection';
import EditClientForm from 'sharedComponents/EditClientForm';
import type {
  GenderType,
  LanguageType,
  RiskLevelType,
} from 'generated/graphql';
import type {
  ClientFormFields,
  ContactFormFields,
} from '../../../shared/types/formTypes';

interface EditClientButtonProps {
  clientData: {
    id: string;
    phone?: string;
    email?: string;
    address?: {
      addressLine1: string;
      addressLine2: string;
      city: string;
      state: string;
      zipCode: string;
    };
    gender?: GenderType;
    language?: LanguageType;
    staff: {
      id: string;
      name: string;
    };
    assignedStaff: { id: string }[];
    riskLevel?: RiskLevelType;
    cmsId?: string;
    noPhone?: boolean;
    clientUpdatedPhone: boolean;
    clientUpdatedAddress: boolean;
    noCar?: boolean;
    housingInsecure?: boolean;
    needsChildCare?: boolean;
    contacts: Connection<ContactFormFields>;
    __typename?: string;
    hasMobileApp?: boolean;
    lastSend?: string;
    carrier?: {
      type?: string;
      error?: string;
    };
    cohort?: {
      id: string;
    };
  };
}

function EditClientButton(props: EditClientButtonProps): JSX.Element {
  const { clientData } = props;
  const { onOpen, isOpen, onClose } = useDisclosure();
  if (!clientData.id) {
    return <></>;
  }
  // hasMobileApp, carrier, and lastSend are included on the client, but should not
  // be altered by staff-web.  This destructure stops them from being included
  // in the edit clients form.
  const {
    hasMobileApp: unneededHasMobileApp,
    lastSend: unneededLastSend,
    carrier: unneededCarrier,
    contacts: contactsConnection,
    assignedStaff,
    cohort,
    __typename: typeName,
    ...formData
  } = clientData;
  const contacts = transformConnection<ContactFormFields>(contactsConnection);

  function validateAddress() {
    const { address } = clientData;
    if (!address) {
      return {};
    }
    let { addressLine1, addressLine2, city, state, zipCode } = address;
    if (zipCode && zipCode.length !== 5) {
      zipCode = '';
    }
    if (addressLine1 && addressLine1.length > 120) {
      addressLine1 = '';
    }
    if (addressLine2 && addressLine2.length > 120) {
      addressLine2 = '';
    }
    if (state && state.length !== 2) {
      state = '';
    }
    if (city && city.length > 50) {
      city = '';
    }
    return { addressLine1, addressLine2, city, state, zipCode };
  }
  return (
    <>
      <Button
        backgroundColor="brand.gray7"
        color="brand.gray2"
        size="xs"
        minWidth="60px"
        onClick={onOpen}
      >
        Edit
      </Button>
      <EditDrawer<ClientFormFields>
        isOpen={isOpen}
        onClose={onClose}
        FormContent={EditClientForm}
        title="Edit Client Details"
        formData={{
          contacts: contacts.length ? contacts : [],
          cohortId: cohort?.id || undefined,
          ...formData,
          address: validateAddress(),
          staffIds: assignedStaff?.length
            ? assignedStaff.map((staff) => staff.id)
            : [],
        }}
        formId="editClientForm"
      />
    </>
  );
}

export default EditClientButton;
