import React from 'react';
import { Flex, Heading, Skeleton } from '@chakra-ui/react';
import AccountBalance from 'sharedIcons/AccountBalance';
import AssignmentTurnedIn from 'sharedIcons/AssignmentTurnedIn';
import Videocam from 'sharedIcons/Videocam';
import MeetingRoom from 'sharedIcons/MeetingRoom';

import StatsTidbitStack from 'sharedComponents/StatsTidbitStack';
import useAttendanceTidbitStats from '../hooks/useAttendanceTidbitStats';
import type { AllStatsType } from '../hooks/useClientsDashboardStats';

interface AttendanceTidbitProps {
  allStats: AllStatsType;
  loading: boolean;
}

const attendanceStatNames = [
  'courtDate',
  'checkIn',
  'virtual',
  'office',
] as const;

const attendanceTidbitTemplates = {
  courtDate: '{count} court dates attended',
  checkIn: '{count} check-ins completed',
  office: '{count} office appointments attended',
  virtual: '{count} video meetings attended',
};

const attendanceTidbitSingleTemplates = {
  courtDate: '{count} court date attended',
  checkIn: '{count} check-in completed',
  office: '{count} office appointment attended',
  virtual: '{count} video meeting attended',
};

const attendanceTidbitIcons = {
  courtDate: AccountBalance,
  checkIn: AssignmentTurnedIn,
  virtual: Videocam,
  office: MeetingRoom,
};

function AttendanceTidbits({
  allStats,
  loading,
}: AttendanceTidbitProps): JSX.Element {
  const { stats } = useAttendanceTidbitStats({
    allStats,
  });
  return (
    <Skeleton isLoaded={!loading} height="100%">
      <Flex
        direction="column"
        height="100%"
        alignItems="start"
        justifyContent="center"
        paddingLeft={{ base: 0, xl: 4 }}
      >
        <Heading
          fontSize={{ base: 'sm', lg: 'md', xl: 'lg' }}
          paddingBottom={{ base: 1, lg: 2 }}
          color="brand.gray1"
        >
          Attendance
        </Heading>
        <StatsTidbitStack
          names={attendanceStatNames}
          templates={attendanceTidbitTemplates}
          singleTemplates={attendanceTidbitSingleTemplates}
          numbers={stats}
          icons={attendanceTidbitIcons}
        />
      </Flex>
    </Skeleton>
  );
}

export default AttendanceTidbits;
