import React from 'react';
import { Flex } from '@chakra-ui/react';

interface EventStatusWithButtonProps {
  StatusButton?: React.ReactNode;
  children: React.ReactNode;
}

function EventStatusWithButton({
  StatusButton,
  children,
}: EventStatusWithButtonProps): JSX.Element {
  return (
    <>
      {children || StatusButton ? (
        <Flex
          direction="column"
          marginX={12}
          marginTop={4}
          justifyContent="center"
          textAlign="center"
          rowGap={3}
        >
          {children}
          <Flex justifyContent="center"> {StatusButton}</Flex>
        </Flex>
      ) : null}
    </>
  );
}

EventStatusWithButton.defaultProps = {
  StatusButton: undefined,
};
export default EventStatusWithButton;
