import React, { useEffect } from 'react';
import { useReactiveVar } from '@apollo/client';
import { useFlags } from 'launchdarkly-react-client-sdk';

import InfoView from 'sharedComponents/InfoView';
import InfoViewSkeleton from 'sharedComponents/InfoViewSkeleton';

import { isStaffUser } from 'sharedHelpers/typeNarrowing';
import { alphabetizeClients } from 'sharedHelpers/clientHelpers';
import transformConnection from 'sharedHelpers/transformConnection';

import { useGetUserQuery } from 'generated/graphql';

import useFilterClients from 'sharedHooks/useFilterClients';
import useNavigateToClient from 'sharedHooks/useNavigateToClient';

import ClientListPanelItem from './components/ScheduleListPanelItem';
import ScheduleDetailPanel from './components/ScheduleInfoPanel';
import AddEventButton from './components/AddEventButton';
import Dashboard from './components/Dashboard';
import { EditEventDrawerProvider } from './components/EditEventDrawerProvider';
import { CheckInResponseDrawerProvider } from './components/CheckInResponseDrawerProvider';

import {
  allClientsListVar,
  organizationId,
} from '../../shared/graphql/apolloCache';

interface SchedulePageProps {
  clientId?: string;
}

function SchedulePage({ clientId }: SchedulePageProps): JSX.Element {
  const clients = useReactiveVar(allClientsListVar);
  const { showScheduleDashboardFlag, filterIncludeEventStaff } = useFlags();

  const { data: serverData, loading } = useGetUserQuery({
    variables: {
      filterIncludeEventStaff: filterIncludeEventStaff || false,
    },
  });

  useEffect(() => {
    if (serverData && isStaffUser(serverData.getUser)) {
      const user = serverData.getUser;
      const { id } = user.organization || {};
      allClientsListVar(user.clients ? transformConnection(user.clients) : []);
      organizationId(id);
    }
  }, [serverData]);

  const filteredClientsList = useFilterClients({
    clientsList: clients,
    sortFunction: alphabetizeClients,
  });

  const navigateToClient = useNavigateToClient();
  useEffect(() => {
    if (!showScheduleDashboardFlag && !clientId && filteredClientsList.length) {
      navigateToClient(filteredClientsList[0].id);
    }
  }, [clientId, filteredClientsList.length]);

  return (
    <EditEventDrawerProvider>
      <CheckInResponseDrawerProvider>
        {loading && <InfoViewSkeleton />}
        {!loading && (
          <InfoView
            selectedItem={clientId}
            listPanelChildren={({ index, style }) => (
              <ClientListPanelItem
                clientsData={filteredClientsList}
                style={style}
                index={index}
              />
            )}
            detailPanelChildren={
              clientId ? (
                <ScheduleDetailPanel clientId={clientId} />
              ) : (
                showScheduleDashboardFlag && <Dashboard />
              )
            }
            title="Schedule"
            numberOfItems={filteredClientsList.length}
            HeaderButton={<AddEventButton defaultClientId={clientId} />}
            errorMessage={
              clients.length > filteredClientsList.length
                ? 'There are no clients matching the current filters'
                : 'You have no clients to display'
            }
          />
        )}
      </CheckInResponseDrawerProvider>
    </EditEventDrawerProvider>
  );
}
SchedulePage.defaultProps = {
  clientId: undefined,
};

export default SchedulePage;
