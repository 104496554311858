import { useState, useEffect } from 'react';
import { useReactiveVar } from '@apollo/client';

import useIdentifyUser from 'sharedHooks/useIdentifyUser';
import { staffList } from '../graphql/apolloCache';

export interface StaffMember {
  name: string;
  id: string;
  lawyer?: boolean | null | undefined;
}

export default (): StaffMember[] => {
  const [staffMembers, setStaffMembers] = useState<StaffMember[]>([]);

  const staffUser = useIdentifyUser();
  const { role, visibleStaffIds } = staffUser || {};
  const staffListVar = useReactiveVar(staffList);

  useEffect(() => {
    if (role) {
      if (visibleStaffIds?.length) {
        setStaffMembers(
          staffListVar.filter((staffMember: StaffMember) =>
            visibleStaffIds.includes(staffMember.id)
          )
        );
      } else if (role && ['supervisor', 'support'].includes(role)) {
        setStaffMembers(staffListVar);
      } else {
        setStaffMembers([]);
      }
    }
  }, [staffUser, staffListVar]);

  return staffMembers
    .filter((staff) => staff.lawyer)
    .sort((a, b) => a.name.localeCompare(b.name));
};
