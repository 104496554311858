import { useHistory, useLocation } from 'react-router-dom';

/**
 * Navigates between pages, keeping the client one is looking at constant
 *
 * @returns A function that triggers the navigation
 */

const useNavigateToPage = (): ((newPage: string, tab?: number) => void) => {
  const location = useLocation();
  const history = useHistory();
  const [, , clientId] = location.pathname.split('/');
  return (newPage, tab) => {
    const newLocation = tab
      ? `/${newPage}/${clientId}/${tab}`
      : `/${newPage}/${clientId}`;
    if (location.pathname !== newLocation) {
      history.push(newLocation);
    }
  };
};

export default useNavigateToPage;
