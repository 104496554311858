import React from 'react';
import { Text } from '@chakra-ui/react';

import emphasizedDateTime from 'sharedHelpers/emphasizedDateTime';
import { fullMonthDate, shortTime } from 'sharedHelpers/textFormat';
import type { ClientEvent } from '../../../../shared/types/clientTypes';

interface DateAndSingleTimeProps {
  event: ClientEvent;
}

function DateAndSingleTime({ event }: DateAndSingleTimeProps): JSX.Element {
  const singleTime = emphasizedDateTime(event);
  return (
    <>
      <Text variant="eventPopoverInformation">{`${fullMonthDate(
        singleTime
      )}`}</Text>
      <Text variant="eventPopoverInformation">{`${shortTime(
        singleTime
      )}`}</Text>
    </>
  );
}
export default DateAndSingleTime;
