import React from 'react';

import { useVirtual, Options as ReactVirtualOptions } from 'react-virtual';

// This was copied from the codesandbox linked in this issue:
// https://github.com/TanStack/react-virtual/issues/251#issuecomment-1043625855

const defaultKeyExtractor = (index: number) => index;

interface Options<T> extends ReactVirtualOptions<T> {
  updateSize?: boolean; // when list is hidden by display none, by setting updateSize={false} we can skip RO callbacks
}

const useDynamicResize = <T extends HTMLElement>({
  updateSize = true,
  ...options
}: Options<T>): ReturnType<typeof useVirtual> => {
  // stores an object containing with a measureRef for each element in the list
  const measureRefCacheRef = React.useRef<
    Record<string, (el: HTMLElement | null) => void>
  >({});
  // stores an object with an element ref for each element in the list
  const elCacheRef = React.useRef<Record<string, Element | null>>({});

  // re-calculates the size of the element
  const update = (key: number | string, el: HTMLElement) => {
    if (updateSize) {
      measureRefCacheRef.current[key](el);
    }
  };
  const updateRef = React.useRef(update);
  updateRef.current = update;

  // A single ResizeObserver can observe any number of elements
  // (via calling .observe for each element you want to track)  It calls
  // the callback passed to the constructor when a resize occurrs on an observed
  // element.
  const resizeObserverRef = React.useRef(
    new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        const entryElement = entry.target;
        const attribute = 'data-key';
        const key = entryElement.getAttribute(attribute);

        if (key === null) {
          throw new Error(`Value not found, for '${attribute}' attribute`);
        }

        const htmlEl = entryElement as HTMLElement;

        updateRef.current(key, htmlEl);
      });
    })
  );

  React.useEffect(() => {
    const resizeObserver = resizeObserverRef.current;
    // Disconnect all resizeObserver targets when the component unmounts
    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const { size, keyExtractor = defaultKeyExtractor } = options;

  // Builds a cache of measureRefs.  useMemo means this only gets rebuilt
  // when the component's props change.
  const cachedMeasureRefWrappers = React.useMemo(() => {
    const makeMeasureRefWrapperForItem = (key: string | number) => (
      currentElement: HTMLElement | null
    ) => {
      if (elCacheRef.current[key]) {
        resizeObserverRef.current.unobserve(elCacheRef.current[key] as Element);
      }

      if (currentElement) {
        // Measure the current size of the element
        updateRef.current(key, currentElement);
        // Add the element to the resize observer's list
        resizeObserverRef.current.observe(currentElement);
      }

      elCacheRef.current[key] = currentElement;
    };

    const refsAccessor: Record<string, (el: HTMLElement | null) => void> = {};

    for (let i = 0; i < size; i += 1) {
      const key = keyExtractor(i);

      refsAccessor[key] = makeMeasureRefWrapperForItem(key);
    }

    return refsAccessor;
  }, [size, keyExtractor]);

  const rowVirtualizer = useVirtual(options);

  const virtualItems = rowVirtualizer.virtualItems.map((item) => {
    measureRefCacheRef.current[item.key] = item.measureRef;

    return {
      ...item,
      measureRef: cachedMeasureRefWrappers[item.key],
    };
  });

  return { ...rowVirtualizer, virtualItems };
};

export default useDynamicResize;
