import { InMemoryCache, makeVar } from '@apollo/client';
import { relayStylePagination } from '@apollo/client/utilities';
import activityFlags from 'sharedHelpers/activityFlags';
import alertsFlags from 'sharedHelpers/alertsFlags';
import statusFlags from 'sharedHelpers/statusFlags';
import type { Client, StaffUser } from 'generated/graphql';
import attendanceInfo from 'sharedHelpers/attendanceInfo';
import typings from '../../generated/typings';

export default new InMemoryCache({
  possibleTypes: typings.possibleTypes,
  typePolicies: {
    Query: {
      fields: {
        getStaffMessages: relayStylePagination(['filter', ['clientId']]),
      },
    },
    Client: {
      fields: {
        messages: relayStylePagination(),
        displayName: {
          read(_, { readField }) {
            return `${readField('firstName')} ${readField('lastName')}`;
          },
        },
        activity: {
          read(_, { readField }) {
            return activityFlags(
              readField('lastReceivedTimestamp'),
              readField('lastOpenedTimestamp')
            );
          },
        },
        alerts: {
          read(_, { readField }) {
            return alertsFlags(
              readField('noPhone'),
              readField('carrier'),
              readField('hasMobileApp'),
              readField('lastSend')
            );
          },
        },
        status: {
          read(_, { readField }) {
            return statusFlags(readField('closed'), readField('inCustody'));
          },
        },
      },
    },
    Event: {
      fields: {
        attendanceInfo: {
          read(_, { readField }) {
            return attendanceInfo(
              readField('type'),
              readField('status', readField('outcome')),
              readField('outcomeUid', readField('outcome'))
            );
          },
        },
      },
    },
    StaffUser: {
      fields: {
        isSupportStaff: {
          read(_, { readField }) {
            return !readField('supervisor') && !readField('lawyer');
          },
        },
        localRole: {
          read(_, { readField }) {
            if (readField('supervisor') && readField('lawyer')) {
              return 'supervisor';
            }
            if (readField('lawyer')) {
              return 'staff';
            }
            return 'support';
          },
        },
      },
    },
  },
});

export const videoChatDisplayName = makeVar('');
export const eventNotificationShown = makeVar<Record<string, boolean>>({});
export const headerHidden = makeVar<boolean>(false);
export const allClientsListVar = makeVar<Client[]>([]);
export const staffList = makeVar<StaffUser[]>([]);
export const organizationId = makeVar('');
export const sentAnnouncementIdsVar = makeVar<string[]>([]);

export const isMasqueradingVar = makeVar(false);
export const masqueradeToken = makeVar('');
export const masqueradeIdentityVar = makeVar<{
  staffName: string;
  organizationUid: string;
}>({ staffName: '', organizationUid: '' });

export const resetMasquerade = (): void => {
  isMasqueradingVar(false);
  masqueradeToken('');
  masqueradeIdentityVar({ staffName: '', organizationUid: '' });
};

export const resetAppReactiveVars = (): void => {
  videoChatDisplayName('');
  eventNotificationShown({});
  headerHidden(false);
  allClientsListVar([]);
  staffList([]);
  organizationId('');
  sentAnnouncementIdsVar([]);
};

export const resetReactiveVars = (): void => {
  resetMasquerade();
  resetAppReactiveVars();
};
