import React, { useEffect, useState } from 'react';
import { Text } from '@chakra-ui/react';

import { fullMonthDate, shortTime } from 'sharedHelpers/textFormat';
import type { ClientEvent } from '../../../../shared/types/clientTypes';

interface OpenCloseDateTimeProps {
  event: ClientEvent & { endTimestamp: number };
}
interface TextData {
  open: string;
  close: string;
}

function OpenCloseDateTime({
  event: { startTimestamp, endTimestamp },
}: OpenCloseDateTimeProps): JSX.Element {
  const [textData, setTextData] = useState<TextData>({
    open: 'Opens',
    close: 'Closes',
  });
  const today = Date.now();

  useEffect(() => {
    if (startTimestamp < today) {
      setTextData((prevState: TextData) => ({ ...prevState, open: 'Opened' }));
    }
  }, [startTimestamp]);

  useEffect(() => {
    if (endTimestamp < today) {
      setTextData((prevState: TextData) => ({ ...prevState, close: 'Closed' }));
    }
  }, [endTimestamp]);

  return (
    <>
      <Text variant="eventPopoverInformation">{`${
        textData.open
      } on ${fullMonthDate(startTimestamp)} at ${shortTime(
        startTimestamp
      )}`}</Text>
      <Text variant="eventPopoverInformation">{`${
        textData.close
      } on ${fullMonthDate(endTimestamp)} at ${shortTime(endTimestamp)}`}</Text>
    </>
  );
}
export default OpenCloseDateTime;
