import filterNullishObjectValues from 'sharedHelpers/filterNullishObjectValues';
import type { CreateContactInput, UpdateContactInput } from 'generated/graphql';
import { newClientId } from 'sharedHooks/useSaveClient';
import { organizationId } from '../graphql/apolloCache';
import type { ContactFormFields } from '../types/formTypes';

export const toCreateContactInput = (
  formData: ContactFormFields
): CreateContactInput => {
  const { name, optIn: optInString, ...contactFields } = formData;
  if (!name) {
    throw new TypeError('Contact name is required!');
  }

  const optIn = optInString === 'true';

  return {
    organizationId: organizationId(),
    clientId: newClientId(),
    name,
    optIn,
    ...filterNullishObjectValues<typeof contactFields>(contactFields),
  };
};

export const toUpdateContactInput = (
  formData: ContactFormFields
): UpdateContactInput => {
  const { id } = formData;

  if (!id) {
    throw new TypeError('Existing contacts must have an id');
  }

  // UpdateContactInput doesn't accept clientId or organizationId.
  // Typescript doesn't allow deletion of non-optional properties, so we
  // destructure them and then don't use them.

  const {
    clientId: unneededClient,
    organizationId: unneededOrganization,
    ...validInputs
  } = toCreateContactInput(formData);

  return { id, ...validInputs };
};
