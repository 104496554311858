import { useEffect, useState } from 'react';
import { startOfHour, subMonths, isAfter, isBefore, isEqual } from 'date-fns';

import {
  useGetCombinedScheduleEventsQuery,
  Event,
  GetCombinedScheduleEventsQueryVariables,
} from 'generated/graphql';

import emphasizedDateTime from 'sharedHelpers/emphasizedDateTime';
import transformConnection from 'sharedHelpers/transformConnection';
import {
  isStaffUser,
  isClientEvent,
  hasAttendance,
} from 'sharedHelpers/typeNarrowing';
import useIdentifyUser from 'sharedHooks/useIdentifyUser';

import type {
  ClientEventData,
  ClientEvent,
} from '../../../shared/types/clientTypes';

interface FilterEvents {
  dashboardDates: {
    startTimestamp: Date;
    endTimestamp: Date;
  };
  attendingFilter: 'allEvents' | 'myEvents' | 'noAttendance';
  events: ClientEvent[];
  id: string;
}

const eventInDateRangeTest = (
  event: ClientEvent,
  startTimestamp: Date,
  endTimestamp: Date
): boolean =>
  (isBefore(emphasizedDateTime(event), endTimestamp) &&
    isAfter(emphasizedDateTime(event), startTimestamp)) ||
  isEqual(emphasizedDateTime(event), startTimestamp);

const eventMatchesAttendingTest = (
  event: ClientEvent,
  attendingFilter: 'allEvents' | 'myEvents' | 'noAttendance',
  currentUserId: string
): boolean => {
  if (attendingFilter === 'allEvents') {
    return true;
  }
  if (attendingFilter === 'myEvents') {
    return !!event.staffAttending && event.staffId === currentUserId;
  }
  if (attendingFilter === 'noAttendance') {
    return event.attendanceInfo === 'BLANK';
  }

  return true;
};

const createClientsArray = (groupEvents: ClientEvent): ClientEventData => ({
  firstName: groupEvents.clientFirstName || '',
  lastName: groupEvents.clientLastName || '',
  id: groupEvents.clientId,
  attended: hasAttendance(groupEvents)
    ? groupEvents.attended || undefined
    : undefined,
  eventId: groupEvents.id,
  outcome: groupEvents.outcome,
  attendanceInfo: groupEvents.attendanceInfo || undefined,
});

export const createArrayEventsDashboard = (
  events: ClientEvent[],
  groupEventId?: string
) => {
  const eventsData: ClientEvent[] = [];
  const groupEvents: { index: number; value: string }[] = [];
  events.forEach((event: ClientEvent) => {
    if (!event.groupEventId && !groupEventId) {
      eventsData.push(event);
    } else
      switch (true) {
        case groupEventId && event.groupEventId !== groupEventId:
          break;

        case groupEventId && event.groupEventId === groupEventId:
          if (groupEvents.some((e) => e?.value === event.groupEventId)) {
            const groupEventSelected = groupEvents.filter(
              (e) => e.value === event.groupEventId
            )[0];
            eventsData[groupEventSelected.index].eventClients?.push(
              createClientsArray(event)
            );
          } else {
            eventsData.push({
              ...event,
              eventClients: [createClientsArray(event)],
            });
            groupEvents.push({
              index: eventsData.length - 1,
              value: event.groupEventId || '',
            });
          }
          break;
        default:
          if (groupEvents.some((e) => e?.value === event.groupEventId)) {
            const groupEventSelected = groupEvents.filter(
              (e) => e.value === event.groupEventId
            )[0];
            eventsData[groupEventSelected.index].eventClients?.push(
              createClientsArray(event)
            );
          } else {
            eventsData.push({
              ...event,
              eventClients: [createClientsArray(event)],
            });
            groupEvents.push({
              index: eventsData.length - 1,
              value: event.groupEventId || '',
            });
          }
          break;
      }
  });

  return eventsData;
};

const filterEvents = (state: FilterEvents): ClientEvent[] => {
  const {
    dashboardDates: { startTimestamp, endTimestamp },
    attendingFilter,
    events,
    id,
  } = state;
  return events.filter(
    (event) =>
      eventInDateRangeTest(event, startTimestamp, endTimestamp) &&
      eventMatchesAttendingTest(event, attendingFilter, id)
  );
};

export default ({
  dashboardDates,
  attendingFilter,
  firstDayOfWeek,
}: {
  dashboardDates: { startTimestamp: Date; endTimestamp: Date };
  attendingFilter: 'allEvents' | 'myEvents' | 'noAttendance';
  firstDayOfWeek: Date | number;
}): {
  events: ClientEvent[];
  loading: boolean;
} => {
  const [events, setEvents] = useState<ClientEvent[]>([]);
  const [firstDayToLoad, setFirstDayToLoad] = useState(firstDayOfWeek);
  const { id } = useIdentifyUser();

  const roundedNow = startOfHour(Date.now());
  const variables: GetCombinedScheduleEventsQueryVariables = {
    startDate: Number(firstDayToLoad),
    lastActiveTimestamp: Number(subMonths(roundedNow, 6)) || Date.now(),
    from: new Date(dashboardDates.startTimestamp).getTime(),
    to: new Date(dashboardDates.endTimestamp).getTime(),
  };

  const { data, loading } = useGetCombinedScheduleEventsQuery({
    variables,
  });

  useEffect(() => {
    // This is a small optimization because we are loading events using `UPCOMING`
    // rather than a set start and end date.  We load all events after the
    // startDate, so if the new week to load is after the current week, the events
    // will be already loaded and we don't need to issue a new query

    // TODO: This should be removed if we switch to startDate and endDate filters
    if (isBefore(firstDayOfWeek, firstDayToLoad)) {
      setFirstDayToLoad(firstDayOfWeek);
    }
  }, [firstDayOfWeek]);

  useEffect(() => {
    if (
      data?.getUser &&
      isStaffUser(data.getUser) &&
      data.getUser.events &&
      id
    ) {
      const eventsList = transformConnection<Event>(data.getUser.events).filter(
        isClientEvent
      );

      const filteredEvents = filterEvents({
        events: eventsList,
        dashboardDates,
        attendingFilter,
        id,
      });

      setEvents(filteredEvents);
    }
  }, [
    data,
    attendingFilter,
    dashboardDates.startTimestamp,
    dashboardDates.endTimestamp,
  ]);

  return { events, loading };
};
