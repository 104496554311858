import React, { useState, useRef } from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  Button,
  Stack,
  Box,
} from '@chakra-ui/react';

import CreateAnnouncementForm from 'sharedComponents/CreateAnnouncementForm';
import SendAnnouncementConfirmation from 'sharedComponents/SendAnnouncementConfirmation';
import CreateAnnouncementClientPicker from 'sharedComponents/CreateAnnouncementClientPicker';

import type { ClientGroups } from 'sharedHooks/useAnnouncementClientGroups';

export interface SendAnnouncementData {
  clientIds: string[];
  body: string;
  group: keyof ClientGroups;
  staffId?: string;
  previous?: boolean;
  cohortId?: string;
}

interface CreateAnnouncementDrawerProps {
  /**
   * Defines whether the drawer is open or not.  Pass this in from useDisclosure.
   */
  isOpen: boolean;
  /**
   * A function that closes the drawer.  Be sure to call the function returned from useDisclosure.
   */
  onClose: () => void;
  /**
   * A function that opens the drawer.  Be sure to call the function returned from useDisclosure.
   */
  onOpen: () => void;
  addSentAnnouncementId: (id: string) => void;
}

function CreateAnnouncementDrawer({
  isOpen,
  onClose,
  onOpen,
  addSentAnnouncementId,
}: CreateAnnouncementDrawerProps): JSX.Element {
  const [loading, setLoading] = useState(false);
  const [confirmIsOpen, setConfirmIsOpen] = useState(false);
  const [renderClientsForm, setRenderClientsForm] = useState(false);

  const previouslySelectedClients = useRef<string[]>([]);

  const defaultAnnouncementData: SendAnnouncementData = {
    body: '',
    clientIds: [],
    group: 'all',
  };

  const [
    announcementFormData,
    setAnnouncementFormData,
  ] = useState<SendAnnouncementData>(defaultAnnouncementData);
  const formId = renderClientsForm ? 'selectClients' : 'createAnnouncementForm';

  const openAnnouncementConfirm = (formData: SendAnnouncementData) => {
    setAnnouncementFormData(formData);
    onClose();
    setConfirmIsOpen(true);
  };

  const confirmOnCancel = () => {
    setConfirmIsOpen(false);
    onOpen();
  };

  const confirmOnSuccesfulSend = (sentAnnouncementId: string) => {
    addSentAnnouncementId(sentAnnouncementId);
    setConfirmIsOpen(false);
    setAnnouncementFormData(defaultAnnouncementData);
  };

  const confirmOnTerminalError = () => {
    setConfirmIsOpen(false);
    setAnnouncementFormData(defaultAnnouncementData);
  };

  return (
    <>
      {confirmIsOpen && (
        <SendAnnouncementConfirmation
          isOpen={confirmIsOpen}
          onSuccessfulSend={confirmOnSuccesfulSend}
          onCancel={confirmOnCancel}
          onTerminalError={confirmOnTerminalError}
          announcementData={announcementFormData}
        />
      )}
      <Drawer isOpen={isOpen} onClose={onClose} placement="right" size="lg">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader color="brand.gray1" fontSize="2xl" fontWeight="normal">
            {renderClientsForm ? 'Choose Clients' : 'New Announcement'}
          </DrawerHeader>

          <DrawerBody>
            {renderClientsForm && (
              <CreateAnnouncementClientPicker
                formId={formId}
                setRenderClientsForm={setRenderClientsForm}
                previouslySelectedClients={previouslySelectedClients.current}
              />
            )}
            <Box display={renderClientsForm ? 'none' : 'block'}>
              <CreateAnnouncementForm
                formData={announcementFormData}
                formId={formId}
                setLoading={setLoading}
                onClose={onClose}
                onSaveFunction={openAnnouncementConfirm}
                chooseClients={(alreadySelectedClients = []) => {
                  previouslySelectedClients.current = alreadySelectedClients;
                  setRenderClientsForm(true);
                  setAnnouncementFormData({
                    ...announcementFormData,
                    previous: false,
                  });
                }}
              />
            </Box>
          </DrawerBody>

          <DrawerFooter justifyContent="flex-start" paddingX={12}>
            <Stack direction="row" spacing={6}>
              <Button
                variant="brandPrimary"
                type="submit"
                form={formId}
                paddingX={10}
                isLoading={loading}
              >
                {renderClientsForm ? 'Next' : 'Send'}
              </Button>
              <Button
                variant="brandCancel"
                marginRight={3}
                paddingX={4}
                onClick={() => {
                  if (renderClientsForm) {
                    setAnnouncementFormData({
                      ...announcementFormData,
                      previous: !previouslySelectedClients.current.length,
                    });
                    setRenderClientsForm(false);
                  } else {
                    onClose();
                  }
                }}
              >
                Cancel
              </Button>
            </Stack>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default CreateAnnouncementDrawer;
