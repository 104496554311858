import React, { useState, useEffect } from 'react';
import { makeVar, useReactiveVar } from '@apollo/client';
import { Heading, Stack, Flex, Button } from '@chakra-ui/react';
import { useFlags } from 'launchdarkly-react-client-sdk';

import useStaffMemberList from 'sharedHooks/useStaffMemberList';

import type { ApiClientsLists } from 'sharedHooks/useApiClientsLists';
import useCohorts from 'sharedHooks/useCohorts';
import filtersList, { FilterData } from 'sharedHelpers/filtersList';
import FilterGroup from './FilterGroup';

import type { ClientFilter } from '../types/clientTypes';

export type Filters = Record<
  keyof ClientFilter,
  Array<string | number | boolean>
>;

export type ApiFilters = {
  activity: [keyof ApiClientsLists];
};

export const filtersVar = makeVar<Filters>({} as Filters);
export const apiFiltersVar = makeVar<ApiFilters>({} as ApiFilters);

interface Filter {
  field: keyof ClientFilter;
  values: Array<string | number | boolean>;
  api?: boolean;
}

interface ApiFilter extends Filter {
  field: 'activity';
  values: [keyof ApiClientsLists];
  api: true;
}

const isApiFilter = (filter: Filter): filter is ApiFilter => !!filter.api;

function updateFilters(newFilter: Filter): void {
  if (isApiFilter(newFilter)) {
    const apiFilters = apiFiltersVar();
    apiFilters[newFilter.field] = newFilter.values;
    apiFiltersVar({ ...apiFilters });
  } else {
    const filters = filtersVar();
    filters[newFilter.field] = newFilter.values;
    filtersVar({ ...filters });
  }
}

function FilterPanel(): JSX.Element {
  const [filters, setStateFilters] = useState<FilterData[]>([]);
  const {
    staffMemberLabelFlag,
    showRiskLevelFlag,
    showIncarceratedFilterFlag,
    showCohortFlag,
    filterIncludeEventStaff,
  } = useFlags();
  const staffMemberList = useStaffMemberList();
  const selectedFilters = useReactiveVar(filtersVar);
  const selectedApiFilters = useReactiveVar(apiFiltersVar);
  const cohortData = useCohorts(showCohortFlag);
  useEffect(() => {
    setStateFilters(
      filtersList(
        {
          staffMemberList,
          staffMemberLabelFlag,
          showRiskLevelFlag,
          showIncarceratedFilterFlag,
          cohortData,
        },
        filterIncludeEventStaff
      )
    );
  }, [staffMemberList.length, cohortData]);

  const clearAllFilters = () => {
    filters.forEach((filter) => {
      const filterForm = document.querySelector<HTMLFormElement>(
        `#${filter.id}`
      );
      filterForm?.reset();
    });
  };

  return (
    <Flex
      direction="column"
      maxHeight="100%"
      overflowY="auto"
      width="17vw"
      borderRadius={9}
      background="brand.gray8"
      padding={7}
      textAlign="left"
    >
      <Flex direction="row" justifyContent="space-between">
        <Heading
          fontSize="18px"
          fontWeight="400"
          color="brand.gray1"
          marginBottom={6}
          position="relative"
        >
          Filter Clients
        </Heading>
        <Button
          size="xs"
          variant="ghost"
          color="brand.blue2"
          onClick={clearAllFilters}
          visibility={
            Object.values(selectedFilters).some(
              (valuesArray) => valuesArray.length
            ) ||
            Object.values(selectedApiFilters).some(
              (valuesArray) => valuesArray.length
            )
              ? 'visible'
              : 'hidden'
          }
        >
          Clear All
        </Button>
      </Flex>
      <Stack spacing={8}>
        {filters.map((filter) => {
          const { field, name, choices, apiChoices, id, defaultValue } = filter;
          return (
            <FilterGroup
              key={field}
              name={name}
              field={field}
              choices={choices}
              apiChoices={apiChoices || []}
              id={id}
              updateFilters={updateFilters}
              defaultValue={defaultValue || []}
            />
          );
        })}
      </Stack>
    </Flex>
  );
}

export default React.memo(FilterPanel);
