import React, { useRef, useState, useEffect } from 'react';
import { useMultipleSelection, UseMultipleSelectionProps } from 'downshift';

import { Flex } from '@chakra-ui/react';
import {
  AutocompleteProvider,
  AutocompleteInput,
} from 'sharedComponents/Autocomplete';

import { Chip, ChipList } from 'sharedComponents/ChipSelector';

interface PossibleItem {
  id: string;
  label: string;
}

interface AutocompleteChipSelectorProps<T extends PossibleItem> {
  /**
   * The list of all items
   */
  itemList: Array<T>;
  /**
   * A function to call when the selected items change
   */
  onChange: (selectedItems: Array<T>) => void;
  /**
   * The list of items to show in the chip box
   */
  value: Array<T>;
  /**
   * The list of initially selected items
   */
  initialValue: Array<T>;
  /**
   * Whether the selector should be entirely disabled
   */
  disabled?: boolean;
  /**
   * A cap on the number of items that can be selected
   */
  maxItems?: number;
  /**
   * The text of the label for accessibility reasons.  Not rendered on
   * screen, but used to indicate to screenreaders what the selector is for
   */
  labelText?: string;
  /**
   * A custom downshift stateReducer to allow complex selection behavior
   */
  stateReducer?: UseMultipleSelectionProps<T>['stateReducer'];
}

/* eslint-disable react/jsx-props-no-spreading */
function AutocompleteChipSelector<T extends PossibleItem>({
  itemList,
  value,
  initialValue,
  onChange,
  maxItems,
  disabled,
  stateReducer,
  labelText,
}: AutocompleteChipSelectorProps<T>): JSX.Element {
  const [items, setItems] = useState<T[]>(itemList);
  const [filteredItems, setFilteredItems] = useState<T[]>(itemList);

  const inputRef = useRef<HTMLInputElement>(null);

  const {
    getSelectedItemProps,
    getDropdownProps,
    addSelectedItem,
    removeSelectedItem,
    setSelectedItems,
    reset,
  } = useMultipleSelection<T>({
    initialSelectedItems: value,
    onSelectedItemsChange: (changes) => {
      onChange(changes.selectedItems || []);
    },

    ...(typeof stateReducer === 'function' && stateReducer),
  });

  useEffect(() => {
    setItems(itemList);
    reset();
  }, [itemList[0]?.id]);

  useEffect(() => {
    setItems(
      itemList.filter(
        (item) => value.findIndex((itemValue) => itemValue.id === item.id) < 0
      )
    );
  }, [value]);

  useEffect(() => {
    setSelectedItems(initialValue);
  }, [initialValue]);

  function getItemsFilter(inputValue: string | undefined) {
    return function itemsFilter(item: T) {
      return !inputValue || item.label.toLowerCase().includes(inputValue);
    };
  }
  const disableAdd = (!!maxItems && value.length >= maxItems) || disabled;

  return (
    <AutocompleteProvider
      possibleItemList={filteredItems}
      inputRef={inputRef}
      addItem={addSelectedItem}
      filterItemsList={(inputValue: string | undefined) => {
        setFilteredItems(
          items.filter(getItemsFilter(inputValue?.toLowerCase()))
        );
      }}
      disabled={disableAdd}
      labelText={labelText}
      renderAnchorElement={(dropdownAnchorRef) => (
        <Flex
          ref={dropdownAnchorRef}
          {...getDropdownProps()}
          opacity={disabled ? 0.4 : 1}
        >
          <ChipList disabled={disableAdd}>
            {value.map((selectedItem, index) => (
              <Chip
                id={selectedItem.id}
                label={selectedItem.label}
                removeItem={() => removeSelectedItem(selectedItem)}
                key={selectedItem.id}
                chipRef={React.createRef()}
                {...getSelectedItemProps({
                  selectedItem,
                  index,
                  refKey: 'chipRef',
                })}
              />
            ))}

            <AutocompleteInput inputRef={inputRef} disabled={disableAdd} />
          </ChipList>
        </Flex>
      )}
    />
  );
}
/* eslint-enable react/jsx-props-no-spreading */

AutocompleteChipSelector.defaultProps = {
  maxItems: undefined,
  disabled: false,
  stateReducer: undefined,
  labelText: 'Select',
};

export default AutocompleteChipSelector;
