import {
  EventType,
  OutcomeType,
  AttendanceInfoType,
} from '../../generated/graphql';
import Outcomes from '../constants/Outcomes';

export default (
  type: EventType | undefined,
  status: OutcomeType | undefined,
  outcomeUid: Outcomes | undefined
): AttendanceInfoType => {
  if (type && [EventType.CheckIn, EventType.Virtual].includes(type)) {
    switch (status) {
      case null:
        return AttendanceInfoType.Blank;
      case OutcomeType.NotFailed:
        return AttendanceInfoType.Attended;
      case OutcomeType.Failed:
        return AttendanceInfoType.Missed;
      default:
        return AttendanceInfoType.Other;
    }
  } else {
    switch (outcomeUid) {
      case null:
        return AttendanceInfoType.Blank;
      case Outcomes.Missed:
      case Outcomes.BenchWarrant:
        return AttendanceInfoType.Missed;
      case Outcomes.Attended:
        return AttendanceInfoType.Attended;
      default:
        return AttendanceInfoType.Other;
    }
  }
};
