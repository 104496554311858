import { gql } from '@apollo/client';

export default gql`
  fragment StatusBarInfo on Client {
    closedAt
    hasMobileApp
    lastSend
    noPhone
    carrier {
      type
      error
    }
    closed
  }
`;
