import { gql } from '@apollo/client';

export default gql`
  query getEventsQuery(
    $id: ID!
    $timestamp: Float!
    $timeFilter: EventFilter!
  ) {
    getClient(id: $id) {
      id
      events(eventFilter: $timeFilter, eventFilterTimestamp: $timestamp)
        @connection(key: "events", filter: ["eventFilter"]) {
        edges {
          node {
            id
            clientId
            staffId
            timestamp
            type
            title
            closed
            groupEventId
            startTimestamp
            outcome {
              id
              outcomeUid
              status
            }
            ... on CheckInEvent {
              checkInTemplate {
                id
                staffDisplayName
              }
              checkInReportId
              isCompleted
              submittedTimestamp
              endTimestamp
            }
            ... on CourtDateEvent {
              attendanceInfo @client
              location {
                id
                addressLine1
                addressLine2
                city
                state
                zip
              }
              attended {
                id
                outcomeId
                name
              }
              room {
                id
                roomId
                description
              }
            }
            ... on OfficeEvent {
              attendanceInfo @client
              location {
                id
                addressLine1
                addressLine2
                city
                state
                zip
              }
              room {
                id
                roomId
                description
              }
              attended {
                id
                outcomeId
                name
              }
            }
            ... on VirtualEvent {
              endTimestamp
            }
          }
        }
      }
    }
  }
`;
