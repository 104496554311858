import React from 'react';
import {
  Button,
  Text,
  AlertDialog,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogContent,
  AlertDialogOverlay,
} from '@chakra-ui/react';

interface ApiErrorAlertProps {
  /**
   * Whether or not the error alert is open
   */
  isOpen: boolean;
  /**
   * A function to execute in order to retry the errored api call
   */
  onTryAgain: () => void;
  /**
   * A function to close the window when the user clicks cancel or hits esc.
   */
  onCancel: () => void;
  /**
   * The text to show as the title of the alert
   */
  title: string;
  /**
   * The text to show as the body of the alert
   */
  body: string;
  /**
   * Whether or not to allow the user to retry the operation.
   */
  enableRetry?: boolean;
  /**
   * A function to call if the user encounters an error that does not permit retries
   */
  onTerminalError?: () => void;
}

function ApiErrorAlert({
  isOpen,
  onTryAgain,
  onCancel,
  title,
  body,
  enableRetry,
  onTerminalError = () => {},
}: ApiErrorAlertProps): JSX.Element {
  const cancelRef = React.useRef<HTMLButtonElement>(null);

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={enableRetry ? onCancel : onTerminalError}
    >
      <AlertDialogOverlay>
        <AlertDialogContent paddingX={2} paddingY={3} borderRadius="4px">
          <AlertDialogHeader fontWeight="normal">{title}</AlertDialogHeader>
          <AlertDialogBody>
            {body}
            {!enableRetry && (
              <Text>Please contact support for assistance.</Text>
            )}
          </AlertDialogBody>

          <AlertDialogFooter width="100%" justifyContent="space-between">
            {enableRetry && (
              <Button paddingX={6} variant="brandPrimary" onClick={onTryAgain}>
                Try again
              </Button>
            )}
            <Button
              paddingX={6}
              variant={enableRetry ? 'brandCancel' : 'brandPrimary'}
              ref={cancelRef}
              onClick={() => {
                if (enableRetry) {
                  onCancel();
                } else {
                  onTerminalError();
                }
              }}
            >
              {enableRetry ? 'Cancel' : 'Close'}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}

ApiErrorAlert.defaultProps = {
  enableRetry: true,
  onTerminalError: () => {},
};

export default ApiErrorAlert;
