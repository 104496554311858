import React from 'react';
import { Controller, Control, RegisterOptions } from 'react-hook-form';
import ReactDatePicker from './DatePicker';

export const timePickerFormat = 'h:mm aa';

interface CustomTimepickerProps {
  fieldName: string;
  control: Control;
  rules?: Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;
  placeholderText?: string;
  disabled?: boolean;
  id?: string;
}

function CustomTimepicker(props: CustomTimepickerProps): JSX.Element {
  const { fieldName, control, rules, placeholderText, disabled, id } = props;
  return (
    <Controller
      name={fieldName}
      control={control}
      rules={rules}
      render={({ field: { onChange, value } }) => (
        <ReactDatePicker
          id={id}
          selected={value ? new Date(value) : null}
          onChange={onChange}
          placeholderText={placeholderText}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={15}
          dateFormat={timePickerFormat}
          disabled={disabled}
        />
      )}
    />
  );
}

CustomTimepicker.defaultProps = {
  rules: {},
  placeholderText: '',
  id: '',
  disabled: false,
};
export default CustomTimepicker;
