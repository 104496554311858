import { fullMonthDateWithDay } from 'sharedHelpers/textFormat';
import emphasizedDateTime from 'sharedHelpers/emphasizedDateTime';

import type { ClientEvent } from '../types/clientTypes';

export interface DayEvents {
  /**
   * A string representing the date that the events occur on
   * eg. "Thursday, June 3, 2021"
   */
  date: string | null;
  /**
   * A list of ClientEvent objects sorted in chronological order.  All events
   * occur on the day represented by date.
   */
  events: ClientEvent[];
}

/**
 * A utility to transform an array of dates sorted in reverse date order into
 * an array of objects, each with a date and a list of events on that date,
 * where the events are sorted in ascending chronological order.
 *
 * @param events an array of events in reverse chronological order.
 * @return an array of DayEvents objects.
 */
export default (events: ClientEvent[]): DayEvents[] =>
  events.reduce((datesArray: DayEvents[], event: ClientEvent) => {
    const renderedDate = fullMonthDateWithDay(emphasizedDateTime(event));
    const lastDate = datesArray[datesArray.length - 1];
    if (lastDate && lastDate.date === renderedDate) {
      lastDate.events.unshift(event);
      return datesArray;
    }
    const newDate: DayEvents = { date: renderedDate, events: [] };
    newDate.events.push(event);
    datesArray.push(newDate);
    return datesArray;
  }, []);

/**
 * A utility to transform an array of dates sorted in reverse date order into
 * an array, with a string entry for each date and the event objects for each
 * event.
 * The array will have the dates in reverse chronological order and the events
 * for each day in ascending chronological order
 *
 * @param events an array of events in reverse chronological order.
 * @return a mixed array of strings and ClientEvent objects
 */
export const flattenEvents = (
  events: ClientEvent[]
): Array<string | ClientEvent> => {
  // The source events are tracked in descending chronological order
  // 1. Push the day string to the target
  // 2. For each event, pop off the source and push to the target - this will reverse
  // event storage into ascending chronological order, per day.
  const flushCurrentDay = (
    day: DayEvents,
    target: Array<string | ClientEvent>
  ) => {
    if (day.date) {
      target.push(day.date);
    }
    while (day.events.length) {
      target.push(day.events.pop() as ClientEvent);
    }
  };
  const currentDay: DayEvents = { date: '', events: [] };
  return events.reduce(
    (
      flatDayEvents: Array<string | ClientEvent>,
      event: ClientEvent,
      currentIndex: number
    ): Array<string | ClientEvent> => {
      const renderedDate = fullMonthDateWithDay(emphasizedDateTime(event));
      // This event is on a new day, flush the prior day
      if (renderedDate !== currentDay.date) {
        flushCurrentDay(currentDay, flatDayEvents);
      }
      currentDay.date = renderedDate;
      currentDay.events.push(event);
      // Flush since this is the last event in the list
      if (currentIndex === events.length - 1) {
        flushCurrentDay(currentDay, flatDayEvents);
      }
      return flatDayEvents;
    },
    []
  );
};

/**
 * A utility to flatten a DayEvents object into a mixed array of strings and
 * ClientEvent objects.  The order of days and events in each day will be
 * preserved from the original
 *
 * @param events a DayEvents object
 * @return a mixed array of strings and ClientEvent objects
 */
export const flattenDayEvents = (
  events: DayEvents[]
): Array<string | ClientEvent> =>
  events.flatMap((event) =>
    event.date ? [event.date, ...event.events] : event.events
  );
