import React, { useEffect } from 'react';
import { useReactiveVar } from '@apollo/client';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useHistory } from 'react-router-dom';

import InfoView from 'sharedComponents/InfoView';
import InfoViewSkeleton from 'sharedComponents/InfoViewSkeleton';

import { isStaffUser } from 'sharedHelpers/typeNarrowing';
import { alphabetizeClients } from 'sharedHelpers/clientHelpers';

import useNavigateToClient from 'sharedHooks/useNavigateToClient';

import { useGetUserQuery } from 'generated/graphql';

import { newClientId } from 'sharedHooks/useSaveClient';

import useFilterClients from 'sharedHooks/useFilterClients';
import AddClientButton from './components/AddClientButton';
import ClientListPanelItem from './components/ClientListPanelItem';
import ClientDetailPanel from './components/ClientDetailPanel';
import Dashboard from './components/Dashboard';

import { clientsList } from './dataTransforms';

import {
  allClientsListVar,
  organizationId,
} from '../../shared/graphql/apolloCache';

interface ClientsPageProps {
  clientId?: string;
  tabId?: string;
}

function ClientsPage({ clientId, tabId }: ClientsPageProps): JSX.Element {
  const clients = useReactiveVar(allClientsListVar);
  const rediectClientId = useReactiveVar(newClientId);
  // dashboard
  const { showClientsDashboardFlag, filterIncludeEventStaff } = useFlags();

  const { data: serverData, loading } = useGetUserQuery({
    variables: {
      filterIncludeEventStaff: filterIncludeEventStaff || false,
    },
  });
  const history = useHistory();

  useEffect(() => {
    if (serverData && isStaffUser(serverData.getUser)) {
      const user = serverData.getUser;
      const { id } = user.organization || {};
      allClientsListVar(clientsList(user.clients));
      organizationId(id);
    }
  }, [serverData]);

  const filteredClientsList = useFilterClients({
    clientsList: clients,
    sortFunction: alphabetizeClients,
  });

  const navigateToClient = useNavigateToClient();
  useEffect(() => {
    if (
      clientId &&
      filteredClientsList.length &&
      !new Set(filteredClientsList.map((client) => client.id)).has(clientId)
    ) {
      history.push('/clients');
    }
    if (
      rediectClientId &&
      new Set(filteredClientsList.map((client) => client.id)).has(
        rediectClientId
      )
    ) {
      navigateToClient(rediectClientId);
    }
    if (!showClientsDashboardFlag && !clientId && filteredClientsList.length) {
      navigateToClient(filteredClientsList[0].id);
    }
  }, [clientId, rediectClientId, filteredClientsList.length]);

  return (
    <>
      {loading && <InfoViewSkeleton />}
      {!loading && (
        <InfoView
          selectedItem={clientId}
          listPanelChildren={({ index, style }) => (
            <ClientListPanelItem
              clientsData={filteredClientsList}
              style={style}
              index={index}
            />
          )}
          detailPanelChildren={
            clientId ? (
              <ClientDetailPanel tab={Number(tabId)} clientId={clientId} />
            ) : (
              showClientsDashboardFlag && <Dashboard />
            )
          }
          title="Clients"
          numberOfItems={filteredClientsList.length}
          HeaderButton={<AddClientButton />}
          errorMessage={
            clients.length > filteredClientsList.length
              ? 'There are no clients matching the current filters'
              : 'You have no clients to display'
          }
        />
      )}
    </>
  );
}
ClientsPage.defaultProps = {
  clientId: undefined,
  tabId: '0',
};

export default ClientsPage;
