import { useEffect, useState } from 'react';

const useDebounce = <T,>(
  value: T | null | undefined,
  delay = 1000
): T | null | undefined => {
  const [debouncedValue, setDebouncedValue] = useState<T | undefined | null>(
    null
  );
  useEffect(() => {
    const timeoutID = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(timeoutID);
    };
  }, [value, delay]);

  return debouncedValue;
};

export default useDebounce;
