import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export default (props: IconProps): JSX.Element => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Icon viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M16 0H6C4.9 0 4 0.9 4 2V4C4 4.55 4.45 5 5 5C5.55 5 6 4.55 6 4V3H16V19H6V18C6 17.45 5.55 17 5 17C4.45 17 4 17.45 4 18V20C4 21.1 4.9 22 6 22H16C17.1 22 18 21.1 18 20V2C18 0.9 17.1 0 16 0ZM4.01 12.47L2.09 10.55C1.74 10.2 1.17 10.2 0.819995 10.55C0.469995 10.9 0.469995 11.47 0.819995 11.82L3.29 14.29C3.68 14.68 4.31 14.68 4.7 14.29L10.55 8.44C10.9 8.09 10.9 7.52 10.55 7.17C10.2 6.82 9.63 6.82 9.27999 7.17L4.01 12.47Z"
    />
  </Icon>
);
