import { useState, useEffect } from 'react';
import type { AllStatsType } from './useClientsDashboardStats';

interface EventStats {
  count: number;
  total: number;
}

export interface AttendanceTidbitsData {
  courtDate: EventStats;
  office: EventStats;
  virtual: EventStats;
  checkIn: EventStats;
}

interface UseAttendanceTidbitsStatsParams {
  allStats: AllStatsType;
}
const blankStats = {
  courtDate: {
    count: 0,
    total: 0,
  },
  office: {
    count: 0,
    total: 0,
  },
  virtual: {
    count: 0,
    total: 0,
  },
  checkIn: {
    count: 0,
    total: 0,
  },
};

function useAttendanceTidbitsStats({
  allStats,
}: UseAttendanceTidbitsStatsParams): {
  stats: AttendanceTidbitsData;
} {
  const [stats, setStats] = useState<AttendanceTidbitsData>(blankStats);

  useEffect(() => {
    if (allStats) {
      const {
        courtDate,
        office,
        virtual,
        checkIn,
      } = allStats.attendanceTidbitsEvents;
      setStats({
        courtDate: { total: courtDate.total, count: courtDate.attended },
        office: { total: office.total, count: office.attended },
        virtual: {
          total: virtual.total,
          count: virtual.attended,
        },
        checkIn: {
          total: checkIn.total,
          count: checkIn.attended,
        },
      });
    }
  }, [allStats]);

  return { stats };
}

export default useAttendanceTidbitsStats;
