import type { Carrier } from 'src/generated/graphql';

export default (
  noPhone: boolean | undefined | null,
  carrier: Carrier | undefined | null,
  hasMobileApp: boolean | undefined | null,
  lastSend: string | undefined | null
): Array<string> => {
  const alertsFilterValues = [];
  const { error, type } = carrier ?? {};
  if (
    hasMobileApp === false &&
    (error != null || lastSend === 'error') &&
    (noPhone === true || carrier == null || type == null)
  ) {
    alertsFilterValues.push('unreachable');
  }
  return alertsFilterValues;
};
