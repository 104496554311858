import React, { useState, useEffect } from 'react';
import {
  Box,
  Stack,
  Checkbox,
  CheckboxGroup,
  FormControl,
  FormLabel,
  Button,
  Flex,
  Skeleton,
  Tooltip,
} from '@chakra-ui/react';
import { useReactiveVar } from '@apollo/client';

import useApiClientsLists, {
  ApiClientsLists,
  apiClientsListsVar,
} from 'sharedHooks/useApiClientsLists';

import type { ClientFilter } from '../types/clientTypes';

interface FilterChoice {
  displayName: string;
  value: string | number;
}

interface Filter {
  field: keyof ClientFilter;
  values: Array<string | number>;
  api?: boolean;
}

interface ApiFilterChoice extends FilterChoice {
  value: keyof ApiClientsLists;
}

interface FilterGroupProps {
  field: keyof ClientFilter;
  name: string;
  id: string;
  choices: FilterChoice[];
  apiChoices: ApiFilterChoice[];
  updateFilters: (newFilter: Filter) => void;
  defaultValue: string[] | number[];
}

function FilterGroup(props: FilterGroupProps): JSX.Element {
  const {
    field,
    name,
    id,
    choices,
    apiChoices,
    updateFilters,
    defaultValue,
  } = props;
  const [groupValues, setGroupValues] = useState<string[] | number[]>([]);
  const [groupApiValues, setGroupApiValues] = useState<string[] | number[]>([]);
  const apiClientsLists = useReactiveVar(apiClientsListsVar);

  useApiClientsLists(apiChoices.map((choice) => choice.value));

  const onFilterChange = (values: string[] | number[]): void => {
    setGroupValues(values);
    updateFilters({ field, values });
  };

  const onApiFilterChange = (values: string[] | number[]): void => {
    setGroupApiValues(values);
    updateFilters({ field, values, api: true });
  };

  useEffect(() => {
    onFilterChange(defaultValue);
  }, []);

  if (!choices.length) {
    return <></>;
  }
  return (
    <Box>
      <form
        onReset={() => {
          onFilterChange([]);
          onApiFilterChange([]);
        }}
        id={`${id}`}
      >
        <FormControl as="fieldset">
          <Flex direction="row" justifyContent="space-between">
            <FormLabel
              as="legend"
              textTransform="uppercase"
              fontSize="sm"
              fontWeight="700"
              color="brand.gray1"
            >
              {name}
            </FormLabel>
            <Button
              size="xs"
              variant="ghost"
              color="brand.blue2"
              visibility={
                [...groupValues, ...groupApiValues].length
                  ? 'visible'
                  : 'hidden'
              }
              type="reset"
              form={`${id}`}
            >
              Clear
            </Button>
          </Flex>
          <CheckboxGroup onChange={onFilterChange} value={groupValues}>
            <Stack>
              {choices.map((choice) => (
                <Checkbox
                  key={choice.value}
                  value={choice.value}
                  color="brand.gray1"
                  borderColor="brand.gray1"
                  display="flex"
                  alignItems="start"
                  sx={{ '.chakra-checkbox__control': { marginTop: '.25em' } }}
                >
                  {choice.displayName}
                </Checkbox>
              ))}
            </Stack>
          </CheckboxGroup>
          <CheckboxGroup onChange={onApiFilterChange} value={groupApiValues}>
            <Stack marginTop={2}>
              {apiChoices.map((choice) => (
                <Skeleton
                  key={choice.value}
                  isLoaded={apiClientsLists[choice.value].loaded}
                  data-testid="filter-group-skeleton"
                >
                  <Checkbox
                    isDisabled={!!apiClientsLists[choice.value].error}
                    value={choice.value}
                    color="brand.gray1"
                    borderColor="brand.gray1"
                    display="flex"
                    alignItems="start"
                    sx={{ '.chakra-checkbox__control': { marginTop: '.25em' } }}
                  >
                    <Tooltip
                      hasArrow
                      placement="bottom-start"
                      isDisabled={!apiClientsLists[choice.value].error}
                      label="There was an issue loading this filter."
                      bg="brand.gray7"
                      color="brand.gray2"
                    >
                      {choice.displayName}
                    </Tooltip>
                  </Checkbox>
                </Skeleton>
              ))}
            </Stack>
          </CheckboxGroup>
        </FormControl>
      </form>
    </Box>
  );
}

export default React.memo(FilterGroup);
