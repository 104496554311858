import React, { HTMLAttributes } from 'react';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import '../styles/date-picker.css';

interface DatePickerProps extends ReactDatePickerProps {
  /** A function that handles onChange events */
  onChange(
    date: Date | null,
    event: React.SyntheticEvent<any> | undefined
  ): void;
  /** The selected Date object for the time or date the user has selected */
  selected?: Date | null;
  /** A string that determines which picker and format to display
   *
   *  When set to 'MMMM d, yyyy', inputs will be formatted as 'January, 1, 2021'.
   *  When set to 'h:mm aa' inputs will be formatted as 9:00 AM.
   *  Additional formatting options at: https://reactdatepicker.com/
   *
   */
  dateFormat?: string | string[];
  /** A boolean that enables/disables the picker */
  disabled?: boolean;
  /** A boolean that when true, displays an arrow on the picker indicating the input field
   *  in focus.
   */
  showPopperArrow?: boolean;
  /** A boolean that when true will display the time picker */
  showTimeSelect?: boolean;
  /** A boolean that when true and showTimeSelect is also true will only display the
   *  time picker but not the calendar picker */
  showTimeSelectOnly?: boolean;
  /** A number of minutes to between time picker selection options */
  timeIntervals?: number;
  /** A string that stands in for an input value until an input value is selected. */
  placeholderText?: string;
}

const DatePicker = ({
  selected,
  dateFormat,
  onChange,
  disabled,
  showPopperArrow = false,
  showTimeSelect,
  timeIntervals,
  placeholderText,
  showTimeSelectOnly,
}: DatePickerProps & HTMLAttributes<HTMLElement>): JSX.Element => (
  <ReactDatePicker
    dateFormat={dateFormat as string}
    selected={selected as Date}
    onChange={onChange}
    disabled={disabled as boolean}
    showPopperArrow={showPopperArrow}
    showTimeSelect={showTimeSelect as boolean}
    showTimeSelectOnly={showTimeSelectOnly as boolean}
    timeIntervals={timeIntervals as number}
    placeholderText={placeholderText as string}
    preventOpenOnFocus
  />
);

DatePicker.defaultProps = {
  selected: null,
  dateFormat: '',
  disabled: false,
  showPopperArrow: false,
  showTimeSelect: false,
  timeIntervals: 0,
  placeholderText: '',
  showTimeSelectOnly: false,
};

export default DatePicker;
