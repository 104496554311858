import { gql } from '@apollo/client';

export default gql`
  fragment getClientInformation on Client {
    displayName
    phone
    staff {
      id
    }
  }
`;

export interface GetClientInformationFragmentData {
  displayName: string;
  phone: string;
  staff: {
    id: string;
  };
}

export type GetClientInformationFragmentVars = never;
