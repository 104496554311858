import React from 'react';
import { Flex, Spacer, Skeleton } from '@chakra-ui/react';

import StatusIcons from 'sharedComponents/StatusIcons';
import CloseClientMenu from 'sharedComponents/CloseClientMenu';
import StatusIndicatorClosed from 'sharedComponents/StatusIndicatorClosed';

import useStatusBarInfo from 'sharedHooks/useStatusBarInfo';

interface StatusBarProps {
  miniBar: boolean;
  clientId: string;
}

function StatusBar({ miniBar, clientId }: StatusBarProps): JSX.Element {
  const currentClient = clientId;
  const { closed, statusIconsData, loading, closedAt } = useStatusBarInfo(
    currentClient
  );

  return (
    <Skeleton
      isLoaded={!loading}
      width={miniBar ? 'fit-content' : '100%'}
      height={miniBar ? 8 : 12}
    >
      {!loading && !!statusIconsData && (
        <Flex
          height={miniBar ? 8 : 12}
          backgroundColor="brand.gray8"
          borderColor="brand.gray6"
          borderWidth="1px"
          borderRadius="4px"
          alignItems="center"
          paddingX={miniBar ? 0 : 5}
        >
          {closed ? (
            <StatusIndicatorClosed mini={miniBar} closedAt={closedAt} />
          ) : (
            <StatusIcons statusData={statusIconsData} mini={miniBar} />
          )}
          {!miniBar && (
            <>
              <Spacer />
              <CloseClientMenu clientId={currentClient} isClosed={closed} />
            </>
          )}
        </Flex>
      )}
    </Skeleton>
  );
}

export default StatusBar;
