import React from 'react';
import { Box } from '@chakra-ui/react';

import useGetClientInformation from 'sharedHooks/useGetClientInformation';
import DetailPanelHeader from 'sharedComponents/DetailPanelHeader';
import PageNavigationTabs from 'sharedComponents/PageNavigationTabs';

import ClientMessagesTabPanel from './MessagesDetailPanel';

interface ClientDetailPanelProps {
  clientId: string;
}

function ClientDetailPanel({ clientId }: ClientDetailPanelProps): JSX.Element {
  const { displayName } = useGetClientInformation(clientId);

  const tabPanelHeight = 'calc(100% - 40px - 64px - 0.5rem)';

  return (
    <>
      {clientId && (
        <Box width="100%" height="100%">
          <DetailPanelHeader title={displayName} miniBar clientId={clientId} />
          <PageNavigationTabs selectedTab={1} />

          <Box height={tabPanelHeight} padding="0">
            <ClientMessagesTabPanel clientId={clientId} />
          </Box>
        </Box>
      )}
    </>
  );
}

export default ClientDetailPanel;
