import type { StaffMember } from 'sharedHooks/useStaffMemberList';
import type { ApiClientsLists } from 'sharedHooks/useApiClientsLists';
import type { CohortData } from 'sharedHooks/useCohorts';
import getLocalizedText from 'sharedHelpers/getLocalizedText';
import RiskLevels from '../constants/RiskLevels';
import type { ClientFilter } from '../types/clientTypes';

interface FilterChoice {
  displayName: string;
  value: string | number;
}

interface ApiChoice extends FilterChoice {
  value: keyof ApiClientsLists;
}

export interface FilterData {
  field: keyof ClientFilter;
  name: string;
  id: string;
  choices: FilterChoice[];
  apiChoices?: ApiChoice[];
  defaultValue?: string[] | number[];
}

const riskLevelFilter = (showRiskLevelFlag: boolean): FilterData => {
  const riskLevelChoices = Object.entries(
    RiskLevels
  ).map(([value, displayName]) => ({ displayName, value }));
  return {
    field: 'riskLevel',
    name: 'risk level',
    id: 'risk-level',
    choices: showRiskLevelFlag ? riskLevelChoices : [],
  };
};

const assignedStaffFilter = (
  staffMemberList: StaffMember[],
  staffMemberLabel: string,
  filterIncludeEventStaff: boolean
): FilterData => {
  const choices = staffMemberList.map((staff) => ({
    displayName: staff.name,
    value: staff.id,
  }));
  return {
    field: filterIncludeEventStaff ? 'assignedStaff' : 'staffId',
    name: staffMemberLabel,
    id: staffMemberLabel,
    choices,
  };
};

const activityFilter = (): FilterData => ({
  field: 'activity',
  name: 'activity',
  id: 'activity',
  choices: [
    { displayName: 'Unread messages', value: 'unread' },
    { displayName: 'Recently Sent', value: 'recent' },
  ],
  apiChoices: [
    { displayName: 'Attendance not marked', value: 'MissingOutcomes' },
    { displayName: 'Events today', value: 'Today' },
    { displayName: 'Events in the next week', value: 'InTheNextWeek' },
  ],
});

const alertsFilter = (): FilterData => ({
  field: 'alerts',
  name: 'alerts',
  id: 'alerts',
  choices: [{ displayName: 'Unreachable', value: 'unreachable' }],
});

const statusFilter = (showIncarceratedFilterFlag: boolean): FilterData => {
  const choices = [
    { displayName: 'Active', value: 'active' },
    { displayName: 'Inactive', value: 'inactive' },
  ];
  if (showIncarceratedFilterFlag) {
    choices.push({
      displayName: 'Incarcerated',
      value: 'inCustody',
    });
  }
  return {
    field: 'status',
    name: 'status',
    id: 'status',
    choices,
    defaultValue: ['active'],
  };
};

const cohortsFilter = (cohortData: CohortData): FilterData => {
  const { cohorts, cohortLabel } = cohortData || {};
  const choices =
    cohorts?.map((cohortItem) => ({
      displayName: getLocalizedText(cohortItem?.name || []),
      value: cohortItem.id,
    })) || [];
  return {
    field: 'cohortId',
    name: cohortLabel,
    id: 'cohort',
    choices,
  };
};

export interface FilterListOptions {
  staffMemberList: StaffMember[];
  staffMemberLabelFlag: 'supervisor' | 'attorney' | 'staff';
  showRiskLevelFlag: boolean;
  showIncarceratedFilterFlag: boolean;
  cohortData: CohortData;
}

export default (
  options: FilterListOptions,
  filterIncludeEventStaff: boolean
): FilterData[] => {
  const {
    staffMemberList,
    staffMemberLabelFlag,
    showRiskLevelFlag,
    showIncarceratedFilterFlag,
    cohortData,
  } = options;
  return [
    activityFilter(),
    alertsFilter(),
    riskLevelFilter(showRiskLevelFlag),
    cohortsFilter(cohortData),
    assignedStaffFilter(
      staffMemberList,
      staffMemberLabelFlag,
      filterIncludeEventStaff
    ),
    statusFilter(showIncarceratedFilterFlag),
  ];
};
