import type { ClientEvent } from 'src/shared/types/clientTypes';

const eventDisplayTitle = (event: ClientEvent) => {
  switch (event.type) {
    case 'COURT_DATE':
      return 'Court Date';
    case 'OFFICE':
    case 'VIRTUAL':
      return event.title;
    case 'CHECK_IN':
      return event.checkInTemplate.staffDisplayName;
    default:
      return 'Event';
  }
};

export default eventDisplayTitle;
