import React, { Suspense, useEffect } from 'react';
import { useReactiveVar } from '@apollo/client';
import { Flex, Box, Spacer, Skeleton } from '@chakra-ui/react';
import MainHeader from 'sharedComponents/MainHeader';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { useGetStaffMemberListQuery } from 'generated/graphql';
import { isStaffUser } from 'sharedHelpers/typeNarrowing';
import transformConnection from 'sharedHelpers/transformConnection';
import {
  headerHidden,
  staffList,
  sentAnnouncementIdsVar,
} from '../graphql/apolloCache';

const FilterPanel = React.lazy(() => import('sharedComponents/FilterPanel'));
const EventNotificationContainer = React.lazy(
  () => import('sharedComponents/EventNotificationContainer')
);
const AnnouncementNotificationContainer = React.lazy(
  () => import('sharedComponents/AnnouncementNotificationContainer')
);
const AnnouncementDetailsContainer = React.lazy(
  () => import('sharedComponents/AnnouncementDetailsContainer')
);

interface PageLayoutProps {
  children: React.ReactNode;
  pageLoading: boolean;
}

function PageLayout({ children, pageLoading }: PageLayoutProps): JSX.Element {
  const hidden = useReactiveVar(headerHidden);
  const sentAnnouncementIds = useReactiveVar(sentAnnouncementIdsVar);
  const { showFilterPanelFlag } = useFlags();
  const { data: serverData } = useGetStaffMemberListQuery();

  // Set up staff list data for filters, announcement and client dropdowns, etc
  useEffect(() => {
    if (serverData && isStaffUser(serverData.getUser)) {
      const user = serverData.getUser;
      const { staff } = user.organization;
      staffList(staff ? transformConnection(staff) : []);
    }
  }, [serverData]);
  return (
    <Box
      style={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
      }}
    >
      <Flex
        direction="column"
        backgroundColor="brand.gray6"
        height="100vh"
        textAlign="center"
      >
        <MainHeader loading={pageLoading} hidden={hidden} />
        <Flex
          justifyContent="space-around"
          paddingX="6.5vw"
          paddingTop={6}
          paddingBottom={8}
          height="calc(100% - 69px)"
        >
          {showFilterPanelFlag && (
            <Suspense fallback={<Skeleton />}>
              <FilterPanel />
              <Spacer minWidth="8px" />
            </Suspense>
          )}

          {children}
          <Suspense fallback={<></>}>
            <EventNotificationContainer />
          </Suspense>

          <Suspense fallback={<></>}>
            <AnnouncementDetailsContainer />
          </Suspense>

          <Suspense fallback={<></>}>
            {sentAnnouncementIds.map((id) => (
              <AnnouncementNotificationContainer announcementId={id} key={id} />
            ))}
          </Suspense>
        </Flex>
      </Flex>
    </Box>
  );
}

export default PageLayout;
