import React, { useState } from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  Button,
  Stack,
} from '@chakra-ui/react';

import DeleteEventConfirmation, {
  EventData,
} from 'sharedComponents/DeleteEventConfirmation';
import UpdateEventsOnClientEditConfirmation from 'sharedComponents/UpdateEventsOnClientEditConfirmation';
import type { ClientFormFields } from '../types/formTypes';

/*
 * @param FormDataType: An interface with the shape of data that your form returns.
 * eg. {clientId: string; firstName: string; lastName: string;}
 */
export interface EditDrawerProps<FormDataType> {
  /**
   * Defines whether the drawer is open or not.  Pass this in from useDisclosure.
   */
  isOpen: boolean;
  /**
   * What happens when the user closes the form (without saving).  Be sure to call the function returned from useDisclosure.
   */
  onClose: () => void;
  /**
   * The form you would like to render inside the drawer.
   */
  FormContent: (props: {
    formData: FormDataType;
    formId: string;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    onClose: () => void;
    onDelete?: (deleteData: any) => void;
    onUpdateClient?: (clientData: any, newPrimaryStaffId: string) => void;
  }) => JSX.Element;
  /**
   * The title for the drawer.
   */
  title: string;
  /**
   * Initial data to pass into your form, eg the existing client or event for an edit form.
   */
  formData: FormDataType;
  /**
   * A unique string that identifies your form.
   */
  formId: string;
  onOpen?: () => void;
}

function EditDrawer<FormDataType>(
  props: EditDrawerProps<FormDataType>
): JSX.Element {
  const {
    isOpen,
    onClose,
    FormContent,
    title,
    formData,
    formId,
    onOpen = () => {},
  } = props;
  const [loading, setLoading] = useState(false);
  const [confirmIsOpen, setConfirmIsOpen] = useState(false);
  const [deleteEventData, setDeleteEventData] = useState<EventData>(
    {} as EventData
  );

  const openDeleteEventConfirm = (eventData: EventData) => {
    setDeleteEventData(eventData);
    onClose();
    setConfirmIsOpen(true);
  };

  const confirmOnCancel = () => {
    setConfirmIsOpen(false);
    onOpen();
  };

  const confirmOnSuccesfulDelete = () => {
    setConfirmIsOpen(false);
  };

  const deleteTerminalError = () => {
    setConfirmIsOpen(false);
    onClose();
  };

  // MSPC update confirmation
  const [clientUpdateConfirmIsOpen, setClientUpdateConfirmIsOpen] = useState(
    false
  );
  const [
    updateClientStaffData,
    setUpdateClientStaffData,
  ] = useState<ClientFormFields>({} as ClientFormFields);
  const [updatedPrimaryStaff, setUpdatedPrimaryStaff] = useState('');

  const openUpdateClientConfirm = (
    clientData: ClientFormFields,
    newPrimaryStaffId: string
  ) => {
    setUpdateClientStaffData(clientData);
    setUpdatedPrimaryStaff(newPrimaryStaffId);
    setClientUpdateConfirmIsOpen(true);
  };

  const onUpdateClientCancel = () => {
    setClientUpdateConfirmIsOpen(false);
  };

  const onUpdateClientSuccess = () => {
    setClientUpdateConfirmIsOpen(false);
    onClose();
  };

  return (
    <>
      {confirmIsOpen && (
        <DeleteEventConfirmation
          isOpen={confirmIsOpen}
          onSuccessfulDelete={confirmOnSuccesfulDelete}
          onCancel={confirmOnCancel}
          eventData={deleteEventData}
          onTerminalError={deleteTerminalError}
        />
      )}
      {clientUpdateConfirmIsOpen && (
        <UpdateEventsOnClientEditConfirmation
          isOpen={clientUpdateConfirmIsOpen}
          onSuccessfulUpdate={onUpdateClientSuccess}
          onCancel={onUpdateClientCancel}
          clientData={updateClientStaffData}
          newPrimaryStaffId={updatedPrimaryStaff}
        />
      )}
      <Drawer isOpen={isOpen} onClose={onClose} placement="right" size="lg">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader color="brand.gray1" fontSize="2xl" fontWeight="normal">
            {title}
          </DrawerHeader>

          <DrawerBody>
            <FormContent
              formData={formData}
              formId={formId}
              setLoading={setLoading}
              onClose={onClose}
              onDelete={openDeleteEventConfirm}
              onUpdateClient={openUpdateClientConfirm}
            />
          </DrawerBody>

          <DrawerFooter justifyContent="flex-start" paddingX={12}>
            <Stack direction="row" spacing={6}>
              <Button
                colorScheme="blue"
                backgroundColor="brand.blue2"
                type="submit"
                form={formId}
                paddingX={10}
                isLoading={loading}
              >
                Save
              </Button>
              <Button
                variant="ghost"
                marginRight={3}
                paddingX={4}
                onClick={onClose}
                color="brand.blue2"
              >
                Cancel
              </Button>
            </Stack>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}

EditDrawer.defaultProps = {
  onOpen: () => {},
};

export default EditDrawer;
export type EditDrawerType<T> = typeof EditDrawer;
