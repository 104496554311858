import { useReactiveVar } from '@apollo/client';
import { apiFiltersVar, ApiFilters } from 'sharedComponents/FilterPanel';
import type { Client } from 'generated/graphql';

import { apiClientsListsVar, ApiClientsLists } from './useApiClientsLists';

const applyApiFilters = (
  currentApiFilters: ApiFilters,
  apiClientsLists: ApiClientsLists
) => (client: Client) =>
  Object.keys(currentApiFilters).length
    ? Object.values(currentApiFilters).every((values) => {
        if (values.length) {
          return values.some((value) =>
            apiClientsLists[value].clients.includes(client.id)
          );
        }
        return true;
      })
    : true;

interface UseApiFilterTest {
  apiTest: (client: Client) => boolean;
}

export default (): UseApiFilterTest => {
  const apiFilters = useReactiveVar(apiFiltersVar);
  const apiClientsLists = useReactiveVar(apiClientsListsVar);

  const apiTest = applyApiFilters(apiFilters, apiClientsLists);
  return { apiTest };
};
