import { useState, useEffect } from 'react';
import { useReactiveVar } from '@apollo/client';
import {
  useGetClientAssignedStaffQuery,
  GetClientAssignedStaffQuery,
  StaffUser,
} from 'generated/graphql';

import { staffList } from '../graphql/apolloCache';

export interface StaffMember {
  name: string;
  id: string;
}

interface AssignableStaffProps {
  clientId: string | undefined;
  enableMSPCFlag: boolean;
}

const generateStaffList = ({
  data,
  staffListVar,
  enableMSPCFlag,
}: {
  data: GetClientAssignedStaffQuery | undefined;
  staffListVar: StaffUser[];
  enableMSPCFlag: boolean;
}): StaffMember[] => {
  if (!staffListVar.length || !data?.getClient) {
    return [];
  }

  if (enableMSPCFlag && data.getClient?.assignedStaff?.length) {
    const assignedStaffList = data.getClient.assignedStaff;
    return assignedStaffList;
  }
  const primaryStaffId = data.getClient.staffId;
  if (primaryStaffId) {
    return [
      staffListVar.find((staff) => staff.id === primaryStaffId) as StaffMember,
    ];
  }
  return [];
};

export default ({
  clientId,
  enableMSPCFlag,
}: AssignableStaffProps): {
  assignedStaff: StaffMember[];
  loading: boolean;
} => {
  const [staffMembers, setStaffMembers] = useState<StaffMember[]>([]);
  const { data, loading } = useGetClientAssignedStaffQuery({
    variables: { id: clientId ?? '' },
    skip: !clientId,
  });

  const staffListVar = useReactiveVar(staffList);

  useEffect(() => {
    setStaffMembers(generateStaffList({ staffListVar, data, enableMSPCFlag }));
  }, [JSON.stringify(staffListVar), data, enableMSPCFlag]);

  return { assignedStaff: staffMembers, loading };
};
