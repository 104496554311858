import { gql } from '@apollo/client';

export default gql`
  query refetchClientsList {
    getUser {
      id
      ... on StaffUser {
        clients {
          edges {
            node {
              id
              firstName
              lastName
              staffId
              messages(last: 1) @connection(key: "messages") {
                edges {
                  node {
                    id
                    status
                    ... on FromStaffMessage {
                      errorMessage
                      body
                    }
                    ... on FromPlatformMessage {
                      errorMessage
                      body
                    }
                    ... on FromPlatformEventMessage {
                      errorMessage
                      body
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
