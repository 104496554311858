import React from 'react';
import { Flex, Text } from '@chakra-ui/react';
import type { PureQueryOptions } from '@apollo/client';

import { shortTime } from 'sharedHelpers/textFormat';
import emphasizedDateTime from 'sharedHelpers/emphasizedDateTime';
import { hasAttendance } from 'sharedHelpers/typeNarrowing';
import AttendanceSelectionMenu from 'sharedComponents/AttendanceSelectionMenu';
import EventAttendanceStatus from 'sharedComponents/EventAttendanceStatus';

import type { ClientEvent } from '../types/clientTypes';

interface AttendanceToolEventRowProps {
  event: ClientEvent;
  refetchQuery: PureQueryOptions;
  refetchDashboard: () => void;
}

function AttendanceToolEventRow({
  event,
  refetchQuery,
  refetchDashboard,
}: AttendanceToolEventRowProps): JSX.Element {
  return (
    <Flex
      direction="row"
      paddingY={3}
      paddingLeft={3}
      borderBottomColor="brand.gray6"
      borderWidth="0.5px 0"
      justifyContent="space-between"
    >
      <Flex maxWidth="66%" width="calc(40vw - 160px)" justifyContent="start">
        <Text
          color="brand.gray1"
          paddingRight={0.5}
          fontSize="md"
          flexShrink="0"
        >{`${event.clientFirstName} ${event.clientLastName}, `}</Text>
        <Text
          color="brand.gray3"
          fontSize="md"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          overflow="hidden"
          paddingRight={0.5}
          flexShrink="1"
        >
          {`${event.title}, `}
        </Text>
        <Text color="brand.gray3" fontSize="md" flexShrink="0">
          {`${shortTime(emphasizedDateTime(event))}`}
        </Text>
      </Flex>

      <Flex flex="0 1 10rem" minWidth={32} paddingX={3}>
        <AttendanceSelectionMenu
          clientEvent={event}
          refetchQuery={refetchQuery}
          refetchDashboard={refetchDashboard}
        />
      </Flex>
    </Flex>
  );
}

export default React.memo(AttendanceToolEventRow);
