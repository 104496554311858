import React, { useEffect } from 'react';
import { useReactiveVar, useApolloClient } from '@apollo/client';
import { Flex, Text, Button } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';

import { masqueradeIdentityVar, resetMasquerade } from '../graphql/apolloCache';
import { resetApolloClient } from '../graphql/apolloClient';

function MasqueradeBanner(): JSX.Element {
  const { staffName, organizationUid } = useReactiveVar(masqueradeIdentityVar);

  const history = useHistory();
  const client = useApolloClient();

  useEffect(() => {
    const storeUnsubscribe = client.onResetStore(async () => {
      history.replace('/');
    });
    return () => {
      storeUnsubscribe();
    };
  }, []);

  return (
    <Flex
      paddingY={3}
      paddingX={4}
      marginX={4}
      width="100%"
      backgroundColor="brand.orange3"
      borderRadius={4}
      borderWidth="1px"
      borderColor="brand.orange1"
      alignItems="center"
    >
      <Text color="brand.gray1" paddingX={2}>
        You are 🎭 masquerading as{' '}
        <Text as="span" fontWeight="bold">
          {staffName}{' '}
        </Text>
        <Text as="span"> from </Text>
        <Text as="span" fontWeight="bold">
          {organizationUid}
        </Text>
      </Text>
      <Button
        aria-label="Stop masquerading"
        backgroundColor="brand.gray7"
        color="brand.gray2"
        size="xs"
        minWidth="60px"
        onClick={() => {
          resetMasquerade();
          resetApolloClient();
        }}
      >
        Stop
      </Button>
    </Flex>
  );
}

export default React.memo(MasqueradeBanner);
