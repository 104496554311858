import { gql } from '@apollo/client';

export default gql`
  query refetchQuietHours {
    getUser {
      id
      type
      ... on StaffUser {
        quietHours {
          id
        }
      }
    }
  }
`;
