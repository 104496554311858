import React from 'react';
import { useQuery } from '@apollo/client';
import { SimpleGrid, Flex } from '@chakra-ui/react';
import { useFlags } from 'launchdarkly-react-client-sdk';

import Error from 'sharedIcons/Error';
import useCohorts from 'sharedHooks/useCohorts';
import useAssignedStaff from 'sharedHooks/useAssignedStaff';
import ClientDetailItem from './ClientDetailItem';
import ClientDetailItemSkeleton from './ClientDetailItemSkeleton';
import EditClientButton from './EditClientButton';
import transformDetailsData, {
  ClientDetailsPanelData,
} from '../transformDetailsData';
import getClientDetailsQuery from '../../../shared/graphql/queries/getClientDetails';

interface ClientDetailsTabPanelProps {
  clientId: string;
}

function ClientDetailsTabPanel(props: ClientDetailsTabPanelProps): JSX.Element {
  const { clientId } = props;
  const {
    staffMemberLabelFlag,
    showTermsFlag,
    showRiskLevelFlag,
    showCohortFlag,
    enableMspcFlag: enableMSPCFlag,
  } = useFlags();

  const { cohortLabel } = useCohorts(showCohortFlag);

  const { data, loading } = useQuery(getClientDetailsQuery, {
    variables: { id: clientId },
  });
  const { assignedStaff, loading: staffLoading } = useAssignedStaff({
    clientId,
    enableMSPCFlag,
  });

  const staffLabel = enableMSPCFlag ? 'assigned staff' : staffMemberLabelFlag;

  if (data || loading) {
    const displayData: ClientDetailsPanelData | false =
      data &&
      transformDetailsData({
        data: data.getClient,
        staffMemberLabelFlag: staffLabel,
        showTermsFlag,
        showRiskLevelFlag,
        showCohortFlag,
        assignedStaff,
        cohortLabel,
      });

    return (
      <Flex
        justifyContent="space-between"
        paddingX={6}
        alignItems="baseline"
        overflowY="auto"
        height="calc(100% - 60px)"
        tabIndex={0}
      >
        <SimpleGrid
          width="100%"
          paddingTop={4}
          paddingLeft={6}
          paddingRight={4}
          columns={2}
          rowGap={4}
          columnGap={8}
          templateColumns="max-content auto"
          maxHeight="calc(100% - 16px)"
        >
          {(loading || staffLoading) &&
            Array.from({ length: 16 }, (_, k) => (
              <ClientDetailItemSkeleton key={k} />
            ))}
          {data &&
            Object.entries(displayData).map(([row, rowValue]) => (
              <ClientDetailItem key={row} label={row} value={rowValue} />
            ))}
        </SimpleGrid>
        <EditClientButton clientData={{ assignedStaff, ...data?.getClient }} />
      </Flex>
    );
  }
  return (
    <Flex
      justifyContent="center"
      color="brand.orange1"
      padding={6}
      height="100%"
      tabIndex={0}
    >
      <Error marginX={1} boxSize="20px" marginY={1} />
      There was an error loading the information for this client.
    </Flex>
  );
}

export default ClientDetailsTabPanel;
