const Tag = {
  parts: ['container', 'label', 'closeButton'],
  variants: {
    defaultChip: {
      container: {
        backgroundColor: 'brand.gray7',
        paddingX: 3,
        paddingY: 2,
        borderRadius: '8px',
        height: 8,
      },
      label: {
        fontSize: 'sm',
        color: 'brand.gray1',
        paddingRight: 1,
      },
      closeButton: {
        color: 'brand.gray7',
        backgroundColor: 'brand.gray2',
        fontSize: 'sm',
        width: '1.1rem',
        height: '1.1rem',
      },
    },
  },
};

const Chip = {
  Tag,
};

const ChipList = {
  baseStyle: {
    display: 'flex',
    gap: 1,
    backgroundColor: 'brand.white',
    borderRadius: '4px',
    borderColor: '#e2e8f0',
    borderWidth: '1px',
    paddingX: 2,
    paddingY: 2,
    minHeight: 12,
    flexWrap: 'wrap',
    width: '100%',
    _hover: { borderColor: 'gray.300' },
  },
};

export { Chip, ChipList };
