import React, { useState, useEffect } from 'react';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import useIdentifyUser from 'sharedHooks/useIdentifyUser';
import config from '../../config';

export default function LaunchDarklyProvider({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  const [LDProvider, setLDProvider] = useState<JSX.Element>(<></>);
  const userInfo = useIdentifyUser();

  const user = userInfo
    ? {
        key: userInfo.id,
        email: userInfo.email,
        custom: {
          organization: userInfo.organization.uid || '',
          type: userInfo.organization.type || '',
          role: userInfo.role || '',
        },
      }
    : undefined;

  const initLDProvider = async () => {
    const Provider = await asyncWithLDProvider({
      clientSideID: config().launchDarkly.clientSideId,
      user,
      options: {
        bootstrap: 'localStorage',
      },
    });
    setLDProvider(<Provider>{children}</Provider>);
  };

  useEffect(() => {
    if (user) {
      initLDProvider();
    }
  }, [userInfo]);

  return LDProvider;
}
