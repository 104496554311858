import React, { useRef, useEffect, useState, useLayoutEffect } from 'react';
import { Box, Stack, Flex } from '@chakra-ui/react';
import { scrollIntoView } from 'scroll-polyfill';

import MessageBubble from 'sharedComponents/MessageBubble';
import MessagesListSkeleton from 'sharedComponents/MessagesListSkeleton';
import ExportMessagesButton from 'sharedComponents/ExportMessagesButton';
import useIdentifyUser from 'sharedHooks/useIdentifyUser';
import { shortDayOrDateTime } from 'sharedHelpers/textFormat';
import {
  useGetVisibleClientMessagesQuery,
  useUpdateLastOpenedTimestampMutation,
  MessageEdge,
  GetMessagesUpdatesDocument,
  GetMessagesUpdatesSubscription,
  GetMessagesUpdatesSubscriptionVariables,
} from 'generated/graphql';
import { useFlags } from 'launchdarkly-react-client-sdk';
import ExportMessagesButtonMSPC from 'sharedComponents/ExportMessagesButtonMSPC';
import { useReactiveVar } from '@apollo/client';
import SendMessageForm from './SendMessageForm';
import type { Message } from '../../../shared/types/clientTypes';
import { isMasqueradingVar } from '../../../shared/graphql/apolloCache';

const NUMBER_OF_MESSAGES_ON_FIRST_FETCH = 50;

interface ClientMessagesTabPanelProps {
  clientId: string;
}

function ClientMessagesTabPanel(
  props: ClientMessagesTabPanelProps
): JSX.Element {
  const { clientId } = props;

  const [scrolledUp, setScrolledUp] = useState(false);
  const [messages, setMessages] = useState<Array<Message | undefined | null>>(
    []
  );
  const [mediaMessages, setMediaMessages] = useState<Array<Message>>([]);
  const scrollRef = useRef<HTMLDivElement>(null);
  const previousClientIdRef = useRef(clientId);

  const { enableMspcFlag: enableMSPCFlag } = useFlags();
  const staffId = useIdentifyUser()?.id || '';
  const isMasquerading = useReactiveVar(isMasqueradingVar);

  const [updateLastOpenedTimestamp] = useUpdateLastOpenedTimestampMutation({
    variables: {
      input: {
        id: previousClientIdRef.current,
        lastOpenedTimestamp: Date.now(),
      },
    },
  });

  const skip = !clientId || !staffId;
  const {
    data,
    loading,
    fetchMore,
    subscribeToMore,
    refetch,
  } = useGetVisibleClientMessagesQuery({
    variables: {
      filter: { clientId, staffId },
      first: NUMBER_OF_MESSAGES_ON_FIRST_FETCH,
    },
    skip,
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (!skip) {
      refetch();
    }
    previousClientIdRef.current = clientId;
    const unsubscribe = subscribeToMore<
      GetMessagesUpdatesSubscription,
      GetMessagesUpdatesSubscriptionVariables
    >({
      document: GetMessagesUpdatesDocument,
      variables: { ids: [clientId] },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        if (subscriptionData.data.messagesForStaffUsers.clientId === clientId) {
          const newMessage: MessageEdge = {
            node: subscriptionData.data.messagesForStaffUsers,
            cursor: subscriptionData.data.messagesForStaffUsers.id,
            __typename: 'MessageEdge',
          };
          const newMessagePageInfo = {
            hasNextPage: false,
            endCursor: subscriptionData.data.messagesForStaffUsers.id,
          };
          if (!prev) {
            return {
              getStaffMessages: {
                pageInfo: newMessagePageInfo,
                edges: [newMessage],
              },
            };
          }
          const previousMessages = prev.getStaffMessages;

          const cacheMessageEdges =
            previousMessages.edges.filter(
              (edge) => edge?.node?.id !== newMessage?.node?.id
            ) || [];
          return {
            getStaffMessages: {
              ...previousMessages,
              edges: [...cacheMessageEdges, newMessage],
            },
          };
        }
        return prev;
      },
    });
    return () => {
      unsubscribe();
      if (!isMasquerading) updateLastOpenedTimestamp();
    };
  }, [clientId]);

  const [loadingMore, setLoadingMore] = useState(false);
  const [enableSizeObserver, setEnableSizeObserver] = useState(true);
  const fetchMoreData = async () => {
    setLoadingMore(true);
    if (data?.getStaffMessages.pageInfo.hasNextPage) {
      await fetchMore({
        variables: {
          after: data.getStaffMessages?.pageInfo.endCursor,
        },
      });
    }
    setEnableSizeObserver(false);
    setLoadingMore(false);
  };

  const messagesEndRef = useRef<HTMLDivElement>(null);
  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      scrollIntoView(messagesEndRef.current, { block: 'end' });
    }
  };

  const isLoading = loadingMore || loading;
  const onLoadMore = async () => {
    if (!isLoading && scrollRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
      const scrolledToEnd =
        Math.abs(scrollHeight - clientHeight - scrollTop) < 1;
      setScrolledUp(!scrolledToEnd);
      /**
       * scrollHeight = the height of the scrollable content (could be taller than the window)
       * clientHeight = the height of the box containing the scrollable content (can not be taller than the window)
       * scrollTop = the Y position of the top of the scrollable content. 0 when your scrollbar is at the top, and scrollHeight-clientHeight when it's at the bottom.
       * We fetch more data if:
       * 1. There are more messages than fit on a page.
       * 2. There is less than a page of messages left above the fold.
       * 3. We're not already loading messages
       */
      if (scrollHeight > clientHeight && scrollTop < clientHeight) {
        await fetchMoreData();
      }
    }
  };

  useLayoutEffect(() => {
    if (!isLoading && !scrolledUp) {
      scrollToBottom();
    }
  }, [messages]);

  useEffect(() => {
    setScrolledUp(false);
  }, [clientId]);

  useEffect(() => {
    if (data && data.getStaffMessages) {
      const nonFutureMessages = data.getStaffMessages.edges.filter(
        (edge) => edge.node?.status !== 'PENDING'
      );
      setMessages(nonFutureMessages.map((edge) => edge.node));

      if (
        nonFutureMessages.length < 20 &&
        data.getStaffMessages.pageInfo.hasNextPage
      ) {
        fetchMoreData();
      }
    } else {
      setMessages([]);
    }
  }, [data]);

  useEffect(() => {
    if (messages && messages.length) {
      const media = messages!.filter(
        (message) => message && 'media' in message && message.media
      );
      if (media && media.length) setMediaMessages(media as Message[]);
    }
  }, [messages]);

  // Images are downloaded from the network after all message data loads from
  // the api. This observer listens for the component resize on image download
  // and ensures the message thread view is in the correct position
  const resizeObserverRef = useRef(
    new ResizeObserver((entries) => {
      entries.forEach(() => {
        if (!scrolledUp) {
          scrollToBottom();
        }
      });
    })
  );

  useEffect(() => {
    const resizeObserver = resizeObserverRef.current;
    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const ExportButton = enableMSPCFlag ? (
    <ExportMessagesButtonMSPC clientId={clientId} />
  ) : (
    <ExportMessagesButton clientId={clientId} />
  );

  const timestampInterval = 5 * 60 * 1000;
  let lastRenderedTimestamp = 0;
  return (
    <Flex height="calc(100% - 0.5rem)" direction="column">
      <Flex
        direction="row"
        justifyContent="flex-end"
        borderBottomColor="brand.gray8"
        borderBottomWidth="1px"
        paddingX={10}
        paddingY={3}
      >
        {ExportButton}
      </Flex>
      <Stack
        paddingX={9}
        overflowY="auto"
        direction="column"
        flex="1 1 calc(100% - 60px)"
        width="100%"
        tabIndex={0}
        aria-label="Messages pane"
        role="feed"
        onScroll={onLoadMore}
        ref={scrollRef}
      >
        {isLoading && <MessagesListSkeleton />}
        {messages &&
          messages
            .filter(
              (message) =>
                message &&
                (('body' in message && message.body) ||
                  ('media' in message && message.media)) &&
                message.timestamp &&
                Date.now() > message.timestamp
            )
            .sort((a, b) => (!a || !b ? 0 : a.timestamp - b.timestamp))
            .map((message) => {
              if (!message) {
                return null;
              }
              let FormattedMessageBubble = null;

              const renderTimestamp =
                message.timestamp - lastRenderedTimestamp > timestampInterval;

              lastRenderedTimestamp = message.timestamp;
              if (message.type === 'INCOMING') {
                FormattedMessageBubble = (
                  <MessageBubble
                    message={message}
                    mediaMessages={mediaMessages}
                    incoming
                    sizeObserverRef={
                      enableSizeObserver ? resizeObserverRef : undefined
                    }
                  />
                );
              } else if (message.body) {
                FormattedMessageBubble = (
                  <MessageBubble
                    message={message}
                    avatar
                    outgoing
                    sizeObserverRef={
                      enableSizeObserver ? resizeObserverRef : undefined
                    }
                  />
                );
              }

              return (
                <Stack direction="column" key={message.id} spacing={2}>
                  {renderTimestamp && (
                    <Box
                      color="brand.gray1"
                      fontSize="14px"
                      lineHeight="16px"
                      marginTop={4}
                      marginBottom={2}
                      fontWeight="light"
                    >
                      {shortDayOrDateTime(message.timestamp)}
                    </Box>
                  )}
                  {FormattedMessageBubble}
                </Stack>
              );
            })}
        <div ref={messagesEndRef} />
      </Stack>
      <SendMessageForm clientId={clientId} />
    </Flex>
  );
}

export default ClientMessagesTabPanel;
