import { useEffect, useState } from 'react';
import useIdentifyUser from './useIdentifyUser';

export interface ClientGroups {
  all: string;
  eventToday: string;
  eventInTheNextWeek: string;
  clientsOfStaff?: string;
  selectClients: string;
  cohortClients?: string;
}

const clientGroups = {
  seesMultipleStaff: {
    all: 'All clients',
    eventToday: 'Clients with events today',
    eventInTheNextWeek: 'Clients with events in the next week',
    cohortClients: 'Choose cohort...',
    clientsOfStaff: 'Clients assigned to...',
    selectClients: 'Choose clients...',
  },
  individualStaff: {
    all: 'All of my clients',
    eventToday: 'My clients with events today',
    eventInTheNextWeek: 'My clients with events in the next week',
    selectClients: 'Choose clients...',
  },
};

export default (): {
  clientGroups?: ClientGroups;
  loading: boolean;
} => {
  const [groupList, setGroupList] = useState<ClientGroups>();
  const [loading, setLoading] = useState(true);

  const staffUser = useIdentifyUser();

  useEffect(() => {
    if (staffUser && staffUser.id) {
      const { role, visibleStaffIds } = staffUser;

      const seesMultipleStaff =
        (role && ['supervisor', 'support'].includes(role)) ||
        !!visibleStaffIds?.length;

      setGroupList(
        seesMultipleStaff
          ? clientGroups.seesMultipleStaff
          : clientGroups.individualStaff
      );
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [staffUser]);

  return { clientGroups: groupList, loading };
};
