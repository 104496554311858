import React, { Suspense } from 'react';
import {
  IconButton,
  Menu,
  MenuList,
  MenuItem,
  MenuButton,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';

import { useHistory } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';

import AccountCircle from 'sharedIcons/AccountCircle';
import EditDrawer from 'sharedComponents/EditDrawer';
import EditQuietHoursForm, {
  QuietHoursFormFields,
} from 'sharedComponents/EditQuietHoursForm';
import useIdentifyUser from 'sharedHooks/useIdentifyUser';

import { logout } from 'sharedHelpers/firebaseAuth';
import { deleteApolloClient } from '../graphql/apolloClient';

const MasqueradeDrawer = React.lazy(
  () => import('sharedComponents/MasqueradeDrawer')
);

function AccountMenu(): JSX.Element {
  const { email, role } = useIdentifyUser();
  const history = useHistory();
  const { onOpen, isOpen, onClose } = useDisclosure();
  const {
    onOpen: onOpenMasquerade,
    isOpen: isOpenMasquerade,
    onClose: onCloseMasquerade,
  } = useDisclosure();
  const { showMasqueradeFlag, enableMspcFlag: enableMSPCFlag } = useFlags();
  const toast = useToast();

  const logOutUser = async () => {
    try {
      toast.closeAll();
      await logout();
      await deleteApolloClient();
      history.replace('/login');
    } catch (e) {
      // send error to Rollbar
      // show an error message
    }
  };

  return (
    <>
      <Menu>
        <IconButton
          icon={<AccountCircle color="brand.gray4" boxSize="44px" />}
          as={MenuButton}
          _focus={{ boxShadow: 'outline' }}
          aria-label="Menu"
          isRound
          size="md"
          backgroundColor="brand.white"
        />
        <MenuList>
          {showMasqueradeFlag && (
            <MenuItem onClick={onOpenMasquerade} color="brand.orange1">
              Masquerade 🎭
            </MenuItem>
          )}
          <MenuItem onClick={onOpen}>Quiet Hours</MenuItem>
          {enableMSPCFlag && (
            <MenuItem cursor="default">
              Role
              <Text
                casing="capitalize"
                fontSize="sm"
                color="brand.gray2"
                paddingLeft={1}
                data-testid="roleLabel"
              >
                {role}
              </Text>
            </MenuItem>
          )}
          <MenuItem onClick={logOutUser}>
            Log out{' '}
            <Text fontSize="sm" color="brand.gray2" paddingLeft={1}>
              ({email})
            </Text>
          </MenuItem>
        </MenuList>
      </Menu>
      <EditDrawer<QuietHoursFormFields>
        isOpen={isOpen}
        onClose={onClose}
        FormContent={EditQuietHoursForm}
        title="Quiet Hours"
        formData={{}}
        formId="quietHoursForm"
      />
      {showMasqueradeFlag && (
        <Suspense fallback={<></>}>
          <MasqueradeDrawer
            isOpen={isOpenMasquerade}
            onClose={onCloseMasquerade}
            onOpen={onOpenMasquerade}
          />
        </Suspense>
      )}
    </>
  );
}

export default AccountMenu;
