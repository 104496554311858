import { datadogRum } from '@datadog/browser-rum';
import type { ApolloError } from '@apollo/client';

export default (
  error: ApolloError,
  queryName: string,
  componentName = ''
): void => {
  if (!queryName) {
    throw new TypeError('Query name is required.');
  }
  error.message = `${error.message} ${queryName}`; // eslint-disable-line no-param-reassign
  datadogRum.addError(error, {
    componentName,
    queryName,
  });
};
