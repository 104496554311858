import React, { useRef, RefObject, ReactChild } from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogContent,
  AlertDialogOverlay,
} from '@chakra-ui/react';

interface MutationConfirmationProps {
  /**
   * Whether or not the confirmation dialog is open
   */
  isOpen: boolean;
  /**
   * A function to close the window when the user clicks cancel or hits esc.
   */
  onCancel: () => void;
  /**
   * A function to retry your mutation
   */
  children: {
    bodyContent: ReactChild;
    confirmButton: ReactChild;
    cancelButton: (cancelRef: RefObject<HTMLButtonElement>) => ReactChild;
  };
}

function MutationConfirmation({
  isOpen,
  onCancel,
  children,
}: MutationConfirmationProps): JSX.Element {
  const { bodyContent, confirmButton, cancelButton } = children;
  const cancelRef = useRef<HTMLButtonElement>(null);

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onCancel}
    >
      <AlertDialogOverlay>
        <AlertDialogContent paddingX={2} paddingY={2} borderRadius="4px">
          <AlertDialogBody paddingY={6}>{bodyContent}</AlertDialogBody>

          <AlertDialogFooter width="100%" justifyContent="space-between">
            {confirmButton}
            {cancelButton(cancelRef)}
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}

export default MutationConfirmation;
