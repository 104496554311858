import { useState, useEffect } from 'react';
import { useGetOrganizationOutcomesQuery } from 'generated/graphql';
import { isStaffUser } from 'sharedHelpers/typeNarrowing';
import type Outcomes from '../constants/Outcomes';

interface Outcome {
  id: string;
  name?: string | null;
  outcomeUid?: Outcomes | null;
}

export default (): { outcomes: Outcome[]; loading: boolean } => {
  const [outcomes, setOutcomes] = useState<Outcome[]>([]);

  const { data, loading } = useGetOrganizationOutcomesQuery();

  useEffect(() => {
    if (data?.getUser && isStaffUser(data.getUser)) {
      setOutcomes(
        ('organization' in data.getUser &&
          (data.getUser.organization.outcomes as Outcome[])) ||
          []
      );
    }
  }, [data]);

  return { outcomes, loading };
};
