import React from 'react';
import { Flex, VStack, StackDivider, Text, Skeleton } from '@chakra-ui/react';
import { useFlags } from 'launchdarkly-react-client-sdk';

import useIdentifyUser from 'sharedHooks/useIdentifyUser';
import useStaffMemberList from 'sharedHooks/useStaffMemberList';

import useEngagementStats from '../hooks/useEngagementTripleStackStats';
import type { AllStatsType } from '../hooks/useClientsDashboardStats';

const statTypes = [
  'staffManualMessages',
  'clientManualMessages',
  'platformMessages',
] as const;
type StatType = typeof statTypes[number];

const roles = ['staff', 'supervisor', 'support'] as const;
type RoleType = typeof roles[number];

const isStatType = (statType: string): statType is StatType =>
  statTypes.includes(statType as StatType);

const isRoleType = (roleType: string): roleType is RoleType =>
  roles.includes(roleType as RoleType);

const statsOrder = {
  staffManualMessages: 2,
  clientManualMessages: 3,
  platformMessages: 1,
};

const statsInfo = (
  statType: string,
  role: string | undefined,
  staffMemberLabelFlag: string,
  staffName: string,
  isFiltered: boolean
) => {
  const statsLabels = {
    staffManualMessages: {
      staff: 'Sent by me',
      supervisor: `Sent by all ${staffMemberLabelFlag}s`,
      support: `Sent by all ${staffMemberLabelFlag}s`,
      supervisorFiltered: `Sent by ${staffName}`,
    },
    clientManualMessages: {
      staff: 'Sent by my clients',
      supervisor: 'Sent by all clients',
      support: 'Sent by all clients',
      supervisorFiltered: `Sent by ${staffName}'s clients`,
    },
    platformMessages: {
      staff: 'Messages sent by Uptrust',
      supervisor: 'Messages sent by Uptrust',
      support: 'Messages sent by Uptrust',
      supervisorFiltered: 'Messages sent by Uptrust',
    },
  };
  if (isFiltered) {
    return statsLabels[statType as StatType].supervisorFiltered;
  }
  if (role && isStatType(statType) && isRoleType(role)) {
    return statsLabels[statType as StatType][role as RoleType];
  }
  return '';
};

function EngagementTripleStack({
  allStats,
  loading,
  selectedStaffId,
}: {
  allStats: AllStatsType;
  loading: boolean;
  selectedStaffId: string;
}): JSX.Element {
  const { stats } = useEngagementStats({
    allStats,
  });
  const { staffMemberLabelFlag } = useFlags();
  const { role } = useIdentifyUser();
  const staffList = useStaffMemberList();
  const staffName =
    staffList.find((staff) => staff.id === selectedStaffId)?.name || '';

  return (
    <Flex direction="column" alignItems="center" width="100%" height="100%">
      <Skeleton
        isLoaded={!loading}
        borderRadius="8px"
        width="90%"
        height="90%"
        minWidth={{ base: '175px', lg: '200px', xl: '225px' }}
        minHeight={{ base: '175px', lg: '200px', xl: '225px' }}
        margin="auto"
      >
        <VStack
          divider={<StackDivider borderColor="brand.gray5" />}
          backgroundColor="brand.blue3"
          paddingY={3}
          paddingX={4}
          borderRadius="8px"
          borderWidth="1px"
          borderColor="brand.blue3"
          alignItems="center"
          justifyContent="space-around"
          width="100%"
          height="100%"
          spacing={{ base: 1, lg: 2 }}
        >
          {Object.entries(stats)
            .sort((a, b) => {
              if (isStatType(a[0]) && isStatType(b[0])) {
                return statsOrder[a[0]] - statsOrder[b[0]];
              }
              return 0;
            })
            .map(([key, value]) => (
              <div key={key}>
                <Text
                  fontSize={{ base: 'xs', lg: 'sm', xl: 'md' }}
                  color="brand.gray1"
                  textTransform="uppercase"
                  lineHeight={{ base: '14px', xl: '18px' }}
                >
                  {statsInfo(
                    key,
                    role,
                    staffMemberLabelFlag,
                    staffName,
                    !!selectedStaffId
                  )}
                </Text>
                <Text
                  fontSize={{ base: 'sm', lg: 'md', xl: 'lg' }}
                  color="brand.gray1"
                  fontWeight="bold"
                >
                  {value}
                </Text>
              </div>
            ))}
        </VStack>
      </Skeleton>
    </Flex>
  );
}

export default EngagementTripleStack;
