export default (
  closed: boolean | undefined | null,
  inCustody: boolean | undefined | null
): Array<string> => {
  const statusFilterValues = [];
  if (closed != null) {
    if (closed === true) {
      statusFilterValues.push('inactive');
    } else {
      statusFilterValues.push('active');
    }
  }
  if (inCustody === true) {
    statusFilterValues.push('inCustody');
  }
  return statusFilterValues;
};
