import { gql } from '@apollo/client';
import StatusBarInfo from '../fragments/statusBarInfo';

export default gql`
  ${StatusBarInfo}
  query getClientDetails($id: ID!) {
    getClient(id: $id) {
      id
      ...StatusBarInfo
      firstName
      lastName
      phone
      email
      gender
      language
      staff {
        id
        name
      }
      riskLevel
      cmsId
      noPhone
      noCar
      housingInsecure
      needsChildCare
      inCustody
      employer
      clientUpdatedPhone
      clientUpdatedAddress
      clientUpdatedEmployer
      docket
      upperDocket
      cases {
        edges {
          node {
            id
            number
            open
          }
        }
      }
      contacts {
        edges {
          node {
            id
            name
            phone
            notes
            optIn
          }
        }
      }
      cohort {
        id
        name {
          locale
          text
        }
      }
      terms {
        id
        endDate
        restitutions {
          edges {
            node {
              id
              amount
              type
            }
          }
        }
        conditions {
          edges {
            node {
              id
              description
            }
          }
        }
      }
      address {
        addressLine1
        addressLine2
        city
        state
        zipCode
      }
    }
  }
`;
