import { useCallback, useState, useEffect } from 'react';

import { toDeleteEventInput } from 'sharedHelpers/toEventInput';

import { ApolloError, useReactiveVar } from '@apollo/client';

import {
  useDeleteCourtDateMutation,
  useDeleteCheckInEventMutation,
  useDeleteOfficeEventMutation,
  useDeleteVirtualEventMutation,
  useDeleteGroupEventMutation,
} from 'generated/graphql';
import { startOfHour, startOfWeek, subMonths } from 'date-fns';

import type { EventsFormInputs } from '../types/formTypes';
import getEvents from '../graphql/queries/getEvents';
import getCombinedScheduleEvents from '../graphql/queries/getCombinedScheduleEvents';
import getOrganizationLocations from '../graphql/queries/getOrganizationLocations';
import { apiClientsListsRefetchQueriesVar } from './useApiClientsLists';

type DeleteEventInput = Partial<EventsFormInputs> &
  Pick<EventsFormInputs, 'id' | 'type' | 'clientId' | 'groupEventId'>;

export type UseSaveEventType = [
  (eventInput: DeleteEventInput) => void,
  {
    loading: boolean;
    error: ApolloError | null | undefined;
    complete: boolean;
  }
];

export default (): UseSaveEventType => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ApolloError>();
  const [complete, setComplete] = useState(false);
  const firstDayOfWeek = startOfWeek(Date.now(), { weekStartsOn: 1 });
  const apiClientsListsRefetchQueries = useReactiveVar(
    apiClientsListsRefetchQueriesVar
  );
  const constructRefetchQueries = (clientId: string) => [
    {
      query: getEvents,
      variables: {
        id: clientId,
        timestamp: Date.now(),
        timeFilter: 'UPCOMING',
      },
    },
    {
      query: getCombinedScheduleEvents,
      variables: {
        startDate: Number(firstDayOfWeek),
        lastActiveTimestamp:
          Number(subMonths(startOfHour(Date.now()), 6)) || Date.now(),
      },
    },
    {
      query: getOrganizationLocations,
      variables: {},
    },
    ...apiClientsListsRefetchQueries,
  ];
  const [
    deleteCourtDate,
    { data: courtDateData, loading: courtDateLoading, error: courtDateError },
  ] = useDeleteCourtDateMutation();
  const [
    deleteCheckInEvent,
    {
      data: checkInEventData,
      loading: checkInEventLoading,
      error: checkInEventError,
    },
  ] = useDeleteCheckInEventMutation();
  const [
    deleteOfficeEvent,
    {
      data: officeEventData,
      loading: officeEventLoading,
      error: officeEventError,
    },
  ] = useDeleteOfficeEventMutation();
  const [
    deleteVirtualEvent,
    {
      data: virtualEventData,
      loading: virtualEventLoading,
      error: virtualEventError,
    },
  ] = useDeleteVirtualEventMutation();
  const [
    deleteGroupEvent,
    {
      data: groupEventData,
      loading: groupEventLoading,
      error: groupEventError,
    },
  ] = useDeleteGroupEventMutation();

  const executeDelete = useCallback((eventInput: DeleteEventInput) => {
    const refetchQueries = constructRefetchQueries(eventInput.clientId || '');
    if (eventInput.groupEventId) {
      deleteGroupEvent({
        variables: {
          id: eventInput.groupEventId,
        },
        refetchQueries,
      });
    } else {
      switch (eventInput.type) {
        case 'COURT_DATE':
          deleteCourtDate({
            variables: {
              input: toDeleteEventInput(eventInput),
            },
            refetchQueries,
          });
          break;
        case 'CHECK_IN':
          deleteCheckInEvent({
            variables: {
              input: toDeleteEventInput(eventInput),
            },
            refetchQueries,
          });
          break;
        case 'OFFICE':
          deleteOfficeEvent({
            variables: {
              input: toDeleteEventInput(eventInput),
            },
            refetchQueries,
          });
          break;
        case 'VIRTUAL':
          deleteVirtualEvent({
            variables: {
              input: toDeleteEventInput(eventInput),
            },
            refetchQueries,
          });
          break;
        default:
          throw new TypeError('type is required');
      }
    }
  }, []);

  useEffect(() => {
    setLoading(
      courtDateLoading ||
        checkInEventLoading ||
        officeEventLoading ||
        virtualEventLoading ||
        groupEventLoading
    );
  }, [
    courtDateLoading,
    checkInEventLoading,
    officeEventLoading,
    virtualEventLoading,
    groupEventLoading,
  ]);

  useEffect(() => {
    setError(
      courtDateError ||
        checkInEventError ||
        officeEventError ||
        virtualEventError ||
        groupEventError
    );
  }, [
    courtDateError,
    checkInEventError,
    officeEventError,
    virtualEventError,
    groupEventError,
  ]);

  useEffect(() => {
    if (
      courtDateData ||
      checkInEventData ||
      officeEventData ||
      virtualEventData ||
      groupEventData
    ) {
      setComplete(true);
    } else {
      setComplete(false);
    }
  }, [
    courtDateData,
    checkInEventData,
    officeEventData,
    virtualEventData,
    groupEventData,
  ]);

  return [executeDelete, { complete, loading, error }];
};
