import React from 'react';
import { Skeleton, Flex } from '@chakra-ui/react';

function InfoViewSkeleton(): JSX.Element {
  return (
    <Flex
      direction="column"
      borderRadius={16}
      marginY="16"
      marginX="auto"
      width="75vw"
      alignSelf="center"
      height="100%"
      paddingLeft="2%"
    >
      <Skeleton
        borderRadius={8}
        height={20}
        marginY="0"
        paddingX={8}
        marginBottom={4}
      />
      <Skeleton height="calc(100% - 80px)" borderRadius={8} />
    </Flex>
  );
}

export default React.memo(InfoViewSkeleton);
