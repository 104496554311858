import { useApolloClient, gql } from '@apollo/client';

interface GetStaffInfoProps {
  id: string;
}

export default function useGetStaffInfo({
  id,
}: GetStaffInfoProps): { id: string; name: string } {
  return (
    useApolloClient().readFragment({
      id: `StaffUser:${id}`,
      fragment: gql`
        fragment StaffInfo on StaffUser {
          id
          name
        }
      `,
    }) ?? { id, name: 'Unknown Staff' }
  );
}
