import React from 'react';
import { Flex, Spacer, Box } from '@chakra-ui/react';

import ListPanelItem from 'sharedComponents/ListPanelItem';

import type { Client } from 'generated/graphql';
import { clientListName } from 'sharedHelpers/clientHelpers';
import Badge from 'sharedComponents/Badge';

interface ClientsListPanelChildrenProps {
  clientsData: Client[];
  style: React.CSSProperties;
  index: number;
}
const ClientsListPanelChildren = (
  props: ClientsListPanelChildrenProps
): JSX.Element => {
  const { clientsData, style, index } = props;
  if (!clientsData.length) {
    return <></>;
  }

  const client = clientsData[index];

  const { lastSend, carrier } = client || {};

  return client ? (
    <ListPanelItem key={client.id} id={client.id} style={style}>
      <Flex>
        <Box alignSelf="flex-end">{clientListName(client)}</Box>
        <Spacer />
        <Badge
          lastSend={lastSend || ''}
          carrierType={carrier?.type || ''}
          color="brand.orange1"
        />
      </Flex>
    </ListPanelItem>
  ) : (
    <></>
  );
};

export default React.memo(ClientsListPanelChildren);
