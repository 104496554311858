import React from 'react';
import { Button } from '@chakra-ui/react';
import AddCircle from 'sharedIcons/AddCircle';

import { useEditEventDrawer } from './EditEventDrawerProvider';

interface AddEventButtonProps {
  defaultClientId: string | undefined;
}

function AddEventButton({ defaultClientId }: AddEventButtonProps): JSX.Element {
  const { onOpen } = useEditEventDrawer();
  return (
    <Button
      variant="brandHeaderPrimary"
      leftIcon={<AddCircle boxSize="16px" marginRight={1} />}
      onClick={() => onOpen({ clientId: defaultClientId || '' })}
    >
      New Event
    </Button>
  );
}

export default AddEventButton;
