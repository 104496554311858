export default {
  firebase: {
    apiKey: 'AIzaSyDgesV7fdGXUWr4WSWKF8KuKh6sTm1CreE',
    authDomain: 'uptrust-reminders-dev.firebaseapp.com',
    databaseURL: 'https://uptrust-reminders-dev.firebaseio.com',
    projectId: 'uptrust-reminders-dev',
    storageBucket: 'uptrust-reminders-dev.appspot.com',
    messagingSenderId: '292462471382',
    appId: '1:292462471382:web:b2ed8ee2b8a0b74b784177',
  },
  api: {
    url: 'https://api-dev.uptrust.co',
    wss: 'wss://api-dev.uptrust.co/graphql',
  },
  launchDarkly: {
    clientSideId: '60bff45259fdb80caa470581',
  },
  datadogRUM: {
    applicationId: '6418ed95-72a0-4858-8e0a-16f99f052947',
    clientToken: 'pubd13d3055d1fd52f71e25cf93d9e3cb63',
    site: 'datadoghq.com',
    service: 'neo-staff-web',
    env: 'dev',
    sampleRate: 100,
    version: import.meta.env.VITE_PUBLIC_VERSION,
    allowedTracingOrigins: ['https://api-dev.uptrust.co/'],
  },
};
