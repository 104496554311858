import { useState, useEffect } from 'react';
import type { AllStatsType } from './useClientsDashboardStats';

interface AttendanceStatsData {
  TOTAL: number;
  ATTENDED: number;
  MISSED: number;
  BLANK: number;
}

const blankStats = {
  TOTAL: 0,
  ATTENDED: 0,
  MISSED: 0,
  BLANK: 0,
};

interface UseAttendanceStatsParams {
  allStats: AllStatsType;
}

function useAttendanceStats({
  allStats,
}: UseAttendanceStatsParams): {
  stats: AttendanceStatsData;
} {
  const [stats, setStats] = useState<AttendanceStatsData>({ ...blankStats });

  useEffect(() => {
    if (allStats) {
      const {
        totalEvents: { attended, missed, unknown },
      } = allStats.attendanceDonutEvents;
      const TOTAL = attended + missed + unknown;
      setStats({
        ATTENDED: attended,
        MISSED: missed,
        BLANK: unknown,
        TOTAL,
      });
    }
  }, [allStats]);

  return { stats };
}

export default useAttendanceStats;
