import { useApolloClient } from '@apollo/client';

import getClientDisplayName, {
  GetClientDisplayNameData,
  GetClientDisplayNameVars,
} from '../graphql/fragments/getClientDisplayName';

import getClientInformationFragment, {
  GetClientInformationFragmentData,
  GetClientInformationFragmentVars,
} from '../graphql/fragments/getClientInformation';

interface ClientInformationData {
  displayName: string;
  phone: string;
  assignedStaffId: string;
}

export default (clientId: string): Partial<ClientInformationData> => {
  const apolloClient = useApolloClient();
  const { displayName } =
    apolloClient.readFragment<
      GetClientDisplayNameData,
      GetClientDisplayNameVars
    >({
      fragment: getClientDisplayName,
      id: `Client:${clientId}`,
    }) || {};

  const { phone, staff } =
    apolloClient.readFragment<
      GetClientInformationFragmentData,
      GetClientInformationFragmentVars
    >({
      fragment: getClientInformationFragment,
      id: `Client:${clientId}`,
    }) || {};

  return { displayName, phone, assignedStaffId: staff?.id };
};
