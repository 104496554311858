export interface Connection<Type> {
  __typename?: string;
  edges: Array<Edge<Type>>;
  pageInfo?: PageInfo;
  totalCount?: number | null;
}

interface Edge<Type> {
  __typename?: string;
  cursor?: string;
  node?: Type;
}

interface PageInfo {
  __typename?: string;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor?: string | null;
  endCursor?: string | null;
}

export default <T>(connection: Connection<T | null | undefined>): Array<T> => {
  const dataArray = connection.edges
    .map((edge) => edge.node)
    .filter((node): node is T => !!node);
  return dataArray;
};
