import React, { useEffect, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/functions';

import { userToken } from 'sharedHelpers/firebaseAuth';

interface AuthProviderProps {
  children: React.ReactNode;
}

export const AuthContext = React.createContext<{
  loading: boolean;
  currentUser: firebase.User | null;
  idToken: string;
  notLoggedIn: boolean;
}>({
  loading: true,
  currentUser: null,
  idToken: '',
  notLoggedIn: false,
});

const AuthProvider = ({ children }: AuthProviderProps): JSX.Element => {
  const [currentUser, setCurrentUser] = useState<firebase.User | null>(null);
  const [loading, setLoading] = useState(true);
  const [idToken, setIdToken] = useState('');
  const [notLoggedIn, setNotLoggedIn] = useState(false);
  userToken();
  useEffect(() => {
    const unsubscribe = firebase.auth().onIdTokenChanged(async (user) => {
      setCurrentUser(user);
      if (user) {
        const token = await user.getIdToken();
        setIdToken(token);
        setLoading(false);
      } else {
        setNotLoggedIn(true);
        setLoading(false);
      }
    });
    return function cleanup() {
      unsubscribe();
    };
  }, []);

  return (
    <AuthContext.Provider
      value={{
        loading,
        currentUser,
        idToken,
        notLoggedIn,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
