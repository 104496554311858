import React from 'react';
import { Text } from '@chakra-ui/react';

import { Location, Room } from 'generated/graphql';

import { formatCityStateZip } from 'sharedHelpers/textFormat';
import Map from 'sharedIcons/Map';

import IconDetail from './IconDetail';

interface LocationInformationProps {
  location: Location;
  room?: Room | null;
}

function LocationInformation({
  location,
  room,
}: LocationInformationProps): JSX.Element {
  return (
    <IconDetail DetailIcon={Map}>
      <Text variant="eventPopoverInformation">{location.addressLine1}</Text>
      <Text variant="eventPopoverInformation">{room?.description}</Text>
      <Text variant="eventPopoverInformation">{`${formatCityStateZip(
        location.city,
        location.state,
        location.zip
      )}`}</Text>
    </IconDetail>
  );
}

LocationInformation.defaultProps = {
  room: undefined,
};
export default LocationInformation;
