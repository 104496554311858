export default function getTimeUntilTokenExpires(
  expirationTime: string
): number {
  if (!expirationTime) return 0;
  const currentTime = Date.now();
  const expirationMilliseconds = Date.parse(expirationTime);
  const secondsUntilTokenExpires =
    (expirationMilliseconds - currentTime) / 1000;
  return secondsUntilTokenExpires;
}
