import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  Flex,
  Stack,
  Text,
  Skeleton,
} from '@chakra-ui/react';
import {
  useGetCheckInReportQuery,
  CheckInReportResponse,
  CheckInReport,
} from 'generated/graphql';
import { fullMonthDateWithDay, shortTime } from 'sharedHelpers/textFormat';
import { clientDisplayName } from 'sharedHelpers/clientHelpers';
import datadogLogGraphQLError from 'sharedHelpers/datadogLogGraphQLError';
import ApiErrorAlert from 'sharedComponents/ApiErrorAlert';
import InfoSection from 'sharedComponents/InfoSection';
import NumeralBadge from 'sharedComponents/NumeralBadge';
import Flag from 'sharedIcons/Flag';

export interface CheckInResponseDetailsDrawerProps {
  checkInReportId: string;
  isOpen: boolean;
  onClose: () => void;
}

function CheckInResponseDetailsDrawer(
  props: CheckInResponseDetailsDrawerProps
): JSX.Element {
  const { isOpen, onClose, checkInReportId } = props;
  const [title, setTitle] = useState('');
  const [alertCount, setAlertCount] = useState<number>(0);
  const [clientFullName, setClientFullName] = useState('');
  const [completedAt, setCompletedAt] = useState('');
  const [responses, setResponses] = useState<CheckInReportResponse[]>([]);
  const [showApiAlert, setShowApiAlert] = useState(false);
  const responsePadding = 4;

  const { data, loading, error, refetch } = useGetCheckInReportQuery({
    variables: {
      id: checkInReportId,
      locale: 'en',
    },
    // TODO we can remove this after API-957 is complete
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (error) {
      datadogLogGraphQLError(
        error,
        'getCheckInReport',
        'CheckInResponseDetailsDrawer'
      );
      if (!data) {
        setShowApiAlert(true);
      }
    }
  }, [data, error]);

  useEffect(() => {
    if (data?.getCheckInReport) {
      const {
        name,
        timestamp,
        client,
        pages,
      } = data.getCheckInReport as CheckInReport;
      const allResponses = pages.flatMap((page) => page.responses);

      const alertTally = allResponses.reduce(
        (previousTally, currentResponse) =>
          currentResponse.sendAlert ? previousTally + 1 : previousTally,
        0
      );
      setAlertCount(alertTally);
      setTitle(name);
      setClientFullName(clientDisplayName(client));
      setCompletedAt(
        `Completed at ${shortTime(timestamp)} on ${fullMonthDateWithDay(
          timestamp
        )}.`
      );
      setResponses(allResponses);
    }
  }, [data]);

  const tooltipNotice =
    alertCount === 1
      ? '1 response is flagged below.'
      : `${alertCount} responses are flagged below.`;
  return (
    <>
      <ApiErrorAlert
        isOpen={showApiAlert}
        onTryAgain={() => {
          refetch();
        }}
        onCancel={() => {
          setShowApiAlert(false);
        }}
        title="Unable to retrieve check-in responses"
        body="We encountered a system error and were unable to retrieve responses for this check-in."
      />
      <Drawer isOpen={isOpen} onClose={onClose} placement="right" size="lg">
        <DrawerOverlay />
        <DrawerContent>
          <Skeleton isLoaded={!loading}>
            <DrawerHeader
              color="brand.gray1"
              fontSize="2xl"
              fontWeight="normal"
            >
              <Flex direction="row">
                <Text paddingRight="15px">{title}</Text>
                {alertCount > 0 && (
                  <Flex direction="column" justifyContent="center">
                    <NumeralBadge
                      color="white"
                      backgroundColor="brand.orange1"
                      number={alertCount}
                      tooltip={tooltipNotice}
                    />
                  </Flex>
                )}
              </Flex>
            </DrawerHeader>
          </Skeleton>
          <DrawerBody>
            <Skeleton isLoaded={!loading}>
              <Flex direction="column">
                <InfoSection variant="brandGray">
                  <Text fontWeight="bold" fontSize="lg">
                    {clientFullName}
                  </Text>
                  <Text>{completedAt}</Text>
                </InfoSection>
              </Flex>
            </Skeleton>
            <Skeleton isLoaded={!loading}>
              <Flex direction="column">
                <InfoSection variant="brandGray">
                  <Text
                    color="brand.gray3"
                    fontSize="sm"
                    paddingBottom={responsePadding}
                  >
                    QUESTIONS
                  </Text>
                  {responses.map((response) => {
                    const questionText = `${response.order}. ${response.questionText}`;
                    return (
                      <Flex
                        data-testid="check-in-response-question"
                        key={response.id}
                        direction="column"
                        paddingBottom={responsePadding}
                      >
                        <Text fontWeight="bold" paddingBottom={responsePadding}>
                          {questionText}
                        </Text>
                        <Flex direction="row">
                          {response.sendAlert && (
                            <Box paddingRight={responsePadding}>
                              <Flag
                                color="brand.orange1"
                                boxSize="20px"
                                data-testid="flag-icon"
                              />
                            </Box>
                          )}
                          <Text paddingBottom={responsePadding}>
                            {response.answerText}
                          </Text>
                        </Flex>
                        {response.needsExplanation &&
                          response.explanationText && (
                            <Text
                              paddingBottom={responsePadding}
                            >{`Explanation: ${response.explanationText}`}</Text>
                          )}
                      </Flex>
                    );
                  })}
                </InfoSection>
              </Flex>
            </Skeleton>
          </DrawerBody>
          <DrawerFooter justifyContent="flex-start" paddingX={12}>
            <Stack direction="row" spacing={6}>
              <Button variant="brandPrimary" onClick={onClose} paddingX={10}>
                Close
              </Button>
            </Stack>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default CheckInResponseDetailsDrawer;
