import React, { useEffect } from 'react';
import { Button, Text } from '@chakra-ui/react';

import MutationConfirmation from 'sharedComponents/MutationConfirmation';
import useSaveClient from 'sharedHooks/useSaveClient';
import useGetStaffInfo from 'sharedHooks/useGetStaffInfo';

import type { ClientFormFields } from '../types/formTypes';

interface UpdateEventsOnClientEditConfirmationProps {
  /**
   * The data being passed to the create/update client mutation.
   */
  clientData: ClientFormFields;
  /**
   * The first in the list of assigned staff, which after modal confirmation and
   * successful client update will become the primary staff id.
   */
  newPrimaryStaffId: string;
  /**
   * Whether or not the confirmation dialog is open
   */
  isOpen: boolean;
  /**
   * A function to close the window after the mutation is successfully executed.
   */
  onSuccessfulUpdate: () => void;
  /**
   * A function to close the window when the user clicks cancel or hits esc.
   */
  onCancel: () => void;
}

function UpdateEventsOnClientEditConfirmation({
  clientData,
  newPrimaryStaffId,
  isOpen,
  onSuccessfulUpdate,
  onCancel,
}: UpdateEventsOnClientEditConfirmationProps): JSX.Element {
  const [save, { loading, complete }] = useSaveClient();

  const onConfirm = () => {
    save(clientData);
  };

  useEffect(() => {
    if (complete) {
      onSuccessfulUpdate();
    }
  }, [complete]);

  const staffName = useGetStaffInfo({ id: newPrimaryStaffId }).name;
  const possessiveName = ` ${staffName}'s ` ?? " Unknown Staff's ";
  const bodyText = (
    <>
      <Text display="inline">
        You have removed a staff member who has upcoming events scheduled with
        this client. Those events will automatically be transferred to
      </Text>
      <Text fontWeight="bold" display="inline">
        {possessiveName}
      </Text>
      <Text display="inline">schedule.</Text>
    </>
  );
  return (
    <MutationConfirmation isOpen={isOpen} onCancel={onCancel}>
      {{
        bodyContent: bodyText,
        confirmButton: (
          <Button
            paddingX={6}
            variant="brandPrimary"
            onClick={onConfirm}
            isLoading={loading}
          >
            Save changes and transfer events
          </Button>
        ),
        cancelButton: (cancelRef) => (
          <Button
            paddingX={6}
            variant="brandCancel"
            ref={cancelRef}
            onClick={onCancel}
            isLoading={loading}
          >
            Cancel
          </Button>
        ),
      }}
    </MutationConfirmation>
  );
}

export default UpdateEventsOnClientEditConfirmation;
