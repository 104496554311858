import React, { useEffect, useState } from 'react';
import { Button } from '@chakra-ui/react';

import { phoneNumberFormat } from 'sharedHelpers/textFormat';
import createPDF from 'sharedHelpers/createPDF';
import docDefinition from 'sharedHelpers/messageExportDocumentDefinition';
import {
  FromEndUserMessage,
  FromPlatformEventMessage,
  FromPlatformMessage,
  FromStaffMessage,
  UndefinedMessage,
  AutomatedAttendanceMessage,
  useGetVisibleClientMessagesLazyQuery,
} from 'generated/graphql';
import useGetClientInformation from 'sharedHooks/useGetClientInformation';
import useIdentifyUser from 'sharedHooks/useIdentifyUser';

interface ExportMessagesButtonMSPCProps {
  clientId: string;
}
type Messages =
  | ({ __typename?: 'AutomatedAttendanceMessage' } & Pick<
      AutomatedAttendanceMessage,
      'id' | 'timestamp' | 'status' | 'type'
    >)
  | ({ __typename?: 'FromEndUserMessage' } & Pick<
      FromEndUserMessage,
      'body' | 'id' | 'timestamp' | 'status' | 'type'
    >)
  | ({ __typename?: 'FromPlatformEventMessage' } & Pick<
      FromPlatformEventMessage,
      'body' | 'id' | 'timestamp' | 'status' | 'type'
    >)
  | ({ __typename?: 'FromPlatformMessage' } & Pick<
      FromPlatformMessage,
      'body' | 'id' | 'timestamp' | 'status' | 'type'
    >)
  | ({ __typename?: 'FromStaffMessage' } & Pick<
      FromStaffMessage,
      'senderName' | 'body' | 'id' | 'timestamp' | 'status' | 'type'
    >)
  | ({ __typename?: 'UndefinedMessage' } & Pick<
      UndefinedMessage,
      'id' | 'timestamp' | 'status' | 'type'
    >);

const generatePDF = async (
  messages: Array<Messages | null | undefined>,
  displayName: string,
  phone: string
) => {
  const createdPDF = await createPDF(
    await docDefinition(messages, displayName, phone)
  );
  createdPDF.download(`Uptrust Message Transcript - ${displayName}.pdf`);
};

function ExportMessagesButtonMSPC({
  clientId,
}: ExportMessagesButtonMSPCProps): JSX.Element {
  const [clicked, setClicked] = useState(false);
  const staffId = useIdentifyUser()?.id || '';
  const [
    loadMessages,
    { called, data: messageData, loading },
  ] = useGetVisibleClientMessagesLazyQuery({
    variables: { filter: { clientId, staffId } },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });

  const { displayName, phone } = useGetClientInformation(clientId);

  useEffect(() => {
    if (clicked && called && !loading && messageData && displayName && phone) {
      const messages = messageData.getStaffMessages.edges.map(
        (edge) => edge.node
      );
      setClicked(false);
      generatePDF(messages, displayName, phoneNumberFormat(phone));
    }
  }, [messageData]);

  const onExportClick = () => {
    setClicked(true);
    loadMessages();
  };

  return (
    <Button
      backgroundColor="brand.gray7"
      color="brand.gray2"
      size="xs"
      isLoading={clicked && loading}
      onClick={onExportClick}
    >
      Export
    </Button>
  );
}

export default ExportMessagesButtonMSPC;
