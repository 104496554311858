export default {
  firebase: {
    apiKey: 'AIzaSyArIgtwT478xAVhpy1tNs3t7CWJ7lvjmkY',
    authDomain: 'uptrust-reminders-test.firebaseapp.com',
    databaseURL: 'https://uptrust-reminders-test.firebaseio.com',
    projectId: 'uptrust-reminders-test',
    storageBucket: 'uptrust-reminders-test.appspot.com',
    messagingSenderId: '970723339667',
    appId: '1:970723339667:web:a66e3a55d2112608ee8c66',
  },
  api: {
    url: 'https://api-test.uptrust.co',
    wss: 'wss://api-test.uptrust.co/graphql',
  },
  launchDarkly: {
    clientSideId: '60bff4368687860cdc0c318b',
  },
  datadogRUM: {
    applicationId: '6418ed95-72a0-4858-8e0a-16f99f052947',
    clientToken: 'pubd13d3055d1fd52f71e25cf93d9e3cb63',
    site: 'datadoghq.com',
    service: 'neo-staff-web',
    env: 'test',
    sampleRate: 100,
    version: import.meta.env.VITE_PUBLIC_VERSION,
    allowedTracingOrigins: ['https://api-test.uptrust.co/'],
  },
};
