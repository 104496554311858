import React from 'react';
import { Text } from '@chakra-ui/react';

import { titleCase } from 'sharedHelpers/textFormat';

import AssignmentLate from 'sharedIcons/AssignmentLate';
import AssignmentTurnedIn from 'sharedIcons/AssignmentTurnedIn';
import AssignmentInd from 'sharedIcons/AssignmentInd';
import useOrganizationOutcomes from 'sharedHooks/useOrganizationOutcomes';
import type { AttendanceInfoType } from 'generated/graphql';
import type { ClientEvent } from '../types/clientTypes';

interface AttendanceToolEventRowProps {
  event: ClientEvent;
}

const AttendanceIcon = ({
  attendanceInfo,
}: {
  attendanceInfo: AttendanceInfoType | undefined | null;
}): JSX.Element => {
  switch (attendanceInfo) {
    case 'ATTENDED':
      return <AssignmentTurnedIn color="brand.blue2" boxSize="20px" />;
    case 'MISSED':
      return <AssignmentLate color="brand.orange1" boxSize="20px" />;

    default:
      return <AssignmentInd color="brand.gray3" boxSize="20px" />;
  }
};

function AttendanceToolEventRow({
  event,
}: AttendanceToolEventRowProps): JSX.Element {
  const { outcomes } = useOrganizationOutcomes();

  return (
    <>
      <AttendanceIcon attendanceInfo={event.attendanceInfo} />
      <Text paddingLeft={2} fontSize="sm">
        {event.attendanceInfo === 'BLANK'
          ? 'Not complete'
          : titleCase(
              (outcomes &&
                outcomes.find(
                  (outcome) => event.outcome.outcomeUid === outcome.outcomeUid
                )?.name) ||
                event.attendanceInfo ||
                'UNKNOWN'
            )}
      </Text>
    </>
  );
}

export default AttendanceToolEventRow;
