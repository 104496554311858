import React from 'react';
import { Tabs, TabList } from '@chakra-ui/react';
import Info from 'sharedIcons/Info';
import Chat from 'sharedIcons/Chat';
import Today from 'sharedIcons/Today';
import TextSnippet from 'sharedIcons/TextSnippet';
import useTabNavigation from 'sharedHooks/useTabNavigation';

import TabStyled from './TabStyled';

interface PageNavigationTabsProps {
  selectedTab: number;
}
function PageNavigationTabs({
  selectedTab,
}: PageNavigationTabsProps): JSX.Element {
  const handleTabsChange = useTabNavigation();

  return (
    <Tabs index={selectedTab} onChange={handleTabsChange} isLazy marginTop={2}>
      <TabList flexWrap="wrap" justifyContent="space-between" paddingX={9}>
        <TabStyled Icon={Info} text="Client Details" />
        <TabStyled Icon={Chat} text="Messages" />
        <TabStyled Icon={Today} text="Schedule" />
        <TabStyled Icon={TextSnippet} text="Notes" />
      </TabList>
    </Tabs>
  );
}

export default PageNavigationTabs;
