import React from 'react';
import { Flex, Heading, Spacer, HStack } from '@chakra-ui/react';

import ClientSearchBar from 'sharedComponents/ClientSearchBar';

interface InfoViewHeaderProps {
  title: string;
  HeaderButton: JSX.Element;
}

function InfoViewHeader(props: InfoViewHeaderProps): JSX.Element {
  const { title, HeaderButton } = props;

  return (
    <Flex
      background="brand.headerGradient"
      borderRadius={8}
      shrink={0}
      height={20}
      marginY="0"
      paddingX={8}
      marginBottom={4}
    >
      <HStack width="100%" paddingY={6}>
        <Heading
          size="lg"
          fontWeight="normal"
          minWidth="185px"
          marginRight="auto"
          textAlign="left"
          paddingRight={8}
          color="brand.white"
        >
          {title}
        </Heading>
        <ClientSearchBar />
        <Spacer />
        {HeaderButton}
      </HStack>
    </Flex>
  );
}

export default InfoViewHeader;
