import React from 'react';
import {
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuOptionGroup,
  MenuItemOption,
  Text,
} from '@chakra-ui/react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ChevronDownIcon } from '@chakra-ui/icons';
import useStaffMemberList from 'sharedHooks/useStaffMemberList';

interface AttendanceSelectionMenuProps {
  selectedStaffMember: string;
  setFilter: (id: string) => void;
}

function AttendanceSelectionMenu({
  selectedStaffMember,
  setFilter,
}: AttendanceSelectionMenuProps): JSX.Element {
  const staffList = useStaffMemberList();
  const { staffMemberLabelFlag } = useFlags();
  const staffListWithAll = [
    { name: `All ${staffMemberLabelFlag}s`, id: '' },
    ...staffList,
  ];

  // This is to get around a not-quite-correct Chakra type.  A radio
  // group (and as such a radio group menu) can only have a single value.  It
  // should never be the case (given the setup of this particular component)
  // that value is not a string.
  const radioGroupOnChange = (value: string | string[]) => {
    if (typeof value === 'string') {
      setFilter(value);
    } else {
      throw new Error('Value set to array');
    }
  };

  return (
    <>
      {!!staffList.length && (
        <Menu placement="bottom-end" matchWidth>
          <MenuButton minWidth="max-content">
            <Flex justifyContent="space-between" alignItems="center">
              <>
                <Flex>
                  <Text color="brand.gray1" paddingX={2}>
                    {
                      staffListWithAll.filter(
                        (staff) => staff.id === selectedStaffMember
                      )[0].name
                    }
                  </Text>
                </Flex>
                <ChevronDownIcon color="brand.gray3" />
              </>
            </Flex>
          </MenuButton>

          <MenuList
            minWidth="max-content"
            maxHeight="min(300px, 30vh)"
            overflowY="auto"
          >
            <MenuOptionGroup
              title="Show stats for:"
              type="radio"
              onChange={radioGroupOnChange}
              fontWeight="normal"
              color="brand.gray3"
              fontSize="sm"
            >
              {staffListWithAll.map((staff) => (
                <MenuItemOption
                  value={staff.id}
                  key={staff.id}
                  color="brand.gray1"
                  _hover={{ backgroundColor: 'brand.blue3' }}
                  fontSize="sm"
                >
                  {staff.name}
                </MenuItemOption>
              ))}
            </MenuOptionGroup>
          </MenuList>
        </Menu>
      )}
    </>
  );
}

export default AttendanceSelectionMenu;
