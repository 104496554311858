import { useCombobox, UseComboboxReturnValue } from 'downshift';

interface PossibleItem {
  id: string;
  label: string;
}

interface UseAutocompleteComboboxProps<T extends PossibleItem> {
  inputItems: T[];
  filterInputItems: (newInputValue: string | undefined) => void;
  addItem: (item: T) => void;
  labelId?: string;
}

function useAutocompleteCombobox<T extends PossibleItem>({
  inputItems,
  filterInputItems,
  addItem,
  labelId,
}: UseAutocompleteComboboxProps<T>): Partial<UseComboboxReturnValue<T>> &
  Pick<
    UseComboboxReturnValue<T>,
    | 'getLabelProps'
    | 'getInputProps'
    | 'getComboboxProps'
    | 'getMenuProps'
    | 'getItemProps'
    | 'highlightedIndex'
    | 'inputValue'
    | 'reset'
    | 'isOpen'
    | 'getToggleButtonProps'
  > {
  const {
    getLabelProps,
    getItemProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    inputValue,
    setHighlightedIndex,
    reset,
    isOpen,
    getToggleButtonProps,
  } = useCombobox({
    items: inputItems,
    itemToString: (item: PossibleItem | null) => item?.label || '',
    onInputValueChange: ({ inputValue: newInputValue }) => {
      filterInputItems(newInputValue);
      setHighlightedIndex(0);
    },
    labelId,
    onStateChange({ type, selectedItem }) {
      filterInputItems(inputValue);
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          if (selectedItem) {
            addItem(selectedItem);
            reset();
          }
          break;
        default:
          break;
      }
    },
  });

  return {
    getLabelProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    getItemProps,
    highlightedIndex,
    inputValue,
    reset,
    isOpen,
    getToggleButtonProps,
  };
}

export default useAutocompleteCombobox;
