enum RiskLevels {
  'LOWEST' = 'Lowest',
  'LOW' = 'Low',
  'MEDIUM' = 'Medium',
  'HIGH' = 'High',
  'HIGHEST' = 'Highest',
  'NOT_ASSESSED' = 'Not assessed',
}

export default RiskLevels;
