import React from 'react';
import { Button, ButtonProps } from '@chakra-ui/react';

import { onOpenAnnouncementDetails } from 'sharedComponents/AnnouncementDetailsContainer';

interface AnnouncementDetailsButtonProps {
  announcementId: string;
  children: React.ReactNode;
  messageTextColor?: string;
}

function AnnouncementDetailsButton({
  messageTextColor,
  announcementId,
  children,
}: AnnouncementDetailsButtonProps & ButtonProps): JSX.Element {
  return (
    <>
      <Button
        color={messageTextColor}
        size="xs"
        fontSize="sm"
        lineHeight="20px"
        variant="link"
        onClick={() => {
          onOpenAnnouncementDetails(announcementId);
        }}
      >
        {children}
      </Button>
    </>
  );
}

AnnouncementDetailsButton.defaultProps = {
  messageTextColor: 'brand.blue2',
};

export default AnnouncementDetailsButton;
