import React from 'react';
import { Flex, Spacer, SkeletonCircle } from '@chakra-ui/react';
import { useReactiveVar } from '@apollo/client';

import MasqueradeBanner from 'sharedComponents/MasqueradeBanner';
import logo from '../../images/logo-header.png';
import { isMasqueradingVar } from '../graphql/apolloCache';

import MainNavigation from './MainNavigation';
import AccountMenu from './AccountMenu';

function MainHeader({
  loading,
  hidden = false,
}: {
  loading: boolean;
  hidden: boolean;
}): JSX.Element {
  const isMasquerading = useReactiveVar(isMasqueradingVar);
  return (
    <Flex
      paddingY={3}
      paddingX={24}
      bg="brand.white"
      alignItems="center"
      display={hidden ? 'none' : 'flex'}
    >
      <img
        src={logo}
        style={{ objectFit: 'contain' }}
        alt="Uptrust logo"
        width="180px"
        height="45px"
      />
      {isMasquerading && <MasqueradeBanner />}
      <Spacer />
      <MainNavigation />
      <SkeletonCircle size="36px" isLoaded={!loading}>
        <AccountMenu />
      </SkeletonCircle>
    </Flex>
  );
}

export default React.memo(MainHeader);
