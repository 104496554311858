import { useCallback } from 'react';

import { Event, useGetEventsQueryQuery, EventFilter } from 'generated/graphql';
import transformConnection from 'sharedHelpers/transformConnection';

type EventFilterableByStaff = Partial<Event> & Pick<Event, 'staffId'>;

function useShouldUpdateClientStaff({
  clientId,
  staffIds,
}: {
  clientId: string;
  staffIds: string[];
}): (updatedStaffIds: string[] | undefined) => boolean {
  if (!clientId || !staffIds.length) {
    return useCallback(() => false, [clientId]);
  }

  const { data } = useGetEventsQueryQuery({
    variables: {
      id: clientId,
      timestamp: Date.now(),
      timeFilter: EventFilter.Upcoming,
    },
    skip: !clientId,
  });

  const events: EventFilterableByStaff[] | [] = data?.getClient?.events
    ? transformConnection(data.getClient.events)
    : [];

  const shouldUpdateClientStaff = useCallback(
    (updatedStaffIds: string[] | undefined) => {
      if (!events.length) {
        return false;
      }
      const removedClientStaffIds = staffIds.filter(
        (staffId) => !updatedStaffIds?.includes(staffId)
      );
      if (!removedClientStaffIds.length) {
        return false;
      }
      return events.some((event) =>
        removedClientStaffIds.includes(event.staffId)
      );
    },
    [events]
  );

  return shouldUpdateClientStaff;
}

export default useShouldUpdateClientStaff;
