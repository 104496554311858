import { useState, useEffect } from 'react';
import { makeVar, useReactiveVar } from '@apollo/client';

import transformConnection from 'sharedHelpers/transformConnection';

import {
  useGetApiClientsListLazyQuery,
  GetClientsFilter,
  TimePeriod,
  Client,
} from 'generated/graphql';
import { clientFullName } from 'sharedHelpers/clientHelpers';
import useIdentifyUser from './useIdentifyUser';
import { allClientsListVar, organizationId } from '../graphql/apolloCache';

interface UseAnnouncementClientsListStatus {
  loading: boolean;
  error: boolean;
}

export type Group =
  | 'all'
  | 'eventToday'
  | 'eventInTheNextWeek'
  | 'clientsOfStaff'
  | 'selectClients'
  | 'cohortIds';

type LoadClientsParams =
  | { group: 'all' }
  | { group: 'clientsOfStaff'; staffId: string }
  | { group: 'eventToday' }
  | { group: 'eventInTheNextWeek' }
  | { group: 'selectClients' }
  | { group: 'cohortIds'; cohortId: string };

export const sendAnnouncementClientIdsVar = makeVar<string[]>([]);
export const messageableClientsListVar = makeVar<Client[]>([]);
export const announcementSearchTextVar = makeVar('');

interface LoadClientsOptions {
  staffId?: string;
  cohortId?: string;
  timeZone?: string;
  hasEventsInPeriod?: 'eventToday' | 'eventInTheNextWeek';
}

const constructFilters = (
  group: Group,
  options: LoadClientsOptions,
  userId: string
): GetClientsFilter => {
  const isTimePeriodGroup = ['eventToday', 'eventInTheNextWeek'].includes(
    group
  );
  const timePeriodFilter =
    group === 'eventToday' ? TimePeriod.Today : TimePeriod.InTheNextWeek;
  return {
    organizationId: organizationId(),
    clientsOfStaffIds: options.staffId ? [options?.staffId] : undefined,
    cohortIds: options.cohortId ? [options?.cohortId] : undefined,
    timeZone: isTimePeriodGroup
      ? Intl.DateTimeFormat().resolvedOptions().timeZone
      : undefined,
    hasEventsInPeriod: isTimePeriodGroup ? timePeriodFilter : undefined,
    messageableByStaffId: userId,
  };
};

const searchTest = (search: string) => (client: Client) =>
  search.length
    ? clientFullName(client).includes(search.toLowerCase())
    : client;

export default (): [
  (
    params:
      | { group: 'all' }
      | { group: 'clientsOfStaff'; staffId: string }
      | { group: 'eventToday' }
      | { group: 'eventInTheNextWeek' }
      | { group: 'selectClients' }
      | { group: 'cohortIds'; cohortId: string }
  ) => void,
  UseAnnouncementClientsListStatus
] => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [group, setGroup] = useState<Group>();
  const [filters, setFilters] = useState<GetClientsFilter>();
  const [clientsListOrigin, setClientsListOrigin] = useState<
    'select' | 'all' | 'filter'
  >();

  const staffUser = useIdentifyUser();
  const allClientsList = useReactiveVar(allClientsListVar);
  const messageableClientsList = useReactiveVar(messageableClientsListVar);
  const searchText = useReactiveVar(announcementSearchTextVar);

  useEffect(() => {
    messageableClientsListVar(
      allClientsList.filter(
        (client) =>
          client.shouldReceiveMessages && searchTest(searchText)(client)
      )
    );
  }, [allClientsList, searchText]);

  const [
    loadClientsList,
    {
      data: clientsListData,
      loading: clientsListLoading,
      error: clientsListError,
    },
  ] = useGetApiClientsListLazyQuery();

  const loadClients = ({
    group: selectedGroup,
    ...options
  }: LoadClientsParams): void => {
    setGroup(selectedGroup);
    if (staffUser?.id) {
      setFilters(constructFilters(selectedGroup, options, staffUser.id));
    }
  };

  useEffect(() => {
    if (group === 'all') {
      setClientsListOrigin('all');
    } else if (group === 'selectClients') {
      setClientsListOrigin('select');
    } else if (filters) {
      setClientsListOrigin('filter');
      loadClientsList({ variables: { filter: filters } });
    }
  }, [group, filters]);

  useEffect(() => {
    if (clientsListOrigin === 'all') {
      sendAnnouncementClientIdsVar(
        messageableClientsList.map((client) => client.id)
      );
    } else if (clientsListData && clientsListOrigin === 'filter') {
      const clientsConnection = clientsListData.getClients;
      if (clientsConnection) {
        sendAnnouncementClientIdsVar(
          transformConnection(clientsConnection).map((client) => client.id)
        );
      }
    }
  }, [clientsListOrigin, clientsListData]);

  useEffect(() => {
    setLoading(clientsListLoading);
  }, [clientsListLoading]);

  useEffect(() => {
    setError(!!clientsListError);
  }, [clientsListError]);

  return [loadClients, { loading, error }];
};
