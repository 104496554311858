import React, { useEffect } from 'react';
import { useReactiveVar } from '@apollo/client';

import InfoView from 'sharedComponents/InfoView';
import InfoViewSkeleton from 'sharedComponents/InfoViewSkeleton';

import { isStaffUser } from 'sharedHelpers/typeNarrowing';
import { alphabetizeClients } from 'sharedHelpers/clientHelpers';
import transformConnection from 'sharedHelpers/transformConnection';

import { useGetUserQuery } from 'generated/graphql';

import useFilterClients from 'sharedHooks/useFilterClients';
import useNavigateToClient from 'sharedHooks/useNavigateToClient';

import { useFlags } from 'launchdarkly-react-client-sdk';

import MessagesListPanelItem from './components/MessagesListPanelItem';
import MessagesInfoPanel from './components/MessagesInfoPanel';
import NewAnnouncementButton from './components/NewAnnouncementButton';

import {
  allClientsListVar,
  organizationId,
} from '../../shared/graphql/apolloCache';

interface MessagesPageProps {
  clientId?: string;
}

function MessagesPage({ clientId }: MessagesPageProps): JSX.Element {
  const clients = useReactiveVar(allClientsListVar);
  const { filterIncludeEventStaff } = useFlags();

  const { data: serverData, loading } = useGetUserQuery({
    variables: {
      filterIncludeEventStaff: filterIncludeEventStaff || false,
    },
  });

  useEffect(() => {
    if (serverData && isStaffUser(serverData.getUser)) {
      const user = serverData.getUser;
      const { id } = user.organization || {};
      allClientsListVar(user.clients ? transformConnection(user.clients) : []);
      organizationId(id);
    }
  }, [serverData]);

  const filteredClientsList = useFilterClients({
    clientsList: clients,
    sortFunction: alphabetizeClients,
  });

  const navigateToClient = useNavigateToClient();
  useEffect(() => {
    if (!clientId && filteredClientsList.length) {
      navigateToClient(filteredClientsList[0].id);
    }
  }, [clientId, filteredClientsList.length]);

  return (
    <>
      {loading && <InfoViewSkeleton />}
      {!loading && (
        <InfoView
          selectedItem={clientId}
          listPanelChildren={({ index, style }) => (
            <MessagesListPanelItem
              clientsData={filteredClientsList}
              style={style}
              index={index}
            />
          )}
          detailPanelChildren={
            clientId && <MessagesInfoPanel clientId={clientId} />
          }
          title="Messages"
          numberOfItems={filteredClientsList.length}
          HeaderButton={<NewAnnouncementButton />}
          errorMessage={
            clients.length > filteredClientsList.length
              ? 'There are no clients matching the current filters'
              : 'You have no clients to display'
          }
        />
      )}
    </>
  );
}
MessagesPage.defaultProps = {
  clientId: undefined,
};

export default MessagesPage;
