import React from 'react';
import { Box, useStyleConfig } from '@chakra-ui/react';

interface ScheduleDateBarProps {
  displayDate: string;
  variant: 'clientsDashboard' | 'clientSchedule';
}

function ScheduleDateBar({
  /**
   * The formatted date string to display in the bar
   */
  displayDate,
  variant,
}: ScheduleDateBarProps): JSX.Element {
  const styles = useStyleConfig('ScheduleDateBar', {
    variant,
  });
  return <Box __css={styles}>{displayDate}</Box>;
}

export default ScheduleDateBar;
