import { useState, useEffect } from 'react';
import { useGetCohortsLazyQuery, Cohort } from 'generated/graphql';
import useIdentifyUser from './useIdentifyUser';

type CohortListItem = Pick<Cohort, 'id' | 'name'>;

export interface CohortData {
  cohortLabel: string;
  cohorts: CohortListItem[];
}

function useCohorts(showCohortFlag: boolean): CohortData {
  const [cohortData, setCohortData] = useState<CohortData>({
    cohortLabel: 'Program Group',
    cohorts: [],
  });

  const user = useIdentifyUser();
  const organizationId = user?.organization?.id;

  const [fetchCohorts, { data }] = useGetCohortsLazyQuery({
    variables: { organizationId: organizationId || '' },
  });

  useEffect(() => {
    if (organizationId && showCohortFlag) {
      fetchCohorts();
    }
  }, [organizationId, showCohortFlag]);

  useEffect(() => {
    if (data?.getOrganization) {
      const { cohortLabel, cohorts } = data.getOrganization;
      if (cohorts && cohorts.length) {
        setCohortData({ cohortLabel: cohortLabel || 'Program Group', cohorts });
      } else {
        setCohortData({ cohortLabel: '', cohorts });
      }
    }
  }, [data]);
  return cohortData;
}

export default useCohorts;
