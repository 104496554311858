import React from 'react';
import { Tab, Box } from '@chakra-ui/react';

interface TabStyledProps {
  Icon: React.ComponentType;
  text: string;
}
function TabStyled(props: TabStyledProps): JSX.Element {
  const { Icon, text } = props;
  return (
    <Tab
      paddingX={0}
      color="brand.gray2"
      _selected={{
        color: 'brand.blue2',
        borderBottomColor: 'brand.blue2',
      }}
    >
      <Icon />
      <Box paddingLeft={3}>{text}</Box>
    </Tab>
  );
}

export default TabStyled;
