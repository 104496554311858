import React, { useEffect, useState } from 'react';
import {
  Flex,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  Button,
  Stack,
  Heading,
  Text,
  Skeleton,
  List,
  ListItem,
} from '@chakra-ui/react';

import { useReactiveVar } from '@apollo/client';

import ApiErrorAlert from 'sharedComponents/ApiErrorAlert';
import MarkChatRead from 'sharedIcons/MarkChatRead';
import SMSFailed from 'sharedIcons/SMSFailed';
import { clientPluralized } from 'sharedHelpers/pluralize';
import datadogLogGraphQLError from 'sharedHelpers/datadogLogGraphQLError';
import { fullMonthDateWithDay, shortTime } from 'sharedHelpers/textFormat';
import {
  clientNameComparator,
  clientListName,
  SortableClient,
} from 'sharedHelpers/clientHelpers';

import { useGetAnnouncementDetailsQuery } from 'generated/graphql';
import { allClientsListVar } from '../graphql/apolloCache';

interface AnnouncementDetailsDrawerProps {
  /**
   * Defines whether the drawer is open or not.  Pass this in from useDisclosure.
   */
  isOpen: boolean;
  /**
   * A function that closes the drawer.  Be sure to call the function returned from useDisclosure.
   */
  onClose: () => void;
  /**
   * The id of the announcement to show.
   */
  id: string;
}

type ListClient = SortableClient;

const styles = {
  box: {
    marginBottom: 'var(--chakra-space-4)',
    padding: 'var(--chakra-space-4) var(--chakra-space-8)',
  },
  formControl: {
    marginBottom: 'var(--chakra-space-4)',
    marginRight: 'var(--chakra-space-5)',
    paddingRight: 'var(--chakra-space-2)',
  },
};

function AnnouncementDetailsDrawer({
  isOpen,
  onClose,
  id,
}: AnnouncementDetailsDrawerProps): JSX.Element {
  const [announcementBody, setAnnouncementBody] = useState('');
  const [sendDate, setSendDate] = useState('');
  const [sentNumber, setSentNumber] = useState(0);
  const [errorNumber, setErrorNumber] = useState(0);
  const [errorClientsList, setErrorClientsList] = useState<ListClient[]>([]);
  const [showApiAlert, setShowApiAlert] = useState(false);

  const allClientsList = useReactiveVar(allClientsListVar);
  const { data, loading, error, refetch } = useGetAnnouncementDetailsQuery({
    variables: { id },
    skip: !id,
  });

  const showDeliveryErrorInformation = errorNumber > 0;
  const successfullyLoaded = !loading && data?.getAnnouncement?.id === id;

  useEffect(() => {
    if (error && isOpen) {
      refetch();
    }
  }, [isOpen]);

  useEffect(() => {
    if (error) {
      datadogLogGraphQLError(
        error,
        'getAnnouncementDetailsQuery',
        'AnnouncementDetailsDrawer'
      );
      if (!data) {
        setShowApiAlert(true);
      }
    }
  }, [error, data]);

  useEffect(() => {
    if (data?.getAnnouncement) {
      const {
        body,
        timestamp,
        errorMessages: { totalCount: totalErrorCount, edges: errorEdges },
        sentMessages: { totalCount: totalSentCount },
      } = data.getAnnouncement;

      setErrorClientsList(
        errorEdges
          .map((edge) => {
            const clientId =
              edge.node?.__typename === 'FromStaffMessage' // eslint-disable-line no-underscore-dangle
                ? edge.node?.clientId
                : '';
            const { firstName, lastName } =
              allClientsList.find((c) => c.id === clientId) || {};
            if (firstName && lastName) {
              return { id: clientId, firstName, lastName };
            }
            return { id: clientId, firstName: '', lastName: '' };
          })
          .filter((i) => !!i.id)

          .sort(clientNameComparator)
      );

      setAnnouncementBody(body);
      setSendDate(
        `${fullMonthDateWithDay(timestamp)} at ${shortTime(timestamp)}`
      );
      setSentNumber(totalSentCount || 0);
      setErrorNumber(totalErrorCount || 0);
      setShowApiAlert(false);
    }
  }, [data]);

  return (
    <>
      <ApiErrorAlert
        isOpen={showApiAlert}
        onTryAgain={() => {
          refetch();
        }}
        onCancel={() => {
          setShowApiAlert(false);
        }}
        title="Unable to retrieve your announcement"
        body="We encountered a system error and were unable to retrieve your
        announcement."
      />
      <Drawer isOpen={isOpen} onClose={onClose} placement="right" size="lg">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader color="brand.gray1" fontSize="2xl" fontWeight="normal">
            Announcement Details
          </DrawerHeader>

          <DrawerBody overflowY="hidden">
            <Flex
              direction="column"
              backgroundColor="brand.gray8"
              color="brand.gray1"
              borderRadius="8px"
              style={{ ...styles.box, paddingBottom: 0 }}
              height="100%"
            >
              <Heading size="sm" paddingTop={6} paddingBottom={1}>
                Announcement
              </Heading>
              <Skeleton
                isLoaded={successfullyLoaded}
                data-testid="announcement-detail-skeleton"
              >
                <Text>{announcementBody}</Text>
              </Skeleton>
              <Heading size="sm" paddingTop={6} paddingBottom={1}>
                Deliveries
              </Heading>
              <Skeleton
                isLoaded={successfullyLoaded}
                data-testid="announcement-detail-skeleton"
              >
                <Text>Sent on {sendDate}</Text>
                <Stack direction="row" paddingY={2}>
                  <MarkChatRead
                    color="brand.blue2"
                    alignSelf="flex-end"
                    boxSize="20px"
                  />
                  <Text>{`Delivered successfully to ${sentNumber} ${clientPluralized(
                    sentNumber
                  )}`}</Text>
                </Stack>
              </Skeleton>
              {showDeliveryErrorInformation && successfullyLoaded && (
                <>
                  <Stack direction="row" paddingY={2}>
                    <SMSFailed
                      color="brand.orange1"
                      alignSelf="flex-end"
                      boxSize="20px"
                    />
                    <Text>{`Unable to deliver to ${errorNumber} ${clientPluralized(
                      errorNumber
                    )}:`}</Text>
                  </Stack>

                  <List
                    backgroundColor="brand.white"
                    marginX={7}
                    borderRadius="4px"
                    padding={3}
                    overflowY="auto"
                    flex-shrink="1"
                  >
                    {errorClientsList.map((client) => (
                      <ListItem
                        role="listitem"
                        key={client.id}
                      >{`${clientListName(client)}`}</ListItem>
                    ))}
                  </List>
                </>
              )}
            </Flex>
          </DrawerBody>

          <DrawerFooter justifyContent="flex-start" paddingX={12}>
            <Stack direction="row" spacing={6}>
              <Button variant="brandPrimary" onClick={onClose} paddingX={10}>
                Close
              </Button>
            </Stack>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default AnnouncementDetailsDrawer;
