import React from 'react';

import { Flex, useCheckbox, Spacer, Text } from '@chakra-ui/react';

import CheckCircle from 'sharedIcons/CheckCircle';

function CreateAnnouncementClientPickerItem({
  isChecked,
  onChange,
  client,
}: {
  isChecked: boolean;
  client: { id: string; lastName?: string | null; firstName?: string | null };
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}): JSX.Element {
  const displayName = `${client.lastName}, ${client.firstName}`;
  const {
    state,
    getInputProps,
    getCheckboxProps,
    getLabelProps,
    getRootProps,
  } = useCheckbox({ isChecked, onChange });
  return (
    <Flex
      borderColor="brand.gray3"
      borderBottomWidth="1px"
      paddingX={5}
      paddingY={3}
      direction="row"
      color="brand.gray1"
      textAlign="left"
      cursor="pointer"
      backgroundColor={state.isChecked ? 'brand.blue3' : 'brand.white'}
      boxShadow={
        state.isFocused
          ? 'inset 0 0 0 2px var(--chakra-colors-brand-focusBorder)'
          : 'none'
      }
      _first={{
        borderTopRadius: '4px',
      }}
      _last={{
        borderBottomRadius: '4px',
        borderBottom: 'none',
      }}
      {...getRootProps()} // eslint-disable-line react/jsx-props-no-spreading
    >
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Text as="label" id={client.id} {...getLabelProps()}>
        {displayName}
      </Text>
      {/* eslint-disable react/jsx-props-no-spreading */}
      <input
        {...getInputProps()}
        value={client.id}
        aria-labelledby={client.id}
      />{' '}
      {/* eslint-enable react/jsx-props-no-spreading */}
      <Spacer />
      <CheckCircle
        {...getCheckboxProps()} // eslint-disable-line react/jsx-props-no-spreading
        width="18px"
        height="18px"
        alignSelf="center"
        color={state.isChecked ? 'brand.blue2' : 'brand.white'}
      />
    </Flex>
  );
}

export default CreateAnnouncementClientPickerItem;
