import React, { useEffect, useState } from 'react';
import { Button } from '@chakra-ui/react';

import { phoneNumberFormat } from 'sharedHelpers/textFormat';
import createPDF from 'sharedHelpers/createPDF';
import docDefinition from 'sharedHelpers/messageExportDocumentDefinition';
import {
  useGetClientMessagesLazyQuery,
  FromEndUserMessage,
  FromPlatformEventMessage,
  FromPlatformMessage,
  FromStaffMessage,
  UndefinedMessage,
  AutomatedAttendanceMessage,
} from 'generated/graphql';
import useGetClientInformation from 'sharedHooks/useGetClientInformation';

interface ExportMessagesButtonProps {
  clientId: string;
}
type Messages =
  | ({ __typename?: 'AutomatedAttendanceMessage' } & Pick<
      AutomatedAttendanceMessage,
      'id' | 'timestamp' | 'status' | 'type'
    >)
  | ({ __typename?: 'FromEndUserMessage' } & Pick<
      FromEndUserMessage,
      'body' | 'id' | 'timestamp' | 'status' | 'type'
    >)
  | ({ __typename?: 'FromPlatformEventMessage' } & Pick<
      FromPlatformEventMessage,
      'body' | 'id' | 'timestamp' | 'status' | 'type'
    >)
  | ({ __typename?: 'FromPlatformMessage' } & Pick<
      FromPlatformMessage,
      'body' | 'id' | 'timestamp' | 'status' | 'type'
    >)
  | ({ __typename?: 'FromStaffMessage' } & Pick<
      FromStaffMessage,
      'senderName' | 'body' | 'id' | 'timestamp' | 'status' | 'type'
    >)
  | ({ __typename?: 'UndefinedMessage' } & Pick<
      UndefinedMessage,
      'id' | 'timestamp' | 'status' | 'type'
    >);

const generatePDF = async (
  messages: Array<Messages | null | undefined>,
  displayName: string,
  phone: string
) => {
  const createdPDF = await createPDF(
    await docDefinition(messages, displayName, phone)
  );
  createdPDF.download(`Uptrust Message Transcript - ${displayName}.pdf`);
};

function ExportMessagesButton({
  clientId,
}: ExportMessagesButtonProps): JSX.Element {
  const [clicked, setClicked] = useState(false);
  const [
    loadMessages,
    { called, data: messageData, loading },
  ] = useGetClientMessagesLazyQuery({
    variables: { id: clientId },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });

  const { displayName, phone } = useGetClientInformation(clientId);

  useEffect(() => {
    if (
      clicked &&
      called &&
      !loading &&
      messageData?.getClient &&
      displayName &&
      phone
    ) {
      const messages = messageData.getClient.messages.edges.map(
        (edge) => edge.node
      );
      setClicked(false);
      generatePDF(messages, displayName, phoneNumberFormat(phone));
    }
  }, [messageData]);

  const onExportClick = () => {
    setClicked(true);
    loadMessages();
  };

  return (
    <Button
      backgroundColor="brand.gray7"
      color="brand.gray2"
      size="xs"
      isLoading={clicked && loading}
      onClick={onExportClick}
    >
      Export
    </Button>
  );
}

export default ExportMessagesButton;
