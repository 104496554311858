import transformConnection, {
  Connection,
} from 'sharedHelpers/transformConnection';
import type { Client, Case } from 'generated/graphql';
import type {
  ClientsListItemWithMessages,
  Message,
  ClientsListItemWithContacts,
  Contact,
  Restitution,
  Condition,
  ClientsListItemWithTerms,
  ClientsListItemWithEvents,
  ClientEvent,
  Location,
  LocationsList,
  Room,
  RoomsList,
} from '../../shared/types/clientTypes';

export const clientMessages = (
  client: ClientsListItemWithMessages
): Message[] => transformConnection(client.messages);

export const clientsList = (
  clients: Connection<Client | null | undefined> | null | undefined
): Client[] => (clients ? transformConnection(clients) : []);

export const contactsList = (client: ClientsListItemWithContacts): Contact[] =>
  transformConnection(client.contacts);

export const restitutionsList = (
  client: ClientsListItemWithTerms
): Restitution[] =>
  client.terms ? transformConnection(client.terms.restitutions) : [];

export const conditionsList = (client: ClientsListItemWithTerms): Condition[] =>
  client.terms ? transformConnection(client.terms.conditions) : [];

export const eventsList = (client: ClientsListItemWithEvents): ClientEvent[] =>
  client.events ? transformConnection(client.events) : [];

export const locationsList = (locations: LocationsList): Location[] =>
  transformConnection(locations);

export const roomsList = (rooms: RoomsList): Room[] =>
  transformConnection(rooms);

export const casesList = (cases: Connection<Case>): Case[] =>
  transformConnection(cases);
