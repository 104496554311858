import React from 'react';

import { Tag, TagLabel, TagCloseButton } from '@chakra-ui/react';

interface ChipProps {
  /**
   * The id of the item rendered.  Passed to removeItem
   */
  id: string;
  /**
   * The text to render on the chip
   */
  label: string;
  /**
   * A function to delete/remove the chip.  Is called when the x button is
   * clicked.
   */
  removeItem: (itemId: string) => void;
}

/**
 * Renders a chip/tag element with a close button.
 */
function Chip({ id, label, removeItem }: ChipProps): JSX.Element {
  return (
    <Tag variant="defaultChip" data-testid="chip">
      <TagLabel fontSize="sm" color="brand.gray1" paddingRight={1}>
        {label}
      </TagLabel>
      <TagCloseButton
        aria-label={`Remove ${label}`}
        onClick={(e) => {
          e.stopPropagation();
          removeItem(id);
        }}
      />
    </Tag>
  );
}

export default Chip;
