import React, { useEffect, useState } from 'react';

import { useForm, Controller } from 'react-hook-form';

import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Select,
  Text,
  Textarea,
} from '@chakra-ui/react';
import { useReactiveVar } from '@apollo/client';

import { clientPluralized } from 'sharedHelpers/pluralize';
import AnnouncementFormClientBoxChip from 'sharedComponents/AnnouncementFormClientBoxChip';

import useAnnouncementClientGroups, {
  ClientGroups,
} from 'sharedHooks/useAnnouncementClientGroups';
import useAnnouncementClientsList, {
  sendAnnouncementClientIdsVar,
} from 'sharedHooks/useAnnouncementClientsList';
import useStaffMemberList from 'sharedHooks/useStaffMemberList';
import useCohorts from 'sharedHooks/useCohorts';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Error from 'sharedIcons/Error';

import type { SendAnnouncementData } from './CreateAnnouncementDrawer';
import maxMessageLength from '../constants/MessageInputMaxLength';

export type AnnouncementFormFields = Partial<SendAnnouncementData>;

export interface CreateAnnouncementFormProps {
  formData: AnnouncementFormFields;
  formId: string;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  onClose: () => void;
  onSaveFunction: (formData: SendAnnouncementData) => void;
  chooseClients: (previouslySelectedClients?: string[]) => void;
}

const styles = {
  box: {
    marginBottom: 'var(--chakra-space-4)',
    padding: 'var(--chakra-space-4) var(--chakra-space-8)',
  },
  formControl: {
    marginBottom: 'var(--chakra-space-4)',
    marginRight: 'var(--chakra-space-5)',
    paddingRight: 'var(--chakra-space-2)',
  },
};

function CreateAnnouncementForm(
  props: CreateAnnouncementFormProps
): JSX.Element {
  const { formData, formId, setLoading, onSaveFunction, chooseClients } = props;
  const [numberOfClients, setNumberOfClients] = useState(
    formData.clientIds?.length || 0
  );
  const { showCohortFlag } = useFlags();
  const [previousGroup, setPreviousGroup] = useState(formData.group || 'all');
  const [showStaffList, setShowStaffList] = useState(false);
  const [showCohortList, setShowCohortList] = useState<boolean>(false);
  const [textAreaCharacterCount, setTextAreaCharacterCount] = useState<number>(
    0
  );
  const staffList = useStaffMemberList();

  const clientsList = useReactiveVar(sendAnnouncementClientIdsVar);

  const { cohortLabel, cohorts } = useCohorts(showCohortFlag);

  const {
    clientGroups,
    loading: groupsLoading,
  } = useAnnouncementClientGroups();

  const [
    loadClients,
    { loading: clientsListLoading, error: clientsListError },
  ] = useAnnouncementClientsList();

  const selectGroup = (group: keyof ClientGroups) => {
    switch (group) {
      case 'all':
      case 'eventToday':
      case 'eventInTheNextWeek':
        setShowStaffList(false);
        setShowCohortList(false);
        loadClients({ group });
        break;
      case 'clientsOfStaff':
        setShowStaffList(true);
        setShowCohortList(false);
        break;
      case 'selectClients':
        setShowStaffList(false);
        setShowCohortList(false);
        loadClients({ group });
        chooseClients();
        break;
      case 'cohortClients':
        setShowCohortList(true);
        break;
      default:
    }
  };

  const defaultValues: AnnouncementFormFields = {
    group: 'all',
  };

  const defaultFormValues = {
    ...defaultValues,
    ...formData,
    group: formData.previous ? previousGroup : formData.group,
  };

  const {
    handleSubmit,
    register,
    watch,
    setValue,
    control,
    formState: { errors },
  } = useForm<AnnouncementFormFields>({
    defaultValues: defaultFormValues,
  });

  const prevGroup = watch('group');

  const bodyFields = register('body', {
    required: 'You must include a message.',
  });

  useEffect(() => {
    const group = formData.previous ? previousGroup : formData.group;
    switch (group) {
      case 'all':
      case 'eventToday':
      case 'eventInTheNextWeek':
        loadClients({ group });
        break;
      case 'clientsOfStaff':
        if (formData.staffId) {
          loadClients({ group, staffId: formData.staffId });
        }
        setShowStaffList(true);
        break;
      case 'selectClients':
        if (formData.clientIds?.length) {
          sendAnnouncementClientIdsVar(formData.clientIds);
        }
        break;
      case 'cohortClients':
        setShowCohortList(true);
        break;
      default:
        loadClients({ group: 'all' });
    }
  }, []);

  useEffect(() => {
    setLoading(groupsLoading);
  }, [groupsLoading]);

  useEffect(() => {
    setLoading(clientsListLoading);
  }, [clientsListLoading]);

  useEffect(() => {
    setNumberOfClients(clientsList.length);
  }, [clientsList]);

  useEffect(() => {
    if (formData.previous) {
      setValue('group', previousGroup);
      if (previousGroup === 'clientsOfStaff') {
        setShowStaffList(true);
      }
      if (previousGroup === 'cohortClients') setShowCohortList(true);
    }
  }, [formData.previous]);

  const onSubmit = (formValues: AnnouncementFormFields) => {
    const { body, staffId, group } = formValues;
    // Validation rules on the fields will catch these issues before onSubmit gets called.
    // The check is just for Typescript.
    if (body?.length && clientsList.length && group) {
      onSaveFunction({ body, staffId, group, clientIds: clientsList });
    }
  };

  return (
    <form
      id={formId}
      name={formId}
      onSubmit={handleSubmit(onSubmit)}
      noValidate
    >
      <Flex flexDirection="column">
        <Box
          backgroundColor="brand.gray8"
          color="brand.gray1"
          borderRadius="8px"
          style={styles.box}
        >
          <Text marginBottom={4} marginTop={1}>
            Announcements let you send a message to multiple clients at once.
            Your announcement will be sent as a separate, individual message to
            each of the clients you specify below.
          </Text>

          <FormControl
            style={styles.formControl}
            isInvalid={prevGroup !== 'selectClients' && !!errors.group}
            isRequired
          >
            <FormLabel variant="editDrawer">Recipients</FormLabel>
            <FormErrorMessage color="brand.orange1">
              <Error /> {errors.group?.message}
            </FormErrorMessage>
            <Controller
              control={control}
              name="group"
              rules={{
                validate: () =>
                  numberOfClients > 0 ||
                  'You must select some clients to send the message to.',
              }}
              render={({ field: { onChange, value } }) => (
                <Select
                  variant="editDrawer"
                  isRequired
                  onChange={(e) => {
                    setPreviousGroup(prevGroup || 'all');
                    selectGroup(e.target.value as keyof ClientGroups);
                    onChange(e);
                  }}
                  value={value || formData.group}
                  disabled={groupsLoading || clientsListLoading}
                >
                  {clientGroups &&
                    Object.entries(clientGroups).map(([group, displayName]) => (
                      <option key={group} value={group}>
                        {displayName}
                      </option>
                    ))}
                </Select>
              )}
            />
          </FormControl>

          {showCohortList && !!cohorts.length && (
            <FormControl
              style={styles.formControl}
              isRequired
              isInvalid={!!errors.cohortId}
            >
              <FormLabel variant="editDrawer">Cohort List</FormLabel>
              <FormErrorMessage>{errors.cohortId?.message}</FormErrorMessage>
              <Controller
                control={control}
                rules={{
                  required: showCohortList
                    ? 'You must select a cohort'
                    : undefined,
                }}
                name="cohortId"
                render={({ field: { onChange, value } }) => (
                  <Select
                    variant="editDrawer"
                    isRequired
                    onChange={(e) => {
                      onChange(e);
                      if (e.target.value) {
                        loadClients({
                          group: 'cohortIds',
                          cohortId: e.target.value,
                        });
                      }
                    }}
                    value={value || formData.cohortId}
                    disabled={groupsLoading || clientsListLoading}
                    placeholder="Select a cohort"
                  >
                    {cohorts.map(({ name, id }) => (
                      <option key={id} value={id}>
                        {name[0].text}
                      </option>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
          )}

          {showStaffList && !!staffList.length && (
            <FormControl
              style={styles.formControl}
              isRequired
              isInvalid={!!errors.staffId}
            >
              <FormLabel variant="editDrawer">Staff Member</FormLabel>
              <FormErrorMessage color="brand.orange1">
                <Error /> {errors.staffId?.message}
              </FormErrorMessage>
              <Controller
                control={control}
                rules={{
                  required: showStaffList
                    ? 'You must select a staff member'
                    : undefined,
                }}
                name="staffId"
                render={({ field: { onChange, value } }) => (
                  <Select
                    variant="editDrawer"
                    isRequired
                    onChange={(e) => {
                      onChange(e);
                      if (e.target.value) {
                        loadClients({
                          group: 'clientsOfStaff',
                          staffId: e.target.value,
                        });
                      }
                    }}
                    value={value || formData.staffId}
                    disabled={groupsLoading || clientsListLoading}
                    placeholder="Select a staff member"
                  >
                    {staffList.map(({ name, id }) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
          )}

          {prevGroup === 'selectClients' && (
            <FormControl
              style={styles.formControl}
              isRequired
              isInvalid={!!errors.group}
            >
              <Flex justifyContent="space-between">
                <FormLabel id="clientsListLabel" variant="editDrawer">
                  Clients
                </FormLabel>
                <Button
                  color="brand.blue2"
                  size="xs"
                  fontSize="xs"
                  variant="link"
                  onClick={() => chooseClients(clientsList)}
                >
                  Add more clients
                </Button>
              </Flex>
              <FormErrorMessage color="brand.orange1">
                <Error /> {errors.group?.message}
              </FormErrorMessage>
              <Flex
                backgroundColor="brand.white"
                borderRadius="4px"
                paddingX={3}
                paddingY={4}
                flexWrap="wrap"
                aria-labelledby="clientsListLabel"
                role="list"
              >
                {clientsList.map((clientId) => (
                  <AnnouncementFormClientBoxChip
                    clientId={clientId}
                    key={clientId}
                  />
                ))}
              </Flex>
            </FormControl>
          )}
          <FormControl
            style={styles.formControl}
            isRequired
            isInvalid={!!errors.body}
          >
            <FormLabel variant="editDrawer">Message</FormLabel>
            <FormErrorMessage color="brand.orange1">
              <Error /> {errors.body?.message}
            </FormErrorMessage>
            <Textarea
              backgroundColor="brand.white"
              height="100%"
              resize="none"
              rows={6}
              maxLength={maxMessageLength}
              {...bodyFields} // eslint-disable-line react/jsx-props-no-spreading
              onChange={(e) => {
                setTextAreaCharacterCount(e.target.value.length);
              }}
            />
          </FormControl>
          {textAreaCharacterCount >= maxMessageLength && (
            <Text textAlign="end" color="brand.orange1">
              Maximum message size is 2000 characters
            </Text>
          )}
          <Text marginBottom={4} marginTop={1}>
            Your message will be sent to {numberOfClients}{' '}
            {clientPluralized(numberOfClients)}.
          </Text>
        </Box>
      </Flex>
    </form>
  );
}

export default CreateAnnouncementForm;
