import { Box, Flex, Progress, Text } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import zxcvbn, { ZXCVBNResult } from 'zxcvbn';

interface PasswordStrengthMeterProps {
  password: string;
  setValue: (value: boolean) => void;
}

function PasswordStrengthMeter({
  password,
  setValue,
}: PasswordStrengthMeterProps): JSX.Element {
  const testedResult = zxcvbn(password);

  const createPasswordLabel = (result: ZXCVBNResult): string => {
    switch (result.score) {
      case 0:
        return 'Weak';
      case 1:
        return 'Weak';
      case 2:
        return 'Fair';
      case 3:
        return 'Good';
      case 4:
        return 'Strong';
      default:
        return 'Weak';
    }
  };

  useEffect(() => {
    setValue(testedResult.score >= 2);
  }, [testedResult]);

  return (
    <Flex justifyContent="center" direction="column" alignItems="center">
      <Box width="90%">
        <Progress value={testedResult.score} max={4} />
        <br />
        <Text>
          {password && (
            <>
              <strong>Password strength:</strong>{' '}
              {createPasswordLabel(testedResult)}
            </>
          )}
        </Text>
      </Box>
    </Flex>
  );
}

export default PasswordStrengthMeter;
