import { useEffect, useState, useCallback } from 'react';
import { makeVar } from '@apollo/client';

import {
  toCreateClientInput,
  toUpdateClientInput,
} from 'sharedHelpers/toClientInput';
import {
  toCreateContactInput,
  toUpdateContactInput,
} from 'sharedHelpers/toContactInput';
import {
  useCreateClientMutation,
  useUpdateClientMutation,
  useCreateContactMutation,
  useUpdateContactMutation,
  GetClientDetailsQuery,
  GetClientAssignedStaffDocument,
} from 'generated/graphql';
import { useFlags } from 'launchdarkly-react-client-sdk';
import type { ClientFormFields, ContactFormFields } from '../types/formTypes';
import saveClientRefetch from '../graphql/queries/saveClientRefetch';
import getClientDetailsQuery from '../graphql/queries/getClientDetails';
import getUser from '../graphql/queries/getUser';

export type UseSaveClientData = [
  (clientData: ClientFormFields & { needsPing?: boolean }) => void,
  {
    loading: boolean;
    clientError: boolean;
    contactError: boolean;
    complete: boolean;
  }
];

export const newClientId = makeVar('');

function useSaveClient(): UseSaveClientData {
  const [contacts, setContacts] = useState<ContactFormFields[]>([]);
  const [loading, setLoading] = useState(false);
  const [clientError, setClientError] = useState(false);
  const [contactError, setContactError] = useState(false);
  const [complete, setComplete] = useState(false);
  const [saving, setSaving] = useState(false);
  const { filterIncludeEventStaff } = useFlags();

  const [
    createClientMutation,
    {
      data: createClientData,
      loading: createClientLoading,
      error: createClientError,
    },
  ] = useCreateClientMutation();

  const [
    createContactMutation,
    {
      data: createContactData,
      loading: createContactLoading,
      error: createContactError,
    },
  ] = useCreateContactMutation();

  const [
    updateClientMutation,
    { loading: updateClientLoading, error: updateClientError },
  ] = useUpdateClientMutation();

  const [
    updateContactMutation,
    { loading: updateContactLoading, error: updateContactError },
  ] = useUpdateContactMutation();

  const save = useCallback(
    (clientData: ClientFormFields & { needsPing?: boolean }) => {
      setSaving(true);
      setContacts(clientData.contacts);
      if (clientData.id) {
        newClientId(clientData.id || '');
        updateClientMutation({
          variables: { input: toUpdateClientInput(clientData) },
          refetchQueries: [
            { query: saveClientRefetch },
            {
              query: GetClientAssignedStaffDocument,
              variables: { id: clientData.id },
            },
          ],
        });
      } else {
        createClientMutation({
          variables: { input: toCreateClientInput(clientData) },
          refetchQueries: [
            { query: saveClientRefetch },
            {
              query: getUser,
              variables: {
                filterIncludeEventStaff: filterIncludeEventStaff || false,
              },
            },
          ],
        });
      }
    },
    [
      createClientMutation,
      createContactMutation,
      updateClientMutation,
      updateContactMutation,
      newClientId(),
    ]
  );

  // Just for update
  useEffect(() => {
    if (updateClientError) {
      setClientError(true);
      setLoading(false);
    }
    if (updateClientLoading) {
      setLoading(true);
    }
    if (
      newClientId() &&
      !updateClientLoading &&
      !createContactLoading &&
      !updateContactLoading &&
      !createContactData &&
      !!contacts.length
    ) {
      contacts.forEach((contact) => {
        if (contact.name) {
          if (contact.id) {
            updateContactMutation({
              variables: { input: toUpdateContactInput(contact) },
            });
          } else {
            createContactMutation({
              variables: { input: toCreateContactInput(contact) },
              update: (cache, { data }) => {
                const newContactFromResponse = data?.createContact.contact;
                if (!newContactFromResponse) {
                  return;
                }
                const clientData = cache.readQuery<GetClientDetailsQuery>({
                  query: getClientDetailsQuery,
                  variables: { id: newClientId() },
                });
                if (!clientData?.getClient) {
                  return;
                }
                const client = clientData.getClient;
                const currentContacts = client.contacts.edges || [];

                const newContacts = [
                  ...currentContacts,
                  { node: newContactFromResponse },
                ];
                cache.writeQuery<GetClientDetailsQuery>({
                  query: getClientDetailsQuery,
                  variables: { id: newClientId() },
                  data: {
                    getClient: {
                      ...client,
                      contacts: { ...client.contacts, edges: newContacts },
                    },
                  },
                });
              },
            });
          }
        }
      });
    }
  }, [
    createContactLoading,
    updateContactLoading,
    updateClientError,
    updateClientLoading,
  ]);

  // Just for Creation
  useEffect(() => {
    if (createClientError || updateClientError) {
      setClientError(true);
      setLoading(false);
    }
    if (createClientLoading || updateClientLoading) {
      setLoading(true);
    }
    if (
      createClientData &&
      (createClientData?.createClient?.client?.id || newClientId()) &&
      !createContactLoading &&
      !updateContactLoading &&
      !createContactData
    ) {
      if (!newClientId()) {
        newClientId(createClientData.createClient.client.id);
      }
      if (contacts.length) {
        contacts.forEach((contact) => {
          if (contact.name) {
            if (contact.id) {
              updateContactMutation({
                variables: { input: toUpdateContactInput(contact) },
              });
            } else {
              createContactMutation({
                variables: { input: toCreateContactInput(contact) },
                update: (cache, { data }) => {
                  const newContactFromResponse = data?.createContact.contact;
                  if (!newContactFromResponse) {
                    return;
                  }
                  const clientData = cache.readQuery<GetClientDetailsQuery>({
                    query: getClientDetailsQuery,
                    variables: { id: newClientId() },
                  });
                  if (!clientData?.getClient) {
                    return;
                  }
                  const client = clientData.getClient;
                  const currentContacts = client.contacts.edges || [];

                  const newContacts = [
                    ...currentContacts,
                    { node: newContactFromResponse },
                  ];
                  cache.writeQuery<GetClientDetailsQuery>({
                    query: getClientDetailsQuery,
                    variables: { id: newClientId() },
                    data: {
                      getClient: {
                        ...client,
                        contacts: { ...client.contacts, edges: newContacts },
                      },
                    },
                  });
                },
              });
            }
          }
        });
      } else if (saving) {
        setLoading(false);
        setComplete(true);
      }
    }
  }, [
    createClientError,
    createClientLoading,
    createClientData,
    updateClientError,
    updateClientLoading,
  ]);

  useEffect(() => {
    if (createContactError || updateContactError) {
      setContactError(true);
      setLoading(false);
    }
    if (
      saving &&
      !createContactLoading &&
      !updateContactLoading &&
      !updateClientLoading
    ) {
      setLoading(false);
      setComplete(true);
      setSaving(false);
    }
  }, [
    createContactError,
    createContactLoading,
    createClientData,
    updateContactLoading,
    updateClientLoading,
    updateContactError,
  ]);

  return [
    save,
    {
      loading,
      clientError,
      contactError,
      complete,
    },
  ];
}

export default useSaveClient;
