import React from 'react';
import { Flex } from '@chakra-ui/react';

import { EventType } from 'generated/graphql';
import { CourtDateEventWithClients } from 'src/shared/types/clientTypes';

import EventDetailsPopoverBase from './EventDetailsPopoverBase';
import DateAndSingleTime from './DateAndSingleTime';
import DateTimeInformation from './DateTimeInformation';
import ClientInformation from './ClientInformation';
import StaffInformation from './StaffInformation';
import LocationInformation from './LocationInformation';

interface CourtDatePopoverProps {
  event: CourtDateEventWithClients;
}

function CourtDatePopover({ event }: CourtDatePopoverProps): JSX.Element {
  return (
    <EventDetailsPopoverBase event={{ ...event, type: EventType.CourtDate }}>
      <Flex direction="column" rowGap={3}>
        <DateTimeInformation>
          <DateAndSingleTime event={{ ...event, type: EventType.CourtDate }} />
        </DateTimeInformation>
        <ClientInformation
          id={event.clientId}
          firstName={event.clientFirstName}
          lastName={event.clientLastName}
          groupEventClients={event.eventClients}
        />
        <StaffInformation id={event.staffId} />
        <LocationInformation location={event.location} room={event.room} />
      </Flex>
    </EventDetailsPopoverBase>
  );
}

export default CourtDatePopover;
