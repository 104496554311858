import React from 'react';
import { Text } from '@chakra-ui/react';

import emphasizedDateTime from 'sharedHelpers/emphasizedDateTime';
import { fullMonthDate, shortTime } from 'sharedHelpers/textFormat';
import type { ClientEvent } from '../../../../shared/types/clientTypes';

interface DateWithStartAndEndTimeProps {
  event: ClientEvent & { endTimestamp: number };
}

function DateWithStartAndEndTime({
  event,
}: DateWithStartAndEndTimeProps): JSX.Element {
  const emphasizedDate = emphasizedDateTime(event);
  return (
    <>
      <Text color="brand.gray1">{`${fullMonthDate(emphasizedDate)}`}</Text>
      <Text color="brand.gray1">{`${shortTime(
        event.startTimestamp
      )} to ${shortTime(event.endTimestamp)}`}</Text>
    </>
  );
}
export default DateWithStartAndEndTime;
