import React, { Suspense, useState, createContext, useContext } from 'react';

import CheckInResponseDetailsDrawer from './CheckInResponseDetailsDrawer';

interface CheckInResponseDrawerContextType {
  isOpen: boolean;
  onClose: () => void;
  onOpen: (checkInReportId: string) => void;
}

const CheckInResponseDrawerContext = createContext<
  CheckInResponseDrawerContextType | undefined
>(undefined);

export function CheckInResponseDrawerProvider({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  const [checkInReportId, setCheckInReportId] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const onOpen = (reportId: string) => {
    setCheckInReportId(reportId);
    setIsOpen(true);
  };

  const onClose = () => {
    setIsOpen(false);
  };

  const value = { isOpen, onOpen, onClose, checkInReportId };
  return (
    <CheckInResponseDrawerContext.Provider value={value}>
      {children}
      <Suspense fallback={<></>}>
        {isOpen && (
          <CheckInResponseDetailsDrawer
            checkInReportId={checkInReportId}
            isOpen={isOpen}
            onClose={onClose}
          />
        )}
      </Suspense>
    </CheckInResponseDrawerContext.Provider>
  );
}

export function useCheckInResponseDrawer(): CheckInResponseDrawerContextType {
  const context = useContext(CheckInResponseDrawerContext);
  if (!context) {
    throw new Error(
      'useCheckInResponseDrawer must be used within the CheckInResponseDrawerProvider'
    );
  }
  return context;
}
