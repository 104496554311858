import {
  User,
  StaffUser,
  Event,
  VirtualEvent,
  CourtDateEvent,
  OfficeEvent,
  EventType,
} from 'generated/graphql';
import type { ClientEvent } from '../types/clientTypes';

export const isStaffUser = (user: User): user is StaffUser =>
  user.type === 'STAFF';

export const isVirtualEvent = (event: Event): event is VirtualEvent =>
  event.type === 'VIRTUAL';

export const hasAttendance = (
  event: Event
): event is CourtDateEvent | OfficeEvent =>
  event.type === 'COURT_DATE' || event.type === 'OFFICE';

export const isClientEvent = (event: Event): event is ClientEvent =>
  Object.values(EventType).includes(event.type);

type EventWithEndTime = ClientEvent & { endTimestamp: number };

export const isEventWithEndTime = (event: Event): event is EventWithEndTime =>
  typeof event.endTimestamp === 'number';
