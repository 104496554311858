import { gql } from '@apollo/client';

export default gql`
  query getAttendanceStatsEvents(
    $lastActiveTimestamp: Float!
    $startDate: Float!
  ) {
    getUser {
      id
      type
      ... on StaffUser {
        events(
          fromLastActiveTimestamp: $lastActiveTimestamp
          eventFilter: UPCOMING
          eventFilterTimestamp: $startDate
        ) {
          edges {
            node {
              id
              startTimestamp
              endTimestamp
              type
              title
              clientFirstName
              clientLastName
              staffId
              closed
              outcome {
                outcomeUid
                status
              }
              attendanceInfo @client
            }
          }
        }
      }
    }
  }
`;
