import React from 'react';
import { Button, useDisclosure } from '@chakra-ui/react';
import EditDrawer from 'sharedComponents/EditDrawer';
import AddCircle from 'sharedIcons/AddCircle';
import EditClientForm from 'sharedComponents/EditClientForm';
import type { ClientFormFields } from '../../../shared/types/formTypes';

function AddClientButton(): JSX.Element {
  const { onOpen, isOpen, onClose } = useDisclosure();
  return (
    <>
      <Button
        variant="brandHeaderPrimary"
        leftIcon={<AddCircle boxSize="16px" marginRight={1} />}
        onClick={onOpen}
      >
        New Client
      </Button>
      <EditDrawer<ClientFormFields>
        isOpen={isOpen}
        onClose={onClose}
        FormContent={EditClientForm}
        title="Add New Client"
        formData={{
          contacts: [],
        }}
        formId="addClientForm"
      />
    </>
  );
}

export default AddClientButton;
