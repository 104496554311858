import React from 'react';

import {
  Flex,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  CloseButton,
} from '@chakra-ui/react';

interface GlobalErrorNotificationProps {
  closeErrorNotification: () => void;
}

function GlobalErrorNotification({
  closeErrorNotification,
}: GlobalErrorNotificationProps): JSX.Element {
  return (
    <Alert status="error" id="errorAlertPopup">
      <AlertIcon alignSelf="flex-start" />
      <Flex direction="column">
        <AlertTitle>There was an error with your request.</AlertTitle>
        <AlertDescription>
          If the problem persists, you may need to refresh the page.
        </AlertDescription>
      </Flex>
      <CloseButton
        alignSelf="flex-start"
        position="relative"
        right={-1}
        top={-1}
        onClick={closeErrorNotification}
      />
    </Alert>
  );
}

export default GlobalErrorNotification;
