import React, { useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';

import useGetClientInformation from 'sharedHooks/useGetClientInformation';
import useQuery from 'sharedHooks/useQuery';
import DetailPanelHeader from 'sharedComponents/DetailPanelHeader';
import PageNavigationTabs from 'sharedComponents/PageNavigationTabs';
import ScheduleDetailsPanel from './ScheduleDetailsPanel';
import { useCheckInResponseDrawer } from './CheckInResponseDrawerProvider';

function ScheduleInfoPanel({ clientId }: { clientId: string }): JSX.Element {
  const { displayName } = useGetClientInformation(clientId);
  const { onOpen } = useCheckInResponseDrawer();
  const checkInReportId = useQuery().get('check-in-response');
  const history = useHistory();

  useEffect(() => {
    if (checkInReportId) {
      onOpen(checkInReportId);
      history.replace(`/schedule/${clientId}`);
    }
  }, []);

  const tabPanelHeight = 'calc(100% - 40px - 64px - 0.5rem)';
  return (
    <>
      {clientId && (
        <Box width="100%" height="100%">
          <DetailPanelHeader title={displayName} miniBar clientId={clientId} />
          <PageNavigationTabs selectedTab={2} />
          <Box height={tabPanelHeight} padding="0">
            <ScheduleDetailsPanel clientId={clientId} />
          </Box>
        </Box>
      )}
    </>
  );
}

export default ScheduleInfoPanel;
