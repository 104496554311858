import React, { useEffect, useState } from 'react';
import { Button, Text } from '@chakra-ui/react';

import type { AnnouncementFormFields } from 'sharedComponents/CreateAnnouncementForm';
import MutationConfirmation from 'sharedComponents/MutationConfirmation';
import ApiErrorAlert from 'sharedComponents/ApiErrorAlert';
import { clientPluralized } from 'sharedHelpers/pluralize';
import datadogLogGraphQLError from 'sharedHelpers/datadogLogGraphQLError';

import { useSendAnnouncementMutation } from 'generated/graphql';
import useAnnouncementClientGroups from 'sharedHooks/useAnnouncementClientGroups';

import useStaffMemberList from 'sharedHooks/useStaffMemberList';

interface SendAnnouncementConfirmationProps {
  /**
   * The data being passed to the sendAnnouncement mutation.
   */
  announcementData: AnnouncementFormFields;
  /**
   * Whether or not the confirmation dialog is open
   */
  isOpen: boolean;
  /**
   * A function to close the window after the mutation is successfully executed.
   */
  onSuccessfulSend: (announcementId: string) => void;
  /**
   * A function to close the window when the user clicks cancel or hits esc.
   */
  onCancel: () => void;
  /**
   * A function to call if the user encounters a terminal error after clicking confirm.
   */
  onTerminalError: () => void;
}

function SendAnnouncementConfirmation({
  isOpen,
  onSuccessfulSend,
  onCancel,
  announcementData,
  onTerminalError,
}: SendAnnouncementConfirmationProps): JSX.Element {
  const { clientGroups } = useAnnouncementClientGroups();
  const staffList = useStaffMemberList();
  const [showApiAlert, setShowApiAlert] = useState(false);
  const [unauthorizedError, setUnauthorizedError] = useState(false);

  const { clientIds, body, group, staffId } = announcementData;

  let groupName = clientGroups && group ? clientGroups[group] : undefined;

  if (groupName && group === 'clientsOfStaff') {
    groupName = `${groupName.slice(0, -3)} ${
      staffList.find((staff) => staff.id === staffId)?.name
    }`;
  }

  if (groupName && group === 'selectClients') {
    groupName = `${clientIds?.length} ${clientPluralized(
      announcementData.clientIds?.length || 0
    )}`;
  }

  const [
    sendAnnouncement,
    { data, loading, error },
  ] = useSendAnnouncementMutation({ onError: () => {} });

  const onConfirm = () => {
    if (body && clientIds?.length) {
      sendAnnouncement({
        variables: { input: { clientsIds: clientIds, body } },
      });
    }
  };

  useEffect(() => {
    if (data?.sendAnnouncement?.announcement?.id) {
      onSuccessfulSend(data.sendAnnouncement.announcement.id);
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      datadogLogGraphQLError(
        error,
        'sendAnnouncement',
        'SendAnnouncementConfirmation'
      );
      if (error.graphQLErrors?.length) {
        setUnauthorizedError(true);
      }
      if (!data) {
        setShowApiAlert(true);
      }
    }
  }, [error, data]);

  return (
    <>
      <ApiErrorAlert
        isOpen={showApiAlert}
        onTryAgain={() => {
          onConfirm();
        }}
        onCancel={onCancel}
        title="Unable to send announcement"
        body="We encountered a system error and were unable to send your
        announcement."
        enableRetry={!unauthorizedError}
        onTerminalError={onTerminalError}
      />
      {!showApiAlert && (
        <MutationConfirmation isOpen={isOpen} onCancel={onCancel}>
          {{
            bodyContent: (
              <>
                Before we send your announcement, please confirm that you want
                your message sent to:
                <Text data-testid="group name" fontWeight="bold">
                  {groupName}
                </Text>
              </>
            ),
            confirmButton: (
              <Button
                paddingX={6}
                variant="brandPrimary"
                onClick={onConfirm}
                isDisabled={!body || !clientIds?.length}
                isLoading={loading}
              >
                Yes, send to {announcementData.clientIds?.length}{' '}
                {clientPluralized(announcementData.clientIds?.length || 0)}
              </Button>
            ),
            cancelButton: (cancelRef) => (
              <Button
                paddingX={6}
                variant="brandCancel"
                ref={cancelRef}
                onClick={onCancel}
                isLoading={loading}
              >
                Cancel
              </Button>
            ),
          }}
        </MutationConfirmation>
      )}
    </>
  );
}

export default SendAnnouncementConfirmation;
