/**
 * A utility to create list keys from strings (for example, a sentence).
 * This will always return the same key given the same input.
 * @param input the string you want a key for.
 * @returns a 10 character string with no spaces.
 */

const listKeyFromString = (input: string): string =>
  btoa(input.replace(/\p{Extended_Pictographic}/u, ''));

export default listKeyFromString;
