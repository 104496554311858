import React, { ReactNode } from 'react';
import { Grid } from '@chakra-ui/react';

import { widgetSizes, isSizedComponent } from './dashboardWidgets';

/**
 * A responsive grid that lays out children in a grid.  To take advantage of
 * automatic layout capabilities, wrap children in SquareWidget or RectangleWidget
 */
interface DashboardGridProps {
  /**
   * The children you wish to render in the grid.  If none of the children is a
   * SizedComponent, the grid will render a single column with all elements
   * stacked one on top of the other.
   */
  children: ReactNode;
  /**
   * The number of rows desired to contain any SizedComponent children.
   */
  numberOfRows?: number;
}

function DashboardGrid({
  numberOfRows = 1,
  children,
}: DashboardGridProps): JSX.Element {
  const columns = Array.isArray(children)
    ? children.reduce((cols: number, child) => {
        if (
          React.isValidElement(child) &&
          typeof child?.props.name === 'string' &&
          isSizedComponent(child.props.name)
        ) {
          return (
            cols + widgetSizes[child.props.name as keyof typeof widgetSizes]
          );
        }
        return cols;
      }, 0)
    : 1;
  const numberOfColumns = Math.ceil(columns / numberOfRows);
  return (
    <Grid
      gap={{ base: 2, md: 3, lg: 4, xl: 6 }}
      paddingX={6}
      templateColumns={`repeat(${numberOfColumns},  minmax(max-content, 1fr))`}
      templateRows={`repeat(${numberOfRows}, fit-content(25%)) 1fr`}
      alignItems="center"
      width="100%"
      height="100%"
    >
      {children}
    </Grid>
  );
}

DashboardGrid.defaultProps = {
  numberOfRows: 1,
};

export default DashboardGrid;
