import React, { useState } from 'react';
import { Text, Flex, Link, List, ListItem } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { ClientEventData } from 'src/shared/types/clientTypes';

import Person from 'sharedIcons/Person';
import People from 'sharedIcons/People';
import Circle from 'sharedIcons/Circle';
import { clientDisplayName } from 'sharedHelpers/clientHelpers';
import IconDetail from './IconDetail';

interface ClientInformationProps {
  id: string;
  firstName?: string | null;
  lastName?: string | null;
  groupEventClients?: ClientEventData[] | null;
}

function ClientInformation({
  id,
  firstName,
  lastName,
  groupEventClients,
}: ClientInformationProps): JSX.Element {
  const [listClientOpen, setListClientOpen] = useState<boolean>(false);
  const icon =
    groupEventClients && groupEventClients.length > 1 ? Person : People;

  const links = {
    client: `/clients/${id}`,
    message: `/messages/${id}`,
    schedule: `/schedule/${id}`,
  };

  const clientsInfo =
    groupEventClients && groupEventClients.length > 1 ? (
      <Text
        fontSize="sm"
        color="brand.blue2"
        onClick={() => setListClientOpen(!listClientOpen)}
        _hover={{
          cursor: 'pointer',
        }}
      >
        {!listClientOpen && `${groupEventClients.length} clients`}
        {listClientOpen && (
          <List>
            {groupEventClients.map((clientData: ClientEventData) => (
              <ListItem key={clientData.id}>
                {clientDisplayName({
                  firstName: clientData.firstName,
                  lastName: clientData.lastName,
                })}
              </ListItem>
            ))}
          </List>
        )}
      </Text>
    ) : (
      <Link as={RouterLink} to={links.client} fontSize="sm" color="brand.gray1">
        {clientDisplayName({
          firstName,
          lastName,
        })}
      </Link>
    );

  return (
    <IconDetail DetailIcon={icon}>
      {clientsInfo}
      {!groupEventClients?.length && (
        <Flex direction="row">
          <Link
            as={RouterLink}
            to={links.message}
            fontSize="sm"
            color="brand.blue2"
          >
            Send message
          </Link>
          <Flex paddingX={2} alignItems="center">
            <Circle boxSize="4px" color="brand.gray5" />
          </Flex>
          <Link
            as={RouterLink}
            to={links.schedule}
            fontSize="sm"
            color="brand.blue2"
          >
            View schedule
          </Link>
        </Flex>
      )}
    </IconDetail>
  );
}

ClientInformation.defaultProps = {
  firstName: '',
  lastName: '',
  groupEventClients: [],
};
export default ClientInformation;
