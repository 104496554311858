import { Flex, IconButton, Text, Tooltip } from '@chakra-ui/react';
import { Outcome } from 'generated/graphql';
import React, { useEffect, useState } from 'react';
import { orderOutcomes } from 'sharedComponents/AttendanceSelectionMenu';
import useOrganizationOutcomes from 'sharedHooks/useOrganizationOutcomes';
import AssignmentInd from 'sharedIcons/AssignmentInd';
import AssignmentLate from 'sharedIcons/AssignmentLate';
import AssignmentTurnedIn from 'sharedIcons/AssignmentTurnedIn';
import Outcomes from '../../../shared/constants/Outcomes';

interface EditEventAllAttendanceProps {
  setAllClient: (outcomeId: string | undefined) => void;
}

function EditEventAllAttendance({
  setAllClient,
}: EditEventAllAttendanceProps): JSX.Element {
  const { outcomes, loading } = useOrganizationOutcomes();
  const [outcomeAttended, setOutcomeAttended] = useState<Outcome>();
  const [outcomeLate, setOutcomeLate] = useState<Outcome>();
  const sortedOutcomes = [...outcomes].sort(orderOutcomes);

  useEffect(() => {
    if (!loading) {
      setOutcomeAttended(
        sortedOutcomes.filter(
          (outcome) => outcome.outcomeUid === Outcomes.Attended
        )[0]
      );
      setOutcomeLate(
        sortedOutcomes.filter(
          (outcome) => outcome.outcomeUid === Outcomes.Missed
        )[0]
      );
    }
  }, [outcomes]);
  return (
    <Flex>
      <Text
        fontWeight="400"
        color="brand.gray1"
        alignItems="center"
        display="inline-flex"
        fontSize="14px"
        marginRight="8px"
      >
        Mark all:{' '}
      </Text>
      <Tooltip label={outcomeAttended?.name || ''} key={outcomeAttended?.id}>
        <IconButton
          minWidth="0px"
          margin="0 5.75px"
          aria-label="Edit event"
          size="sm"
          icon={<AssignmentTurnedIn width="16.5px" height="18.33px" />}
          color="brand.blue2"
          variant="ghost"
          onClick={() => setAllClient(outcomeAttended?.id || '')}
          _hover={{
            background: 'inherit',
          }}
        />
      </Tooltip>
      <Tooltip label={outcomeLate?.name || ''} key={outcomeLate?.id}>
        <IconButton
          minWidth="0px"
          aria-label="Edit event"
          margin="0 5.75px"
          size="sm"
          icon={<AssignmentLate width="16.5px" height="18.33px" />}
          color="brand.orange1"
          variant="ghost"
          onClick={() => setAllClient(outcomeLate?.id || '')}
          _hover={{
            background: 'inherit',
          }}
        />
      </Tooltip>
      <Tooltip label="Clear All" key={0}>
        <IconButton
          minWidth="0px"
          margin="0 5.75px"
          aria-label="Edit event"
          size="sm"
          icon={<AssignmentInd width="16.5px" height="18.33px" />}
          color="brand.gray3"
          variant="ghost"
          onClick={() => setAllClient(undefined)}
          _hover={{
            background: 'inherit',
          }}
        />
      </Tooltip>
    </Flex>
  );
}

export default EditEventAllAttendance;
