import React from 'react';
import { GridItem, FlexboxProps } from '@chakra-ui/react';

/**
 * A sized widget for use with the DashboardGrid.
 * This widget is square in shape, and is the same height as its width.
 */

function SquareWidget({
  children,
  name = 'SquareWidget', // eslint-disable-line @typescript-eslint/no-unused-vars
  justifySelf = 'auto',
}: {
  /**
   * A collection of react children to render inside of the widget
   */
  children: React.ReactChild;
  name?: 'SquareWidget';
  justifySelf?: FlexboxProps['justifySelf'];
}): JSX.Element {
  return (
    <GridItem
      colSpan={2}
      style={{ aspectRatio: '1 / 1' }}
      height="min(100%, 350px)"
      maxWidth="min(100%, 350px)"
      justifySelf={justifySelf}
    >
      {children}
    </GridItem>
  );
}

SquareWidget.defaultProps = {
  name: 'SquareWidget',
  justifySelf: 'auto',
};

export default SquareWidget;
