import { extendTheme } from '@chakra-ui/react';
import components from './components';

const theme = {
  breakpoints: {
    sm: '1280px',
    md: '1440px',
    lg: '1600px',
    xl: '2560px',
    '2xl': '3840px',
  },
  components,
  colors: {
    brand: {
      black: '#000000',
      gray1: '#4f4f4f',
      gray2: '#6f6f6f',
      gray3: '#949494',
      gray4: '#bdbdbd',
      gray5: '#cccccc',
      gray6: '#dedede',
      gray7: '#efefef',
      gray8: '#f4f4f4',
      blue1: '#001871',
      blue2: '#307fe2',
      blue2Hover: '#2B73CC',
      blue3: '#e9f2fc',
      orange1: '#ff6900',
      orange3: '#ffe8d9',
      white: '#ffffff',
      headerGradient: `linear-gradient(90.01deg, #307FE2 0%, #C0D8F6 99.99%)`,
      focusBorder: 'rgba(48, 127, 226, 0.6)',
    },
  },
};

export default extendTheme(theme);
