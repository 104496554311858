import { useHistory, useLocation } from 'react-router-dom';

/**
 * Navigates between clients, keeping the page one is looking at constant
 *
 * @returns A function that triggers the navigation
 */

const useNavigateToClient = (): ((newClientId: string) => void) => {
  const location = useLocation();
  const history = useHistory();

  const [, page, , ...rest] = location.pathname.split('/');
  return (newClientId) => {
    const newLocation = `/${page || 'client'}/${newClientId}/${rest.join('/')}`;
    if (location.pathname !== newLocation) {
      history.push(newLocation);
    }
  };
};

export default useNavigateToClient;
