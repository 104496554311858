import React from 'react';
import { Flex, Heading, Skeleton } from '@chakra-ui/react';

import StatsTidbitStack from 'sharedComponents/StatsTidbitStack';
import useEngagementTidbitStats from '../hooks/useEngagementTidbitStats';
import type { AllStatsType } from '../hooks/useClientsDashboardStats';

interface EngagementTidbitProps {
  allStats: AllStatsType;
  loading: boolean;
}

const engagementStatNames = [
  'clientsWithApp',
  'clientsWithValidSMS',
  'unreachableClients',
  'successfulReminders',
  'clientsWhoSentMessage',
] as const;

const engagementTidbitTemplates = {
  clientsWithApp: '{count} clients are currently using the Uptrust app',
  clientsWithValidSMS: '{count} clients received reminders via SMS',
  unreachableClients: '{count} clients are currently unreachable',
  successfulReminders: '{count} reminders were delivered successfully',
  clientsWhoSentMessage: '{count} clients sent messages',
};

const engagementTidbitSingleTemplates = {
  clientsWithApp: '{count} client is currently using the Uptrust app',
  clientsWithValidSMS: '{count} client received reminders via SMS',
  unreachableClients: '{count} client is currently unreachable',
  successfulReminders: '{count} reminder was delivered successfully',
  clientsWhoSentMessage: '{count} client sent messages',
};

function EngagementTidbits({
  allStats,
  loading,
}: EngagementTidbitProps): JSX.Element {
  const { stats } = useEngagementTidbitStats({
    allStats,
  });
  return (
    <Skeleton isLoaded={!loading} height="100%">
      <Flex
        direction="column"
        height="100%"
        alignItems="start"
        justifyContent="center"
        paddingLeft={{ base: 0, xl: 4 }}
      >
        <Heading
          fontSize={{ base: 'sm', lg: 'md', xl: 'lg' }}
          paddingBottom={{ base: 1, lg: 2 }}
          color="brand.gray1"
        >
          Engagement
        </Heading>
        <StatsTidbitStack
          names={engagementStatNames}
          templates={engagementTidbitTemplates}
          singleTemplates={engagementTidbitSingleTemplates}
          numbers={stats}
        />
      </Flex>
    </Skeleton>
  );
}

export default EngagementTidbits;
