import { gql } from '@apollo/client';

export default gql`
  query getOrganizationLocations {
    getUser {
      id
      ... on StaffUser {
        name
        organization {
          id
          locations {
            id
            addressLine1
            addressLine2
            city
            state
            zip
            isExternal
            providesChildCare
            rooms {
              edges {
                node {
                  id
                  description
                }
              }
            }
          }
        }
      }
    }
  }
`;
