import React from 'react';

import { Flex, useStyleConfig } from '@chakra-ui/react';

interface ChipListProps {
  /**
   * The tags to render in the list
   */
  children: React.ReactNode;
  /**
   * Variants are not implemented yet
   */
  variant?: never;
  disabled?: boolean;
}

/**
 * Displays a list of chips.  Expands in height as needed to fit children.
 */

function ChipList({ variant, disabled, children }: ChipListProps): JSX.Element {
  const styles = useStyleConfig('ChipList', { variant });
  return (
    <Flex __css={styles} cursor={disabled ? 'not-allowed' : 'pointer'}>
      {children}
    </Flex>
  );
}

ChipList.defaultProps = {
  variant: undefined,
  disabled: false,
};

export default ChipList;
