import {
  phoneNumberFormat,
  sentenceCase,
  titleCase,
  fullMonthDate,
  formatMoney,
} from 'sharedHelpers/textFormat';
import listKeyFromString from 'sharedHelpers/listKeyFromString';
import getLocalizedText from 'sharedHelpers/getLocalizedText';
import type { Case, Cohort } from 'generated/graphql';
import {
  contactsList,
  restitutionsList,
  conditionsList,
  casesList,
} from './dataTransforms';
import type {
  Contact,
  Restitution,
  Condition,
  FullClientsListItem,
} from '../../shared/types/clientTypes';
import type { DetailListItem } from './components/ClientDetailItem';
import Genders from '../../shared/constants/Genders';
import Languages, { LanguageNames } from '../../shared/constants/Languages';
import RiskLevels from '../../shared/constants/RiskLevels';

interface BaseClientDetailsPanelData {
  'Phone Number': string | null;
  'Email Address': string | null;
  Gender: Genders | null;
  Language: LanguageNames | null;
  Circumstances: string | null;
  'Client ID': string | null;
  'Risk Level'?: string | null;
  'Term Ends'?: string | null;
  Restitution?: DetailListItem[];
  Conditions?: DetailListItem[];
  Contacts: DetailListItem[];
  'Employer(s)': string | null;
  'Lower Docket'?: string;
  'Upper Docket'?: string;
  Cases?: DetailListItem[];
  Address: DetailListItem[];
}

interface SupervisorClientDetailsPanelData extends BaseClientDetailsPanelData {
  Supervisor: DetailListItem[];
}

interface AttorneyClientDetailsPanelData extends BaseClientDetailsPanelData {
  Attorney: DetailListItem[];
}

interface StaffClientDetailsPanelData extends BaseClientDetailsPanelData {
  Staff: DetailListItem[];
}

interface AssignedStaffClientDetailsPanelData
  extends BaseClientDetailsPanelData {
  AssignedStaff: DetailListItem[];
}

type StaffLabels = 'Supervisor' | 'Attorney' | 'Staff' | 'Assigned Staff';

export type ClientDetailsPanelData =
  | SupervisorClientDetailsPanelData
  | AttorneyClientDetailsPanelData
  | StaffClientDetailsPanelData
  | AssignedStaffClientDetailsPanelData;

const formatCircumstances = (data: FullClientsListItem) => {
  const circumstances = [
    data.noCar ? 'no vehicle' : null,
    data.housingInsecure ? 'housing insecure' : null,
    data.needsChildCare ? 'needs childcare' : null,
    data.inCustody ? 'incarcerated' : null,
    data.noPhone ? 'no phone' : null,
  ];
  return circumstances.filter((x) => x).join(', ');
};
const formatContacts = (contacts: Contact[]): DetailListItem[] => {
  const contactStrings = contacts.map((contact, i) => {
    const contactDetails = [
      contact.name,
      contact.phone ? phoneNumberFormat(contact.phone) : null,
      contact.notes,
    ];
    return {
      id: contact.id || `${i}`,
      value: contactDetails.filter((x) => x).join(', '),
    };
  });
  return contactStrings;
};

function formatCityStateZip(
  address: {
    city: string;
    state: string;
    zipCode: string;
  } | null
) {
  if (!address) {
    return null;
  }
  const cityStateZipCodeArray = [address.city, address.state, address.zipCode];
  return cityStateZipCodeArray.filter((item) => !!item).join(', ');
}
const formatAddress = (
  addressData: {
    addressLine1: string;
    addressLine2?: string | undefined | null;
    city: string;
    state: string;
    zipCode: string;
  } | null
): DetailListItem[] => {
  const addressDataValues = [
    { id: 'addressLine1', value: addressData?.addressLine1 },
    { id: 'addressLine2', value: addressData?.addressLine2 },
    { id: 'cityStateZip', value: formatCityStateZip(addressData) },
  ];
  return addressDataValues.filter(
    (address): address is DetailListItem => !!address.value
  );
};
const formatConditions = (conditions: Condition[]): DetailListItem[] =>
  conditions.map((condition) => ({
    id: listKeyFromString(condition.description),
    value: condition.description,
  }));

const formatRestitutions = (restitutions: Restitution[]): DetailListItem[] =>
  restitutions.map((restitution) => ({
    id: listKeyFromString(
      `${restitution.type}, ${formatMoney(restitution.amount)}`
    ),
    value: `${restitution.type}, ${formatMoney(restitution.amount)}`,
  }));

const showTermsData = (showTermsFlag: boolean, data: FullClientsListItem) => {
  if (showTermsFlag) {
    return {
      'Supervision Ends': fullMonthDate(data?.terms?.endDate),
      Restitution: formatRestitutions(restitutionsList(data)),
      Conditions: formatConditions(conditionsList(data)),
    };
  }
  return {};
};

const showRiskLevelData = (
  showRiskLevelFlag: boolean,
  riskLevel: string | null
) => {
  if (showRiskLevelFlag) {
    return {
      'Risk Level': riskLevel
        ? RiskLevels[riskLevel as keyof typeof RiskLevels]
        : null,
    };
  }
  return {};
};

const showStaffMemberData = (
  staffMemberLabel: 'supervisor' | 'attorney' | 'staff' | 'assigned staff',
  staffList: { id: string; name: string }[]
): {
  [key in StaffLabels]: DetailListItem[];
} => {
  const staffLabel = titleCase(staffMemberLabel);

  return {
    [staffLabel]: staffList.map((staff) => ({
      id: staff.id,
      value: staff.name,
    })),
  } as {
    [key in StaffLabels]: DetailListItem[];
  };
};

const showLowerDocket = (docket: string | null) => {
  if (docket) {
    return { 'Lower Docket': docket };
  }
  return {};
};

const showUpperDocket = (upperDocket: string | null) => {
  if (upperDocket) {
    return { 'Upper Docket': upperDocket };
  }
  return {};
};

const transformCaseData = (cases: Case[]) => {
  if (!cases.length) {
    return {};
  }
  const openCases = cases
    .filter((c) => c.open)
    .map((c) => ({ id: c.id, value: `${c.number}, open` }));

  const closedCases = cases
    .filter((c) => !c.open)
    .map((c) => ({ id: c.id, value: `${c.number}, closed` }));

  return {
    Cases: [...openCases, ...closedCases],
  };
};

const showCohortData = (
  cohort: Pick<Cohort, 'id' | 'name'> | null,
  showCohortFlag: boolean,
  cohortLabel?: string
) => {
  if (!showCohortFlag || cohortLabel === '') {
    return {};
  }
  // Try to show English.  If there isn't an english name, try to show the first
  // value. If that fails, show nothing.
  const cohortName = getLocalizedText(cohort?.name || []);
  return { [titleCase(cohortLabel || '')]: cohortName };
};

const transformDetailsData = ({
  data,
  staffMemberLabelFlag,
  showTermsFlag,
  showRiskLevelFlag,
  showCohortFlag,
  assignedStaff,
  cohortLabel,
}: {
  data: FullClientsListItem;
  staffMemberLabelFlag: 'supervisor' | 'attorney' | 'staff' | 'assigned staff';
  showTermsFlag: boolean;
  showRiskLevelFlag: boolean;
  showCohortFlag: boolean;
  assignedStaff: { id: string; name: string }[];
  cohortLabel?: string;
}): ClientDetailsPanelData => {
  const {
    phone,
    address,
    email,
    gender,
    language,
    employer,
    riskLevel,
    docket,
    upperDocket,
    cases,
    cohort,
  } = data;
  const termsData = showTermsData(showTermsFlag, data);
  const riskLevelData = showRiskLevelData(showRiskLevelFlag, riskLevel);
  const staffMemberData = showStaffMemberData(
    staffMemberLabelFlag,
    assignedStaff
  );
  const detailsData = {
    'Phone Number': phoneNumberFormat(phone),
    Address: formatAddress(address),
    'Email Address': email,
    Gender: Genders[gender as keyof typeof Genders],
    Language: language
      ? Languages[language as keyof typeof Languages].name
      : null,
    'Employer(s)': employer,
    Circumstances: sentenceCase(formatCircumstances(data)),
    'Client ID': data.cmsId,
    ...staffMemberData,
    ...riskLevelData,
    ...showCohortData(cohort, showCohortFlag, cohortLabel),
    ...termsData,
    Contacts: formatContacts(contactsList(data)),
    ...showLowerDocket(docket),
    ...showUpperDocket(upperDocket),
    ...transformCaseData(casesList(cases)),
  };

  return detailsData;
};

export default transformDetailsData;
