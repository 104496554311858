import { useState, useEffect } from 'react';
import { startOfHour, subMonths, isAfter, isBefore } from 'date-fns';
import type { PureQueryOptions } from '@apollo/client';
import {
  useGetAttendanceStatsEventsQuery,
  AttendanceInfoType,
} from 'generated/graphql';

import emphasizedDateTime from 'sharedHelpers/emphasizedDateTime';
import transformConnection from 'sharedHelpers/transformConnection';
import {
  isStaffUser,
  isClientEvent,
  hasAttendance,
} from 'sharedHelpers/typeNarrowing';
import getAttendanceStatsEvents from '../graphql/queries/getAttendanceStatsEvents';
import type { ClientEvent } from '../types/clientTypes';

interface UseAttendanceToolEventsParams {
  dashboardDates: {
    startDate: Date | number;
    endDate: Date | number;
  };
  unmarkedOnly: boolean;
  selectedStaffId: string;
}

export default ({
  dashboardDates,
  unmarkedOnly,
  selectedStaffId,
}: UseAttendanceToolEventsParams): {
  events: ClientEvent[];
  loading: boolean;
  refetchQuery: PureQueryOptions;
} => {
  const [events, setEvents] = useState<ClientEvent[]>([]);
  const roundedNow = startOfHour(Date.now());
  const variables = {
    startDate: Number(dashboardDates.startDate || Date.now()),
    lastActiveTimestamp: Number(subMonths(roundedNow, 6)) || Date.now(),
  };

  const { data, loading } = useGetAttendanceStatsEventsQuery({
    variables,
  });

  const refetchQuery = {
    query: getAttendanceStatsEvents,
    variables,
  };

  useEffect(() => {
    if (data?.getUser && isStaffUser(data.getUser) && data.getUser.events) {
      const eventsList = transformConnection(data.getUser.events);

      setEvents(
        eventsList
          .filter(isClientEvent)
          .filter(
            (event) =>
              isBefore(emphasizedDateTime(event), dashboardDates.endDate) &&
              isAfter(emphasizedDateTime(event), dashboardDates.startDate)
          )

          .filter((event) =>
            selectedStaffId ? event.staffId === selectedStaffId : true
          )
          .filter((event) =>
            unmarkedOnly
              ? hasAttendance(event) &&
                event.attendanceInfo === AttendanceInfoType.Blank
              : true
          )
          .filter((e) => e)
      );
    }
  }, [data, unmarkedOnly, selectedStaffId, dashboardDates]);

  return { events, loading, refetchQuery };
};
