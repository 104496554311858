import React from 'react';
import { Heading, Flex, Skeleton } from '@chakra-ui/react';
import StatusBar from 'sharedComponents/StatusBar';

interface DetailPanelHeaderProps {
  title?: string;
  miniBar: boolean;
  clientId: string;
}

function DetailPanelHeader(props: DetailPanelHeaderProps): JSX.Element {
  const { title, miniBar, clientId } = props;
  return (
    <Skeleton isLoaded={title !== 'undefined undefined'}>
      <Flex
        direction="row"
        flexWrap="wrap"
        paddingTop={3}
        paddingBottom={2}
        paddingLeft={9}
        paddingRight={8}
      >
        <Heading
          size="lg"
          color="brand.blue1"
          textAlign="left"
          fontWeight="normal"
          paddingBottom={2}
          paddingRight={6}
          width={miniBar ? 'fit-content' : '100%'}
        >
          {title}
        </Heading>

        <StatusBar miniBar={miniBar} clientId={clientId} />
      </Flex>
    </Skeleton>
  );
}

DetailPanelHeader.defaultProps = {
  title: '',
};

export default DetailPanelHeader;
