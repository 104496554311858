import { useState, useEffect } from 'react';

import {
  useGetClientsDashboardStatsLazyQuery,
  GetClientsDashboardStatsQuery,
  StatusType,
} from 'generated/graphql';
import useIdentifyUser from 'sharedHooks/useIdentifyUser';
import useStaffMemberList from 'sharedHooks/useStaffMemberList';

interface UseClientsDashboardStats {
  startDate: Date;
  endDate: Date;
  selectedStaffId?: string;
}

export type AllStatsType = GetClientsDashboardStatsQuery['getStats'];

const blankStats = {
  attendanceDonutEvents: {
    totalEvents: {
      attended: 0,
      missed: 0,
      unknown: 0,
    },
  },
  attendanceTidbitsEvents: {
    courtDate: {
      attended: 0,
      total: 0,
    },
    office: {
      attended: 0,
      total: 0,
    },
    virtual: {
      attended: 0,
      total: 0,
    },
    checkIn: {
      attended: 0,
      total: 0,
    },
  },
  engagementTripleStackMessages: {
    fromStaff: 0,
    fromEndUser: 0,
    fromPlatform: 0,
  },

  engagementTidbitDeliveredReminders: {
    fromPlatform: 0,
  },
  engagementTidbitAllReminders: {
    fromPlatform: 0,
  },
  engagementTidbitClients: {
    hasMobileApp: 0,
    reachableBySms: 0,
    unreachable: 0,
    sentMessage: 0,
    total: 0,
  },
};

function useClientsDashboardStats({
  startDate,
  endDate,
  selectedStaffId,
}: UseClientsDashboardStats): {
  allStats: AllStatsType;
  loading: boolean;
} {
  const {
    organization: { id: organizationId },
    id: currentUserId,
    role,
  } = useIdentifyUser();
  const [allStats, setAllStats] = useState<AllStatsType>(blankStats);
  const [fetch, { data, loading }] = useGetClientsDashboardStatsLazyQuery();
  const staffList = useStaffMemberList();
  let staffIds: string[] = [];

  if (role === 'staff' && currentUserId) {
    staffIds = [currentUserId];
  } else {
    staffIds = selectedStaffId
      ? [selectedStaffId]
      : staffList.map((staff) => staff.id);
  }

  useEffect(() => {
    if (organizationId && currentUserId) {
      const onlyAssignedTo = !!selectedStaffId;
      const sharedVariables = {
        organizationId,
        staffIds,
        onlyAssignedTo,
        startTimestamp: Number(startDate),
        endTimestamp: Number(endDate),
      };
      fetch({
        variables: {
          attendanceDonutEventsFilter: {
            ...sharedVariables,
          },
          attendanceTidbitsEventsFilter: {
            ...sharedVariables,
          },
          engagementTidbitsMessagesFilter: {
            ...sharedVariables,
            includeStatuses: [StatusType.Delivered],
          },
          engagementTidbitsTotalMessagesFilter: {
            ...sharedVariables,
            excludeStatuses: [StatusType.Skipped],
          },
          engagementTidbitsClientsFilter: {
            ...sharedVariables,
          },
          engagementTripleStackMessagesFilter: {
            ...sharedVariables,
            excludeStatuses: [StatusType.Skipped],
          },
        },
      });
    }
  }, [
    organizationId,
    currentUserId,
    selectedStaffId,
    staffIds.length,
    startDate,
    endDate,
  ]);

  useEffect(() => {
    if (data?.getStats) {
      setAllStats(data.getStats);
    }
  }, [data]);
  return { allStats, loading };
}

export default useClientsDashboardStats;
