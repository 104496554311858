import { gql } from '@apollo/client';

export default gql`
  query getApiClientsList($filter: GetClientsFilter!) {
    getClients(filter: $filter) {
      edges {
        node {
          id
        }
      }
    }
  }
`;
