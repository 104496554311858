import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { verifyPasswordResetCode } from 'sharedHelpers/firebaseAuth';
import ResetPasswordPage from './ResetPasswordPage';

function ValidateFirebaseFunction(): JSX.Element {
  const params = new URLSearchParams(window.location.search);
  const actionCode = params.get('oobCode');
  const mode = params.get('mode');

  const [validCode, setValidCode] = useState<boolean>(true);
  const [content, setContent] = useState<JSX.Element>(<></>);

  useEffect(() => {
    const validateCode = async () => {
      const userEmail = await verifyPasswordResetCode(actionCode || '');
      setValidCode(userEmail !== undefined);
      setContent(<ResetPasswordPage actionCode={actionCode || ''} />);
    };
    switch (mode) {
      case 'resetPassword':
        // Verify the password reset code is valid.
        validateCode();

        break;
      case 'recoverEmail':
        break;
      case 'verifyEmail':
        break;
      default:
        setValidCode(false);
        break;
    }
  }, []);

  if (!validCode) {
    return <Redirect to="/login" />;
  }
  return <>{content}</>;
}

export default ValidateFirebaseFunction;
