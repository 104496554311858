import React, { useEffect } from 'react';

import { RenderProps, useToast } from '@chakra-ui/react';

import GlobalErrorNotification from 'sharedComponents/GlobalErrorNotification';

interface UseGlobalErrorNotificationProps {
  showNotification: boolean;
  updateErrorState: (shouldShowError: boolean) => void;
}

function useGlobalErrorNotification({
  showNotification,
  updateErrorState,
}: UseGlobalErrorNotificationProps): void {
  const toast = useToast();
  const id = 'graphQLErrorToast';

  useEffect(() => {
    if (showNotification && !toast.isActive(id)) {
      toast({
        render: ({ onClose }: RenderProps) => (
          <GlobalErrorNotification
            closeErrorNotification={() => {
              onClose();
              updateErrorState(false);
            }}
          />
        ),
        duration: null,
        position: 'top',
        id,
      });
    }
  });
}

export default useGlobalErrorNotification;
