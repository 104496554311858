import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export default (props: IconProps): JSX.Element => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Icon viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M16 15.44L2.61 2.05C2.22 1.66 1.59 1.66 1.2 2.05C0.810001 2.44 0.810001 3.07 1.2 3.46L4 6.27V20C4 21.1 4.9 22 6 22H16C17.02 22 17.85 21.23 17.98 20.25L19 21.27C19.39 21.66 20.02 21.66 20.41 21.27C20.8 20.88 20.8 20.25 20.41 19.86L18 17.44L16 15.44ZM6 18V8.27L15.73 18H6ZM16 4V12.61L18 14.61V2C18 0.9 17.1 0 16 0H6C5.29 0 4.67 0.37 4.32 0.93L7.39 4H16Z"
    />
  </Icon>
);
